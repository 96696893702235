import { MessageManager } from "src/core/streaming/core/messageManager";
import { ErrorHandler } from "src/core/streaming/core/streamClient";
import {
  SextantDataSource,
  SuccessCallback
} from "src/core/streaming/pooledDataSource";
import {
  BgpNeighborSummary,
  Breakout,
  FabricTransaction,
  Fan,
  IpNextHop,
  IpRouteTable,
  L2Fdb,
  L3Table,
  PortConfig,
  PortCounters,
  PortNeighbor,
  ProxyConnectionState,
  ProxyConnectionStats,
  Psu,
  QsfpDomSensor,
  QsfpDomThreshold,
  QsfpInfo,
  RemedyAction,
  StpPortState,
  StpVlanPortState,
  StpVlanState,
  TempSensor,
  VlanVniMap
} from "src/gen/schema/schema/schema";
import { SchemaType } from "src/gen/schema/schema_path/schema_path";
import { PortAssert } from "src/pages/assertions/portAssertUtils";
import { DeviceAssert } from "src/pages/assertions/switchAssertUtils";
import { BaseDataSourceApi } from "./baseDataSourceApi";
import { Fabric, Node } from "src/gen/schema/models/models";
import { stringComparator } from "src/utils/comparator";

/* valid Sextant device id xx-xx-xx-xx-xx-xx */
const DEVICE_ID_FORMAT = /([a-f0-9]{2}-){5}([a-f0-9]{2})/i;
/* valid Sextant device id de-ad-xx-xx-xx-xx */
const IS_DEAD = /de-ad-([a-f0-9]{2}-?){4}/i;

/**
 * Takes a Fabric object and
 * returns all the "Node:deviceIds"
 * in a coma delimited string like
 * xx-xx-xx-xx-xx-xx,xx-xx-xx-xx-xx-xx
 * This is being used so hooks calling
 * Sextant apis have a more stable request
 * @param fabric
 * @param delimiter
 */
export const getSextantDeviceIds = (
  fabric: Fabric | undefined,
  delimiter: string = ","
): string => {
  if (fabric) {
    const nodes = [...fabric.nodes];
    return nodes
      .sort((a: Node, b: Node) => stringComparator(a.deviceId, b.deviceId))
      .map((c: Node) => c.deviceId)
      .join(delimiter);
  }
  return "";
};

const isValidDeviceId = (deviceId: string): boolean => {
  if (!DEVICE_ID_FORMAT.test(deviceId)) {
    console.trace("Device id has wrong format");
    return false;
  }
  if (IS_DEAD.test(deviceId)) {
    console.log(`Device id ${deviceId} is a temp id`);
    return false;
  }
  return true;
};

class SextantApi extends BaseDataSourceApi<SextantDataSource> {
  initDataSource(): SextantDataSource {
    return new SextantDataSource(window.cnc.sextantPool);
  }

  subscribeFabricVlanVniMap(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<VlanVniMap[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: validDeviceIds,
          types: [SchemaType.VlanVniMapType]
        }),
        MessageManager.transformer((s) => s.vlanVniMap),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeFabricIPMacAddress(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<L2Fdb[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: validDeviceIds,
          types: [SchemaType.L2FdbType]
        }),
        MessageManager.transformer((s) => s.l2Fdb),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeFabricLLDP(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<PortNeighbor[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: validDeviceIds,
          types: [SchemaType.PortNeighborType]
        }),
        MessageManager.transformer((s) => s.portNeighbor),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeFabricArpMac(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<L3Table[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: validDeviceIds,
          types: [SchemaType.L3TableType]
        }),
        MessageManager.transformer((s) => s.l3Table),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchPortConfig(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<PortConfig[]>,
    onError: ErrorHandler
  ) {
    if (isValidDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.PortConfigType]
        }),
        MessageManager.transformer((s) => s.portConfig),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeFabricPortConfig(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<PortConfig[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: deviceIds,
          types: [SchemaType.PortConfigType]
        }),
        MessageManager.transformer((s) => s.portConfig),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeStpPortState(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<StpPortState[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: deviceIds,
          types: [SchemaType.StpPortStateType]
        }),
        MessageManager.transformer((s) => s.stpPortState),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeStpVlanState(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<StpVlanState[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: deviceIds,
          types: [SchemaType.StpVlanStateType]
        }),
        MessageManager.transformer((s) => s.stpVlanState),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeStpVlanPortState(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<StpVlanPortState[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: deviceIds,
          types: [SchemaType.StpVlanPortStateType]
        }),
        MessageManager.transformer((s) => s.stpVlanPortState),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchFan(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<Fan[]>,
    onError: ErrorHandler
  ) {
    if (isValidDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.FanType]
        }),
        MessageManager.transformer((s) => s.fan),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchPsu(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<Psu[]>,
    onError: ErrorHandler
  ) {
    if (isValidDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.PsuType]
        }),
        MessageManager.transformer((s) => s.psu),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchTemp(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<TempSensor[]>,
    onError: ErrorHandler
  ) {
    if (isValidDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.TempSensorType]
        }),
        MessageManager.transformer((s) => s.tempSensor),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeRemedyAction(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<RemedyAction[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.RemedyActionType]
      }),
      MessageManager.transformer((s) => s.remedyAction),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeProxyConnectionState(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<ProxyConnectionState[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: validDeviceIds,
          types: [SchemaType.ProxyConnectionStateType]
        }),
        MessageManager.transformer((s) => s.proxyConnectionState),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeProxyConnectionStats(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<ProxyConnectionStats[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: deviceIds,
          types: [SchemaType.ProxyConnectionStatsType]
        }),
        MessageManager.transformer((s) => s.proxyConnectionStats),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeIpRouteTableType(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<IpRouteTable[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          fabricId: [fabricId],
          switchId: deviceIds,
          types: [SchemaType.IpRouteTableType]
        }),
        MessageManager.transformer((s) => s.ipRouteTable),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeIpNextHop(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<IpNextHop[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          fabricId: [fabricId],
          switchId: deviceIds,
          types: [SchemaType.IpNextHopType]
        }),
        MessageManager.transformer((s) => s.ipNextHop),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchPortNeighbor(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<PortNeighbor[]>,
    onError: ErrorHandler
  ) {
    if (isValidDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.PortNeighborType]
        }),
        MessageManager.transformer((s) => s.portNeighbor),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchPortState(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<Breakout[]>,
    onError: ErrorHandler
  ) {
    // no need to wrap as subscribeMultiSwitchPortState handles it.
    return this.subscribeMultiSwitchPortState(
      fabricId,
      [deviceId],
      onSuccess,
      onError
    );
  }

  subscribeMultiSwitchPortState(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<Breakout[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: validDeviceIds,
          types: [SchemaType.BreakoutType]
        }),
        MessageManager.transformer((s) => s.breakout),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchQsfpDomThresholdInfo(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<QsfpDomThreshold[]>,
    onError: ErrorHandler
  ) {
    if (isValidDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.QsfpDomThresholdType]
        }),
        MessageManager.transformer((s) => s.qsfpDomThreshold),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchPortCounters(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<PortCounters[]>,
    onError: ErrorHandler
  ) {
    if (isValidDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.PortCountersType]
        }),
        MessageManager.transformer((s) => s.portCounters),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeSwitchQsfpInfo(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<QsfpInfo[]>,
    onError: ErrorHandler
  ) {
    if (isValidDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.QsfpInfoType]
        }),
        MessageManager.transformer((s) => s.qsfpInfo),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeBgpPeerNeighbor(
    fabricId: string,
    deviceId: string[],
    onSuccess: SuccessCallback<BgpNeighborSummary[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        switchId: deviceId,
        types: [SchemaType.BgpNeighborSummaryType]
      }),
      MessageManager.transformer((s) => s.bgpNeighborSummary),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeSwitchOptics(
    fabricId: string,
    deviceId: string,
    onSuccess: SuccessCallback<QsfpDomSensor[]>,
    onError: ErrorHandler
  ) {
    if (isValidDeviceId(deviceId)) {
      return this.getDataSource().subscribe(
        fabricId,
        MessageManager.create({
          switchId: [deviceId],
          types: [SchemaType.QsfpDomSensorType]
        }),
        MessageManager.transformer((s) => s.qsfpDomSensor),
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeFabricTransactions(
    fabricId: string,
    onSuccess: SuccessCallback<FabricTransaction[]>,
    onError: ErrorHandler
  ) {
    return this.getDataSource().subscribe(
      fabricId,
      MessageManager.create({
        fabricId: [fabricId],
        types: [SchemaType.FabricTransactionType]
      }),
      MessageManager.transformer((s) => s.fabricTransaction),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  subscribeAllDeviceAsserts(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<DeviceAssert[]>,
    onError: ErrorHandler
  ): typeof MessageManager.unsubscribe {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      const req = MessageManager.create({
        switchId: validDeviceIds,
        types: [SchemaType.AllDeviceAssertTypes]
      });

      const transformer = MessageManager.transformer((series) => {
        if (series) {
          if (series.assertDeviceConnectedToCloud.length) {
            return series.assertDeviceConnectedToCloud;
          }
          if (series.assertDeviceConnectedToFabric.length) {
            return series.assertDeviceConnectedToFabric;
          }
          if (series.assertVlanHasTraffic.length) {
            return series.assertVlanHasTraffic;
          }
          if (series.assertStaticDefaultRouteExists.length) {
            return series.assertStaticDefaultRouteExists;
          }
          if (series.assertManagementPortConfigSame.length) {
            return series.assertManagementPortConfigSame;
          }
          if (series.assertDeviceResourceUsageBelowThreshold.length) {
            return series.assertDeviceResourceUsageBelowThreshold;
          }
          if (series.assertBgpPeerEstablished.length) {
            return series.assertBgpPeerEstablished;
          }
        }
        return [];
      });

      return this.getDataSource().subscribe(
        fabricId,
        req,
        transformer,
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }

  subscribeAllPortAsserts(
    fabricId: string,
    deviceIds: string[],
    onSuccess: SuccessCallback<PortAssert[]>,
    onError: ErrorHandler
  ) {
    const validDeviceIds = deviceIds.filter((id) => isValidDeviceId(id));
    if (validDeviceIds.length > 0) {
      const req = MessageManager.create({
        switchId: validDeviceIds,
        types: [SchemaType.AllPortAssertTypes]
      });

      const transformer = MessageManager.transformer((series) => {
        if (series) {
          if (series.assertPortLinkDown.length) {
            return series.assertPortLinkDown;
          }
          if (series.assertPortLinkUp.length) {
            return series.assertPortLinkUp;
          }
          if (series.assertPortConnectionSpeedMatch.length) {
            return series.assertPortConnectionSpeedMatch;
          }
          if (series.assertPortExpectedNeighbor.length) {
            return series.assertPortExpectedNeighbor;
          }
          if (series.assertPortConnectionSpeedMatch.length) {
            return series.assertPortConnectionSpeedMatch;
          }
          if (series.assertPortNotConnectedToFabric.length) {
            return series.assertPortNotConnectedToFabric;
          }
          if (series.assertPortSpeedConsistent.length) {
            return series.assertPortSpeedConsistent;
          }
        }
        return [];
      });

      return this.getDataSource().subscribe(
        fabricId,
        req,
        transformer,
        MessageManager.reducer,
        onSuccess,
        onError
      );
    }
    onSuccess([]);
    return MessageManager.unsubscribe;
  }
}

const Sextant = new SextantApi();
export { Sextant };
