import { ErrorHandler } from "src/core/streaming/core/streamClient";
import { SchemaType } from "src/gen/schema/schema_path/schema_path";
import {
  FabricConfig,
  FabricDevice,
  ManagementState
} from "src/gen/schema/schema/schema";
import {
  BilgeDataSource,
  SuccessCallback
} from "src/core/streaming/pooledDataSource";
import { MessageManager } from "src/core/streaming/core/messageManager";
import { BaseDataSourceApi } from "./baseDataSourceApi";

/**
 * Currently there is only 1 global bilge
 * stream. Make sure that we only use one
 * to makes calls by using a consistent ID.
 */
export const BILGE_ID: string = "all_org";

export class BilgeApi extends BaseDataSourceApi<BilgeDataSource> {
  initDataSource(): BilgeDataSource {
    return new BilgeDataSource(window.cnc.bilgePool);
  }

  orgFabric(
    onSuccess: SuccessCallback<FabricConfig[]>,
    onError: ErrorHandler
  ): () => void {
    return this.getDataSource().subscribe(
      BILGE_ID, // this id is only used to get the correct stream
      MessageManager.create({
        objectId: ["all_org"],
        types: [SchemaType.FabricConfigType]
      }),
      MessageManager.transformer((ds) => ds.fabricConfig),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  orgFabricDevice(
    onSuccess: SuccessCallback<FabricDevice[]>,
    onError: ErrorHandler
  ): () => void {
    return this.getDataSource().subscribe(
      BILGE_ID, // this id is only used to get the correct stream
      MessageManager.create({
        objectId: ["all_org"],
        types: [SchemaType.FabricDeviceType]
      }),
      MessageManager.transformer((ds) => ds.fabricDevice),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }

  detectedDevicesConfiguration(
    onSuccess: SuccessCallback<ManagementState[]>,
    onError: ErrorHandler
  ): () => void {
    return this.getDataSource().subscribe(
      BILGE_ID, // this id is only used to get the correct stream
      MessageManager.create({
        objectId: ["all_org"],
        types: [SchemaType.ManagementStateType]
      }),
      MessageManager.transformer((ds) => ds.managementState),
      MessageManager.reducer,
      onSuccess,
      onError
    );
  }
}
const Bilge = new BilgeApi();
export { Bilge };
