import { Observable } from "./observable";
import { logger } from "../../globals";

type EVENT = "reconnect" | "connect" | "open" | "message" | "error" | "close";
const HEART_BEAT = 30000;
const MAX_COOLDOWN = 10000;
const KEEP_ALIVE_MSG = "Keep Alive";
const KEEP_ALIVE_TYPE = "echoResponse";

const isKeepAlive = (msg: string): boolean => {
  return msg.includes(KEEP_ALIVE_TYPE) && msg.includes(KEEP_ALIVE_MSG);
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export class Socket extends Observable<any> {
  static RECONNECT: EVENT = "reconnect";
  static CONNECT: EVENT = "connect";
  static OPEN: EVENT = "open";
  static MESSAGE: EVENT = "message";
  static ERROR: EVENT = "error";
  static CLOSE: EVENT = "close";

  private timerId: NodeJS.Timeout | undefined;

  public reconnect: boolean = true;
  public connected: boolean = false;
  /* Private instance variables */
  private socket: WebSocket | undefined;
  /**
   * queue will buffer messages if the
   * websocket is not connected. These
   * messages will be sent when the
   * websocket is connected.
   */
  private queue: string[] = [];
  private cooldown = 500;

  constructor() {
    super();
    this.addEvents([Socket.ERROR, Socket.OPEN, Socket.MESSAGE, Socket.CLOSE]);
  }

  init(url: string) {
    try {
      const readyState = this.socket?.readyState;
      switch (readyState) {
        /**
         * There exists a race condition
         * where if we try to send a flurry
         * of requests the socket will
         * open again and again while it
         * is connecting. If we are already
         * connecting just return and pass the
         * request. it will be queued and sent
         * when the socket is open.
         *
         * Also, a small chance it
         * opened while this was sent.
         */
        case WebSocket.CONNECTING:
        case WebSocket.OPEN:
          return;
        default: {
          const ws = new WebSocket(url);
          ws.onopen = () => {
            this.connected = true;
            this.fireEvent(Socket.OPEN, true);
            this.queue.forEach((msg: string) => {
              if (msg) {
                this.send(msg);
              }
            });
            /* once the messages have been sent reset the queue */
            this.queue = [];
            this.cooldown = 500;
            this.keepAlive();
          };
          ws.onmessage = (m: MessageEvent<string>) => {
            if (!isKeepAlive(m.data)) {
              this.fireEvent(Socket.MESSAGE, m);
            }
          };
          ws.onerror = (e: Event) => {
            this.fireEvent(Socket.ERROR, e);
            logger.error(e);
          };
          ws.onclose = (e: CloseEvent) => {
            this.connected = false;
            this.queue.length = 0;
            this.fireEvent(Socket.CLOSE, e);
          };

          this.on(
            Socket.CLOSE,
            () => {
              if (this.reconnect) {
                /**
                 * What is the behavior if
                 * we get disconnected? do
                 * we have to rebuild the
                 * subscriptions or are they
                 * kept with the session?
                 */
                setTimeout(() => {
                  this.init(url);
                  this.fireEvent(Socket.RECONNECT, true);
                  /**
                   * Each time you try to reconnect double
                   * the cooldown interval up to 10 seconds,
                   * so we do not spam the server if you
                   * cannot connect.
                   */
                  this.cooldown = Math.max(this.cooldown * 2, MAX_COOLDOWN);
                }, this.cooldown);
              }
            },
            this
          );
          this.socket = ws;
        }
      }
    } catch (e) {
      logger.error(e);
    }
  }

  close() {
    clearInterval(this.timerId);
    this.reconnect = false;
    this.socket?.close();
  }

  /**
   * Keeps the WebSocket connection alive by sending periodic ping messages.
   *
   * This method sets up an interval that sends a ping message
   * to the WebSocket server every 30 seconds. The interval is
   * stored in `this.timerId` so it can be cleared later if needed.
   */
  keepAlive() {
    const socket = this.socket;
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    this.timerId = setInterval(() => {
      if (socket) {
        const requestTs = new Date();
        const msg = JSON.stringify(
          {
            echoRequest: {
              numResponses: 1,
              message: KEEP_ALIVE_MSG,
              waitSecs: 0,
              requestTs
            }
          },
          null,
          "  "
        );
        socket.send(msg);
      }
    }, HEART_BEAT);
  }

  send(msg: string) {
    if (this.connected && this.socket?.readyState === WebSocket.OPEN) {
      try {
        this.socket?.send(msg);
      } catch (e: unknown) {
        // we do nothing with this.
      }
    } else {
      /**
       * Since there is a chance that a
       * component might send a message
       * before the socket is open, this
       * will add queue up those messages,
       * they will be sent when the
       * socket opens.
       */
      this.queue.push(msg);
    }
  }
}
