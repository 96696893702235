// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/models.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  Breakout,
  CableType,
  cableTypeFromJSON,
  cableTypeToJSON,
  ConfigOrigin,
  configOriginFromJSON,
  configOriginToJSON,
  ConfigType,
  configTypeFromJSON,
  configTypeToJSON,
  ConnectedState,
  connectedStateFromJSON,
  connectedStateToJSON,
  FabricTopology,
  fabricTopologyFromJSON,
  fabricTopologyToJSON,
  LacpMode,
  lacpModeFromJSON,
  lacpModeToJSON,
  NodeRole,
  nodeRoleFromJSON,
  nodeRoleToJSON,
  NodeType,
  nodeTypeFromJSON,
  nodeTypeToJSON,
  OsType,
  osTypeFromJSON,
  osTypeToJSON,
  PortRole,
  portRoleFromJSON,
  portRoleToJSON,
} from "./fabric";
import { Annotation, FabricType, fabricTypeFromJSON, fabricTypeToJSON } from "./global";
import {
  InterfaceStp,
  Metadata,
  NetworkInterface,
  PortConnection,
  PortEndpoint,
  RouteInfo,
  Svi,
  VlanMember,
  VniDhcp,
} from "./types";

export const protobufPackage = "models";

/**
 * NetworkPort encapsulates properties of a physical network port.
 *
 * INTERNAL
 *
 * NetworkPort is always on a Linecard. Each Linecard may contain dozens of ports.
 * A Node may have multiple linecards. Port name uniquely identifies a port within a node.
 * Port name is in the following format:
 *    Ethernet{{ Linecard }}_{{ Index }}_{{ Breakout index }}
 *
 * A NetworkPort maybe broken out into multiple NetworkPort objects. This is desirable
 * when NetworkPort has high bandwidth, and attached hosts have interfaces with lower
 * bandwidth. For example, a 400Gbps NetworkPort maybe broken out into four 100Gbps
 * NetworkPort objects. When a network port is in break-out mode, then there can be many
 * NetworkPort objects with the same Index. For example, following NetworkPort objects are
 * in break-out mode on port index 1.
 * E.g.:
 *    Ethernet1_1_1: Index = 1, Breakout = 1
 *    Ethernet1_1_4: Index = 1, Breakout = 4
 *    Ethernet1_2_2: Index = 2, Breakout = 2
 */
export interface NetworkPort {
  /**
   * The unique identifier of NetworkPort. Identifier is required to update an existing
   * NetworkPort. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /**
   * The canonical name of NetworkPort. Name must have a prefix of Ethernet (E.g. Ethernet1_1).
   * NetworkPort name cannot be modified.
   */
  name: string;
  /** The identifier of th Node that owns the NetworkPort. NodeId is readonly, and cannot be modified. */
  nodeId: string;
  /** A user-defined description of NetworkPort. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if NetworkPort is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the NetworkPort object. */
  metadata:
    | Metadata
    | undefined;
  /**
   * Index of the NetworkPort on the linecard. Port index is one based. Index remains
   * same for all NetworkPort objects in break-out mode. Index is readonly.
   */
  index: number;
  /** Indicates that port is in a break-out mode. Breakout flag is readonly. */
  breakout: boolean;
  /**
   * Breakout index of the port. Breakout index is not set when port is not in breakout mode.
   * Breakout index is readonly.
   */
  breakoutIndex: number;
  /** Linecard index of the port. Linecard is readonly. */
  linecard: number;
  /**
   * The identifier of VRF to which this port belongs to. VRF identifier is set only for
   * routed ports that are added to a VRF.
   */
  vrfId: string;
  /**
   * VlanIds defines the identifiers Vlan in which this port is a member of.
   * VlanIds is readonly. VlanIds is set by GetNodesPorts API.
   */
  vlanIds: number[];
  /**
   * Vnis defines the identifiers Vni to which this port is attached to.
   * Vnis is readonly. Vnis is set by GetNodesPorts API.
   */
  vnis: number[];
  /**
   * A set of roles of the NetworkPort. Port roles are mandatory, and must
   * have exactly one role in it.
   */
  roles: PortRole[];
  /**
   * Indicates that port is in link-down state. In this mode, AdminState is UP,
   * however, port may not forward any traffic to external hosts. LinkDown state
   * is not supported for Fabric ports.
   */
  linkDown: boolean;
  /**
   * Port's IPv4 addresses. The service supports up to 2 IPv4 addresses, and every
   * address must be a network address. Meaning, address must be in CIDR format
   * with CIDR < 32. IPv4 address is valid only for routed ports.
   */
  ipv4Addresses: string[];
  /**
   * Port's IPv6 addresses. The service supports up to 2 IPv6 addresses, and every
   * address must be a network address. Meaning, address must be in CIDR format
   * with CIDR < 128. IPv6 address is valid only for routed ports.
   */
  ipv6Addresses: string[];
  /**
   * Number of SubInterface objects attached to the NetworkPort object.
   * This property is readonly.
   */
  subInfCount: number;
  /**
   * Spanning tree config for the port. Config is used only when port is a member
   * of a Vlan.
   */
  stp:
    | InterfaceStp
    | undefined;
  /** User provided cable model name (PID). */
  pluggable: string;
  /**
   * MTU of the NetworkPort. Default value is 9100. MTU value must be
   * between 1500 and 9100.
   */
  mtu: number;
  /** Maximum speed of the NetworkPort as reported by system (readonly) (E.g. 10G). */
  maxSpeed: string;
  /**
   * Configurable speed of the NetworkPort (E.g. 10G). Note that port speed cannot
   * be set for breakout ports.
   */
  speed: string;
  /**
   * User may increment ForceCounter attribute to force a config update when there
   * are no real changes. This maybe needed to startup (un-shut) a port when STP
   * triggers port shutdown.
   */
  forceCounter: number;
}

/** ManagementPort encapsulates the configuration of a management port on a device. */
export interface ManagementPort {
  /**
   * The unique identifier of the ManagementPort. Identifier is required to update an existing ManagementPort.
   * If not provided, then assume the port is to be created.
   */
  id: string;
  /**
   * The identifier of the Node that owns the ManagementPort. NodeId is readonly,
   * and cannot be modified.
   */
  nodeId: string;
  /** A name for the port, eth0. This is a read-only attribute. */
  name: string;
  /** A description of the configuration. This is a read-only attribute. */
  description: string;
  /** The IPv4 address for the port. IPv4 address must be a host IPv4 address. */
  ipv4Address: string;
  /** The IPv4 gateway address for the port. */
  ipv4Gateway: string;
  /** The IPv6 address for the port. IPv6 address must be a host IPv6 address. */
  ipv6Address: string;
  /** The IPv6 gateway address for the port. */
  ipv6Gateway: string;
  /** A list of one or more DNS addresses for the port. */
  dnsAddresses: string[];
  /** A http address for a configured proxy for the port. */
  proxyAddress: string;
  /** A user name for the proxy server if provided. */
  proxyUsername: string;
  /** A reference to a set of credentials for the proxy. */
  proxyCredentialsId: string;
  /** A password to be used to authenticate to the proxy. Once set, this attribute is not returned. */
  proxyPassword: string;
  /** The administrative state of the port. */
  enabled: boolean;
  /** The connected state denoting if the port has ever successfully connected to the service. */
  connectedState: ConnectedState;
  /** Determines if the IpV4 configuration is static or from DHCP */
  ipv4ConfigType: ConfigType;
  /** Determines if the IpV6 configuration is static or from DHCP */
  ipv6ConfigType: ConfigType;
  /** NTP servers. */
  ntpAddresses: string[];
  /** A list of one or more cloud URLs for the device. */
  cloudUrls: string[];
  /**
   * ConfigOrigin defines the source of the configuration, either from the cloud or the device.
   * This is a read-only attribute.
   */
  configOrigin: ConfigOrigin;
  /**
   * Readonly revision metadata of the ManagementPort object. Metadata is not needed
   * for object creation or modification.
   */
  metadata:
    | Metadata
    | undefined;
  /**
   * An indicator that the password should be cleared. The unencrypted password is not returned
   * to the caller and this is used to differentiate between clearing the password and not changing it.
   */
  setProxyPassword: boolean;
  /** A list of IP addresses or domain names that should not be proxied. */
  noProxy: string[];
}

/**
 * PortChannel represents a set of physical ports in a PortChannel or LACP config.
 * PortChannel name is unique within a fabric. The service automatically assigns a
 * name if a name is not specified during PortChannel creation.
 * NOTES:
 * - PortChannel object must have HOST_PORT or ROUTED_PORT as role.
 * - PortChannel members cannot be part of Vlans.
 * - PortChannel member ports must have MLAG_PORT as port role.
 */
export interface PortChannel {
  /**
   * The unique identifier of PortChannel. Identifier is required to update an existing
   * PortChannel. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The name of PortChannel. Name must have a prefix of PortChannel (E.g. PortChannel1). */
  name: string;
  /** A user-defined description of PortChannel. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if PortChannel is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the portchannel object. */
  metadata:
    | Metadata
    | undefined;
  /** The identifier of Fabric to which this PortChannel belongs to. FabricId is mandatory. */
  fabricId: string;
  /**
   * A set of roles of the PortChannel. Roles must have exactly one entry, and it
   * must be either HOST_PORT or ROUTED_PORT.
   */
  roles: PortRole[];
  /**
   * A set of member NetworkPort objects (E.g. Ethernet1_1, Ethernet1_4 etc.).
   * There must be at least two members.
   */
  members: PortEndpoint[];
  /** LACP mode. */
  lacpMode: LacpMode;
  /** MTU of the PortChannel. Value must be between 1500 and 9600. */
  mtu: number;
  /** Minimum number of active links needed for PortChannel to operate. */
  minLinks: number;
  /**
   * IPv4 addresses of PortChannel. The service supports up to 2 IPv4 addresses, and
   * every address must be a network address. Meaning, address must be in CIDR
   * format with CIDR < 32. IPv4 address is valid only for routed PortChannel.
   */
  ipv4Addresses: string[];
  /**
   * IPv6 addresses of PortChannel. The service supports up to 2 IPv6 addresses, and
   * every address must be a network address. Meaning, address must be in CIDR
   * format with CIDR < 128. IPv6 address is valid only for routed PortChannel.
   */
  ipv6Addresses: string[];
  /** MAC address used for EVPN multi-homing. */
  sysMac: string;
  /**
   * Spanning tree config for the port. Config is used only when port is a member
   * of a Vlan. Note that STP is supported only when PortChannel is un LAG mode.
   */
  stp:
    | InterfaceStp
    | undefined;
  /**
   * The identifier of VRF to which this PortChannel belongs to. VRF identifier is set
   * only for routed PortChannel that are added to a VRF.
   */
  vrfId: string;
  /**
   * VlanIds defines the identifiers Vlan in which this PortChannel is a member of.
   * VlanIds is readonly.
   */
  vlanIds: number[];
  /**
   * Vnis defines the identifiers Vni in which this PortChannel is a member of.
   * Vnis is readonly.
   */
  vnis: number[];
  /** Maximum speed of the PortChannel (readonly) (E.g. 10G). */
  maxSpeed: string;
}

/**
 * SubInterface encapsulates properties of a sub-port or sub-interface. SubInterface provides
 * logical division of a physical interface. SubInterface cannot be added to a NetworkPort or
 * a PortChannel attached to Vlans. SubInterface maybe added a routed port or routed PortChannel.
 */
export interface SubInterface {
  /**
   * The unique identifier of SubInterface. Identifier is required to update an existing
   * SubInterface. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /**
   * The user-defined name of the SubInterface. SubInterface name is unique, and must be
   * in the format (<Ethernet1_4_1|<PortChannel0>)[.]<ID>
   */
  name: string;
  /** A user-defined description of SubInterface. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if SubInterface is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the object. */
  metadata:
    | Metadata
    | undefined;
  /** The identifier of Fabric to which this SubInterface belongs to. FabricId is mandatory. */
  fabricId: string;
  /** The identifier of the Node where SubInterface is located. NodeId is mandatory. */
  nodeId: string;
  /** Vlan identifier of SubInterface. */
  vlanId: number;
  /** The identifier of VRF to which this SubInterface belongs to. */
  vrfId: string;
  /** The name of parent interface. This property is readonly. */
  parent: string;
  /**
   * SubInterface's IPv4 addresses. The service supports up to 2 IPv4 addresses, and every
   * address must be a network address. Meaning, address must be in CIDR format
   * with CIDR < 32.
   */
  ipv4Addresses: string[];
  /**
   * SubInterface's IPv6 addresses. The service supports up to 2 IPv6 addresses, and every
   * address must be a network address. Meaning, address must be in CIDR format
   * with CIDR < 128.
   */
  ipv6Addresses: string[];
}

/**
 * Node encapsulates properties of a blueprint fabric node. A node has
 * the following properties and child objects.
 *  - roles: A set of roles that defines what the node is for.
 *  - ports: Physical ports of node. If a port is in break-out mode,
 *    then the break-out ports are treated as physical ports of the node.
 *  - routerId: The service allocated router identifier meant for BGP sessions.
 */
export interface Node {
  /**
   * The unique identifier of the Node. Identifier is required to update an existing
   * node. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /**
   * The user-defined name of the Node. Node name is unique, and must be a FQDN
   * compliant, single label hostname.
   */
  name: string;
  /** A user-defined description of the node. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if node is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the node object. */
  metadata:
    | Metadata
    | undefined;
  /** Serial number of the node. Serial number cannot be nullified. */
  serialNumber: string;
  /** Hardware model name of the node. Model name cannot be nullified. */
  modelName: string;
  /** Operating system type of the node (E.g. SONiC, NEXUS etc.). OsType cannot be nullified. */
  osType: OsType;
  /** Node type (E.g. BLUEPRINT, SWITCH etc.). Node type is readonly. */
  nodeType: NodeType;
  /** Position of Node in the fabric's node list. Position is readonly. */
  position: number;
  /** The identifier of Fabric to which the node belongs. Fabric identifier is readonly. */
  fabricId: string;
  /** The identifier of bound (attached) physical device. Device identifier is readonly. */
  deviceId: string;
  /** A user-defined location string (E.g. SJC20). */
  location: string;
  /**
   * A set of roles of the Node. Node roles are mandatory. A Node can
   * have either LEAF or SPINE role, but not both.
   */
  roles: NodeRole[];
  /** NetworkPort objects of the node. Ports are optional for node updates. */
  ports: NetworkPort[];
  /**
   * Fabric connection objects of the node. Note that connection list do not have
   * host (endpoint) connections.
   */
  connections: PortConnection[];
  /** Management ports for the node. */
  managementPorts: ManagementPort[];
  /**
   * Port breakout configs of the node. Breakouts are set only when entire Fabric
   * config is queried.
   */
  breakouts: PortBreakout[];
}

/**
 * Fabric is a collection of nodes and port interconnections.
 *  - nodes: A set of nodes classified as LEAF, SPINE.
 *  - connections: Physical port interconnections between nodes.
 */
export interface Fabric {
  /**
   * The unique identifier of Fabric. Identifier is required to update an existing
   * Fabric. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of Fabric. Fabric name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of Fabric. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if Fabric is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the Fabric object. */
  metadata:
    | Metadata
    | undefined;
  /** Fabric topology. Topology can be either SPINE_LEAF, MESH or SWITCH. */
  topology: FabricTopology;
  /** Type of fabric (BLUEPRINT or INVENTORY) */
  fabricType: FabricType;
  /** Expected fabric cable type. */
  cableType: CableType;
  /** Expected pluggable (QSFP or product identifier) */
  pluggable: string;
  /** A user-defined location string (E.g. SJC20). */
  location: string;
  /** Physical address line (E.g. 320 My Street) */
  address: string;
  /** City name (E.g. San Jose) */
  city: string;
  /** Country code (E.g. US) */
  country: string;
  /**
   * A set of nodes that are part of Fabric. Nodes are optional during Fabric
   * modification. If provided, Nodes set must be a full set.
   */
  nodes: Node[];
  /**
   * A set of Fabric interconnection objects. Connections are optional during Fabric
   * modification. If provided, Connections set must be a full set.
   */
  connections: PortConnection[];
  /** Optional static anycast gateway's MAC address. */
  sagMac: string;
  /** Number of active config candidates. NumCandidates is readonly. */
  numCandidates: number;
  /**
   * A set of nodes that are discovered but not unrecognized by the service. GetFabrics
   * API does not return unrecognized nodes. However, GetFabricInventories API sets
   * Unrecognized nodes when it finds non-service switches.
   */
  unrecognized: Node[];
}

/**
 * StaticRoutes encapsulates a set of StaticRoute objects. StaticRoutes maybe
 * defined at tenancy level, and then applied to multiple Vrf objects.
 */
export interface StaticRoutes {
  /**
   * The unique identifier of StaticRoutes. Identifier is required to update an existing
   * StaticRoutes. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of the StaticRoutes. StaticRoutes name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of StaticRoutes. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if StaticRoutes is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the StaticRoutes object. */
  metadata:
    | Metadata
    | undefined;
  /** The identifier of Fabric to which this StaticRoutes belong. Fabric identifier is mandatory. */
  fabricId: string;
  /** A set of StaticRouteInfo specifications. */
  routes: RouteInfo[];
  /** The identifier of Vrf to which this StaticRoutes belong. Vrf identifier is immutable once set. */
  vrfId: string;
}

/**
 * Vrf encapsulates properties of a virtual-routing-and-forwarding object.
 * Vrf allows having more than one routing tables on a single switch. Vrf
 * implements VRF-lite (IP VRF), or VRF without MPLS.
 *
 *  1) A Vrf is a tenant object, and is inactive until it is attached to a Vni.
 *  2) There is a one-to-one relationship between Vrf and a L3VNI.
 *  3) A Vrf maybe attached to several L2VNI objects.
 */
export interface Vrf {
  /**
   * The unique identifier of Vrf. Identifier is required to update an existing
   * Vrf. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of the Vrf. Vrf name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of Vrf. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if Vrf is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the Vrf object. */
  metadata:
    | Metadata
    | undefined;
  /** The identifier of Fabric to which this Vrf belong. Fabric identifier is mandatory. */
  fabricId: string;
  /** A set of interfaces that are part of Vrf. */
  interfaces: NetworkInterface[];
  /** Indicates that Vrf is a default (aut-created) VRF (readonly). */
  isDefault: boolean;
  /** VNI of the parent L3VNI (readonly). */
  vni: number;
  /** Optional ASN of the VRF to be used for BGP peering. */
  asn: number;
  /**
   * IPv4 address range of Loopbacks. Loopbacks are created when DHCP relays
   * are attached to VRF. Loopbacks may also be created for other purposes.
   * Caller must provide an IP range in CIDR format. CIDR range should be large
   * enough to created Loopbacks on all switches in the fabric.
   * E.g. 10.10.10.1/24
   */
  ipv4Loopbacks: string[];
  /**
   * IPv6 address range of Loopbacks. Caller must provide an IP range in CIDR format.
   * CIDR range should be large enough to created Loopbacks on all switches in the fabric.
   * E.g. 3000::1/112
   */
  ipv6Loopbacks: string[];
  /**
   * Globally unique route-target of VRF. RouteTarget is readonly.
   * FIXME: This field should not be exposed via REST API.
   */
  routeTarget: string;
}

/**
 * Vlan represents a Vlan in a tenancy. Vlan encapsulates a VLAN identifier, and
 * a set of network ports from leaf node. Vlan is locally significant, meaning
 * a Vlan is meaningful only within the switch. The service allows creating "global"
 * Vlan, however it is meaningful only in the context of a switch.
 *
 *          VLAN-ID      Ports
 *         ---------    -------
 * Leaf0:
 *           10         [Ethernet1_1, Ethernet1_8]
 *           20         [Ethernet1_4, Ethernet1_12]
 * Leaf1:
 *           10         [Ethernet1_16, Ethernet1_32]
 *           20         [Ethernet1_1, Ethernet1_12]
 *
 * NOTES: Vlan always belong to a Vni.
 */
export interface Vlan {
  /**
   * The unique identifier of Vlan. Identifier is required to update an existing
   * Vlan. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of the Vlan. Vlan name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of Vlan. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if Vlan is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the Vlan object. */
  metadata:
    | Metadata
    | undefined;
  /** The identifier of Fabric to which this Vlan belong. */
  fabricId: string;
  /**
   * The Vlan identifier. Vlan identifier must be between 2 and 3600. The service
   * reserves Vlan identifier between 3601 and 4096.
   */
  vlanId: number;
  /**
   * Vlan's SVI objects. SVI objects are optional for Vlan updates. However,
   * if SVI objects are supplied, then it must be a full set.
   */
  svis: Svi[];
  /** A set of NetworkPort objects that are members of this Vlan. */
  members: VlanMember[];
  /** Vni to which this Vlan is attached to. Vni is readonly. */
  vni: number;
  /** Vrf object attached to this Vlan. Vrf identifier is readonly. */
  vrfId: string;
}

/**
 * Vni encapsulates properties of a L2/L3 logical network (VNI). Vni has two distinct
 * modes - L2VNI and L3VNI. In L3VNI mode, Vni allows bidirectional flow of traffic
 * between a Layer-2 bridged network and a Layer-3 routed network. Whereas, in L2VNI
 * mode, Vni allows traffic between all participating ports through a subnet.
 *
 * INTERNAL
 *
 * Vni spans two or more ports from multiple nodes. In L3VNI mode, Vni also has IP
 * interfaces on all nodes. Each Node may have separate IP interface or a single
 * anycast gateway IP address that spans all nodes. L3VNI also has a default VRF that
 * gets added automatically when Vni is created. User may not change default VRF.
 *
 *   Leaf0::Ethernet1_1 ---->
 *                          | ---> Vlan --> Vni (VRF in L3VNI mode)
 *   Leaf1::Ethernet1_8 ---->
 *
 * The service automatically allocates a VNI for Vni object. User may set a VNI during
 * Vni creation. However, user may not change an existing VNI.
 *
 * Tabular representation of VNIs on a fabric.
 * +--------+--------+---------+---------------+-----------------------------------------------+
 * |  VNI   |  VLAN  | Switch  |     Port      | Comments                                      |
 * +--------+--------+---------+---------------+-----------------------------------------------+
 * |  5000  |   0    |    *    |       *       | Host ports from all switches are assigned to  |
 * |        |        |         |               | VNI 5000 as untagged members. VNI 5000 gets   |
 * |        |        |         |               | an internal VLAN tag.                         |
 * +--------+--------+---------+---------------+-----------------------------------------------+
 * |  6000  |   60   |    *    |       *       | Host ports from all switches are assigned to  |
 * |        |        |         |               | VNI 6000. VNI 6000 has a VLAN tag of 60 on    |
 * |        |        |         |               | all switches.                                 |
 * +--------+--------+---------+---------------+-----------------------------------------------+
 * |  6000  |   60   |    *    |  Ethernet1_3  | Ethernet1_3 from all switches are assigned to |
 * |        |        |         |               | VNI 6000 with a VLAN tag of 60.               |
 * +--------+--------+---------+---------------+-----------------------------------------------+
 * |  6000  |   61   |    2    |  Ethernet1_4  | Ethernet1_4 from switch-2 is assigned to VNI  |
 * |        |        |         |               | 6000 with a VLAN tag of 61. VNI 6000 on       |
 * |        |        |         |               | switch-2 will have just one port in it.       |
 * +--------+--------+---------+---------------+-----------------------------------------------+
 * |  6000  |   61   |    2    |  Ethernet1_5  | Ethernet1_5 from switch-2 is assigned to VNI  |
 * |        |        |         |               | 6000 with a VLAN tag of 61. On switch-2, VNI  |
 * |        |        |         |               | 6000 will have Ethernet1_4 and Ethernet1_5 as |
 * |        |        |         |               | member ports with a VLAN tag of 61.           |
 * +--------+--------+---------+---------------+-----------------------------------------------+
 * |  7000  |   70   |    *    |  Ethernet1_5  | Ethernet1_5 from all switches are assigned to |
 * |        |        |         |               | VNI 7000 with a VLAN tag of 70.               |
 * +--------+--------+---------+---------------+-----------------------------------------------+
 * |  8000  |   0    |    *    |  Ethernet1_6  | Ethernet1_6 from all switches are assigned to |
 * |        |        |         |               | VNI 8000 as untagged member. VNI 8000 gets an |
 * |        |        |         |               | internal VLAN tag. Ethernet1_6 is removed     |
 * |        |        |         |               | from VNI 5000 on all switches. .              |
 * +--------+--------+---------+---------------+-----------------------------------------------+
 */
export interface Vni {
  /**
   * The unique object identifier of Vni. Identifier is required to update an existing
   * Vni. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of the Vni. Vni name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of Vni. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if Vni is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the object. */
  metadata:
    | Metadata
    | undefined;
  /**
   * The identifier of Fabric to which this Vni belongs to. Fabric identifier
   * or fabric name must be provided.
   */
  fabricId: string;
  /**
   * The Vxlan network identifier (VNI). The service allocates a VNI if not provided.
   * If VNI is provided, then the value must be between 2 and 6000000. The service reserves
   * VNI values above 6000000 (6M).
   */
  vni: number;
  /** Indicates that Vni is a default (aut-created) VNI (readonly). */
  isDefault: boolean;
  /**
   * Maximum transfer unit of Vni. Value must be between 1500 and 9600.
   * MTU of all member network ports will inherit this MTU.
   */
  mtu: number;
  /** The member network ports of Vni. Must provide member ports during Vni creation. */
  members: VlanMember[];
  /**
   * Indicates that Vni is in L3VNI mode. The service will create A VRF in L3VNI mode.
   * Once set, user may not change mode from L3VNI to L2VNI or vice versa.
   */
  isL3: boolean;
  /**
   * The switch virtual interfaces (SVI) of Vni in L3VNI mode or static-anycast-gateway
   * of Vni in L2VNI mode.
   * L2VNI rules:
   *   1) There must be exactly one SAG enabled SVI entry.
   *   2) NodeId attribute of SVI must be empty.
   * L3VNI rules:
   *   1) There must be one SVI per node per Vlan.
   *   2) NodeId attribute of SVI must be set to the node where SVI should be active.
   */
  svis: Svi[];
  /**
   * The Vrf associated with the Vni.
   * L2VNI:
   *   1) Vrf belongs to a separate L3VNI object.
   *   2) User may link SVI of VNI with an existing VRF.
   *   3) If Vrf is present in setVnis request, then the service will link SVI to VRF.
   *   4) The service will not update or delete any other VRF interfaces.
   * L3VNI:
   *   1) Vrf belongs to the L3VNI itself.
   *   2) Vni automatically creates a VRF.
   *   3) All SVIs of L3VNI are automatically added to the VRF.
   */
  vrfId: string;
}

/**
 * Loopback represents a loopback device in a tenancy. Loopback encapsulates
 * an IPv4 address, and an optional IPv6 address.
 */
export interface Loopback {
  /**
   * The unique identifier of Loopback. Identifier is required to update an existing
   * Loopback. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /**
   * The user-defined name of the Loopback. Loopback name is unique within a node.
   * Loopback name has a fixed prefix of Loopback and an integer suffix (Eg. Loopback10).
   */
  name: string;
  /** A user-defined description of Loopback. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if Loopback is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the Loopback object. */
  metadata:
    | Metadata
    | undefined;
  /** The fabric identifier to which this loopback belong to. */
  fabricId: string;
  /** The node identifier to which this Loopback belong to. */
  nodeId: string;
  /** IPv4 address of Loopback device. IPv4 address must be a host IPv4 address. */
  ipv4Address: string;
  /** IPv6 address of Loopback device. IPv6 address must be a host IPv6 address. */
  ipv6Address: string;
  /** Vrf object attached to this Loopback. Vrf identifier is readonly. */
  vrfId: string;
}

/**
 * DhcpRelay encapsulates properties of a DHCP relay object. DhcpRelay are
 * specific to Vrf objects, and all interfaces in a DhcpRelay must be in the
 * Vrf. A DhcpRelay must have IP addresses of the DHCP server, and a set of
 * VNI objects that need DHCP relay feature.
 */
export interface DhcpRelay {
  /**
   * The unique identifier of DhcpRelay. Identifier is required to update an existing
   * DhcpRelay. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of the DhcpRelay. DhcpRelay name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of DhcpRelay. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if DhcpRelay is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the object. */
  metadata:
    | Metadata
    | undefined;
  /**
   * The identifier of Fabric to which this DhcpRelay belong. Fabric identifier is mandatory,
   * and immutable once set.
   */
  fabricId: string;
  /**
   * The identifier of Vrf to which this DhcpRelay belong. Vrf identifier is mandatory, and
   * immutable once set.
   */
  vrfId: string;
  /** IPv4 addresses of DHCP server. May provided a maximum of 2 IPv4 addresses. */
  ipv4Addresses: string[];
  /** IPv6 addresses of DHCP server. May provided a maximum of 2 IPv6 addresses. */
  ipv6Addresses: string[];
  /** DHCP relay specification on a per VNI basis. User may specify a maximum of 30 VNIs. */
  vnis: VniDhcp[];
}

/**
 * PortBreakout encapsulates network port breakout policies. PortBreakout does not represent
 * a configurable object. It is a policy that gets applied to a switch.
 */
export interface PortBreakout {
  /**
   * The unique identifier of PortBreakout. Identifier is required to update an existing
   * PortBreakout. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of the PortBreakout object. */
  name: string;
  /** A user-defined description of PortBreakout. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if PortBreakout is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the object. */
  metadata:
    | Metadata
    | undefined;
  /** The identifier of Fabric to which this PortBreakout belongs to. FabricId is mandatory. */
  fabricId: string;
  /** The identifier of the node for which the PortBreakout is for. NodeId is mandatory. */
  nodeId: string;
  /**
   * Breakout config for a set of ports.
   *
   * @deprecated
   */
  breakout:
    | Breakout
    | undefined;
  /** User-defined cable model name (PID). */
  pluggable: string;
  /** Breakout mode. E.g. 2x200G(2) */
  mode: string;
  /** The names of base network ports. */
  ports: string[];
  /** The output of the port breakout operation (readonly). */
  breakouts: string[];
}

function createBaseNetworkPort(): NetworkPort {
  return {
    id: "",
    name: "",
    nodeId: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    index: 0,
    breakout: false,
    breakoutIndex: 0,
    linecard: 0,
    vrfId: "",
    vlanIds: [],
    vnis: [],
    roles: [],
    linkDown: false,
    ipv4Addresses: [],
    ipv6Addresses: [],
    subInfCount: 0,
    stp: undefined,
    pluggable: "",
    mtu: 0,
    maxSpeed: "",
    speed: "",
    forceCounter: 0,
  };
}

export const NetworkPort = {
  encode(message: NetworkPort, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.nodeId !== "") {
      writer.uint32(26).string(message.nodeId);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(56).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(66).fork()).ldelim();
    }
    if (message.index !== 0) {
      writer.uint32(80).uint32(message.index);
    }
    if (message.breakout !== false) {
      writer.uint32(88).bool(message.breakout);
    }
    if (message.breakoutIndex !== 0) {
      writer.uint32(96).uint32(message.breakoutIndex);
    }
    if (message.linecard !== 0) {
      writer.uint32(104).uint32(message.linecard);
    }
    if (message.vrfId !== "") {
      writer.uint32(114).string(message.vrfId);
    }
    writer.uint32(122).fork();
    for (const v of message.vlanIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(130).fork();
    for (const v of message.vnis) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(162).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.linkDown !== false) {
      writer.uint32(168).bool(message.linkDown);
    }
    for (const v of message.ipv4Addresses) {
      writer.uint32(178).string(v!);
    }
    for (const v of message.ipv6Addresses) {
      writer.uint32(186).string(v!);
    }
    if (message.subInfCount !== 0) {
      writer.uint32(192).uint32(message.subInfCount);
    }
    if (message.stp !== undefined) {
      InterfaceStp.encode(message.stp, writer.uint32(202).fork()).ldelim();
    }
    if (message.pluggable !== "") {
      writer.uint32(234).string(message.pluggable);
    }
    if (message.mtu !== 0) {
      writer.uint32(240).uint32(message.mtu);
    }
    if (message.maxSpeed !== "") {
      writer.uint32(250).string(message.maxSpeed);
    }
    if (message.speed !== "") {
      writer.uint32(258).string(message.speed);
    }
    if (message.forceCounter !== 0) {
      writer.uint32(264).uint32(message.forceCounter);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkPort {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkPort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.index = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.breakout = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.breakoutIndex = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.linecard = reader.uint32();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 15:
          if (tag === 120) {
            message.vlanIds.push(reader.uint32());

            continue;
          }

          if (tag === 122) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.vlanIds.push(reader.uint32());
            }

            continue;
          }

          break;
        case 16:
          if (tag === 128) {
            message.vnis.push(reader.uint32());

            continue;
          }

          if (tag === 130) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.vnis.push(reader.uint32());
            }

            continue;
          }

          break;
        case 20:
          if (tag === 160) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 162) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.linkDown = reader.bool();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.ipv4Addresses.push(reader.string());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.ipv6Addresses.push(reader.string());
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.subInfCount = reader.uint32();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.stp = InterfaceStp.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.pluggable = reader.string();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.maxSpeed = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.speed = reader.string();
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.forceCounter = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkPort {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      breakout: isSet(object.breakout) ? globalThis.Boolean(object.breakout) : false,
      breakoutIndex: isSet(object.breakoutIndex) ? globalThis.Number(object.breakoutIndex) : 0,
      linecard: isSet(object.linecard) ? globalThis.Number(object.linecard) : 0,
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      vlanIds: globalThis.Array.isArray(object?.vlanIds) ? object.vlanIds.map((e: any) => globalThis.Number(e)) : [],
      vnis: globalThis.Array.isArray(object?.vnis) ? object.vnis.map((e: any) => globalThis.Number(e)) : [],
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => portRoleFromJSON(e)) : [],
      linkDown: isSet(object.linkDown) ? globalThis.Boolean(object.linkDown) : false,
      ipv4Addresses: globalThis.Array.isArray(object?.ipv4Addresses)
        ? object.ipv4Addresses.map((e: any) => globalThis.String(e))
        : [],
      ipv6Addresses: globalThis.Array.isArray(object?.ipv6Addresses)
        ? object.ipv6Addresses.map((e: any) => globalThis.String(e))
        : [],
      subInfCount: isSet(object.subInfCount) ? globalThis.Number(object.subInfCount) : 0,
      stp: isSet(object.stp) ? InterfaceStp.fromJSON(object.stp) : undefined,
      pluggable: isSet(object.pluggable) ? globalThis.String(object.pluggable) : "",
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      maxSpeed: isSet(object.maxSpeed) ? globalThis.String(object.maxSpeed) : "",
      speed: isSet(object.speed) ? globalThis.String(object.speed) : "",
      forceCounter: isSet(object.forceCounter) ? globalThis.Number(object.forceCounter) : 0,
    };
  },

  toJSON(message: NetworkPort): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.breakout !== false) {
      obj.breakout = message.breakout;
    }
    if (message.breakoutIndex !== 0) {
      obj.breakoutIndex = Math.round(message.breakoutIndex);
    }
    if (message.linecard !== 0) {
      obj.linecard = Math.round(message.linecard);
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.vlanIds?.length) {
      obj.vlanIds = message.vlanIds.map((e) => Math.round(e));
    }
    if (message.vnis?.length) {
      obj.vnis = message.vnis.map((e) => Math.round(e));
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => portRoleToJSON(e));
    }
    if (message.linkDown !== false) {
      obj.linkDown = message.linkDown;
    }
    if (message.ipv4Addresses?.length) {
      obj.ipv4Addresses = message.ipv4Addresses;
    }
    if (message.ipv6Addresses?.length) {
      obj.ipv6Addresses = message.ipv6Addresses;
    }
    if (message.subInfCount !== 0) {
      obj.subInfCount = Math.round(message.subInfCount);
    }
    if (message.stp !== undefined) {
      obj.stp = InterfaceStp.toJSON(message.stp);
    }
    if (message.pluggable !== "") {
      obj.pluggable = message.pluggable;
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.maxSpeed !== "") {
      obj.maxSpeed = message.maxSpeed;
    }
    if (message.speed !== "") {
      obj.speed = message.speed;
    }
    if (message.forceCounter !== 0) {
      obj.forceCounter = Math.round(message.forceCounter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkPort>, I>>(base?: I): NetworkPort {
    return NetworkPort.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkPort>, I>>(object: I): NetworkPort {
    const message = createBaseNetworkPort();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.nodeId = object.nodeId ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.index = object.index ?? 0;
    message.breakout = object.breakout ?? false;
    message.breakoutIndex = object.breakoutIndex ?? 0;
    message.linecard = object.linecard ?? 0;
    message.vrfId = object.vrfId ?? "";
    message.vlanIds = object.vlanIds?.map((e) => e) || [];
    message.vnis = object.vnis?.map((e) => e) || [];
    message.roles = object.roles?.map((e) => e) || [];
    message.linkDown = object.linkDown ?? false;
    message.ipv4Addresses = object.ipv4Addresses?.map((e) => e) || [];
    message.ipv6Addresses = object.ipv6Addresses?.map((e) => e) || [];
    message.subInfCount = object.subInfCount ?? 0;
    message.stp = (object.stp !== undefined && object.stp !== null) ? InterfaceStp.fromPartial(object.stp) : undefined;
    message.pluggable = object.pluggable ?? "";
    message.mtu = object.mtu ?? 0;
    message.maxSpeed = object.maxSpeed ?? "";
    message.speed = object.speed ?? "";
    message.forceCounter = object.forceCounter ?? 0;
    return message;
  },
};

function createBaseManagementPort(): ManagementPort {
  return {
    id: "",
    nodeId: "",
    name: "",
    description: "",
    ipv4Address: "",
    ipv4Gateway: "",
    ipv6Address: "",
    ipv6Gateway: "",
    dnsAddresses: [],
    proxyAddress: "",
    proxyUsername: "",
    proxyCredentialsId: "",
    proxyPassword: "",
    enabled: false,
    connectedState: 0,
    ipv4ConfigType: 0,
    ipv6ConfigType: 0,
    ntpAddresses: [],
    cloudUrls: [],
    configOrigin: 0,
    metadata: undefined,
    setProxyPassword: false,
    noProxy: [],
  };
}

export const ManagementPort = {
  encode(message: ManagementPort, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.ipv4Address !== "") {
      writer.uint32(42).string(message.ipv4Address);
    }
    if (message.ipv4Gateway !== "") {
      writer.uint32(50).string(message.ipv4Gateway);
    }
    if (message.ipv6Address !== "") {
      writer.uint32(58).string(message.ipv6Address);
    }
    if (message.ipv6Gateway !== "") {
      writer.uint32(66).string(message.ipv6Gateway);
    }
    for (const v of message.dnsAddresses) {
      writer.uint32(74).string(v!);
    }
    if (message.proxyAddress !== "") {
      writer.uint32(82).string(message.proxyAddress);
    }
    if (message.proxyUsername !== "") {
      writer.uint32(90).string(message.proxyUsername);
    }
    if (message.proxyCredentialsId !== "") {
      writer.uint32(98).string(message.proxyCredentialsId);
    }
    if (message.proxyPassword !== "") {
      writer.uint32(106).string(message.proxyPassword);
    }
    if (message.enabled !== false) {
      writer.uint32(112).bool(message.enabled);
    }
    if (message.connectedState !== 0) {
      writer.uint32(120).int32(message.connectedState);
    }
    if (message.ipv4ConfigType !== 0) {
      writer.uint32(128).int32(message.ipv4ConfigType);
    }
    if (message.ipv6ConfigType !== 0) {
      writer.uint32(136).int32(message.ipv6ConfigType);
    }
    for (const v of message.ntpAddresses) {
      writer.uint32(146).string(v!);
    }
    for (const v of message.cloudUrls) {
      writer.uint32(154).string(v!);
    }
    if (message.configOrigin !== 0) {
      writer.uint32(160).int32(message.configOrigin);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(170).fork()).ldelim();
    }
    if (message.setProxyPassword !== false) {
      writer.uint32(176).bool(message.setProxyPassword);
    }
    for (const v of message.noProxy) {
      writer.uint32(194).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagementPort {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagementPort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ipv4Address = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ipv4Gateway = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ipv6Address = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.ipv6Gateway = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.dnsAddresses.push(reader.string());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.proxyAddress = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.proxyUsername = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.proxyCredentialsId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.proxyPassword = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.connectedState = reader.int32() as any;
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.ipv4ConfigType = reader.int32() as any;
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.ipv6ConfigType = reader.int32() as any;
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.ntpAddresses.push(reader.string());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.cloudUrls.push(reader.string());
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.configOrigin = reader.int32() as any;
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.setProxyPassword = reader.bool();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.noProxy.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagementPort {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      ipv4Address: isSet(object.ipv4Address) ? globalThis.String(object.ipv4Address) : "",
      ipv4Gateway: isSet(object.ipv4Gateway) ? globalThis.String(object.ipv4Gateway) : "",
      ipv6Address: isSet(object.ipv6Address) ? globalThis.String(object.ipv6Address) : "",
      ipv6Gateway: isSet(object.ipv6Gateway) ? globalThis.String(object.ipv6Gateway) : "",
      dnsAddresses: globalThis.Array.isArray(object?.dnsAddresses)
        ? object.dnsAddresses.map((e: any) => globalThis.String(e))
        : [],
      proxyAddress: isSet(object.proxyAddress) ? globalThis.String(object.proxyAddress) : "",
      proxyUsername: isSet(object.proxyUsername) ? globalThis.String(object.proxyUsername) : "",
      proxyCredentialsId: isSet(object.proxyCredentialsId) ? globalThis.String(object.proxyCredentialsId) : "",
      proxyPassword: isSet(object.proxyPassword) ? globalThis.String(object.proxyPassword) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      connectedState: isSet(object.connectedState) ? connectedStateFromJSON(object.connectedState) : 0,
      ipv4ConfigType: isSet(object.ipv4ConfigType) ? configTypeFromJSON(object.ipv4ConfigType) : 0,
      ipv6ConfigType: isSet(object.ipv6ConfigType) ? configTypeFromJSON(object.ipv6ConfigType) : 0,
      ntpAddresses: globalThis.Array.isArray(object?.ntpAddresses)
        ? object.ntpAddresses.map((e: any) => globalThis.String(e))
        : [],
      cloudUrls: globalThis.Array.isArray(object?.cloudUrls)
        ? object.cloudUrls.map((e: any) => globalThis.String(e))
        : [],
      configOrigin: isSet(object.configOrigin) ? configOriginFromJSON(object.configOrigin) : 0,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      setProxyPassword: isSet(object.setProxyPassword) ? globalThis.Boolean(object.setProxyPassword) : false,
      noProxy: globalThis.Array.isArray(object?.noProxy) ? object.noProxy.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ManagementPort): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.ipv4Address !== "") {
      obj.ipv4Address = message.ipv4Address;
    }
    if (message.ipv4Gateway !== "") {
      obj.ipv4Gateway = message.ipv4Gateway;
    }
    if (message.ipv6Address !== "") {
      obj.ipv6Address = message.ipv6Address;
    }
    if (message.ipv6Gateway !== "") {
      obj.ipv6Gateway = message.ipv6Gateway;
    }
    if (message.dnsAddresses?.length) {
      obj.dnsAddresses = message.dnsAddresses;
    }
    if (message.proxyAddress !== "") {
      obj.proxyAddress = message.proxyAddress;
    }
    if (message.proxyUsername !== "") {
      obj.proxyUsername = message.proxyUsername;
    }
    if (message.proxyCredentialsId !== "") {
      obj.proxyCredentialsId = message.proxyCredentialsId;
    }
    if (message.proxyPassword !== "") {
      obj.proxyPassword = message.proxyPassword;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.connectedState !== 0) {
      obj.connectedState = connectedStateToJSON(message.connectedState);
    }
    if (message.ipv4ConfigType !== 0) {
      obj.ipv4ConfigType = configTypeToJSON(message.ipv4ConfigType);
    }
    if (message.ipv6ConfigType !== 0) {
      obj.ipv6ConfigType = configTypeToJSON(message.ipv6ConfigType);
    }
    if (message.ntpAddresses?.length) {
      obj.ntpAddresses = message.ntpAddresses;
    }
    if (message.cloudUrls?.length) {
      obj.cloudUrls = message.cloudUrls;
    }
    if (message.configOrigin !== 0) {
      obj.configOrigin = configOriginToJSON(message.configOrigin);
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.setProxyPassword !== false) {
      obj.setProxyPassword = message.setProxyPassword;
    }
    if (message.noProxy?.length) {
      obj.noProxy = message.noProxy;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagementPort>, I>>(base?: I): ManagementPort {
    return ManagementPort.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagementPort>, I>>(object: I): ManagementPort {
    const message = createBaseManagementPort();
    message.id = object.id ?? "";
    message.nodeId = object.nodeId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.ipv4Address = object.ipv4Address ?? "";
    message.ipv4Gateway = object.ipv4Gateway ?? "";
    message.ipv6Address = object.ipv6Address ?? "";
    message.ipv6Gateway = object.ipv6Gateway ?? "";
    message.dnsAddresses = object.dnsAddresses?.map((e) => e) || [];
    message.proxyAddress = object.proxyAddress ?? "";
    message.proxyUsername = object.proxyUsername ?? "";
    message.proxyCredentialsId = object.proxyCredentialsId ?? "";
    message.proxyPassword = object.proxyPassword ?? "";
    message.enabled = object.enabled ?? false;
    message.connectedState = object.connectedState ?? 0;
    message.ipv4ConfigType = object.ipv4ConfigType ?? 0;
    message.ipv6ConfigType = object.ipv6ConfigType ?? 0;
    message.ntpAddresses = object.ntpAddresses?.map((e) => e) || [];
    message.cloudUrls = object.cloudUrls?.map((e) => e) || [];
    message.configOrigin = object.configOrigin ?? 0;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.setProxyPassword = object.setProxyPassword ?? false;
    message.noProxy = object.noProxy?.map((e) => e) || [];
    return message;
  },
};

function createBasePortChannel(): PortChannel {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    roles: [],
    members: [],
    lacpMode: 0,
    mtu: 0,
    minLinks: 0,
    ipv4Addresses: [],
    ipv6Addresses: [],
    sysMac: "",
    stp: undefined,
    vrfId: "",
    vlanIds: [],
    vnis: [],
    maxSpeed: "",
  };
}

export const PortChannel = {
  encode(message: PortChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    writer.uint32(82).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.members) {
      PortEndpoint.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.lacpMode !== 0) {
      writer.uint32(96).int32(message.lacpMode);
    }
    if (message.mtu !== 0) {
      writer.uint32(104).uint32(message.mtu);
    }
    if (message.minLinks !== 0) {
      writer.uint32(112).uint32(message.minLinks);
    }
    for (const v of message.ipv4Addresses) {
      writer.uint32(122).string(v!);
    }
    for (const v of message.ipv6Addresses) {
      writer.uint32(130).string(v!);
    }
    if (message.sysMac !== "") {
      writer.uint32(138).string(message.sysMac);
    }
    if (message.stp !== undefined) {
      InterfaceStp.encode(message.stp, writer.uint32(202).fork()).ldelim();
    }
    if (message.vrfId !== "") {
      writer.uint32(162).string(message.vrfId);
    }
    writer.uint32(170).fork();
    for (const v of message.vlanIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(178).fork();
    for (const v of message.vnis) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.maxSpeed !== "") {
      writer.uint32(186).string(message.maxSpeed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag === 80) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.members.push(PortEndpoint.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.lacpMode = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.minLinks = reader.uint32();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.ipv4Addresses.push(reader.string());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.ipv6Addresses.push(reader.string());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.sysMac = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.stp = InterfaceStp.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 21:
          if (tag === 168) {
            message.vlanIds.push(reader.uint32());

            continue;
          }

          if (tag === 170) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.vlanIds.push(reader.uint32());
            }

            continue;
          }

          break;
        case 22:
          if (tag === 176) {
            message.vnis.push(reader.uint32());

            continue;
          }

          if (tag === 178) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.vnis.push(reader.uint32());
            }

            continue;
          }

          break;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.maxSpeed = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortChannel {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => portRoleFromJSON(e)) : [],
      members: globalThis.Array.isArray(object?.members)
        ? object.members.map((e: any) => PortEndpoint.fromJSON(e))
        : [],
      lacpMode: isSet(object.lacpMode) ? lacpModeFromJSON(object.lacpMode) : 0,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      minLinks: isSet(object.minLinks) ? globalThis.Number(object.minLinks) : 0,
      ipv4Addresses: globalThis.Array.isArray(object?.ipv4Addresses)
        ? object.ipv4Addresses.map((e: any) => globalThis.String(e))
        : [],
      ipv6Addresses: globalThis.Array.isArray(object?.ipv6Addresses)
        ? object.ipv6Addresses.map((e: any) => globalThis.String(e))
        : [],
      sysMac: isSet(object.sysMac) ? globalThis.String(object.sysMac) : "",
      stp: isSet(object.stp) ? InterfaceStp.fromJSON(object.stp) : undefined,
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      vlanIds: globalThis.Array.isArray(object?.vlanIds) ? object.vlanIds.map((e: any) => globalThis.Number(e)) : [],
      vnis: globalThis.Array.isArray(object?.vnis) ? object.vnis.map((e: any) => globalThis.Number(e)) : [],
      maxSpeed: isSet(object.maxSpeed) ? globalThis.String(object.maxSpeed) : "",
    };
  },

  toJSON(message: PortChannel): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => portRoleToJSON(e));
    }
    if (message.members?.length) {
      obj.members = message.members.map((e) => PortEndpoint.toJSON(e));
    }
    if (message.lacpMode !== 0) {
      obj.lacpMode = lacpModeToJSON(message.lacpMode);
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.minLinks !== 0) {
      obj.minLinks = Math.round(message.minLinks);
    }
    if (message.ipv4Addresses?.length) {
      obj.ipv4Addresses = message.ipv4Addresses;
    }
    if (message.ipv6Addresses?.length) {
      obj.ipv6Addresses = message.ipv6Addresses;
    }
    if (message.sysMac !== "") {
      obj.sysMac = message.sysMac;
    }
    if (message.stp !== undefined) {
      obj.stp = InterfaceStp.toJSON(message.stp);
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.vlanIds?.length) {
      obj.vlanIds = message.vlanIds.map((e) => Math.round(e));
    }
    if (message.vnis?.length) {
      obj.vnis = message.vnis.map((e) => Math.round(e));
    }
    if (message.maxSpeed !== "") {
      obj.maxSpeed = message.maxSpeed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortChannel>, I>>(base?: I): PortChannel {
    return PortChannel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortChannel>, I>>(object: I): PortChannel {
    const message = createBasePortChannel();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.roles = object.roles?.map((e) => e) || [];
    message.members = object.members?.map((e) => PortEndpoint.fromPartial(e)) || [];
    message.lacpMode = object.lacpMode ?? 0;
    message.mtu = object.mtu ?? 0;
    message.minLinks = object.minLinks ?? 0;
    message.ipv4Addresses = object.ipv4Addresses?.map((e) => e) || [];
    message.ipv6Addresses = object.ipv6Addresses?.map((e) => e) || [];
    message.sysMac = object.sysMac ?? "";
    message.stp = (object.stp !== undefined && object.stp !== null) ? InterfaceStp.fromPartial(object.stp) : undefined;
    message.vrfId = object.vrfId ?? "";
    message.vlanIds = object.vlanIds?.map((e) => e) || [];
    message.vnis = object.vnis?.map((e) => e) || [];
    message.maxSpeed = object.maxSpeed ?? "";
    return message;
  },
};

function createBaseSubInterface(): SubInterface {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    nodeId: "",
    vlanId: 0,
    vrfId: "",
    parent: "",
    ipv4Addresses: [],
    ipv6Addresses: [],
  };
}

export const SubInterface = {
  encode(message: SubInterface, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(82).string(message.nodeId);
    }
    if (message.vlanId !== 0) {
      writer.uint32(88).uint32(message.vlanId);
    }
    if (message.vrfId !== "") {
      writer.uint32(98).string(message.vrfId);
    }
    if (message.parent !== "") {
      writer.uint32(106).string(message.parent);
    }
    for (const v of message.ipv4Addresses) {
      writer.uint32(122).string(v!);
    }
    for (const v of message.ipv6Addresses) {
      writer.uint32(130).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubInterface {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubInterface();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.vlanId = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.parent = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.ipv4Addresses.push(reader.string());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.ipv6Addresses.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubInterface {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      ipv4Addresses: globalThis.Array.isArray(object?.ipv4Addresses)
        ? object.ipv4Addresses.map((e: any) => globalThis.String(e))
        : [],
      ipv6Addresses: globalThis.Array.isArray(object?.ipv6Addresses)
        ? object.ipv6Addresses.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: SubInterface): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.ipv4Addresses?.length) {
      obj.ipv4Addresses = message.ipv4Addresses;
    }
    if (message.ipv6Addresses?.length) {
      obj.ipv6Addresses = message.ipv6Addresses;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubInterface>, I>>(base?: I): SubInterface {
    return SubInterface.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubInterface>, I>>(object: I): SubInterface {
    const message = createBaseSubInterface();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.vlanId = object.vlanId ?? 0;
    message.vrfId = object.vrfId ?? "";
    message.parent = object.parent ?? "";
    message.ipv4Addresses = object.ipv4Addresses?.map((e) => e) || [];
    message.ipv6Addresses = object.ipv6Addresses?.map((e) => e) || [];
    return message;
  },
};

function createBaseNode(): Node {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    serialNumber: "",
    modelName: "",
    osType: 0,
    nodeType: 0,
    position: 0,
    fabricId: "",
    deviceId: "",
    location: "",
    roles: [],
    ports: [],
    connections: [],
    managementPorts: [],
    breakouts: [],
  };
}

export const Node = {
  encode(message: Node, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.serialNumber !== "") {
      writer.uint32(82).string(message.serialNumber);
    }
    if (message.modelName !== "") {
      writer.uint32(90).string(message.modelName);
    }
    if (message.osType !== 0) {
      writer.uint32(96).int32(message.osType);
    }
    if (message.nodeType !== 0) {
      writer.uint32(104).int32(message.nodeType);
    }
    if (message.position !== 0) {
      writer.uint32(112).uint32(message.position);
    }
    if (message.fabricId !== "") {
      writer.uint32(122).string(message.fabricId);
    }
    if (message.deviceId !== "") {
      writer.uint32(130).string(message.deviceId);
    }
    if (message.location !== "") {
      writer.uint32(138).string(message.location);
    }
    writer.uint32(162).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.ports) {
      NetworkPort.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.connections) {
      PortConnection.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    for (const v of message.managementPorts) {
      ManagementPort.encode(v!, writer.uint32(186).fork()).ldelim();
    }
    for (const v of message.breakouts) {
      PortBreakout.encode(v!, writer.uint32(202).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Node {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.osType = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.nodeType = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.position = reader.uint32();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.location = reader.string();
          continue;
        case 20:
          if (tag === 160) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 162) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.ports.push(NetworkPort.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.connections.push(PortConnection.decode(reader, reader.uint32()));
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.managementPorts.push(ManagementPort.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.breakouts.push(PortBreakout.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Node {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      osType: isSet(object.osType) ? osTypeFromJSON(object.osType) : 0,
      nodeType: isSet(object.nodeType) ? nodeTypeFromJSON(object.nodeType) : 0,
      position: isSet(object.position) ? globalThis.Number(object.position) : 0,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => nodeRoleFromJSON(e)) : [],
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => NetworkPort.fromJSON(e)) : [],
      connections: globalThis.Array.isArray(object?.connections)
        ? object.connections.map((e: any) => PortConnection.fromJSON(e))
        : [],
      managementPorts: globalThis.Array.isArray(object?.managementPorts)
        ? object.managementPorts.map((e: any) => ManagementPort.fromJSON(e))
        : [],
      breakouts: globalThis.Array.isArray(object?.breakouts)
        ? object.breakouts.map((e: any) => PortBreakout.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Node): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.osType !== 0) {
      obj.osType = osTypeToJSON(message.osType);
    }
    if (message.nodeType !== 0) {
      obj.nodeType = nodeTypeToJSON(message.nodeType);
    }
    if (message.position !== 0) {
      obj.position = Math.round(message.position);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => nodeRoleToJSON(e));
    }
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => NetworkPort.toJSON(e));
    }
    if (message.connections?.length) {
      obj.connections = message.connections.map((e) => PortConnection.toJSON(e));
    }
    if (message.managementPorts?.length) {
      obj.managementPorts = message.managementPorts.map((e) => ManagementPort.toJSON(e));
    }
    if (message.breakouts?.length) {
      obj.breakouts = message.breakouts.map((e) => PortBreakout.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Node>, I>>(base?: I): Node {
    return Node.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Node>, I>>(object: I): Node {
    const message = createBaseNode();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.serialNumber = object.serialNumber ?? "";
    message.modelName = object.modelName ?? "";
    message.osType = object.osType ?? 0;
    message.nodeType = object.nodeType ?? 0;
    message.position = object.position ?? 0;
    message.fabricId = object.fabricId ?? "";
    message.deviceId = object.deviceId ?? "";
    message.location = object.location ?? "";
    message.roles = object.roles?.map((e) => e) || [];
    message.ports = object.ports?.map((e) => NetworkPort.fromPartial(e)) || [];
    message.connections = object.connections?.map((e) => PortConnection.fromPartial(e)) || [];
    message.managementPorts = object.managementPorts?.map((e) => ManagementPort.fromPartial(e)) || [];
    message.breakouts = object.breakouts?.map((e) => PortBreakout.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFabric(): Fabric {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    topology: 0,
    fabricType: 0,
    cableType: 0,
    pluggable: "",
    location: "",
    address: "",
    city: "",
    country: "",
    nodes: [],
    connections: [],
    sagMac: "",
    numCandidates: 0,
    unrecognized: [],
  };
}

export const Fabric = {
  encode(message: Fabric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.topology !== 0) {
      writer.uint32(80).int32(message.topology);
    }
    if (message.fabricType !== 0) {
      writer.uint32(96).int32(message.fabricType);
    }
    if (message.cableType !== 0) {
      writer.uint32(104).int32(message.cableType);
    }
    if (message.pluggable !== "") {
      writer.uint32(114).string(message.pluggable);
    }
    if (message.location !== "") {
      writer.uint32(130).string(message.location);
    }
    if (message.address !== "") {
      writer.uint32(138).string(message.address);
    }
    if (message.city !== "") {
      writer.uint32(146).string(message.city);
    }
    if (message.country !== "") {
      writer.uint32(154).string(message.country);
    }
    for (const v of message.nodes) {
      Node.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    for (const v of message.connections) {
      PortConnection.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    if (message.sagMac !== "") {
      writer.uint32(178).string(message.sagMac);
    }
    if (message.numCandidates !== 0) {
      writer.uint32(184).uint32(message.numCandidates);
    }
    for (const v of message.unrecognized) {
      Node.encode(v!, writer.uint32(250).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Fabric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.topology = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.fabricType = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.cableType = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.pluggable = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.location = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.address = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.city = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.country = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.nodes.push(Node.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.connections.push(PortConnection.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.sagMac = reader.string();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.numCandidates = reader.uint32();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.unrecognized.push(Node.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Fabric {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      topology: isSet(object.topology) ? fabricTopologyFromJSON(object.topology) : 0,
      fabricType: isSet(object.fabricType) ? fabricTypeFromJSON(object.fabricType) : 0,
      cableType: isSet(object.cableType) ? cableTypeFromJSON(object.cableType) : 0,
      pluggable: isSet(object.pluggable) ? globalThis.String(object.pluggable) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      city: isSet(object.city) ? globalThis.String(object.city) : "",
      country: isSet(object.country) ? globalThis.String(object.country) : "",
      nodes: globalThis.Array.isArray(object?.nodes) ? object.nodes.map((e: any) => Node.fromJSON(e)) : [],
      connections: globalThis.Array.isArray(object?.connections)
        ? object.connections.map((e: any) => PortConnection.fromJSON(e))
        : [],
      sagMac: isSet(object.sagMac) ? globalThis.String(object.sagMac) : "",
      numCandidates: isSet(object.numCandidates) ? globalThis.Number(object.numCandidates) : 0,
      unrecognized: globalThis.Array.isArray(object?.unrecognized)
        ? object.unrecognized.map((e: any) => Node.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Fabric): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.topology !== 0) {
      obj.topology = fabricTopologyToJSON(message.topology);
    }
    if (message.fabricType !== 0) {
      obj.fabricType = fabricTypeToJSON(message.fabricType);
    }
    if (message.cableType !== 0) {
      obj.cableType = cableTypeToJSON(message.cableType);
    }
    if (message.pluggable !== "") {
      obj.pluggable = message.pluggable;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.city !== "") {
      obj.city = message.city;
    }
    if (message.country !== "") {
      obj.country = message.country;
    }
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => Node.toJSON(e));
    }
    if (message.connections?.length) {
      obj.connections = message.connections.map((e) => PortConnection.toJSON(e));
    }
    if (message.sagMac !== "") {
      obj.sagMac = message.sagMac;
    }
    if (message.numCandidates !== 0) {
      obj.numCandidates = Math.round(message.numCandidates);
    }
    if (message.unrecognized?.length) {
      obj.unrecognized = message.unrecognized.map((e) => Node.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Fabric>, I>>(base?: I): Fabric {
    return Fabric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Fabric>, I>>(object: I): Fabric {
    const message = createBaseFabric();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.topology = object.topology ?? 0;
    message.fabricType = object.fabricType ?? 0;
    message.cableType = object.cableType ?? 0;
    message.pluggable = object.pluggable ?? "";
    message.location = object.location ?? "";
    message.address = object.address ?? "";
    message.city = object.city ?? "";
    message.country = object.country ?? "";
    message.nodes = object.nodes?.map((e) => Node.fromPartial(e)) || [];
    message.connections = object.connections?.map((e) => PortConnection.fromPartial(e)) || [];
    message.sagMac = object.sagMac ?? "";
    message.numCandidates = object.numCandidates ?? 0;
    message.unrecognized = object.unrecognized?.map((e) => Node.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStaticRoutes(): StaticRoutes {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    routes: [],
    vrfId: "",
  };
}

export const StaticRoutes = {
  encode(message: StaticRoutes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    for (const v of message.routes) {
      RouteInfo.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.vrfId !== "") {
      writer.uint32(90).string(message.vrfId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StaticRoutes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaticRoutes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.routes.push(RouteInfo.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.vrfId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaticRoutes {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      routes: globalThis.Array.isArray(object?.routes) ? object.routes.map((e: any) => RouteInfo.fromJSON(e)) : [],
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
    };
  },

  toJSON(message: StaticRoutes): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.routes?.length) {
      obj.routes = message.routes.map((e) => RouteInfo.toJSON(e));
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StaticRoutes>, I>>(base?: I): StaticRoutes {
    return StaticRoutes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StaticRoutes>, I>>(object: I): StaticRoutes {
    const message = createBaseStaticRoutes();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.routes = object.routes?.map((e) => RouteInfo.fromPartial(e)) || [];
    message.vrfId = object.vrfId ?? "";
    return message;
  },
};

function createBaseVrf(): Vrf {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    interfaces: [],
    isDefault: false,
    vni: 0,
    asn: 0,
    ipv4Loopbacks: [],
    ipv6Loopbacks: [],
    routeTarget: "",
  };
}

export const Vrf = {
  encode(message: Vrf, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    for (const v of message.interfaces) {
      NetworkInterface.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.isDefault !== false) {
      writer.uint32(96).bool(message.isDefault);
    }
    if (message.vni !== 0) {
      writer.uint32(104).uint32(message.vni);
    }
    if (message.asn !== 0) {
      writer.uint32(112).uint32(message.asn);
    }
    for (const v of message.ipv4Loopbacks) {
      writer.uint32(146).string(v!);
    }
    for (const v of message.ipv6Loopbacks) {
      writer.uint32(154).string(v!);
    }
    if (message.routeTarget !== "") {
      writer.uint32(162).string(message.routeTarget);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Vrf {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVrf();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.interfaces.push(NetworkInterface.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.vni = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.asn = reader.uint32();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.ipv4Loopbacks.push(reader.string());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.ipv6Loopbacks.push(reader.string());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.routeTarget = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Vrf {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      interfaces: globalThis.Array.isArray(object?.interfaces)
        ? object.interfaces.map((e: any) => NetworkInterface.fromJSON(e))
        : [],
      isDefault: isSet(object.isDefault) ? globalThis.Boolean(object.isDefault) : false,
      vni: isSet(object.vni) ? globalThis.Number(object.vni) : 0,
      asn: isSet(object.asn) ? globalThis.Number(object.asn) : 0,
      ipv4Loopbacks: globalThis.Array.isArray(object?.ipv4Loopbacks)
        ? object.ipv4Loopbacks.map((e: any) => globalThis.String(e))
        : [],
      ipv6Loopbacks: globalThis.Array.isArray(object?.ipv6Loopbacks)
        ? object.ipv6Loopbacks.map((e: any) => globalThis.String(e))
        : [],
      routeTarget: isSet(object.routeTarget) ? globalThis.String(object.routeTarget) : "",
    };
  },

  toJSON(message: Vrf): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.interfaces?.length) {
      obj.interfaces = message.interfaces.map((e) => NetworkInterface.toJSON(e));
    }
    if (message.isDefault !== false) {
      obj.isDefault = message.isDefault;
    }
    if (message.vni !== 0) {
      obj.vni = Math.round(message.vni);
    }
    if (message.asn !== 0) {
      obj.asn = Math.round(message.asn);
    }
    if (message.ipv4Loopbacks?.length) {
      obj.ipv4Loopbacks = message.ipv4Loopbacks;
    }
    if (message.ipv6Loopbacks?.length) {
      obj.ipv6Loopbacks = message.ipv6Loopbacks;
    }
    if (message.routeTarget !== "") {
      obj.routeTarget = message.routeTarget;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Vrf>, I>>(base?: I): Vrf {
    return Vrf.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Vrf>, I>>(object: I): Vrf {
    const message = createBaseVrf();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.interfaces = object.interfaces?.map((e) => NetworkInterface.fromPartial(e)) || [];
    message.isDefault = object.isDefault ?? false;
    message.vni = object.vni ?? 0;
    message.asn = object.asn ?? 0;
    message.ipv4Loopbacks = object.ipv4Loopbacks?.map((e) => e) || [];
    message.ipv6Loopbacks = object.ipv6Loopbacks?.map((e) => e) || [];
    message.routeTarget = object.routeTarget ?? "";
    return message;
  },
};

function createBaseVlan(): Vlan {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    vlanId: 0,
    svis: [],
    members: [],
    vni: 0,
    vrfId: "",
  };
}

export const Vlan = {
  encode(message: Vlan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    if (message.vlanId !== 0) {
      writer.uint32(80).uint32(message.vlanId);
    }
    for (const v of message.svis) {
      Svi.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.members) {
      VlanMember.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.vni !== 0) {
      writer.uint32(152).uint32(message.vni);
    }
    if (message.vrfId !== "") {
      writer.uint32(162).string(message.vrfId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Vlan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.vlanId = reader.uint32();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.svis.push(Svi.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.members.push(VlanMember.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.vni = reader.uint32();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.vrfId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Vlan {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      svis: globalThis.Array.isArray(object?.svis) ? object.svis.map((e: any) => Svi.fromJSON(e)) : [],
      members: globalThis.Array.isArray(object?.members) ? object.members.map((e: any) => VlanMember.fromJSON(e)) : [],
      vni: isSet(object.vni) ? globalThis.Number(object.vni) : 0,
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
    };
  },

  toJSON(message: Vlan): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.svis?.length) {
      obj.svis = message.svis.map((e) => Svi.toJSON(e));
    }
    if (message.members?.length) {
      obj.members = message.members.map((e) => VlanMember.toJSON(e));
    }
    if (message.vni !== 0) {
      obj.vni = Math.round(message.vni);
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Vlan>, I>>(base?: I): Vlan {
    return Vlan.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Vlan>, I>>(object: I): Vlan {
    const message = createBaseVlan();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.vlanId = object.vlanId ?? 0;
    message.svis = object.svis?.map((e) => Svi.fromPartial(e)) || [];
    message.members = object.members?.map((e) => VlanMember.fromPartial(e)) || [];
    message.vni = object.vni ?? 0;
    message.vrfId = object.vrfId ?? "";
    return message;
  },
};

function createBaseVni(): Vni {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    vni: 0,
    isDefault: false,
    mtu: 0,
    members: [],
    isL3: false,
    svis: [],
    vrfId: "",
  };
}

export const Vni = {
  encode(message: Vni, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(66).string(message.fabricId);
    }
    if (message.vni !== 0) {
      writer.uint32(80).uint32(message.vni);
    }
    if (message.isDefault !== false) {
      writer.uint32(88).bool(message.isDefault);
    }
    if (message.mtu !== 0) {
      writer.uint32(96).uint32(message.mtu);
    }
    for (const v of message.members) {
      VlanMember.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.isL3 !== false) {
      writer.uint32(112).bool(message.isL3);
    }
    for (const v of message.svis) {
      Svi.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    if (message.vrfId !== "") {
      writer.uint32(130).string(message.vrfId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Vni {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVni();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.vni = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.members.push(VlanMember.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.isL3 = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.svis.push(Svi.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.vrfId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Vni {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vni: isSet(object.vni) ? globalThis.Number(object.vni) : 0,
      isDefault: isSet(object.isDefault) ? globalThis.Boolean(object.isDefault) : false,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      members: globalThis.Array.isArray(object?.members) ? object.members.map((e: any) => VlanMember.fromJSON(e)) : [],
      isL3: isSet(object.isL3) ? globalThis.Boolean(object.isL3) : false,
      svis: globalThis.Array.isArray(object?.svis) ? object.svis.map((e: any) => Svi.fromJSON(e)) : [],
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
    };
  },

  toJSON(message: Vni): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vni !== 0) {
      obj.vni = Math.round(message.vni);
    }
    if (message.isDefault !== false) {
      obj.isDefault = message.isDefault;
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.members?.length) {
      obj.members = message.members.map((e) => VlanMember.toJSON(e));
    }
    if (message.isL3 !== false) {
      obj.isL3 = message.isL3;
    }
    if (message.svis?.length) {
      obj.svis = message.svis.map((e) => Svi.toJSON(e));
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Vni>, I>>(base?: I): Vni {
    return Vni.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Vni>, I>>(object: I): Vni {
    const message = createBaseVni();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.vni = object.vni ?? 0;
    message.isDefault = object.isDefault ?? false;
    message.mtu = object.mtu ?? 0;
    message.members = object.members?.map((e) => VlanMember.fromPartial(e)) || [];
    message.isL3 = object.isL3 ?? false;
    message.svis = object.svis?.map((e) => Svi.fromPartial(e)) || [];
    message.vrfId = object.vrfId ?? "";
    return message;
  },
};

function createBaseLoopback(): Loopback {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    nodeId: "",
    ipv4Address: "",
    ipv6Address: "",
    vrfId: "",
  };
}

export const Loopback = {
  encode(message: Loopback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(82).string(message.nodeId);
    }
    if (message.ipv4Address !== "") {
      writer.uint32(90).string(message.ipv4Address);
    }
    if (message.ipv6Address !== "") {
      writer.uint32(98).string(message.ipv6Address);
    }
    if (message.vrfId !== "") {
      writer.uint32(162).string(message.vrfId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Loopback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoopback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.ipv4Address = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.ipv6Address = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.vrfId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Loopback {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      ipv4Address: isSet(object.ipv4Address) ? globalThis.String(object.ipv4Address) : "",
      ipv6Address: isSet(object.ipv6Address) ? globalThis.String(object.ipv6Address) : "",
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
    };
  },

  toJSON(message: Loopback): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.ipv4Address !== "") {
      obj.ipv4Address = message.ipv4Address;
    }
    if (message.ipv6Address !== "") {
      obj.ipv6Address = message.ipv6Address;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Loopback>, I>>(base?: I): Loopback {
    return Loopback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Loopback>, I>>(object: I): Loopback {
    const message = createBaseLoopback();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.ipv4Address = object.ipv4Address ?? "";
    message.ipv6Address = object.ipv6Address ?? "";
    message.vrfId = object.vrfId ?? "";
    return message;
  },
};

function createBaseDhcpRelay(): DhcpRelay {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    vrfId: "",
    ipv4Addresses: [],
    ipv6Addresses: [],
    vnis: [],
  };
}

export const DhcpRelay = {
  encode(message: DhcpRelay, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    if (message.vrfId !== "") {
      writer.uint32(82).string(message.vrfId);
    }
    for (const v of message.ipv4Addresses) {
      writer.uint32(122).string(v!);
    }
    for (const v of message.ipv6Addresses) {
      writer.uint32(130).string(v!);
    }
    for (const v of message.vnis) {
      VniDhcp.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DhcpRelay {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDhcpRelay();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.ipv4Addresses.push(reader.string());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.ipv6Addresses.push(reader.string());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.vnis.push(VniDhcp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DhcpRelay {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      ipv4Addresses: globalThis.Array.isArray(object?.ipv4Addresses)
        ? object.ipv4Addresses.map((e: any) => globalThis.String(e))
        : [],
      ipv6Addresses: globalThis.Array.isArray(object?.ipv6Addresses)
        ? object.ipv6Addresses.map((e: any) => globalThis.String(e))
        : [],
      vnis: globalThis.Array.isArray(object?.vnis) ? object.vnis.map((e: any) => VniDhcp.fromJSON(e)) : [],
    };
  },

  toJSON(message: DhcpRelay): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.ipv4Addresses?.length) {
      obj.ipv4Addresses = message.ipv4Addresses;
    }
    if (message.ipv6Addresses?.length) {
      obj.ipv6Addresses = message.ipv6Addresses;
    }
    if (message.vnis?.length) {
      obj.vnis = message.vnis.map((e) => VniDhcp.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DhcpRelay>, I>>(base?: I): DhcpRelay {
    return DhcpRelay.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DhcpRelay>, I>>(object: I): DhcpRelay {
    const message = createBaseDhcpRelay();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.vrfId = object.vrfId ?? "";
    message.ipv4Addresses = object.ipv4Addresses?.map((e) => e) || [];
    message.ipv6Addresses = object.ipv6Addresses?.map((e) => e) || [];
    message.vnis = object.vnis?.map((e) => VniDhcp.fromPartial(e)) || [];
    return message;
  },
};

function createBasePortBreakout(): PortBreakout {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    nodeId: "",
    breakout: undefined,
    pluggable: "",
    mode: "",
    ports: [],
    breakouts: [],
  };
}

export const PortBreakout = {
  encode(message: PortBreakout, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(82).string(message.nodeId);
    }
    if (message.breakout !== undefined) {
      Breakout.encode(message.breakout, writer.uint32(90).fork()).ldelim();
    }
    if (message.pluggable !== "") {
      writer.uint32(98).string(message.pluggable);
    }
    if (message.mode !== "") {
      writer.uint32(106).string(message.mode);
    }
    for (const v of message.ports) {
      writer.uint32(114).string(v!);
    }
    for (const v of message.breakouts) {
      writer.uint32(162).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortBreakout {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortBreakout();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.breakout = Breakout.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.pluggable = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.mode = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.ports.push(reader.string());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.breakouts.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortBreakout {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      breakout: isSet(object.breakout) ? Breakout.fromJSON(object.breakout) : undefined,
      pluggable: isSet(object.pluggable) ? globalThis.String(object.pluggable) : "",
      mode: isSet(object.mode) ? globalThis.String(object.mode) : "",
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => globalThis.String(e)) : [],
      breakouts: globalThis.Array.isArray(object?.breakouts)
        ? object.breakouts.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: PortBreakout): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.breakout !== undefined) {
      obj.breakout = Breakout.toJSON(message.breakout);
    }
    if (message.pluggable !== "") {
      obj.pluggable = message.pluggable;
    }
    if (message.mode !== "") {
      obj.mode = message.mode;
    }
    if (message.ports?.length) {
      obj.ports = message.ports;
    }
    if (message.breakouts?.length) {
      obj.breakouts = message.breakouts;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortBreakout>, I>>(base?: I): PortBreakout {
    return PortBreakout.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortBreakout>, I>>(object: I): PortBreakout {
    const message = createBasePortBreakout();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.breakout = (object.breakout !== undefined && object.breakout !== null)
      ? Breakout.fromPartial(object.breakout)
      : undefined;
    message.pluggable = object.pluggable ?? "";
    message.mode = object.mode ?? "";
    message.ports = object.ports?.map((e) => e) || [];
    message.breakouts = object.breakouts?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
