// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: statsd/statsd.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "statsd";

/** ConfigSourceType enumerates config source. */
export enum ConfigSourceType {
  CONFIG_SOURCE_UNSPECIFIED = 0,
  /** CONFIG_SOURCE_CONSOLE - Config from craft console. */
  CONFIG_SOURCE_CONSOLE = 1,
  /** CONFIG_SOURCE_CLOUD - Config from cloud. */
  CONFIG_SOURCE_CLOUD = 2,
  UNRECOGNIZED = -1,
}

export function configSourceTypeFromJSON(object: any): ConfigSourceType {
  switch (object) {
    case 0:
    case "CONFIG_SOURCE_UNSPECIFIED":
      return ConfigSourceType.CONFIG_SOURCE_UNSPECIFIED;
    case 1:
    case "CONFIG_SOURCE_CONSOLE":
      return ConfigSourceType.CONFIG_SOURCE_CONSOLE;
    case 2:
    case "CONFIG_SOURCE_CLOUD":
      return ConfigSourceType.CONFIG_SOURCE_CLOUD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConfigSourceType.UNRECOGNIZED;
  }
}

export function configSourceTypeToJSON(object: ConfigSourceType): string {
  switch (object) {
    case ConfigSourceType.CONFIG_SOURCE_UNSPECIFIED:
      return "CONFIG_SOURCE_UNSPECIFIED";
    case ConfigSourceType.CONFIG_SOURCE_CONSOLE:
      return "CONFIG_SOURCE_CONSOLE";
    case ConfigSourceType.CONFIG_SOURCE_CLOUD:
      return "CONFIG_SOURCE_CLOUD";
    case ConfigSourceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** IpMethod enumerates ip method. */
export enum IpMethodType {
  IP_METHOD_UNSPECIFIED = 0,
  /** IP_METHOD_DHCP - Ip method is DHCP. */
  IP_METHOD_DHCP = 1,
  /** IP_METHOD_STATIC - Ip method is static. */
  IP_METHOD_STATIC = 2,
  UNRECOGNIZED = -1,
}

export function ipMethodTypeFromJSON(object: any): IpMethodType {
  switch (object) {
    case 0:
    case "IP_METHOD_UNSPECIFIED":
      return IpMethodType.IP_METHOD_UNSPECIFIED;
    case 1:
    case "IP_METHOD_DHCP":
      return IpMethodType.IP_METHOD_DHCP;
    case 2:
    case "IP_METHOD_STATIC":
      return IpMethodType.IP_METHOD_STATIC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IpMethodType.UNRECOGNIZED;
  }
}

export function ipMethodTypeToJSON(object: IpMethodType): string {
  switch (object) {
    case IpMethodType.IP_METHOD_UNSPECIFIED:
      return "IP_METHOD_UNSPECIFIED";
    case IpMethodType.IP_METHOD_DHCP:
      return "IP_METHOD_DHCP";
    case IpMethodType.IP_METHOD_STATIC:
      return "IP_METHOD_STATIC";
    case IpMethodType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum LoopbackModeType {
  INVALID_LOOPBACK = 0,
  /** FACILITY - Low. */
  FACILITY = 1,
  /** TERMINAL - Medium. */
  TERMINAL = 2,
  UNRECOGNIZED = -1,
}

export function loopbackModeTypeFromJSON(object: any): LoopbackModeType {
  switch (object) {
    case 0:
    case "INVALID_LOOPBACK":
      return LoopbackModeType.INVALID_LOOPBACK;
    case 1:
    case "FACILITY":
      return LoopbackModeType.FACILITY;
    case 2:
    case "TERMINAL":
      return LoopbackModeType.TERMINAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LoopbackModeType.UNRECOGNIZED;
  }
}

export function loopbackModeTypeToJSON(object: LoopbackModeType): string {
  switch (object) {
    case LoopbackModeType.INVALID_LOOPBACK:
      return "INVALID_LOOPBACK";
    case LoopbackModeType.FACILITY:
      return "FACILITY";
    case LoopbackModeType.TERMINAL:
      return "TERMINAL";
    case LoopbackModeType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum RifInterfaceType {
  RIF_INTERFACE_TYPE_UNSPECIFIED = 0,
  VLAN = 1,
  PORT = 2,
  SUB_PORT = 3,
  UNRECOGNIZED = -1,
}

export function rifInterfaceTypeFromJSON(object: any): RifInterfaceType {
  switch (object) {
    case 0:
    case "RIF_INTERFACE_TYPE_UNSPECIFIED":
      return RifInterfaceType.RIF_INTERFACE_TYPE_UNSPECIFIED;
    case 1:
    case "VLAN":
      return RifInterfaceType.VLAN;
    case 2:
    case "PORT":
      return RifInterfaceType.PORT;
    case 3:
    case "SUB_PORT":
      return RifInterfaceType.SUB_PORT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RifInterfaceType.UNRECOGNIZED;
  }
}

export function rifInterfaceTypeToJSON(object: RifInterfaceType): string {
  switch (object) {
    case RifInterfaceType.RIF_INTERFACE_TYPE_UNSPECIFIED:
      return "RIF_INTERFACE_TYPE_UNSPECIFIED";
    case RifInterfaceType.VLAN:
      return "VLAN";
    case RifInterfaceType.PORT:
      return "PORT";
    case RifInterfaceType.SUB_PORT:
      return "SUB_PORT";
    case RifInterfaceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** StatsTrafficClass enumerates traffic class values. */
export enum StatsTrafficClass {
  INVALID_CLASS = 0,
  /** STATS_TRAFFIC_LOW - Low. */
  STATS_TRAFFIC_LOW = 1,
  /** STATS_TRAFFIC_MEDIUM - Medium. */
  STATS_TRAFFIC_MEDIUM = 2,
  /** STATS_TRAFFIC_HIGH - High. */
  STATS_TRAFFIC_HIGH = 3,
  UNRECOGNIZED = -1,
}

export function statsTrafficClassFromJSON(object: any): StatsTrafficClass {
  switch (object) {
    case 0:
    case "INVALID_CLASS":
      return StatsTrafficClass.INVALID_CLASS;
    case 1:
    case "STATS_TRAFFIC_LOW":
      return StatsTrafficClass.STATS_TRAFFIC_LOW;
    case 2:
    case "STATS_TRAFFIC_MEDIUM":
      return StatsTrafficClass.STATS_TRAFFIC_MEDIUM;
    case 3:
    case "STATS_TRAFFIC_HIGH":
      return StatsTrafficClass.STATS_TRAFFIC_HIGH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StatsTrafficClass.UNRECOGNIZED;
  }
}

export function statsTrafficClassToJSON(object: StatsTrafficClass): string {
  switch (object) {
    case StatsTrafficClass.INVALID_CLASS:
      return "INVALID_CLASS";
    case StatsTrafficClass.STATS_TRAFFIC_LOW:
      return "STATS_TRAFFIC_LOW";
    case StatsTrafficClass.STATS_TRAFFIC_MEDIUM:
      return "STATS_TRAFFIC_MEDIUM";
    case StatsTrafficClass.STATS_TRAFFIC_HIGH:
      return "STATS_TRAFFIC_HIGH";
    case StatsTrafficClass.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** AclRuleCounter encapsulates stats counters of ACL rules. */
export interface AclRuleCounter {
  saiAclCounterAttrBytes: number;
  saiAclCounterAttrPackets: number;
  name: string;
}

export interface BufferPoolStat {
  saiBufferPoolStatWatermarkBytes: number;
  saiBufferPoolStatXoffRoomWatermarkBytes: number;
  poolName: string;
}

/** Chassis related information from state db */
export interface ChassisInfo {
  model: string;
  psuNum: string;
  revision: string;
  serial: string;
  chassisName: string;
  lldpLocChassisId: string;
  sonicVersion: string;
  agentsVersion: string;
}

export interface ChassisInventory {
  metaData: StatsMetadata | undefined;
  lldpTable: LldpEntry[];
  localChassisInfo: ChassisInfo | undefined;
  chassisPorts: ChassisPort[];
}

export interface ChassisPort {
  portName: string;
  alias: string;
  lanes: string;
  index: number;
  mtu: number;
  speed: string;
}

export interface CounterStats {
  statsInfo: StatsMetadata | undefined;
  chassisInfo: ChassisInfo | undefined;
  interfaceStats: SaiPortCounters[];
  rifStats: RifCounters[];
  tunnelStats: TunnelCounters[];
  queueStats: QueueStatCounters[];
  pgWmstats: PgWatermarkCounters[];
  bufferPoolStats: BufferPoolStat[];
  fabricPortStats: FabricPortCounter[];
  aclRuleStats: AclRuleCounter[];
}

export interface Counters {
  inOctets: number;
  inPkts: number;
  inUnicastPkts: number;
  inBroadcastPkts: number;
  inMulticastPkts: number;
  inDiscards: number;
  inErrors: number;
  inUnknownProtos: number;
  inFcsErrors: number;
  outOctets: number;
  outPkts: number;
  outUnicastPkts: number;
  outBroadcastPkts: number;
  outMulticastPkts: number;
  outDiscards: number;
  outErrors: number;
  carrierTransitions: number;
  lastClear: Date | undefined;
}

export interface DnsConfig {
  dnsServers: string[];
}

export interface DrakeConfig {
  cloudBaseUrlList: string[];
}

export interface EthernetCounters {
  inMacControlFrames: number;
  inMacPauseFrames: number;
  inOversizeFrames: number;
  inUndersizeFrames: number;
  inJabberFrames: number;
  inFragmentFrames: number;
  in8021qFrames: number;
  inCrcErrors: number;
  inBlockErrors: number;
  inCarrierErrors: number;
  inInterruptedTx: number;
  inLateCollision: number;
  inMacErrorsRx: number;
  inSingleCollision: number;
  inSymbolError: number;
  inMaxsizeExceeded: number;
  outMacControlFrames: number;
  outMacPauseFrames: number;
  out8021qFrames: number;
  outMacErrorsTx: number;
  inDistributionCounters: EthernetPacketsInDistribution | undefined;
}

export interface EthernetPacketsInDistribution {
  inFrames64Octets: number;
  inFrames65127Octets: number;
  inFrames128255Octets: number;
  inFrames256511Octets: number;
  inFrames5121023Octets: number;
  inFrames10241518Octets: number;
}

export interface FabricPortCounter {
  saiPortStatIfInErrors: number;
  saiPortStatIfInFabricDataUnits: number;
  saiPortStatIfInFecCorrectableFrames: number;
  saiPortStatIfInFecNotCorrectableFrames: number;
  saiPortStatIfInFecSymbolErrors: number;
  saiPortStatIfInOctets: number;
  saiPortStatIfOutFabricDataUnits: number;
  saiPortStatIfOutOctets: number;
  portName: string;
}

/** Fan status for PSU and Fan tray */
export interface FanInfo {
  direction: string;
  drawerName: string;
  isReplaceable: string;
  ledStatus: string;
  model: string;
  presence: string;
  serial: string;
  speed: string;
  speedTarget: string;
  speedTolerance: string;
  status: string;
  timestamp: string;
}

export interface Interface {
  config: InterfaceConfig | undefined;
  name: string;
  openconfigIfEthernetEthernet: InterfaceOpenconfigIfEthernet | undefined;
  state: InterfaceState | undefined;
  subinterfaces: InterfaceSubinterfaces | undefined;
  tunnel: Tunnel | undefined;
}

export interface InterfaceConfig {
  name: string;
  mtu: number;
  loopbackMode: LoopbackModeType;
  description: string;
  enabled: boolean;
}

/** Logical interface config */
export interface InterfaceInfo {
  name: string;
  ipAddr: string;
  vrf: string;
  state: string;
}

export interface InterfaceIpv4 {
  addresses: string;
  neighbors: Ipv4Neighbors | undefined;
  unnumbered: Ipv4Unnumbered | undefined;
  config: Ipv4Config | undefined;
  state: Ipv4State | undefined;
}

export interface InterfaceIpv6 {
  addresses: string;
  neighbors: Ipv6Neighbors | undefined;
  unnumbered: Ipv6Unnumbered | undefined;
  config: Ipv6Config | undefined;
  state: string;
  autoconf: Ipv6AutoConf | undefined;
}

export interface InterfaceOpenconfigIfEthernet {
  config: InterfaceOpenconfigIfEthernetConfig | undefined;
  state: InterfaceOpenconfigIfEthernetState | undefined;
}

export interface InterfaceOpenconfigIfEthernetConfig {
  portSpeed: string;
  macAddress: string;
  enableFlowControl: boolean;
}

export interface InterfaceOpenconfigIfEthernetState {
  portSpeed: string;
  macAddress: string;
  enableFlowControl: boolean;
  hwMacAddress: string;
  counters: EthernetCounters | undefined;
}

export interface InterfaceRef {
  config: InterfaceRefConfig | undefined;
  state: InterfaceRefState | undefined;
}

export interface InterfaceRefConfig {
  interface: string;
  subinterface: string;
}

export interface InterfaceRefState {
  interface: string;
  subinterface: string;
}

export interface InterfaceState {
  adminStatus: string;
  counters: Counters | undefined;
  description: string;
  ifindex: number;
  mtu: number;
  name: string;
  operStatus: string;
  loopbackMode: LoopbackModeType;
  enabled: boolean;
  outRate: number;
  inRate: number;
  lastChange: Date | undefined;
}

export interface InterfaceSubinterfaces {
  subinterface: Subinterface[];
}

export interface Interfaces {
  interface: Interface[];
  statsMdata: StatsMetadata | undefined;
}

/** IpCounters encapsulates counters of an IP address. */
export interface IpCounters {
  inOctets: number;
  inPkts: number;
  inForwardedPkts: number;
  inForwardedOctets: number;
  inDiscardedPkts: number;
  inErrorPkts: number;
  outOctets: number;
  outPkts: number;
  outForwardedPkts: number;
  outForwardedOctets: number;
  outDiscardedPkts: number;
  outErrorPkts: number;
}

export interface IpmCounter {
  saiIpmCounterStatRxPktsBin0: number;
  saiIpmCounterStatRxPktsBin1: number;
  saiIpmCounterStatRxPktsBin2: number;
  saiIpmCounterStatRxPktsBin3: number;
  saiIpmCounterStatRxPktsBin4: number;
  saiIpmCounterStatRxPktsBin5: number;
  saiIpmCounterStatRxPktsBin6: number;
  saiIpmCounterStatRxPktsBin7: number;
  saiIpmCounterStatRxPktsBin8: number;
  saiIpmCounterStatRxPktsBin9: number;
  saiIpmCounterStatRxPktsBin10: number;
  saiIpmCounterStatRxPktsBin11: number;
  saiIpmCounterStatRxPktsBin12: number;
  saiIpmCounterStatRxPktsBin13: number;
  saiIpmCounterStatRxPktsBin14: number;
  saiIpmCounterStatRxPktsBin15: number;
  saiIpmCounterStatRxPktsBin16: number;
  saiIpmCounterStatRxPktsBin17: number;
  saiIpmCounterStatRxPktsBin18: number;
  saiIpmCounterStatRxPktsBin19: number;
  saiIpmCounterStatRxPktsBin20: number;
  saiIpmCounterStatRxPktsBin21: number;
  saiIpmCounterStatRxPktsBin22: number;
  saiIpmCounterStatRxPktsBin23: number;
  saiIpmCounterStatRxPktsBin24: number;
  saiIpmCounterStatRxPktsBin25: number;
  saiIpmCounterStatRxPktsBin26: number;
  saiIpmCounterStatRxPktsBin27: number;
  sessionId: string;
  srcAddr: string;
  dstAddr: string;
  vrf: string;
  flowLabel: number;
  txInterval: number;
  dscp: number;
  histogramTemplate: string;
  rxPkts: number;
  alternateMarkingInterval: number;
  livenessTimer: number;
  state: string;
  description: string;
  confSessionId: string;
}

/** IPM Session Status */
export interface IpmSessionStatus {
  srcAddr: string;
  dstAddr: string;
  vrf: string;
  flowLabel: number;
  txInterval: number;
  dscp: number;
  srcPort: number;
  dstPort: number;
  histogramTemplate: string;
  livenessTimer: number;
  state: string;
  sessionId: string;
  description: string;
  confSessionId: string;
}

export interface IpmStats {
  statsInfo: StatsMetadata | undefined;
  chassisInfo: ChassisInfo | undefined;
  ipmCounters: IpmCounter[];
}

export interface Ipv4Config {
  enabled: boolean;
  mtu: number;
  dhcpClient: boolean;
}

export interface Ipv4Neighbor {
  ip: string;
  config: Ipv4NeighborConfig | undefined;
  state: Ipv4NeighborState | undefined;
}

export interface Ipv4NeighborConfig {
  ip: string;
  linkLayerAddress: string;
}

export interface Ipv4NeighborState {
  ip: string;
  linkLayerAddress: string;
  origin: string;
}

export interface Ipv4Neighbors {
  neighbor: Ipv4Neighbor[];
}

export interface Ipv4State {
  enabled: boolean;
  mtu: number;
  dhcpClient: boolean;
  counters: IpCounters | undefined;
}

export interface Ipv4Unnumbered {
  config: Ipv4UnnumberedConfig | undefined;
  state: Ipv4UnnumberedState | undefined;
  interfaceRef: InterfaceRef | undefined;
}

export interface Ipv4UnnumberedConfig {
  enabled: boolean;
}

export interface Ipv4UnnumberedState {
  enabled: boolean;
}

export interface Ipv6AutoConf {
  config: Ipv6AutoConfConfig | undefined;
  state: Ipv6AutoConfState | undefined;
}

export interface Ipv6AutoConfConfig {
  createGlobalAddresses: boolean;
  createTemporaryAddresses: boolean;
  temporaryValidLifetime: number;
  temporaryPreferredLifetime: number;
}

export interface Ipv6AutoConfState {
  createGlobalAddresses: boolean;
  createTemporaryAddresses: boolean;
  temporaryValidLifetime: number;
  temporaryPreferredLifetime: number;
}

export interface Ipv6Config {
  enabled: boolean;
  mtu: number;
  dhcpClient: boolean;
  dupAddrDetectTransmits: number;
  counters: IpCounters | undefined;
}

export interface Ipv6Neighbor {
  ip: string;
  config: Ipv6NeighborConfig | undefined;
  state: Ipv6NeighborState | undefined;
}

export interface Ipv6NeighborConfig {
  ip: string;
  linkLayerAddress: string;
}

export interface Ipv6NeighborState {
  ip: string;
  linkLayerAddress: string;
  origin: string;
  isRouter: boolean;
  neighborState: string;
}

export interface Ipv6Neighbors {
  neighbor: Ipv6Neighbor[];
}

export interface Ipv6Unnumbered {
  config: Ipv6UnnumberedConfig | undefined;
  state: Ipv6UnnumberedState | undefined;
  interfaceRef: InterfaceRef | undefined;
}

export interface Ipv6UnnumberedConfig {
  enabled: boolean;
}

export interface Ipv6UnnumberedState {
  enabled: boolean;
}

export interface LldpEntry {
  lldpRemChassisId: string;
  lldpRemChassisIdSubtype: string;
  lldpRemIndex: number;
  lldpRemManAddr: string;
  lldpRemPortDesc: string;
  lldpRemPortId: string;
  lldpRemPortIdSubtype: string;
  lldpRemSysCapEnabled: string;
  lldpRemSysCapSupported: string;
  lldpRemSysDesc: string;
  lldpRemSysName: string;
  lldpRemTimeMark: string;
  localPort: string;
}

export interface MgmtConfig {
  sonic: SonicConfig | undefined;
  proxy: ProxyConfig | undefined;
  dns: DnsConfig | undefined;
  drake: DrakeConfig | undefined;
  ntpServers: string[];
  configSource: ConfigSourceType;
  ipv4Method: IpMethodType;
  ipv6Method: IpMethodType;
}

export interface NodeProcessStats {
  metaData: StatsMetadata | undefined;
  chassisInfo: ChassisInfo | undefined;
  processStats: ProcessStat[];
}

export interface PgWatermarkCounters {
  saiIngressPriorityGroupStatDroppedPackets: number;
  saiIngressPriorityGroupStatSharedWatermarkBytes: number;
  portName: string;
}

/** Physical Port Config */
export interface PhyPortConfig {
  adminStatus: string;
  alias: string;
  index: number;
  lanes: string;
  mtu: number;
  speed: string;
}

/** Physical Port Status */
export interface PhyPortStatus {
  adminStatus: string;
  hostTxReady: string;
  mtu: number;
  netdevOperStatus: string;
  speed: string;
  state: string;
  supportedFecs: string;
  supportedSpeeds: string;
  alias: string;
  description: string;
  index: number;
  lanes: string;
  operStatus: string;
  portName: string;
  subport: string;
  asicPortName: string;
  role: string;
  sourceNpu: string;
  targetNpu: string;
  lineOperStatus: string;
  systemOperStatus: string;
}

/** Platform related information from config db (DEVICE_METADATA) */
export interface PlatformInfo {
  bufferModel: string;
  defaultBgpStatus: string;
  defaultPfcwdStatus: string;
  hostname: string;
  hwsku: string;
  mac: string;
  platform: string;
  synchronousMode: string;
}

export interface PortRateCounters {
  rxBps: number;
  rxPps: number;
  saiPortStatIfInNonUcastPktsLast: number;
  saiPortStatIfInOctetsLast: number;
  saiPortStatIfInUcastPktsLast: number;
  saiPortStatIfOutNonUcastPktsLast: number;
  saiPortStatIfOutUcastPktsLast: number;
  saiPortStatIfOutOctetsLast: number;
  txBps: number;
  txPps: number;
}

export interface ProcessStat {
  cpu: string;
  mem: string;
  cmd: string;
  ppid: string;
  stime: string;
  time: string;
  tt: string;
  uid: string;
  pid: string;
}

export interface ProxyConfig {
  httpProxy: string;
  httpsProxy: string;
  noProxy: string;
  user: string;
  pass: string;
}

export interface Psuinfo {
  current: string;
  isReplaceable: string;
  ledStatus: string;
  model: string;
  power: string;
  presence: string;
  revision: string;
  serial: string;
  status: string;
  temp: string;
  tempThreshold: string;
  voltage: string;
  voltageMaxThreshold: string;
  voltageMinThreshold: string;
}

export interface PuntHandlerRequest {
  unknownVlanRequest: UnknownVlansRequest | undefined;
}

export interface QueueStatCounters {
  saiQueueStatBytes: number;
  saiQueueStatDroppedBytes: number;
  saiQueueStatDroppedPackets: number;
  saiQueueStatPackets: number;
  saiQueueStatSharedWatermarkBytes: number;
  queuePortName: string;
}

export interface RateStats {
  statsInfo: StatsMetadata | undefined;
  portRates: PortRateCounters[];
  rifRates: RifRateCounters[];
  tunnelRates: TunnelRateCounters[];
}

/** Logical(L3 address/Vlan) interface counters */
export interface RifCounters {
  saiRouterInterfaceStatInErrorOctets: number;
  saiRouterInterfaceStatInErrorPackets: number;
  saiRouterInterfaceStatInOctets: number;
  saiRouterInterfaceStatInPackets: number;
  saiRouterInterfaceStatOutErrorOctets: number;
  saiRouterInterfaceStatOutErrorPackets: number;
  saiRouterInterfaceStatOutOctets: number;
  saiRouterInterfaceStatOutPackets: number;
  rifName: string;
  rifType: RifInterfaceType;
}

export interface RifRateCounters {
  rxBps: number;
  rxPps: number;
  saiRouterInterfaceStatInOctetsLast: number;
  saiRouterInterfaceStatInPacketsLast: number;
  saiRouterInterfaceStatOutOctetsLast: number;
  saiRouterInterfaceStatOutPacketsLast: number;
  txBps: number;
  txPps: number;
}

export interface SaiPortCounters {
  saiPortStatEtherInPkts1024To1518Octets: number;
  saiPortStatEtherInPkts128To255Octets: number;
  saiPortStatEtherInPkts1519To2047Octets: number;
  saiPortStatEtherInPkts2048To4095Octets: number;
  saiPortStatEtherInPkts256To511Octets: number;
  saiPortStatEtherInPkts4096To9216Octets: number;
  saiPortStatEtherInPkts512To1023Octets: number;
  saiPortStatEtherInPkts64Octets: number;
  saiPortStatEtherInPkts65To127Octets: number;
  saiPortStatEtherInPkts9217To16383Octets: number;
  saiPortStatEtherOutPkts1024To1518Octets: number;
  saiPortStatEtherOutPkts128To255Octets: number;
  saiPortStatEtherOutPkts1519To2047Octets: number;
  saiPortStatEtherOutPkts2048To4095Octets: number;
  saiPortStatEtherOutPkts256To511Octets: number;
  saiPortStatEtherOutPkts4096To9216Octets: number;
  saiPortStatEtherOutPkts512To1023Octets: number;
  saiPortStatEtherOutPkts64Octets: number;
  saiPortStatEtherOutPkts65To127Octets: number;
  saiPortStatEtherOutPkts9217To16383Octets: number;
  saiPortStatEtherRxOversizePkts: number;
  saiPortStatEtherStatsFragments: number;
  saiPortStatEtherStatsJabbers: number;
  saiPortStatEtherStatsTxNoErrors: number;
  saiPortStatEtherStatsUndersizePkts: number;
  saiPortStatEtherTxOversizePkts: number;
  saiPortStatIfInBroadcastPkts: number;
  saiPortStatIfInDiscards: number;
  saiPortStatIfInErrors: number;
  saiPortStatIfInFecCorrectableFrames: number;
  saiPortStatIfInFecNotCorrectableFrames: number;
  saiPortStatIfInFecSymbolErrors: number;
  saiPortStatIfInMulticastPkts: number;
  saiPortStatIfInNonUcastPkts: number;
  saiPortStatIfInOctets: number;
  saiPortStatIfInUcastPkts: number;
  saiPortStatIfInUnknownProtos: number;
  saiPortStatIfOutBroadcastPkts: number;
  saiPortStatIfOutDiscards: number;
  saiPortStatIfOutErrors: number;
  saiPortStatIfOutMulticastPkts: number;
  saiPortStatIfOutNonUcastPkts: number;
  saiPortStatIfOutOctets: number;
  saiPortStatIfOutQlen: number;
  saiPortStatIfOutUcastPkts: number;
  saiPortStatInDroppedPkts: number;
  saiPortStatIpInReceives: number;
  saiPortStatIpInUcastPkts: number;
  saiPortStatOutDroppedPkts: number;
  saiPortStatPauseRxPkts: number;
  saiPortStatPauseTxPkts: number;
  saiPortStatPfc0RxPkts: number;
  saiPortStatPfc0TxPkts: number;
  saiPortStatPfc1RxPkts: number;
  saiPortStatPfc1TxPkts: number;
  saiPortStatPfc2RxPkts: number;
  saiPortStatPfc2TxPkts: number;
  saiPortStatPfc3RxPkts: number;
  saiPortStatPfc3TxPkts: number;
  saiPortStatPfc4RxPkts: number;
  saiPortStatPfc4TxPkts: number;
  saiPortStatPfc5RxPkts: number;
  saiPortStatPfc5TxPkts: number;
  saiPortStatPfc6RxPkts: number;
  saiPortStatPfc6TxPkts: number;
  saiPortStatPfc7RxPkts: number;
  saiPortStatPfc7TxPkts: number;
  portName: string;
  portIndex: number;
}

export interface SonicConfig {
  ipv4Addr: string;
  ipv6Addr: string;
  ipv4Gateway: string;
  ipv6Gateway: string;
  hostname: string;
}

export interface SonicEvent {
  metaData: StatsMetadata | undefined;
  chassisInfo: ChassisInfo | undefined;
  portStateChange: PhyPortStatus[];
}

export interface StateInfo {
  metaData: StatsMetadata | undefined;
  portsState: PhyPortStatus[];
  interfacesState: InterfaceInfo[];
  tunnelsState: VnetInfo[];
  chassisInfo: ChassisInfo | undefined;
  lldpTable: LldpEntry[];
  vlansConfig: VlanConfig | undefined;
  mgmtNetworkConfig: MgmtConfig | undefined;
  ipmSessionState: IpmSessionStatus[];
}

/** StatsMetadata encapsulates metadata properties. */
export interface StatsMetadata {
  trafficClass: StatsTrafficClass;
  collectedAt: Date | undefined;
}

export interface Subinterface {
  index: number;
  config: SubinterfaceConfig | undefined;
  state: SubinterfaceState | undefined;
  vlan: string;
  openconfigIfIpIpv4: InterfaceIpv4 | undefined;
  openconfigIfIpIpv6: InterfaceIpv6 | undefined;
}

export interface SubinterfaceConfig {
  index: number;
  description: string;
  enabled: boolean;
}

export interface SubinterfaceState {
  index: number;
  description: string;
  enabled: boolean;
  name: string;
  ifindex: number;
  adminStatus: string;
  operStatus: string;
  lastChange: Date | undefined;
  logical: boolean;
  management: boolean;
  cpu: boolean;
  counters: Counters | undefined;
}

export interface TemperatureInfo {
  criticalHighThreshold: string;
  criticalLowThreshold: string;
  highThreshold: string;
  isReplaceable: string;
  lowThreshold: string;
  maximumTemperature: string;
  minimumTemperature: string;
  temperature: string;
  timestamp: string;
  warningStatus: string;
}

export interface TransceiverDomSensor {
  rx1power: string;
  rx2power: string;
  rx3power: string;
  rx4power: string;
  rxLos: string;
  temperature: string;
  tx1bias: string;
  tx1power: string;
  tx2bias: string;
  tx2power: string;
  tx3bias: string;
  tx3power: string;
  tx4bias: string;
  tx4power: string;
  txDisable: string;
  txDisabledChannel: string;
  txFault: string;
  voltage: string;
}

export interface TransceiverInfo {
  applicationAdvertisement: string;
  cableLength: number;
  cableType: string;
  connector: string;
  domCapability: string;
  encoding: string;
  extIdentifier: string;
  extRateselectCompliance: string;
  isReplaceable: string;
  manufacturer: string;
  model: string;
  nominalBitRate: string;
  serial: string;
  specificationCompliance: string;
  type: string;
  vendorDate: string;
  vendorOui: string;
  vendorRev: string;
}

export interface Tunnel {
  config: TunnelConfig | undefined;
  state: TunnelState | undefined;
  openconfigIfIpIpv4: InterfaceIpv4 | undefined;
  openconfigIfIpIpv6: InterfaceIpv6 | undefined;
}

export interface TunnelConfig {
  src: string;
  dst: string;
  ttl: number;
  greKey: number;
}

export interface TunnelCounters {
  saiTunnelStatInOctets: number;
  saiTunnelStatInPackets: number;
  saiTunnelStatOutOctets: number;
  saiTunnelStatOutPackets: number;
  tunnelName: string;
}

export interface TunnelRateCounters {
  rxBps: number;
  rxPps: number;
  saiTunnelStatInOctetsLast: number;
  saiTunnelStatInPacketsLast: number;
  saiTunnelStatOutOctetsLast: number;
  saiTunnelStatOutPacketsLast: number;
  txBps: number;
  txPps: number;
}

export interface TunnelState {
  src: string;
  dst: string;
  ttl: number;
  greKey: number;
}

export interface UnknownVlan {
  vlanId: number;
  port: string;
  packetCount: number;
  arrivalTime: number;
  origLenBytes: number;
  packetContents: Uint8Array;
}

export interface UnknownVlansRequest {
  requestContext: string;
  clear: boolean;
  includePacketData: boolean;
}

export interface UnknownVlansTable {
  error: boolean;
  errorMessage: string;
  requestContext: string;
  vlans: UnknownVlan[];
}

export interface VlanConfig {
  vlans: VlanState[];
  vlanMembers: VlanMemberInfo[];
}

export interface VlanMemberConfig {
  ifName: string;
  taggingMode: string;
}

export interface VlanMemberInfo {
  vlanId: number;
  members: VlanMemberConfig[];
}

export interface VlanState {
  adminStatus: string;
  hostIfname: string;
  mac: string;
  mtu: number;
  vlanId: number;
}

export interface VnetInfo {
  name: string;
  scope: string;
  vni: string;
  vxlanTunnel: string;
}

function createBaseAclRuleCounter(): AclRuleCounter {
  return { saiAclCounterAttrBytes: 0, saiAclCounterAttrPackets: 0, name: "" };
}

export const AclRuleCounter = {
  encode(message: AclRuleCounter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.saiAclCounterAttrBytes !== 0) {
      writer.uint32(16).uint64(message.saiAclCounterAttrBytes);
    }
    if (message.saiAclCounterAttrPackets !== 0) {
      writer.uint32(24).uint64(message.saiAclCounterAttrPackets);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AclRuleCounter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAclRuleCounter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.saiAclCounterAttrBytes = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.saiAclCounterAttrPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AclRuleCounter {
    return {
      saiAclCounterAttrBytes: isSet(object.SAI_ACL_COUNTER_ATTR_BYTES)
        ? globalThis.Number(object.SAI_ACL_COUNTER_ATTR_BYTES)
        : 0,
      saiAclCounterAttrPackets: isSet(object.SAI_ACL_COUNTER_ATTR_PACKETS)
        ? globalThis.Number(object.SAI_ACL_COUNTER_ATTR_PACKETS)
        : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: AclRuleCounter): unknown {
    const obj: any = {};
    if (message.saiAclCounterAttrBytes !== 0) {
      obj.SAI_ACL_COUNTER_ATTR_BYTES = Math.round(message.saiAclCounterAttrBytes);
    }
    if (message.saiAclCounterAttrPackets !== 0) {
      obj.SAI_ACL_COUNTER_ATTR_PACKETS = Math.round(message.saiAclCounterAttrPackets);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AclRuleCounter>, I>>(base?: I): AclRuleCounter {
    return AclRuleCounter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AclRuleCounter>, I>>(object: I): AclRuleCounter {
    const message = createBaseAclRuleCounter();
    message.saiAclCounterAttrBytes = object.saiAclCounterAttrBytes ?? 0;
    message.saiAclCounterAttrPackets = object.saiAclCounterAttrPackets ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseBufferPoolStat(): BufferPoolStat {
  return { saiBufferPoolStatWatermarkBytes: 0, saiBufferPoolStatXoffRoomWatermarkBytes: 0, poolName: "" };
}

export const BufferPoolStat = {
  encode(message: BufferPoolStat, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.saiBufferPoolStatWatermarkBytes !== 0) {
      writer.uint32(16).uint64(message.saiBufferPoolStatWatermarkBytes);
    }
    if (message.saiBufferPoolStatXoffRoomWatermarkBytes !== 0) {
      writer.uint32(24).uint64(message.saiBufferPoolStatXoffRoomWatermarkBytes);
    }
    if (message.poolName !== "") {
      writer.uint32(34).string(message.poolName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BufferPoolStat {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBufferPoolStat();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.saiBufferPoolStatWatermarkBytes = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.saiBufferPoolStatXoffRoomWatermarkBytes = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.poolName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BufferPoolStat {
    return {
      saiBufferPoolStatWatermarkBytes: isSet(object.SAI_BUFFER_POOL_STAT_WATERMARK_BYTES)
        ? globalThis.Number(object.SAI_BUFFER_POOL_STAT_WATERMARK_BYTES)
        : 0,
      saiBufferPoolStatXoffRoomWatermarkBytes: isSet(object.SAI_BUFFER_POOL_STAT_XOFF_ROOM_WATERMARK_BYTES)
        ? globalThis.Number(object.SAI_BUFFER_POOL_STAT_XOFF_ROOM_WATERMARK_BYTES)
        : 0,
      poolName: isSet(object.poolName) ? globalThis.String(object.poolName) : "",
    };
  },

  toJSON(message: BufferPoolStat): unknown {
    const obj: any = {};
    if (message.saiBufferPoolStatWatermarkBytes !== 0) {
      obj.SAI_BUFFER_POOL_STAT_WATERMARK_BYTES = Math.round(message.saiBufferPoolStatWatermarkBytes);
    }
    if (message.saiBufferPoolStatXoffRoomWatermarkBytes !== 0) {
      obj.SAI_BUFFER_POOL_STAT_XOFF_ROOM_WATERMARK_BYTES = Math.round(message.saiBufferPoolStatXoffRoomWatermarkBytes);
    }
    if (message.poolName !== "") {
      obj.poolName = message.poolName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BufferPoolStat>, I>>(base?: I): BufferPoolStat {
    return BufferPoolStat.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BufferPoolStat>, I>>(object: I): BufferPoolStat {
    const message = createBaseBufferPoolStat();
    message.saiBufferPoolStatWatermarkBytes = object.saiBufferPoolStatWatermarkBytes ?? 0;
    message.saiBufferPoolStatXoffRoomWatermarkBytes = object.saiBufferPoolStatXoffRoomWatermarkBytes ?? 0;
    message.poolName = object.poolName ?? "";
    return message;
  },
};

function createBaseChassisInfo(): ChassisInfo {
  return {
    model: "",
    psuNum: "",
    revision: "",
    serial: "",
    chassisName: "",
    lldpLocChassisId: "",
    sonicVersion: "",
    agentsVersion: "",
  };
}

export const ChassisInfo = {
  encode(message: ChassisInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.model !== "") {
      writer.uint32(18).string(message.model);
    }
    if (message.psuNum !== "") {
      writer.uint32(26).string(message.psuNum);
    }
    if (message.revision !== "") {
      writer.uint32(34).string(message.revision);
    }
    if (message.serial !== "") {
      writer.uint32(42).string(message.serial);
    }
    if (message.chassisName !== "") {
      writer.uint32(50).string(message.chassisName);
    }
    if (message.lldpLocChassisId !== "") {
      writer.uint32(58).string(message.lldpLocChassisId);
    }
    if (message.sonicVersion !== "") {
      writer.uint32(66).string(message.sonicVersion);
    }
    if (message.agentsVersion !== "") {
      writer.uint32(74).string(message.agentsVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChassisInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChassisInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.model = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.psuNum = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.revision = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.chassisName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lldpLocChassisId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.sonicVersion = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.agentsVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChassisInfo {
    return {
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      psuNum: isSet(object.psuNum) ? globalThis.String(object.psuNum) : "",
      revision: isSet(object.revision) ? globalThis.String(object.revision) : "",
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      chassisName: isSet(object.chassisName) ? globalThis.String(object.chassisName) : "",
      lldpLocChassisId: isSet(object.lldpLocChassisId) ? globalThis.String(object.lldpLocChassisId) : "",
      sonicVersion: isSet(object.sonicVersion) ? globalThis.String(object.sonicVersion) : "",
      agentsVersion: isSet(object.agentsVersion) ? globalThis.String(object.agentsVersion) : "",
    };
  },

  toJSON(message: ChassisInfo): unknown {
    const obj: any = {};
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.psuNum !== "") {
      obj.psuNum = message.psuNum;
    }
    if (message.revision !== "") {
      obj.revision = message.revision;
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.chassisName !== "") {
      obj.chassisName = message.chassisName;
    }
    if (message.lldpLocChassisId !== "") {
      obj.lldpLocChassisId = message.lldpLocChassisId;
    }
    if (message.sonicVersion !== "") {
      obj.sonicVersion = message.sonicVersion;
    }
    if (message.agentsVersion !== "") {
      obj.agentsVersion = message.agentsVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChassisInfo>, I>>(base?: I): ChassisInfo {
    return ChassisInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChassisInfo>, I>>(object: I): ChassisInfo {
    const message = createBaseChassisInfo();
    message.model = object.model ?? "";
    message.psuNum = object.psuNum ?? "";
    message.revision = object.revision ?? "";
    message.serial = object.serial ?? "";
    message.chassisName = object.chassisName ?? "";
    message.lldpLocChassisId = object.lldpLocChassisId ?? "";
    message.sonicVersion = object.sonicVersion ?? "";
    message.agentsVersion = object.agentsVersion ?? "";
    return message;
  },
};

function createBaseChassisInventory(): ChassisInventory {
  return { metaData: undefined, lldpTable: [], localChassisInfo: undefined, chassisPorts: [] };
}

export const ChassisInventory = {
  encode(message: ChassisInventory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.metaData !== undefined) {
      StatsMetadata.encode(message.metaData, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.lldpTable) {
      LldpEntry.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.localChassisInfo !== undefined) {
      ChassisInfo.encode(message.localChassisInfo, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.chassisPorts) {
      ChassisPort.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChassisInventory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChassisInventory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.metaData = StatsMetadata.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lldpTable.push(LldpEntry.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.localChassisInfo = ChassisInfo.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.chassisPorts.push(ChassisPort.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChassisInventory {
    return {
      metaData: isSet(object.metaData) ? StatsMetadata.fromJSON(object.metaData) : undefined,
      lldpTable: globalThis.Array.isArray(object?.lldpTable)
        ? object.lldpTable.map((e: any) => LldpEntry.fromJSON(e))
        : [],
      localChassisInfo: isSet(object.localChassisInfo) ? ChassisInfo.fromJSON(object.localChassisInfo) : undefined,
      chassisPorts: globalThis.Array.isArray(object?.chassisPorts)
        ? object.chassisPorts.map((e: any) => ChassisPort.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ChassisInventory): unknown {
    const obj: any = {};
    if (message.metaData !== undefined) {
      obj.metaData = StatsMetadata.toJSON(message.metaData);
    }
    if (message.lldpTable?.length) {
      obj.lldpTable = message.lldpTable.map((e) => LldpEntry.toJSON(e));
    }
    if (message.localChassisInfo !== undefined) {
      obj.localChassisInfo = ChassisInfo.toJSON(message.localChassisInfo);
    }
    if (message.chassisPorts?.length) {
      obj.chassisPorts = message.chassisPorts.map((e) => ChassisPort.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChassisInventory>, I>>(base?: I): ChassisInventory {
    return ChassisInventory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChassisInventory>, I>>(object: I): ChassisInventory {
    const message = createBaseChassisInventory();
    message.metaData = (object.metaData !== undefined && object.metaData !== null)
      ? StatsMetadata.fromPartial(object.metaData)
      : undefined;
    message.lldpTable = object.lldpTable?.map((e) => LldpEntry.fromPartial(e)) || [];
    message.localChassisInfo = (object.localChassisInfo !== undefined && object.localChassisInfo !== null)
      ? ChassisInfo.fromPartial(object.localChassisInfo)
      : undefined;
    message.chassisPorts = object.chassisPorts?.map((e) => ChassisPort.fromPartial(e)) || [];
    return message;
  },
};

function createBaseChassisPort(): ChassisPort {
  return { portName: "", alias: "", lanes: "", index: 0, mtu: 0, speed: "" };
}

export const ChassisPort = {
  encode(message: ChassisPort, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.portName !== "") {
      writer.uint32(18).string(message.portName);
    }
    if (message.alias !== "") {
      writer.uint32(26).string(message.alias);
    }
    if (message.lanes !== "") {
      writer.uint32(34).string(message.lanes);
    }
    if (message.index !== 0) {
      writer.uint32(40).uint32(message.index);
    }
    if (message.mtu !== 0) {
      writer.uint32(48).uint32(message.mtu);
    }
    if (message.speed !== "") {
      writer.uint32(58).string(message.speed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChassisPort {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChassisPort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.alias = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lanes = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.index = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.speed = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChassisPort {
    return {
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      alias: isSet(object.alias) ? globalThis.String(object.alias) : "",
      lanes: isSet(object.lanes) ? globalThis.String(object.lanes) : "",
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      speed: isSet(object.speed) ? globalThis.String(object.speed) : "",
    };
  },

  toJSON(message: ChassisPort): unknown {
    const obj: any = {};
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.alias !== "") {
      obj.alias = message.alias;
    }
    if (message.lanes !== "") {
      obj.lanes = message.lanes;
    }
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.speed !== "") {
      obj.speed = message.speed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChassisPort>, I>>(base?: I): ChassisPort {
    return ChassisPort.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChassisPort>, I>>(object: I): ChassisPort {
    const message = createBaseChassisPort();
    message.portName = object.portName ?? "";
    message.alias = object.alias ?? "";
    message.lanes = object.lanes ?? "";
    message.index = object.index ?? 0;
    message.mtu = object.mtu ?? 0;
    message.speed = object.speed ?? "";
    return message;
  },
};

function createBaseCounterStats(): CounterStats {
  return {
    statsInfo: undefined,
    chassisInfo: undefined,
    interfaceStats: [],
    rifStats: [],
    tunnelStats: [],
    queueStats: [],
    pgWmstats: [],
    bufferPoolStats: [],
    fabricPortStats: [],
    aclRuleStats: [],
  };
}

export const CounterStats = {
  encode(message: CounterStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statsInfo !== undefined) {
      StatsMetadata.encode(message.statsInfo, writer.uint32(18).fork()).ldelim();
    }
    if (message.chassisInfo !== undefined) {
      ChassisInfo.encode(message.chassisInfo, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.interfaceStats) {
      SaiPortCounters.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.rifStats) {
      RifCounters.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.tunnelStats) {
      TunnelCounters.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.queueStats) {
      QueueStatCounters.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.pgWmstats) {
      PgWatermarkCounters.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.bufferPoolStats) {
      BufferPoolStat.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.fabricPortStats) {
      FabricPortCounter.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.aclRuleStats) {
      AclRuleCounter.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CounterStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCounterStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.statsInfo = StatsMetadata.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.chassisInfo = ChassisInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.interfaceStats.push(SaiPortCounters.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.rifStats.push(RifCounters.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tunnelStats.push(TunnelCounters.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.queueStats.push(QueueStatCounters.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.pgWmstats.push(PgWatermarkCounters.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.bufferPoolStats.push(BufferPoolStat.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.fabricPortStats.push(FabricPortCounter.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.aclRuleStats.push(AclRuleCounter.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CounterStats {
    return {
      statsInfo: isSet(object.statsInfo) ? StatsMetadata.fromJSON(object.statsInfo) : undefined,
      chassisInfo: isSet(object.chassisInfo) ? ChassisInfo.fromJSON(object.chassisInfo) : undefined,
      interfaceStats: globalThis.Array.isArray(object?.interfaceStats)
        ? object.interfaceStats.map((e: any) => SaiPortCounters.fromJSON(e))
        : [],
      rifStats: globalThis.Array.isArray(object?.rifStats)
        ? object.rifStats.map((e: any) => RifCounters.fromJSON(e))
        : [],
      tunnelStats: globalThis.Array.isArray(object?.tunnelStats)
        ? object.tunnelStats.map((e: any) => TunnelCounters.fromJSON(e))
        : [],
      queueStats: globalThis.Array.isArray(object?.queueStats)
        ? object.queueStats.map((e: any) => QueueStatCounters.fromJSON(e))
        : [],
      pgWmstats: globalThis.Array.isArray(object?.pgWmstats)
        ? object.pgWmstats.map((e: any) => PgWatermarkCounters.fromJSON(e))
        : [],
      bufferPoolStats: globalThis.Array.isArray(object?.bufferPoolStats)
        ? object.bufferPoolStats.map((e: any) => BufferPoolStat.fromJSON(e))
        : [],
      fabricPortStats: globalThis.Array.isArray(object?.fabricPortStats)
        ? object.fabricPortStats.map((e: any) => FabricPortCounter.fromJSON(e))
        : [],
      aclRuleStats: globalThis.Array.isArray(object?.aclRuleStats)
        ? object.aclRuleStats.map((e: any) => AclRuleCounter.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CounterStats): unknown {
    const obj: any = {};
    if (message.statsInfo !== undefined) {
      obj.statsInfo = StatsMetadata.toJSON(message.statsInfo);
    }
    if (message.chassisInfo !== undefined) {
      obj.chassisInfo = ChassisInfo.toJSON(message.chassisInfo);
    }
    if (message.interfaceStats?.length) {
      obj.interfaceStats = message.interfaceStats.map((e) => SaiPortCounters.toJSON(e));
    }
    if (message.rifStats?.length) {
      obj.rifStats = message.rifStats.map((e) => RifCounters.toJSON(e));
    }
    if (message.tunnelStats?.length) {
      obj.tunnelStats = message.tunnelStats.map((e) => TunnelCounters.toJSON(e));
    }
    if (message.queueStats?.length) {
      obj.queueStats = message.queueStats.map((e) => QueueStatCounters.toJSON(e));
    }
    if (message.pgWmstats?.length) {
      obj.pgWmstats = message.pgWmstats.map((e) => PgWatermarkCounters.toJSON(e));
    }
    if (message.bufferPoolStats?.length) {
      obj.bufferPoolStats = message.bufferPoolStats.map((e) => BufferPoolStat.toJSON(e));
    }
    if (message.fabricPortStats?.length) {
      obj.fabricPortStats = message.fabricPortStats.map((e) => FabricPortCounter.toJSON(e));
    }
    if (message.aclRuleStats?.length) {
      obj.aclRuleStats = message.aclRuleStats.map((e) => AclRuleCounter.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CounterStats>, I>>(base?: I): CounterStats {
    return CounterStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CounterStats>, I>>(object: I): CounterStats {
    const message = createBaseCounterStats();
    message.statsInfo = (object.statsInfo !== undefined && object.statsInfo !== null)
      ? StatsMetadata.fromPartial(object.statsInfo)
      : undefined;
    message.chassisInfo = (object.chassisInfo !== undefined && object.chassisInfo !== null)
      ? ChassisInfo.fromPartial(object.chassisInfo)
      : undefined;
    message.interfaceStats = object.interfaceStats?.map((e) => SaiPortCounters.fromPartial(e)) || [];
    message.rifStats = object.rifStats?.map((e) => RifCounters.fromPartial(e)) || [];
    message.tunnelStats = object.tunnelStats?.map((e) => TunnelCounters.fromPartial(e)) || [];
    message.queueStats = object.queueStats?.map((e) => QueueStatCounters.fromPartial(e)) || [];
    message.pgWmstats = object.pgWmstats?.map((e) => PgWatermarkCounters.fromPartial(e)) || [];
    message.bufferPoolStats = object.bufferPoolStats?.map((e) => BufferPoolStat.fromPartial(e)) || [];
    message.fabricPortStats = object.fabricPortStats?.map((e) => FabricPortCounter.fromPartial(e)) || [];
    message.aclRuleStats = object.aclRuleStats?.map((e) => AclRuleCounter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCounters(): Counters {
  return {
    inOctets: 0,
    inPkts: 0,
    inUnicastPkts: 0,
    inBroadcastPkts: 0,
    inMulticastPkts: 0,
    inDiscards: 0,
    inErrors: 0,
    inUnknownProtos: 0,
    inFcsErrors: 0,
    outOctets: 0,
    outPkts: 0,
    outUnicastPkts: 0,
    outBroadcastPkts: 0,
    outMulticastPkts: 0,
    outDiscards: 0,
    outErrors: 0,
    carrierTransitions: 0,
    lastClear: undefined,
  };
}

export const Counters = {
  encode(message: Counters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.inOctets !== 0) {
      writer.uint32(16).uint64(message.inOctets);
    }
    if (message.inPkts !== 0) {
      writer.uint32(24).uint64(message.inPkts);
    }
    if (message.inUnicastPkts !== 0) {
      writer.uint32(32).uint64(message.inUnicastPkts);
    }
    if (message.inBroadcastPkts !== 0) {
      writer.uint32(40).uint64(message.inBroadcastPkts);
    }
    if (message.inMulticastPkts !== 0) {
      writer.uint32(48).uint64(message.inMulticastPkts);
    }
    if (message.inDiscards !== 0) {
      writer.uint32(56).uint64(message.inDiscards);
    }
    if (message.inErrors !== 0) {
      writer.uint32(64).uint64(message.inErrors);
    }
    if (message.inUnknownProtos !== 0) {
      writer.uint32(72).uint64(message.inUnknownProtos);
    }
    if (message.inFcsErrors !== 0) {
      writer.uint32(80).uint64(message.inFcsErrors);
    }
    if (message.outOctets !== 0) {
      writer.uint32(88).uint64(message.outOctets);
    }
    if (message.outPkts !== 0) {
      writer.uint32(96).uint64(message.outPkts);
    }
    if (message.outUnicastPkts !== 0) {
      writer.uint32(104).uint64(message.outUnicastPkts);
    }
    if (message.outBroadcastPkts !== 0) {
      writer.uint32(112).uint64(message.outBroadcastPkts);
    }
    if (message.outMulticastPkts !== 0) {
      writer.uint32(120).uint64(message.outMulticastPkts);
    }
    if (message.outDiscards !== 0) {
      writer.uint32(128).uint64(message.outDiscards);
    }
    if (message.outErrors !== 0) {
      writer.uint32(136).uint64(message.outErrors);
    }
    if (message.carrierTransitions !== 0) {
      writer.uint32(144).uint64(message.carrierTransitions);
    }
    if (message.lastClear !== undefined) {
      Timestamp.encode(toTimestamp(message.lastClear), writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Counters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.inOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.inPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.inUnicastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.inBroadcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.inMulticastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.inDiscards = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.inErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.inUnknownProtos = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.inFcsErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.outOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.outPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.outUnicastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.outBroadcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.outMulticastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.outDiscards = longToNumber(reader.uint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.outErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.carrierTransitions = longToNumber(reader.uint64() as Long);
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.lastClear = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Counters {
    return {
      inOctets: isSet(object["in-octets"]) ? globalThis.Number(object["in-octets"]) : 0,
      inPkts: isSet(object["in-pkts"]) ? globalThis.Number(object["in-pkts"]) : 0,
      inUnicastPkts: isSet(object["in-unicast-pkts"]) ? globalThis.Number(object["in-unicast-pkts"]) : 0,
      inBroadcastPkts: isSet(object["in-broadcast-pkts"]) ? globalThis.Number(object["in-broadcast-pkts"]) : 0,
      inMulticastPkts: isSet(object["in-multicast-pkts"]) ? globalThis.Number(object["in-multicast-pkts"]) : 0,
      inDiscards: isSet(object["in-discards"]) ? globalThis.Number(object["in-discards"]) : 0,
      inErrors: isSet(object["in-errors"]) ? globalThis.Number(object["in-errors"]) : 0,
      inUnknownProtos: isSet(object["in-unknown-protos"]) ? globalThis.Number(object["in-unknown-protos"]) : 0,
      inFcsErrors: isSet(object["in-fcs-errors"]) ? globalThis.Number(object["in-fcs-errors"]) : 0,
      outOctets: isSet(object["out-octets"]) ? globalThis.Number(object["out-octets"]) : 0,
      outPkts: isSet(object["out-pkts"]) ? globalThis.Number(object["out-pkts"]) : 0,
      outUnicastPkts: isSet(object["out-unicast-pkts"]) ? globalThis.Number(object["out-unicast-pkts"]) : 0,
      outBroadcastPkts: isSet(object["out-broadcast-pkts"]) ? globalThis.Number(object["out-broadcast-pkts"]) : 0,
      outMulticastPkts: isSet(object["out-multicast-pkts"]) ? globalThis.Number(object["out-multicast-pkts"]) : 0,
      outDiscards: isSet(object["out-discards"]) ? globalThis.Number(object["out-discards"]) : 0,
      outErrors: isSet(object["out-errors"]) ? globalThis.Number(object["out-errors"]) : 0,
      carrierTransitions: isSet(object["carrier-transitions"]) ? globalThis.Number(object["carrier-transitions"]) : 0,
      lastClear: isSet(object["last-clear"]) ? fromJsonTimestamp(object["last-clear"]) : undefined,
    };
  },

  toJSON(message: Counters): unknown {
    const obj: any = {};
    if (message.inOctets !== 0) {
      obj["in-octets"] = Math.round(message.inOctets);
    }
    if (message.inPkts !== 0) {
      obj["in-pkts"] = Math.round(message.inPkts);
    }
    if (message.inUnicastPkts !== 0) {
      obj["in-unicast-pkts"] = Math.round(message.inUnicastPkts);
    }
    if (message.inBroadcastPkts !== 0) {
      obj["in-broadcast-pkts"] = Math.round(message.inBroadcastPkts);
    }
    if (message.inMulticastPkts !== 0) {
      obj["in-multicast-pkts"] = Math.round(message.inMulticastPkts);
    }
    if (message.inDiscards !== 0) {
      obj["in-discards"] = Math.round(message.inDiscards);
    }
    if (message.inErrors !== 0) {
      obj["in-errors"] = Math.round(message.inErrors);
    }
    if (message.inUnknownProtos !== 0) {
      obj["in-unknown-protos"] = Math.round(message.inUnknownProtos);
    }
    if (message.inFcsErrors !== 0) {
      obj["in-fcs-errors"] = Math.round(message.inFcsErrors);
    }
    if (message.outOctets !== 0) {
      obj["out-octets"] = Math.round(message.outOctets);
    }
    if (message.outPkts !== 0) {
      obj["out-pkts"] = Math.round(message.outPkts);
    }
    if (message.outUnicastPkts !== 0) {
      obj["out-unicast-pkts"] = Math.round(message.outUnicastPkts);
    }
    if (message.outBroadcastPkts !== 0) {
      obj["out-broadcast-pkts"] = Math.round(message.outBroadcastPkts);
    }
    if (message.outMulticastPkts !== 0) {
      obj["out-multicast-pkts"] = Math.round(message.outMulticastPkts);
    }
    if (message.outDiscards !== 0) {
      obj["out-discards"] = Math.round(message.outDiscards);
    }
    if (message.outErrors !== 0) {
      obj["out-errors"] = Math.round(message.outErrors);
    }
    if (message.carrierTransitions !== 0) {
      obj["carrier-transitions"] = Math.round(message.carrierTransitions);
    }
    if (message.lastClear !== undefined) {
      obj["last-clear"] = message.lastClear.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Counters>, I>>(base?: I): Counters {
    return Counters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Counters>, I>>(object: I): Counters {
    const message = createBaseCounters();
    message.inOctets = object.inOctets ?? 0;
    message.inPkts = object.inPkts ?? 0;
    message.inUnicastPkts = object.inUnicastPkts ?? 0;
    message.inBroadcastPkts = object.inBroadcastPkts ?? 0;
    message.inMulticastPkts = object.inMulticastPkts ?? 0;
    message.inDiscards = object.inDiscards ?? 0;
    message.inErrors = object.inErrors ?? 0;
    message.inUnknownProtos = object.inUnknownProtos ?? 0;
    message.inFcsErrors = object.inFcsErrors ?? 0;
    message.outOctets = object.outOctets ?? 0;
    message.outPkts = object.outPkts ?? 0;
    message.outUnicastPkts = object.outUnicastPkts ?? 0;
    message.outBroadcastPkts = object.outBroadcastPkts ?? 0;
    message.outMulticastPkts = object.outMulticastPkts ?? 0;
    message.outDiscards = object.outDiscards ?? 0;
    message.outErrors = object.outErrors ?? 0;
    message.carrierTransitions = object.carrierTransitions ?? 0;
    message.lastClear = object.lastClear ?? undefined;
    return message;
  },
};

function createBaseDnsConfig(): DnsConfig {
  return { dnsServers: [] };
}

export const DnsConfig = {
  encode(message: DnsConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dnsServers) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DnsConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDnsConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dnsServers.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DnsConfig {
    return {
      dnsServers: globalThis.Array.isArray(object?.dnsServers)
        ? object.dnsServers.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: DnsConfig): unknown {
    const obj: any = {};
    if (message.dnsServers?.length) {
      obj.dnsServers = message.dnsServers;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DnsConfig>, I>>(base?: I): DnsConfig {
    return DnsConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DnsConfig>, I>>(object: I): DnsConfig {
    const message = createBaseDnsConfig();
    message.dnsServers = object.dnsServers?.map((e) => e) || [];
    return message;
  },
};

function createBaseDrakeConfig(): DrakeConfig {
  return { cloudBaseUrlList: [] };
}

export const DrakeConfig = {
  encode(message: DrakeConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.cloudBaseUrlList) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DrakeConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDrakeConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cloudBaseUrlList.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DrakeConfig {
    return {
      cloudBaseUrlList: globalThis.Array.isArray(object?.cloudBaseUrlList)
        ? object.cloudBaseUrlList.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: DrakeConfig): unknown {
    const obj: any = {};
    if (message.cloudBaseUrlList?.length) {
      obj.cloudBaseUrlList = message.cloudBaseUrlList;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DrakeConfig>, I>>(base?: I): DrakeConfig {
    return DrakeConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DrakeConfig>, I>>(object: I): DrakeConfig {
    const message = createBaseDrakeConfig();
    message.cloudBaseUrlList = object.cloudBaseUrlList?.map((e) => e) || [];
    return message;
  },
};

function createBaseEthernetCounters(): EthernetCounters {
  return {
    inMacControlFrames: 0,
    inMacPauseFrames: 0,
    inOversizeFrames: 0,
    inUndersizeFrames: 0,
    inJabberFrames: 0,
    inFragmentFrames: 0,
    in8021qFrames: 0,
    inCrcErrors: 0,
    inBlockErrors: 0,
    inCarrierErrors: 0,
    inInterruptedTx: 0,
    inLateCollision: 0,
    inMacErrorsRx: 0,
    inSingleCollision: 0,
    inSymbolError: 0,
    inMaxsizeExceeded: 0,
    outMacControlFrames: 0,
    outMacPauseFrames: 0,
    out8021qFrames: 0,
    outMacErrorsTx: 0,
    inDistributionCounters: undefined,
  };
}

export const EthernetCounters = {
  encode(message: EthernetCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.inMacControlFrames !== 0) {
      writer.uint32(16).uint64(message.inMacControlFrames);
    }
    if (message.inMacPauseFrames !== 0) {
      writer.uint32(24).uint64(message.inMacPauseFrames);
    }
    if (message.inOversizeFrames !== 0) {
      writer.uint32(32).uint64(message.inOversizeFrames);
    }
    if (message.inUndersizeFrames !== 0) {
      writer.uint32(40).uint64(message.inUndersizeFrames);
    }
    if (message.inJabberFrames !== 0) {
      writer.uint32(48).uint64(message.inJabberFrames);
    }
    if (message.inFragmentFrames !== 0) {
      writer.uint32(56).uint64(message.inFragmentFrames);
    }
    if (message.in8021qFrames !== 0) {
      writer.uint32(64).uint64(message.in8021qFrames);
    }
    if (message.inCrcErrors !== 0) {
      writer.uint32(72).uint64(message.inCrcErrors);
    }
    if (message.inBlockErrors !== 0) {
      writer.uint32(80).uint64(message.inBlockErrors);
    }
    if (message.inCarrierErrors !== 0) {
      writer.uint32(88).uint64(message.inCarrierErrors);
    }
    if (message.inInterruptedTx !== 0) {
      writer.uint32(96).uint64(message.inInterruptedTx);
    }
    if (message.inLateCollision !== 0) {
      writer.uint32(104).uint64(message.inLateCollision);
    }
    if (message.inMacErrorsRx !== 0) {
      writer.uint32(112).uint64(message.inMacErrorsRx);
    }
    if (message.inSingleCollision !== 0) {
      writer.uint32(120).uint64(message.inSingleCollision);
    }
    if (message.inSymbolError !== 0) {
      writer.uint32(128).uint64(message.inSymbolError);
    }
    if (message.inMaxsizeExceeded !== 0) {
      writer.uint32(136).uint64(message.inMaxsizeExceeded);
    }
    if (message.outMacControlFrames !== 0) {
      writer.uint32(144).uint64(message.outMacControlFrames);
    }
    if (message.outMacPauseFrames !== 0) {
      writer.uint32(152).uint64(message.outMacPauseFrames);
    }
    if (message.out8021qFrames !== 0) {
      writer.uint32(160).uint64(message.out8021qFrames);
    }
    if (message.outMacErrorsTx !== 0) {
      writer.uint32(168).uint64(message.outMacErrorsTx);
    }
    if (message.inDistributionCounters !== undefined) {
      EthernetPacketsInDistribution.encode(message.inDistributionCounters, writer.uint32(178).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EthernetCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEthernetCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.inMacControlFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.inMacPauseFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.inOversizeFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.inUndersizeFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.inJabberFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.inFragmentFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.in8021qFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.inCrcErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.inBlockErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.inCarrierErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.inInterruptedTx = longToNumber(reader.uint64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.inLateCollision = longToNumber(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.inMacErrorsRx = longToNumber(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.inSingleCollision = longToNumber(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.inSymbolError = longToNumber(reader.uint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.inMaxsizeExceeded = longToNumber(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.outMacControlFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.outMacPauseFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.out8021qFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.outMacErrorsTx = longToNumber(reader.uint64() as Long);
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.inDistributionCounters = EthernetPacketsInDistribution.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EthernetCounters {
    return {
      inMacControlFrames: isSet(object["in-mac-control-frames"])
        ? globalThis.Number(object["in-mac-control-frames"])
        : 0,
      inMacPauseFrames: isSet(object["in-mac-pause-frames"]) ? globalThis.Number(object["in-mac-pause-frames"]) : 0,
      inOversizeFrames: isSet(object["in-oversize-frames"]) ? globalThis.Number(object["in-oversize-frames"]) : 0,
      inUndersizeFrames: isSet(object["in-undersize-frames"]) ? globalThis.Number(object["in-undersize-frames"]) : 0,
      inJabberFrames: isSet(object["in-jabber-frames"]) ? globalThis.Number(object["in-jabber-frames"]) : 0,
      inFragmentFrames: isSet(object["in-fragment-frames"]) ? globalThis.Number(object["in-fragment-frames"]) : 0,
      in8021qFrames: isSet(object["in-8021q-frames"]) ? globalThis.Number(object["in-8021q-frames"]) : 0,
      inCrcErrors: isSet(object["in-crc-errors"]) ? globalThis.Number(object["in-crc-errors"]) : 0,
      inBlockErrors: isSet(object["in-block-errors"]) ? globalThis.Number(object["in-block-errors"]) : 0,
      inCarrierErrors: isSet(object["in-carrier-errors"]) ? globalThis.Number(object["in-carrier-errors"]) : 0,
      inInterruptedTx: isSet(object["in-interrupted-tx"]) ? globalThis.Number(object["in-interrupted-tx"]) : 0,
      inLateCollision: isSet(object["in-late-collision"]) ? globalThis.Number(object["in-late-collision"]) : 0,
      inMacErrorsRx: isSet(object["in-mac-errors-rx"]) ? globalThis.Number(object["in-mac-errors-rx"]) : 0,
      inSingleCollision: isSet(object["in-single-collision"]) ? globalThis.Number(object["in-single-collision"]) : 0,
      inSymbolError: isSet(object["in-symbol-error"]) ? globalThis.Number(object["in-symbol-error"]) : 0,
      inMaxsizeExceeded: isSet(object["in-maxsize-exceeded"]) ? globalThis.Number(object["in-maxsize-exceeded"]) : 0,
      outMacControlFrames: isSet(object["out-mac-control-frames"])
        ? globalThis.Number(object["out-mac-control-frames"])
        : 0,
      outMacPauseFrames: isSet(object["out-mac-pause-frames"]) ? globalThis.Number(object["out-mac-pause-frames"]) : 0,
      out8021qFrames: isSet(object["out-8021q-frames"]) ? globalThis.Number(object["out-8021q-frames"]) : 0,
      outMacErrorsTx: isSet(object["out-mac-errors-tx"]) ? globalThis.Number(object["out-mac-errors-tx"]) : 0,
      inDistributionCounters: isSet(object.inDistributionCounters)
        ? EthernetPacketsInDistribution.fromJSON(object.inDistributionCounters)
        : undefined,
    };
  },

  toJSON(message: EthernetCounters): unknown {
    const obj: any = {};
    if (message.inMacControlFrames !== 0) {
      obj["in-mac-control-frames"] = Math.round(message.inMacControlFrames);
    }
    if (message.inMacPauseFrames !== 0) {
      obj["in-mac-pause-frames"] = Math.round(message.inMacPauseFrames);
    }
    if (message.inOversizeFrames !== 0) {
      obj["in-oversize-frames"] = Math.round(message.inOversizeFrames);
    }
    if (message.inUndersizeFrames !== 0) {
      obj["in-undersize-frames"] = Math.round(message.inUndersizeFrames);
    }
    if (message.inJabberFrames !== 0) {
      obj["in-jabber-frames"] = Math.round(message.inJabberFrames);
    }
    if (message.inFragmentFrames !== 0) {
      obj["in-fragment-frames"] = Math.round(message.inFragmentFrames);
    }
    if (message.in8021qFrames !== 0) {
      obj["in-8021q-frames"] = Math.round(message.in8021qFrames);
    }
    if (message.inCrcErrors !== 0) {
      obj["in-crc-errors"] = Math.round(message.inCrcErrors);
    }
    if (message.inBlockErrors !== 0) {
      obj["in-block-errors"] = Math.round(message.inBlockErrors);
    }
    if (message.inCarrierErrors !== 0) {
      obj["in-carrier-errors"] = Math.round(message.inCarrierErrors);
    }
    if (message.inInterruptedTx !== 0) {
      obj["in-interrupted-tx"] = Math.round(message.inInterruptedTx);
    }
    if (message.inLateCollision !== 0) {
      obj["in-late-collision"] = Math.round(message.inLateCollision);
    }
    if (message.inMacErrorsRx !== 0) {
      obj["in-mac-errors-rx"] = Math.round(message.inMacErrorsRx);
    }
    if (message.inSingleCollision !== 0) {
      obj["in-single-collision"] = Math.round(message.inSingleCollision);
    }
    if (message.inSymbolError !== 0) {
      obj["in-symbol-error"] = Math.round(message.inSymbolError);
    }
    if (message.inMaxsizeExceeded !== 0) {
      obj["in-maxsize-exceeded"] = Math.round(message.inMaxsizeExceeded);
    }
    if (message.outMacControlFrames !== 0) {
      obj["out-mac-control-frames"] = Math.round(message.outMacControlFrames);
    }
    if (message.outMacPauseFrames !== 0) {
      obj["out-mac-pause-frames"] = Math.round(message.outMacPauseFrames);
    }
    if (message.out8021qFrames !== 0) {
      obj["out-8021q-frames"] = Math.round(message.out8021qFrames);
    }
    if (message.outMacErrorsTx !== 0) {
      obj["out-mac-errors-tx"] = Math.round(message.outMacErrorsTx);
    }
    if (message.inDistributionCounters !== undefined) {
      obj.inDistributionCounters = EthernetPacketsInDistribution.toJSON(message.inDistributionCounters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EthernetCounters>, I>>(base?: I): EthernetCounters {
    return EthernetCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EthernetCounters>, I>>(object: I): EthernetCounters {
    const message = createBaseEthernetCounters();
    message.inMacControlFrames = object.inMacControlFrames ?? 0;
    message.inMacPauseFrames = object.inMacPauseFrames ?? 0;
    message.inOversizeFrames = object.inOversizeFrames ?? 0;
    message.inUndersizeFrames = object.inUndersizeFrames ?? 0;
    message.inJabberFrames = object.inJabberFrames ?? 0;
    message.inFragmentFrames = object.inFragmentFrames ?? 0;
    message.in8021qFrames = object.in8021qFrames ?? 0;
    message.inCrcErrors = object.inCrcErrors ?? 0;
    message.inBlockErrors = object.inBlockErrors ?? 0;
    message.inCarrierErrors = object.inCarrierErrors ?? 0;
    message.inInterruptedTx = object.inInterruptedTx ?? 0;
    message.inLateCollision = object.inLateCollision ?? 0;
    message.inMacErrorsRx = object.inMacErrorsRx ?? 0;
    message.inSingleCollision = object.inSingleCollision ?? 0;
    message.inSymbolError = object.inSymbolError ?? 0;
    message.inMaxsizeExceeded = object.inMaxsizeExceeded ?? 0;
    message.outMacControlFrames = object.outMacControlFrames ?? 0;
    message.outMacPauseFrames = object.outMacPauseFrames ?? 0;
    message.out8021qFrames = object.out8021qFrames ?? 0;
    message.outMacErrorsTx = object.outMacErrorsTx ?? 0;
    message.inDistributionCounters =
      (object.inDistributionCounters !== undefined && object.inDistributionCounters !== null)
        ? EthernetPacketsInDistribution.fromPartial(object.inDistributionCounters)
        : undefined;
    return message;
  },
};

function createBaseEthernetPacketsInDistribution(): EthernetPacketsInDistribution {
  return {
    inFrames64Octets: 0,
    inFrames65127Octets: 0,
    inFrames128255Octets: 0,
    inFrames256511Octets: 0,
    inFrames5121023Octets: 0,
    inFrames10241518Octets: 0,
  };
}

export const EthernetPacketsInDistribution = {
  encode(message: EthernetPacketsInDistribution, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.inFrames64Octets !== 0) {
      writer.uint32(16).uint64(message.inFrames64Octets);
    }
    if (message.inFrames65127Octets !== 0) {
      writer.uint32(24).uint64(message.inFrames65127Octets);
    }
    if (message.inFrames128255Octets !== 0) {
      writer.uint32(32).uint64(message.inFrames128255Octets);
    }
    if (message.inFrames256511Octets !== 0) {
      writer.uint32(40).uint64(message.inFrames256511Octets);
    }
    if (message.inFrames5121023Octets !== 0) {
      writer.uint32(48).uint64(message.inFrames5121023Octets);
    }
    if (message.inFrames10241518Octets !== 0) {
      writer.uint32(56).uint64(message.inFrames10241518Octets);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EthernetPacketsInDistribution {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEthernetPacketsInDistribution();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.inFrames64Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.inFrames65127Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.inFrames128255Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.inFrames256511Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.inFrames5121023Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.inFrames10241518Octets = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EthernetPacketsInDistribution {
    return {
      inFrames64Octets: isSet(object["in-frames-64-octets"]) ? globalThis.Number(object["in-frames-64-octets"]) : 0,
      inFrames65127Octets: isSet(object["in-frames-65-127-octets"])
        ? globalThis.Number(object["in-frames-65-127-octets"])
        : 0,
      inFrames128255Octets: isSet(object["in-frames-128-255-octets"])
        ? globalThis.Number(object["in-frames-128-255-octets"])
        : 0,
      inFrames256511Octets: isSet(object["in-frames-256-511-octets"])
        ? globalThis.Number(object["in-frames-256-511-octets"])
        : 0,
      inFrames5121023Octets: isSet(object["in-frames-512-1023-octets"])
        ? globalThis.Number(object["in-frames-512-1023-octets"])
        : 0,
      inFrames10241518Octets: isSet(object["in-frames-1024-1518-octets"])
        ? globalThis.Number(object["in-frames-1024-1518-octets"])
        : 0,
    };
  },

  toJSON(message: EthernetPacketsInDistribution): unknown {
    const obj: any = {};
    if (message.inFrames64Octets !== 0) {
      obj["in-frames-64-octets"] = Math.round(message.inFrames64Octets);
    }
    if (message.inFrames65127Octets !== 0) {
      obj["in-frames-65-127-octets"] = Math.round(message.inFrames65127Octets);
    }
    if (message.inFrames128255Octets !== 0) {
      obj["in-frames-128-255-octets"] = Math.round(message.inFrames128255Octets);
    }
    if (message.inFrames256511Octets !== 0) {
      obj["in-frames-256-511-octets"] = Math.round(message.inFrames256511Octets);
    }
    if (message.inFrames5121023Octets !== 0) {
      obj["in-frames-512-1023-octets"] = Math.round(message.inFrames5121023Octets);
    }
    if (message.inFrames10241518Octets !== 0) {
      obj["in-frames-1024-1518-octets"] = Math.round(message.inFrames10241518Octets);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EthernetPacketsInDistribution>, I>>(base?: I): EthernetPacketsInDistribution {
    return EthernetPacketsInDistribution.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EthernetPacketsInDistribution>, I>>(
    object: I,
  ): EthernetPacketsInDistribution {
    const message = createBaseEthernetPacketsInDistribution();
    message.inFrames64Octets = object.inFrames64Octets ?? 0;
    message.inFrames65127Octets = object.inFrames65127Octets ?? 0;
    message.inFrames128255Octets = object.inFrames128255Octets ?? 0;
    message.inFrames256511Octets = object.inFrames256511Octets ?? 0;
    message.inFrames5121023Octets = object.inFrames5121023Octets ?? 0;
    message.inFrames10241518Octets = object.inFrames10241518Octets ?? 0;
    return message;
  },
};

function createBaseFabricPortCounter(): FabricPortCounter {
  return {
    saiPortStatIfInErrors: 0,
    saiPortStatIfInFabricDataUnits: 0,
    saiPortStatIfInFecCorrectableFrames: 0,
    saiPortStatIfInFecNotCorrectableFrames: 0,
    saiPortStatIfInFecSymbolErrors: 0,
    saiPortStatIfInOctets: 0,
    saiPortStatIfOutFabricDataUnits: 0,
    saiPortStatIfOutOctets: 0,
    portName: "",
  };
}

export const FabricPortCounter = {
  encode(message: FabricPortCounter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.saiPortStatIfInErrors !== 0) {
      writer.uint32(16).uint64(message.saiPortStatIfInErrors);
    }
    if (message.saiPortStatIfInFabricDataUnits !== 0) {
      writer.uint32(24).uint64(message.saiPortStatIfInFabricDataUnits);
    }
    if (message.saiPortStatIfInFecCorrectableFrames !== 0) {
      writer.uint32(32).uint64(message.saiPortStatIfInFecCorrectableFrames);
    }
    if (message.saiPortStatIfInFecNotCorrectableFrames !== 0) {
      writer.uint32(40).uint64(message.saiPortStatIfInFecNotCorrectableFrames);
    }
    if (message.saiPortStatIfInFecSymbolErrors !== 0) {
      writer.uint32(48).uint64(message.saiPortStatIfInFecSymbolErrors);
    }
    if (message.saiPortStatIfInOctets !== 0) {
      writer.uint32(56).uint64(message.saiPortStatIfInOctets);
    }
    if (message.saiPortStatIfOutFabricDataUnits !== 0) {
      writer.uint32(64).uint64(message.saiPortStatIfOutFabricDataUnits);
    }
    if (message.saiPortStatIfOutOctets !== 0) {
      writer.uint32(72).uint64(message.saiPortStatIfOutOctets);
    }
    if (message.portName !== "") {
      writer.uint32(82).string(message.portName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricPortCounter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricPortCounter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.saiPortStatIfInErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.saiPortStatIfInFabricDataUnits = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.saiPortStatIfInFecCorrectableFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.saiPortStatIfInFecNotCorrectableFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.saiPortStatIfInFecSymbolErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.saiPortStatIfInOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.saiPortStatIfOutFabricDataUnits = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.saiPortStatIfOutOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.portName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricPortCounter {
    return {
      saiPortStatIfInErrors: isSet(object.SAI_PORT_STAT_IF_IN_ERRORS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_ERRORS)
        : 0,
      saiPortStatIfInFabricDataUnits: isSet(object.SAI_PORT_STAT_IF_IN_FABRIC_DATA_UNITS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_FABRIC_DATA_UNITS)
        : 0,
      saiPortStatIfInFecCorrectableFrames: isSet(object.SAI_PORT_STAT_IF_IN_FEC_CORRECTABLE_FRAMES)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_FEC_CORRECTABLE_FRAMES)
        : 0,
      saiPortStatIfInFecNotCorrectableFrames: isSet(object.SAI_PORT_STAT_IF_IN_FEC_NOT_CORRECTABLE_FRAMES)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_FEC_NOT_CORRECTABLE_FRAMES)
        : 0,
      saiPortStatIfInFecSymbolErrors: isSet(object.SAI_PORT_STAT_IF_IN_FEC_SYMBOL_ERRORS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_FEC_SYMBOL_ERRORS)
        : 0,
      saiPortStatIfInOctets: isSet(object.SAI_PORT_STAT_IF_IN_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_OCTETS)
        : 0,
      saiPortStatIfOutFabricDataUnits: isSet(object.SAI_PORT_STAT_IF_OUT_FABRIC_DATA_UNITS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_FABRIC_DATA_UNITS)
        : 0,
      saiPortStatIfOutOctets: isSet(object.SAI_PORT_STAT_IF_OUT_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_OCTETS)
        : 0,
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
    };
  },

  toJSON(message: FabricPortCounter): unknown {
    const obj: any = {};
    if (message.saiPortStatIfInErrors !== 0) {
      obj.SAI_PORT_STAT_IF_IN_ERRORS = Math.round(message.saiPortStatIfInErrors);
    }
    if (message.saiPortStatIfInFabricDataUnits !== 0) {
      obj.SAI_PORT_STAT_IF_IN_FABRIC_DATA_UNITS = Math.round(message.saiPortStatIfInFabricDataUnits);
    }
    if (message.saiPortStatIfInFecCorrectableFrames !== 0) {
      obj.SAI_PORT_STAT_IF_IN_FEC_CORRECTABLE_FRAMES = Math.round(message.saiPortStatIfInFecCorrectableFrames);
    }
    if (message.saiPortStatIfInFecNotCorrectableFrames !== 0) {
      obj.SAI_PORT_STAT_IF_IN_FEC_NOT_CORRECTABLE_FRAMES = Math.round(message.saiPortStatIfInFecNotCorrectableFrames);
    }
    if (message.saiPortStatIfInFecSymbolErrors !== 0) {
      obj.SAI_PORT_STAT_IF_IN_FEC_SYMBOL_ERRORS = Math.round(message.saiPortStatIfInFecSymbolErrors);
    }
    if (message.saiPortStatIfInOctets !== 0) {
      obj.SAI_PORT_STAT_IF_IN_OCTETS = Math.round(message.saiPortStatIfInOctets);
    }
    if (message.saiPortStatIfOutFabricDataUnits !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_FABRIC_DATA_UNITS = Math.round(message.saiPortStatIfOutFabricDataUnits);
    }
    if (message.saiPortStatIfOutOctets !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_OCTETS = Math.round(message.saiPortStatIfOutOctets);
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricPortCounter>, I>>(base?: I): FabricPortCounter {
    return FabricPortCounter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricPortCounter>, I>>(object: I): FabricPortCounter {
    const message = createBaseFabricPortCounter();
    message.saiPortStatIfInErrors = object.saiPortStatIfInErrors ?? 0;
    message.saiPortStatIfInFabricDataUnits = object.saiPortStatIfInFabricDataUnits ?? 0;
    message.saiPortStatIfInFecCorrectableFrames = object.saiPortStatIfInFecCorrectableFrames ?? 0;
    message.saiPortStatIfInFecNotCorrectableFrames = object.saiPortStatIfInFecNotCorrectableFrames ?? 0;
    message.saiPortStatIfInFecSymbolErrors = object.saiPortStatIfInFecSymbolErrors ?? 0;
    message.saiPortStatIfInOctets = object.saiPortStatIfInOctets ?? 0;
    message.saiPortStatIfOutFabricDataUnits = object.saiPortStatIfOutFabricDataUnits ?? 0;
    message.saiPortStatIfOutOctets = object.saiPortStatIfOutOctets ?? 0;
    message.portName = object.portName ?? "";
    return message;
  },
};

function createBaseFanInfo(): FanInfo {
  return {
    direction: "",
    drawerName: "",
    isReplaceable: "",
    ledStatus: "",
    model: "",
    presence: "",
    serial: "",
    speed: "",
    speedTarget: "",
    speedTolerance: "",
    status: "",
    timestamp: "",
  };
}

export const FanInfo = {
  encode(message: FanInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.direction !== "") {
      writer.uint32(18).string(message.direction);
    }
    if (message.drawerName !== "") {
      writer.uint32(26).string(message.drawerName);
    }
    if (message.isReplaceable !== "") {
      writer.uint32(34).string(message.isReplaceable);
    }
    if (message.ledStatus !== "") {
      writer.uint32(42).string(message.ledStatus);
    }
    if (message.model !== "") {
      writer.uint32(50).string(message.model);
    }
    if (message.presence !== "") {
      writer.uint32(58).string(message.presence);
    }
    if (message.serial !== "") {
      writer.uint32(66).string(message.serial);
    }
    if (message.speed !== "") {
      writer.uint32(74).string(message.speed);
    }
    if (message.speedTarget !== "") {
      writer.uint32(82).string(message.speedTarget);
    }
    if (message.speedTolerance !== "") {
      writer.uint32(90).string(message.speedTolerance);
    }
    if (message.status !== "") {
      writer.uint32(98).string(message.status);
    }
    if (message.timestamp !== "") {
      writer.uint32(106).string(message.timestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FanInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFanInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.direction = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.drawerName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.isReplaceable = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ledStatus = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.model = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.presence = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.speed = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.speedTarget = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.speedTolerance = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.status = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.timestamp = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FanInfo {
    return {
      direction: isSet(object.direction) ? globalThis.String(object.direction) : "",
      drawerName: isSet(object.drawerName) ? globalThis.String(object.drawerName) : "",
      isReplaceable: isSet(object.isReplaceable) ? globalThis.String(object.isReplaceable) : "",
      ledStatus: isSet(object.ledStatus) ? globalThis.String(object.ledStatus) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      presence: isSet(object.presence) ? globalThis.String(object.presence) : "",
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      speed: isSet(object.speed) ? globalThis.String(object.speed) : "",
      speedTarget: isSet(object.speedTarget) ? globalThis.String(object.speedTarget) : "",
      speedTolerance: isSet(object.speedTolerance) ? globalThis.String(object.speedTolerance) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      timestamp: isSet(object.timestamp) ? globalThis.String(object.timestamp) : "",
    };
  },

  toJSON(message: FanInfo): unknown {
    const obj: any = {};
    if (message.direction !== "") {
      obj.direction = message.direction;
    }
    if (message.drawerName !== "") {
      obj.drawerName = message.drawerName;
    }
    if (message.isReplaceable !== "") {
      obj.isReplaceable = message.isReplaceable;
    }
    if (message.ledStatus !== "") {
      obj.ledStatus = message.ledStatus;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.presence !== "") {
      obj.presence = message.presence;
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.speed !== "") {
      obj.speed = message.speed;
    }
    if (message.speedTarget !== "") {
      obj.speedTarget = message.speedTarget;
    }
    if (message.speedTolerance !== "") {
      obj.speedTolerance = message.speedTolerance;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.timestamp !== "") {
      obj.timestamp = message.timestamp;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FanInfo>, I>>(base?: I): FanInfo {
    return FanInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FanInfo>, I>>(object: I): FanInfo {
    const message = createBaseFanInfo();
    message.direction = object.direction ?? "";
    message.drawerName = object.drawerName ?? "";
    message.isReplaceable = object.isReplaceable ?? "";
    message.ledStatus = object.ledStatus ?? "";
    message.model = object.model ?? "";
    message.presence = object.presence ?? "";
    message.serial = object.serial ?? "";
    message.speed = object.speed ?? "";
    message.speedTarget = object.speedTarget ?? "";
    message.speedTolerance = object.speedTolerance ?? "";
    message.status = object.status ?? "";
    message.timestamp = object.timestamp ?? "";
    return message;
  },
};

function createBaseInterface(): Interface {
  return {
    config: undefined,
    name: "",
    openconfigIfEthernetEthernet: undefined,
    state: undefined,
    subinterfaces: undefined,
    tunnel: undefined,
  };
}

export const Interface = {
  encode(message: Interface, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      InterfaceConfig.encode(message.config, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.openconfigIfEthernetEthernet !== undefined) {
      InterfaceOpenconfigIfEthernet.encode(message.openconfigIfEthernetEthernet, writer.uint32(34).fork()).ldelim();
    }
    if (message.state !== undefined) {
      InterfaceState.encode(message.state, writer.uint32(42).fork()).ldelim();
    }
    if (message.subinterfaces !== undefined) {
      InterfaceSubinterfaces.encode(message.subinterfaces, writer.uint32(50).fork()).ldelim();
    }
    if (message.tunnel !== undefined) {
      Tunnel.encode(message.tunnel, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Interface {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterface();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.config = InterfaceConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.openconfigIfEthernetEthernet = InterfaceOpenconfigIfEthernet.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.state = InterfaceState.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.subinterfaces = InterfaceSubinterfaces.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tunnel = Tunnel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Interface {
    return {
      config: isSet(object.config) ? InterfaceConfig.fromJSON(object.config) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      openconfigIfEthernetEthernet: isSet(object["openconfig-if-ethernet:ethernet"])
        ? InterfaceOpenconfigIfEthernet.fromJSON(object["openconfig-if-ethernet:ethernet"])
        : undefined,
      state: isSet(object.state) ? InterfaceState.fromJSON(object.state) : undefined,
      subinterfaces: isSet(object.subinterfaces) ? InterfaceSubinterfaces.fromJSON(object.subinterfaces) : undefined,
      tunnel: isSet(object.tunnel) ? Tunnel.fromJSON(object.tunnel) : undefined,
    };
  },

  toJSON(message: Interface): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = InterfaceConfig.toJSON(message.config);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.openconfigIfEthernetEthernet !== undefined) {
      obj["openconfig-if-ethernet:ethernet"] = InterfaceOpenconfigIfEthernet.toJSON(
        message.openconfigIfEthernetEthernet,
      );
    }
    if (message.state !== undefined) {
      obj.state = InterfaceState.toJSON(message.state);
    }
    if (message.subinterfaces !== undefined) {
      obj.subinterfaces = InterfaceSubinterfaces.toJSON(message.subinterfaces);
    }
    if (message.tunnel !== undefined) {
      obj.tunnel = Tunnel.toJSON(message.tunnel);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Interface>, I>>(base?: I): Interface {
    return Interface.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Interface>, I>>(object: I): Interface {
    const message = createBaseInterface();
    message.config = (object.config !== undefined && object.config !== null)
      ? InterfaceConfig.fromPartial(object.config)
      : undefined;
    message.name = object.name ?? "";
    message.openconfigIfEthernetEthernet =
      (object.openconfigIfEthernetEthernet !== undefined && object.openconfigIfEthernetEthernet !== null)
        ? InterfaceOpenconfigIfEthernet.fromPartial(object.openconfigIfEthernetEthernet)
        : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? InterfaceState.fromPartial(object.state)
      : undefined;
    message.subinterfaces = (object.subinterfaces !== undefined && object.subinterfaces !== null)
      ? InterfaceSubinterfaces.fromPartial(object.subinterfaces)
      : undefined;
    message.tunnel = (object.tunnel !== undefined && object.tunnel !== null)
      ? Tunnel.fromPartial(object.tunnel)
      : undefined;
    return message;
  },
};

function createBaseInterfaceConfig(): InterfaceConfig {
  return { name: "", mtu: 0, loopbackMode: 0, description: "", enabled: false };
}

export const InterfaceConfig = {
  encode(message: InterfaceConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.mtu !== 0) {
      writer.uint32(24).uint32(message.mtu);
    }
    if (message.loopbackMode !== 0) {
      writer.uint32(32).int32(message.loopbackMode);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.loopbackMode = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceConfig {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      loopbackMode: isSet(object["loopback-mode"]) ? loopbackModeTypeFromJSON(object["loopback-mode"]) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
    };
  },

  toJSON(message: InterfaceConfig): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.loopbackMode !== 0) {
      obj["loopback-mode"] = loopbackModeTypeToJSON(message.loopbackMode);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceConfig>, I>>(base?: I): InterfaceConfig {
    return InterfaceConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceConfig>, I>>(object: I): InterfaceConfig {
    const message = createBaseInterfaceConfig();
    message.name = object.name ?? "";
    message.mtu = object.mtu ?? 0;
    message.loopbackMode = object.loopbackMode ?? 0;
    message.description = object.description ?? "";
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseInterfaceInfo(): InterfaceInfo {
  return { name: "", ipAddr: "", vrf: "", state: "" };
}

export const InterfaceInfo = {
  encode(message: InterfaceInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.ipAddr !== "") {
      writer.uint32(26).string(message.ipAddr);
    }
    if (message.vrf !== "") {
      writer.uint32(34).string(message.vrf);
    }
    if (message.state !== "") {
      writer.uint32(42).string(message.state);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ipAddr = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.vrf = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.state = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceInfo {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      ipAddr: isSet(object.ipAddr) ? globalThis.String(object.ipAddr) : "",
      vrf: isSet(object.vrf) ? globalThis.String(object.vrf) : "",
      state: isSet(object.state) ? globalThis.String(object.state) : "",
    };
  },

  toJSON(message: InterfaceInfo): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.ipAddr !== "") {
      obj.ipAddr = message.ipAddr;
    }
    if (message.vrf !== "") {
      obj.vrf = message.vrf;
    }
    if (message.state !== "") {
      obj.state = message.state;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceInfo>, I>>(base?: I): InterfaceInfo {
    return InterfaceInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceInfo>, I>>(object: I): InterfaceInfo {
    const message = createBaseInterfaceInfo();
    message.name = object.name ?? "";
    message.ipAddr = object.ipAddr ?? "";
    message.vrf = object.vrf ?? "";
    message.state = object.state ?? "";
    return message;
  },
};

function createBaseInterfaceIpv4(): InterfaceIpv4 {
  return { addresses: "", neighbors: undefined, unnumbered: undefined, config: undefined, state: undefined };
}

export const InterfaceIpv4 = {
  encode(message: InterfaceIpv4, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.addresses !== "") {
      writer.uint32(18).string(message.addresses);
    }
    if (message.neighbors !== undefined) {
      Ipv4Neighbors.encode(message.neighbors, writer.uint32(26).fork()).ldelim();
    }
    if (message.unnumbered !== undefined) {
      Ipv4Unnumbered.encode(message.unnumbered, writer.uint32(34).fork()).ldelim();
    }
    if (message.config !== undefined) {
      Ipv4Config.encode(message.config, writer.uint32(42).fork()).ldelim();
    }
    if (message.state !== undefined) {
      Ipv4State.encode(message.state, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceIpv4 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceIpv4();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.addresses = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.neighbors = Ipv4Neighbors.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.unnumbered = Ipv4Unnumbered.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.config = Ipv4Config.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.state = Ipv4State.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceIpv4 {
    return {
      addresses: isSet(object.addresses) ? globalThis.String(object.addresses) : "",
      neighbors: isSet(object.neighbors) ? Ipv4Neighbors.fromJSON(object.neighbors) : undefined,
      unnumbered: isSet(object.unnumbered) ? Ipv4Unnumbered.fromJSON(object.unnumbered) : undefined,
      config: isSet(object.config) ? Ipv4Config.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? Ipv4State.fromJSON(object.state) : undefined,
    };
  },

  toJSON(message: InterfaceIpv4): unknown {
    const obj: any = {};
    if (message.addresses !== "") {
      obj.addresses = message.addresses;
    }
    if (message.neighbors !== undefined) {
      obj.neighbors = Ipv4Neighbors.toJSON(message.neighbors);
    }
    if (message.unnumbered !== undefined) {
      obj.unnumbered = Ipv4Unnumbered.toJSON(message.unnumbered);
    }
    if (message.config !== undefined) {
      obj.config = Ipv4Config.toJSON(message.config);
    }
    if (message.state !== undefined) {
      obj.state = Ipv4State.toJSON(message.state);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceIpv4>, I>>(base?: I): InterfaceIpv4 {
    return InterfaceIpv4.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceIpv4>, I>>(object: I): InterfaceIpv4 {
    const message = createBaseInterfaceIpv4();
    message.addresses = object.addresses ?? "";
    message.neighbors = (object.neighbors !== undefined && object.neighbors !== null)
      ? Ipv4Neighbors.fromPartial(object.neighbors)
      : undefined;
    message.unnumbered = (object.unnumbered !== undefined && object.unnumbered !== null)
      ? Ipv4Unnumbered.fromPartial(object.unnumbered)
      : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? Ipv4Config.fromPartial(object.config)
      : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? Ipv4State.fromPartial(object.state)
      : undefined;
    return message;
  },
};

function createBaseInterfaceIpv6(): InterfaceIpv6 {
  return {
    addresses: "",
    neighbors: undefined,
    unnumbered: undefined,
    config: undefined,
    state: "",
    autoconf: undefined,
  };
}

export const InterfaceIpv6 = {
  encode(message: InterfaceIpv6, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.addresses !== "") {
      writer.uint32(18).string(message.addresses);
    }
    if (message.neighbors !== undefined) {
      Ipv6Neighbors.encode(message.neighbors, writer.uint32(26).fork()).ldelim();
    }
    if (message.unnumbered !== undefined) {
      Ipv6Unnumbered.encode(message.unnumbered, writer.uint32(34).fork()).ldelim();
    }
    if (message.config !== undefined) {
      Ipv6Config.encode(message.config, writer.uint32(42).fork()).ldelim();
    }
    if (message.state !== "") {
      writer.uint32(50).string(message.state);
    }
    if (message.autoconf !== undefined) {
      Ipv6AutoConf.encode(message.autoconf, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceIpv6 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceIpv6();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.addresses = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.neighbors = Ipv6Neighbors.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.unnumbered = Ipv6Unnumbered.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.config = Ipv6Config.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.state = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.autoconf = Ipv6AutoConf.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceIpv6 {
    return {
      addresses: isSet(object.addresses) ? globalThis.String(object.addresses) : "",
      neighbors: isSet(object.neighbors) ? Ipv6Neighbors.fromJSON(object.neighbors) : undefined,
      unnumbered: isSet(object.unnumbered) ? Ipv6Unnumbered.fromJSON(object.unnumbered) : undefined,
      config: isSet(object.config) ? Ipv6Config.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      autoconf: isSet(object.autoconf) ? Ipv6AutoConf.fromJSON(object.autoconf) : undefined,
    };
  },

  toJSON(message: InterfaceIpv6): unknown {
    const obj: any = {};
    if (message.addresses !== "") {
      obj.addresses = message.addresses;
    }
    if (message.neighbors !== undefined) {
      obj.neighbors = Ipv6Neighbors.toJSON(message.neighbors);
    }
    if (message.unnumbered !== undefined) {
      obj.unnumbered = Ipv6Unnumbered.toJSON(message.unnumbered);
    }
    if (message.config !== undefined) {
      obj.config = Ipv6Config.toJSON(message.config);
    }
    if (message.state !== "") {
      obj.state = message.state;
    }
    if (message.autoconf !== undefined) {
      obj.autoconf = Ipv6AutoConf.toJSON(message.autoconf);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceIpv6>, I>>(base?: I): InterfaceIpv6 {
    return InterfaceIpv6.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceIpv6>, I>>(object: I): InterfaceIpv6 {
    const message = createBaseInterfaceIpv6();
    message.addresses = object.addresses ?? "";
    message.neighbors = (object.neighbors !== undefined && object.neighbors !== null)
      ? Ipv6Neighbors.fromPartial(object.neighbors)
      : undefined;
    message.unnumbered = (object.unnumbered !== undefined && object.unnumbered !== null)
      ? Ipv6Unnumbered.fromPartial(object.unnumbered)
      : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? Ipv6Config.fromPartial(object.config)
      : undefined;
    message.state = object.state ?? "";
    message.autoconf = (object.autoconf !== undefined && object.autoconf !== null)
      ? Ipv6AutoConf.fromPartial(object.autoconf)
      : undefined;
    return message;
  },
};

function createBaseInterfaceOpenconfigIfEthernet(): InterfaceOpenconfigIfEthernet {
  return { config: undefined, state: undefined };
}

export const InterfaceOpenconfigIfEthernet = {
  encode(message: InterfaceOpenconfigIfEthernet, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      InterfaceOpenconfigIfEthernetConfig.encode(message.config, writer.uint32(18).fork()).ldelim();
    }
    if (message.state !== undefined) {
      InterfaceOpenconfigIfEthernetState.encode(message.state, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceOpenconfigIfEthernet {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceOpenconfigIfEthernet();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.config = InterfaceOpenconfigIfEthernetConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.state = InterfaceOpenconfigIfEthernetState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceOpenconfigIfEthernet {
    return {
      config: isSet(object.config) ? InterfaceOpenconfigIfEthernetConfig.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? InterfaceOpenconfigIfEthernetState.fromJSON(object.state) : undefined,
    };
  },

  toJSON(message: InterfaceOpenconfigIfEthernet): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = InterfaceOpenconfigIfEthernetConfig.toJSON(message.config);
    }
    if (message.state !== undefined) {
      obj.state = InterfaceOpenconfigIfEthernetState.toJSON(message.state);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceOpenconfigIfEthernet>, I>>(base?: I): InterfaceOpenconfigIfEthernet {
    return InterfaceOpenconfigIfEthernet.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceOpenconfigIfEthernet>, I>>(
    object: I,
  ): InterfaceOpenconfigIfEthernet {
    const message = createBaseInterfaceOpenconfigIfEthernet();
    message.config = (object.config !== undefined && object.config !== null)
      ? InterfaceOpenconfigIfEthernetConfig.fromPartial(object.config)
      : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? InterfaceOpenconfigIfEthernetState.fromPartial(object.state)
      : undefined;
    return message;
  },
};

function createBaseInterfaceOpenconfigIfEthernetConfig(): InterfaceOpenconfigIfEthernetConfig {
  return { portSpeed: "", macAddress: "", enableFlowControl: false };
}

export const InterfaceOpenconfigIfEthernetConfig = {
  encode(message: InterfaceOpenconfigIfEthernetConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.portSpeed !== "") {
      writer.uint32(18).string(message.portSpeed);
    }
    if (message.macAddress !== "") {
      writer.uint32(26).string(message.macAddress);
    }
    if (message.enableFlowControl !== false) {
      writer.uint32(32).bool(message.enableFlowControl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceOpenconfigIfEthernetConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceOpenconfigIfEthernetConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.portSpeed = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.macAddress = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enableFlowControl = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceOpenconfigIfEthernetConfig {
    return {
      portSpeed: isSet(object["port-speed"]) ? globalThis.String(object["port-speed"]) : "",
      macAddress: isSet(object["mac-address"]) ? globalThis.String(object["mac-address"]) : "",
      enableFlowControl: isSet(object["enable-flow-control"])
        ? globalThis.Boolean(object["enable-flow-control"])
        : false,
    };
  },

  toJSON(message: InterfaceOpenconfigIfEthernetConfig): unknown {
    const obj: any = {};
    if (message.portSpeed !== "") {
      obj["port-speed"] = message.portSpeed;
    }
    if (message.macAddress !== "") {
      obj["mac-address"] = message.macAddress;
    }
    if (message.enableFlowControl !== false) {
      obj["enable-flow-control"] = message.enableFlowControl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceOpenconfigIfEthernetConfig>, I>>(
    base?: I,
  ): InterfaceOpenconfigIfEthernetConfig {
    return InterfaceOpenconfigIfEthernetConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceOpenconfigIfEthernetConfig>, I>>(
    object: I,
  ): InterfaceOpenconfigIfEthernetConfig {
    const message = createBaseInterfaceOpenconfigIfEthernetConfig();
    message.portSpeed = object.portSpeed ?? "";
    message.macAddress = object.macAddress ?? "";
    message.enableFlowControl = object.enableFlowControl ?? false;
    return message;
  },
};

function createBaseInterfaceOpenconfigIfEthernetState(): InterfaceOpenconfigIfEthernetState {
  return { portSpeed: "", macAddress: "", enableFlowControl: false, hwMacAddress: "", counters: undefined };
}

export const InterfaceOpenconfigIfEthernetState = {
  encode(message: InterfaceOpenconfigIfEthernetState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.portSpeed !== "") {
      writer.uint32(18).string(message.portSpeed);
    }
    if (message.macAddress !== "") {
      writer.uint32(26).string(message.macAddress);
    }
    if (message.enableFlowControl !== false) {
      writer.uint32(32).bool(message.enableFlowControl);
    }
    if (message.hwMacAddress !== "") {
      writer.uint32(42).string(message.hwMacAddress);
    }
    if (message.counters !== undefined) {
      EthernetCounters.encode(message.counters, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceOpenconfigIfEthernetState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceOpenconfigIfEthernetState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.portSpeed = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.macAddress = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enableFlowControl = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.hwMacAddress = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.counters = EthernetCounters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceOpenconfigIfEthernetState {
    return {
      portSpeed: isSet(object["port-speed"]) ? globalThis.String(object["port-speed"]) : "",
      macAddress: isSet(object["mac-address"]) ? globalThis.String(object["mac-address"]) : "",
      enableFlowControl: isSet(object["enable-flow-control"])
        ? globalThis.Boolean(object["enable-flow-control"])
        : false,
      hwMacAddress: isSet(object["hw-mac-address"]) ? globalThis.String(object["hw-mac-address"]) : "",
      counters: isSet(object.counters) ? EthernetCounters.fromJSON(object.counters) : undefined,
    };
  },

  toJSON(message: InterfaceOpenconfigIfEthernetState): unknown {
    const obj: any = {};
    if (message.portSpeed !== "") {
      obj["port-speed"] = message.portSpeed;
    }
    if (message.macAddress !== "") {
      obj["mac-address"] = message.macAddress;
    }
    if (message.enableFlowControl !== false) {
      obj["enable-flow-control"] = message.enableFlowControl;
    }
    if (message.hwMacAddress !== "") {
      obj["hw-mac-address"] = message.hwMacAddress;
    }
    if (message.counters !== undefined) {
      obj.counters = EthernetCounters.toJSON(message.counters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceOpenconfigIfEthernetState>, I>>(
    base?: I,
  ): InterfaceOpenconfigIfEthernetState {
    return InterfaceOpenconfigIfEthernetState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceOpenconfigIfEthernetState>, I>>(
    object: I,
  ): InterfaceOpenconfigIfEthernetState {
    const message = createBaseInterfaceOpenconfigIfEthernetState();
    message.portSpeed = object.portSpeed ?? "";
    message.macAddress = object.macAddress ?? "";
    message.enableFlowControl = object.enableFlowControl ?? false;
    message.hwMacAddress = object.hwMacAddress ?? "";
    message.counters = (object.counters !== undefined && object.counters !== null)
      ? EthernetCounters.fromPartial(object.counters)
      : undefined;
    return message;
  },
};

function createBaseInterfaceRef(): InterfaceRef {
  return { config: undefined, state: undefined };
}

export const InterfaceRef = {
  encode(message: InterfaceRef, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      InterfaceRefConfig.encode(message.config, writer.uint32(18).fork()).ldelim();
    }
    if (message.state !== undefined) {
      InterfaceRefState.encode(message.state, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceRef {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceRef();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.config = InterfaceRefConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.state = InterfaceRefState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceRef {
    return {
      config: isSet(object.config) ? InterfaceRefConfig.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? InterfaceRefState.fromJSON(object.state) : undefined,
    };
  },

  toJSON(message: InterfaceRef): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = InterfaceRefConfig.toJSON(message.config);
    }
    if (message.state !== undefined) {
      obj.state = InterfaceRefState.toJSON(message.state);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceRef>, I>>(base?: I): InterfaceRef {
    return InterfaceRef.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceRef>, I>>(object: I): InterfaceRef {
    const message = createBaseInterfaceRef();
    message.config = (object.config !== undefined && object.config !== null)
      ? InterfaceRefConfig.fromPartial(object.config)
      : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? InterfaceRefState.fromPartial(object.state)
      : undefined;
    return message;
  },
};

function createBaseInterfaceRefConfig(): InterfaceRefConfig {
  return { interface: "", subinterface: "" };
}

export const InterfaceRefConfig = {
  encode(message: InterfaceRefConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.interface !== "") {
      writer.uint32(18).string(message.interface);
    }
    if (message.subinterface !== "") {
      writer.uint32(26).string(message.subinterface);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceRefConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceRefConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.interface = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subinterface = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceRefConfig {
    return {
      interface: isSet(object.interface) ? globalThis.String(object.interface) : "",
      subinterface: isSet(object.subinterface) ? globalThis.String(object.subinterface) : "",
    };
  },

  toJSON(message: InterfaceRefConfig): unknown {
    const obj: any = {};
    if (message.interface !== "") {
      obj.interface = message.interface;
    }
    if (message.subinterface !== "") {
      obj.subinterface = message.subinterface;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceRefConfig>, I>>(base?: I): InterfaceRefConfig {
    return InterfaceRefConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceRefConfig>, I>>(object: I): InterfaceRefConfig {
    const message = createBaseInterfaceRefConfig();
    message.interface = object.interface ?? "";
    message.subinterface = object.subinterface ?? "";
    return message;
  },
};

function createBaseInterfaceRefState(): InterfaceRefState {
  return { interface: "", subinterface: "" };
}

export const InterfaceRefState = {
  encode(message: InterfaceRefState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.interface !== "") {
      writer.uint32(18).string(message.interface);
    }
    if (message.subinterface !== "") {
      writer.uint32(26).string(message.subinterface);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceRefState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceRefState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.interface = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subinterface = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceRefState {
    return {
      interface: isSet(object.interface) ? globalThis.String(object.interface) : "",
      subinterface: isSet(object.subinterface) ? globalThis.String(object.subinterface) : "",
    };
  },

  toJSON(message: InterfaceRefState): unknown {
    const obj: any = {};
    if (message.interface !== "") {
      obj.interface = message.interface;
    }
    if (message.subinterface !== "") {
      obj.subinterface = message.subinterface;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceRefState>, I>>(base?: I): InterfaceRefState {
    return InterfaceRefState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceRefState>, I>>(object: I): InterfaceRefState {
    const message = createBaseInterfaceRefState();
    message.interface = object.interface ?? "";
    message.subinterface = object.subinterface ?? "";
    return message;
  },
};

function createBaseInterfaceState(): InterfaceState {
  return {
    adminStatus: "",
    counters: undefined,
    description: "",
    ifindex: 0,
    mtu: 0,
    name: "",
    operStatus: "",
    loopbackMode: 0,
    enabled: false,
    outRate: 0,
    inRate: 0,
    lastChange: undefined,
  };
}

export const InterfaceState = {
  encode(message: InterfaceState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.adminStatus !== "") {
      writer.uint32(18).string(message.adminStatus);
    }
    if (message.counters !== undefined) {
      Counters.encode(message.counters, writer.uint32(26).fork()).ldelim();
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.ifindex !== 0) {
      writer.uint32(40).uint32(message.ifindex);
    }
    if (message.mtu !== 0) {
      writer.uint32(48).uint32(message.mtu);
    }
    if (message.name !== "") {
      writer.uint32(58).string(message.name);
    }
    if (message.operStatus !== "") {
      writer.uint32(66).string(message.operStatus);
    }
    if (message.loopbackMode !== 0) {
      writer.uint32(72).int32(message.loopbackMode);
    }
    if (message.enabled !== false) {
      writer.uint32(80).bool(message.enabled);
    }
    if (message.outRate !== 0) {
      writer.uint32(89).double(message.outRate);
    }
    if (message.inRate !== 0) {
      writer.uint32(97).double(message.inRate);
    }
    if (message.lastChange !== undefined) {
      Timestamp.encode(toTimestamp(message.lastChange), writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.adminStatus = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.counters = Counters.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.ifindex = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.name = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.operStatus = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.loopbackMode = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.outRate = reader.double();
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.inRate = reader.double();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.lastChange = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceState {
    return {
      adminStatus: isSet(object["admin-status"]) ? globalThis.String(object["admin-status"]) : "",
      counters: isSet(object.counters) ? Counters.fromJSON(object.counters) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      ifindex: isSet(object.ifindex) ? globalThis.Number(object.ifindex) : 0,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      operStatus: isSet(object["oper-status"]) ? globalThis.String(object["oper-status"]) : "",
      loopbackMode: isSet(object["loopback-mode"]) ? loopbackModeTypeFromJSON(object["loopback-mode"]) : 0,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      outRate: isSet(object["out-rate"]) ? globalThis.Number(object["out-rate"]) : 0,
      inRate: isSet(object["in-rate"]) ? globalThis.Number(object["in-rate"]) : 0,
      lastChange: isSet(object["last-change"]) ? fromJsonTimestamp(object["last-change"]) : undefined,
    };
  },

  toJSON(message: InterfaceState): unknown {
    const obj: any = {};
    if (message.adminStatus !== "") {
      obj["admin-status"] = message.adminStatus;
    }
    if (message.counters !== undefined) {
      obj.counters = Counters.toJSON(message.counters);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.ifindex !== 0) {
      obj.ifindex = Math.round(message.ifindex);
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.operStatus !== "") {
      obj["oper-status"] = message.operStatus;
    }
    if (message.loopbackMode !== 0) {
      obj["loopback-mode"] = loopbackModeTypeToJSON(message.loopbackMode);
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.outRate !== 0) {
      obj["out-rate"] = message.outRate;
    }
    if (message.inRate !== 0) {
      obj["in-rate"] = message.inRate;
    }
    if (message.lastChange !== undefined) {
      obj["last-change"] = message.lastChange.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceState>, I>>(base?: I): InterfaceState {
    return InterfaceState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceState>, I>>(object: I): InterfaceState {
    const message = createBaseInterfaceState();
    message.adminStatus = object.adminStatus ?? "";
    message.counters = (object.counters !== undefined && object.counters !== null)
      ? Counters.fromPartial(object.counters)
      : undefined;
    message.description = object.description ?? "";
    message.ifindex = object.ifindex ?? 0;
    message.mtu = object.mtu ?? 0;
    message.name = object.name ?? "";
    message.operStatus = object.operStatus ?? "";
    message.loopbackMode = object.loopbackMode ?? 0;
    message.enabled = object.enabled ?? false;
    message.outRate = object.outRate ?? 0;
    message.inRate = object.inRate ?? 0;
    message.lastChange = object.lastChange ?? undefined;
    return message;
  },
};

function createBaseInterfaceSubinterfaces(): InterfaceSubinterfaces {
  return { subinterface: [] };
}

export const InterfaceSubinterfaces = {
  encode(message: InterfaceSubinterfaces, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.subinterface) {
      Subinterface.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceSubinterfaces {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceSubinterfaces();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subinterface.push(Subinterface.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceSubinterfaces {
    return {
      subinterface: globalThis.Array.isArray(object?.subinterface)
        ? object.subinterface.map((e: any) => Subinterface.fromJSON(e))
        : [],
    };
  },

  toJSON(message: InterfaceSubinterfaces): unknown {
    const obj: any = {};
    if (message.subinterface?.length) {
      obj.subinterface = message.subinterface.map((e) => Subinterface.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceSubinterfaces>, I>>(base?: I): InterfaceSubinterfaces {
    return InterfaceSubinterfaces.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceSubinterfaces>, I>>(object: I): InterfaceSubinterfaces {
    const message = createBaseInterfaceSubinterfaces();
    message.subinterface = object.subinterface?.map((e) => Subinterface.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInterfaces(): Interfaces {
  return { interface: [], statsMdata: undefined };
}

export const Interfaces = {
  encode(message: Interfaces, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.interface) {
      Interface.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.statsMdata !== undefined) {
      StatsMetadata.encode(message.statsMdata, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Interfaces {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaces();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.interface.push(Interface.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.statsMdata = StatsMetadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Interfaces {
    return {
      interface: globalThis.Array.isArray(object?.interface)
        ? object.interface.map((e: any) => Interface.fromJSON(e))
        : [],
      statsMdata: isSet(object.statsMdata) ? StatsMetadata.fromJSON(object.statsMdata) : undefined,
    };
  },

  toJSON(message: Interfaces): unknown {
    const obj: any = {};
    if (message.interface?.length) {
      obj.interface = message.interface.map((e) => Interface.toJSON(e));
    }
    if (message.statsMdata !== undefined) {
      obj.statsMdata = StatsMetadata.toJSON(message.statsMdata);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Interfaces>, I>>(base?: I): Interfaces {
    return Interfaces.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Interfaces>, I>>(object: I): Interfaces {
    const message = createBaseInterfaces();
    message.interface = object.interface?.map((e) => Interface.fromPartial(e)) || [];
    message.statsMdata = (object.statsMdata !== undefined && object.statsMdata !== null)
      ? StatsMetadata.fromPartial(object.statsMdata)
      : undefined;
    return message;
  },
};

function createBaseIpCounters(): IpCounters {
  return {
    inOctets: 0,
    inPkts: 0,
    inForwardedPkts: 0,
    inForwardedOctets: 0,
    inDiscardedPkts: 0,
    inErrorPkts: 0,
    outOctets: 0,
    outPkts: 0,
    outForwardedPkts: 0,
    outForwardedOctets: 0,
    outDiscardedPkts: 0,
    outErrorPkts: 0,
  };
}

export const IpCounters = {
  encode(message: IpCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.inOctets !== 0) {
      writer.uint32(16).uint64(message.inOctets);
    }
    if (message.inPkts !== 0) {
      writer.uint32(24).uint64(message.inPkts);
    }
    if (message.inForwardedPkts !== 0) {
      writer.uint32(32).uint64(message.inForwardedPkts);
    }
    if (message.inForwardedOctets !== 0) {
      writer.uint32(40).uint64(message.inForwardedOctets);
    }
    if (message.inDiscardedPkts !== 0) {
      writer.uint32(48).uint64(message.inDiscardedPkts);
    }
    if (message.inErrorPkts !== 0) {
      writer.uint32(56).uint64(message.inErrorPkts);
    }
    if (message.outOctets !== 0) {
      writer.uint32(64).uint64(message.outOctets);
    }
    if (message.outPkts !== 0) {
      writer.uint32(72).uint64(message.outPkts);
    }
    if (message.outForwardedPkts !== 0) {
      writer.uint32(80).uint64(message.outForwardedPkts);
    }
    if (message.outForwardedOctets !== 0) {
      writer.uint32(88).uint64(message.outForwardedOctets);
    }
    if (message.outDiscardedPkts !== 0) {
      writer.uint32(96).uint64(message.outDiscardedPkts);
    }
    if (message.outErrorPkts !== 0) {
      writer.uint32(104).uint64(message.outErrorPkts);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.inOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.inPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.inForwardedPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.inForwardedOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.inDiscardedPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.inErrorPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.outOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.outPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.outForwardedPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.outForwardedOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.outDiscardedPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.outErrorPkts = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpCounters {
    return {
      inOctets: isSet(object["in-octets"]) ? globalThis.Number(object["in-octets"]) : 0,
      inPkts: isSet(object["in-pkts"]) ? globalThis.Number(object["in-pkts"]) : 0,
      inForwardedPkts: isSet(object["in-forwarded-pkts"]) ? globalThis.Number(object["in-forwarded-pkts"]) : 0,
      inForwardedOctets: isSet(object["in-forwarded-octets"]) ? globalThis.Number(object["in-forwarded-octets"]) : 0,
      inDiscardedPkts: isSet(object["in-discarded-pkts"]) ? globalThis.Number(object["in-discarded-pkts"]) : 0,
      inErrorPkts: isSet(object["in-error-pkts"]) ? globalThis.Number(object["in-error-pkts"]) : 0,
      outOctets: isSet(object["out-octets"]) ? globalThis.Number(object["out-octets"]) : 0,
      outPkts: isSet(object["out-pkts"]) ? globalThis.Number(object["out-pkts"]) : 0,
      outForwardedPkts: isSet(object["out-forwarded-pkts"]) ? globalThis.Number(object["out-forwarded-pkts"]) : 0,
      outForwardedOctets: isSet(object["out-forwarded-octets"]) ? globalThis.Number(object["out-forwarded-octets"]) : 0,
      outDiscardedPkts: isSet(object["out-discarded-pkts"]) ? globalThis.Number(object["out-discarded-pkts"]) : 0,
      outErrorPkts: isSet(object["out-error-pkts"]) ? globalThis.Number(object["out-error-pkts"]) : 0,
    };
  },

  toJSON(message: IpCounters): unknown {
    const obj: any = {};
    if (message.inOctets !== 0) {
      obj["in-octets"] = Math.round(message.inOctets);
    }
    if (message.inPkts !== 0) {
      obj["in-pkts"] = Math.round(message.inPkts);
    }
    if (message.inForwardedPkts !== 0) {
      obj["in-forwarded-pkts"] = Math.round(message.inForwardedPkts);
    }
    if (message.inForwardedOctets !== 0) {
      obj["in-forwarded-octets"] = Math.round(message.inForwardedOctets);
    }
    if (message.inDiscardedPkts !== 0) {
      obj["in-discarded-pkts"] = Math.round(message.inDiscardedPkts);
    }
    if (message.inErrorPkts !== 0) {
      obj["in-error-pkts"] = Math.round(message.inErrorPkts);
    }
    if (message.outOctets !== 0) {
      obj["out-octets"] = Math.round(message.outOctets);
    }
    if (message.outPkts !== 0) {
      obj["out-pkts"] = Math.round(message.outPkts);
    }
    if (message.outForwardedPkts !== 0) {
      obj["out-forwarded-pkts"] = Math.round(message.outForwardedPkts);
    }
    if (message.outForwardedOctets !== 0) {
      obj["out-forwarded-octets"] = Math.round(message.outForwardedOctets);
    }
    if (message.outDiscardedPkts !== 0) {
      obj["out-discarded-pkts"] = Math.round(message.outDiscardedPkts);
    }
    if (message.outErrorPkts !== 0) {
      obj["out-error-pkts"] = Math.round(message.outErrorPkts);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpCounters>, I>>(base?: I): IpCounters {
    return IpCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpCounters>, I>>(object: I): IpCounters {
    const message = createBaseIpCounters();
    message.inOctets = object.inOctets ?? 0;
    message.inPkts = object.inPkts ?? 0;
    message.inForwardedPkts = object.inForwardedPkts ?? 0;
    message.inForwardedOctets = object.inForwardedOctets ?? 0;
    message.inDiscardedPkts = object.inDiscardedPkts ?? 0;
    message.inErrorPkts = object.inErrorPkts ?? 0;
    message.outOctets = object.outOctets ?? 0;
    message.outPkts = object.outPkts ?? 0;
    message.outForwardedPkts = object.outForwardedPkts ?? 0;
    message.outForwardedOctets = object.outForwardedOctets ?? 0;
    message.outDiscardedPkts = object.outDiscardedPkts ?? 0;
    message.outErrorPkts = object.outErrorPkts ?? 0;
    return message;
  },
};

function createBaseIpmCounter(): IpmCounter {
  return {
    saiIpmCounterStatRxPktsBin0: 0,
    saiIpmCounterStatRxPktsBin1: 0,
    saiIpmCounterStatRxPktsBin2: 0,
    saiIpmCounterStatRxPktsBin3: 0,
    saiIpmCounterStatRxPktsBin4: 0,
    saiIpmCounterStatRxPktsBin5: 0,
    saiIpmCounterStatRxPktsBin6: 0,
    saiIpmCounterStatRxPktsBin7: 0,
    saiIpmCounterStatRxPktsBin8: 0,
    saiIpmCounterStatRxPktsBin9: 0,
    saiIpmCounterStatRxPktsBin10: 0,
    saiIpmCounterStatRxPktsBin11: 0,
    saiIpmCounterStatRxPktsBin12: 0,
    saiIpmCounterStatRxPktsBin13: 0,
    saiIpmCounterStatRxPktsBin14: 0,
    saiIpmCounterStatRxPktsBin15: 0,
    saiIpmCounterStatRxPktsBin16: 0,
    saiIpmCounterStatRxPktsBin17: 0,
    saiIpmCounterStatRxPktsBin18: 0,
    saiIpmCounterStatRxPktsBin19: 0,
    saiIpmCounterStatRxPktsBin20: 0,
    saiIpmCounterStatRxPktsBin21: 0,
    saiIpmCounterStatRxPktsBin22: 0,
    saiIpmCounterStatRxPktsBin23: 0,
    saiIpmCounterStatRxPktsBin24: 0,
    saiIpmCounterStatRxPktsBin25: 0,
    saiIpmCounterStatRxPktsBin26: 0,
    saiIpmCounterStatRxPktsBin27: 0,
    sessionId: "",
    srcAddr: "",
    dstAddr: "",
    vrf: "",
    flowLabel: 0,
    txInterval: 0,
    dscp: 0,
    histogramTemplate: "",
    rxPkts: 0,
    alternateMarkingInterval: 0,
    livenessTimer: 0,
    state: "",
    description: "",
    confSessionId: "",
  };
}

export const IpmCounter = {
  encode(message: IpmCounter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.saiIpmCounterStatRxPktsBin0 !== 0) {
      writer.uint32(16).uint64(message.saiIpmCounterStatRxPktsBin0);
    }
    if (message.saiIpmCounterStatRxPktsBin1 !== 0) {
      writer.uint32(24).uint64(message.saiIpmCounterStatRxPktsBin1);
    }
    if (message.saiIpmCounterStatRxPktsBin2 !== 0) {
      writer.uint32(32).uint64(message.saiIpmCounterStatRxPktsBin2);
    }
    if (message.saiIpmCounterStatRxPktsBin3 !== 0) {
      writer.uint32(40).uint64(message.saiIpmCounterStatRxPktsBin3);
    }
    if (message.saiIpmCounterStatRxPktsBin4 !== 0) {
      writer.uint32(48).uint64(message.saiIpmCounterStatRxPktsBin4);
    }
    if (message.saiIpmCounterStatRxPktsBin5 !== 0) {
      writer.uint32(56).uint64(message.saiIpmCounterStatRxPktsBin5);
    }
    if (message.saiIpmCounterStatRxPktsBin6 !== 0) {
      writer.uint32(64).uint64(message.saiIpmCounterStatRxPktsBin6);
    }
    if (message.saiIpmCounterStatRxPktsBin7 !== 0) {
      writer.uint32(72).uint64(message.saiIpmCounterStatRxPktsBin7);
    }
    if (message.saiIpmCounterStatRxPktsBin8 !== 0) {
      writer.uint32(80).uint64(message.saiIpmCounterStatRxPktsBin8);
    }
    if (message.saiIpmCounterStatRxPktsBin9 !== 0) {
      writer.uint32(88).uint64(message.saiIpmCounterStatRxPktsBin9);
    }
    if (message.saiIpmCounterStatRxPktsBin10 !== 0) {
      writer.uint32(96).uint64(message.saiIpmCounterStatRxPktsBin10);
    }
    if (message.saiIpmCounterStatRxPktsBin11 !== 0) {
      writer.uint32(104).uint64(message.saiIpmCounterStatRxPktsBin11);
    }
    if (message.saiIpmCounterStatRxPktsBin12 !== 0) {
      writer.uint32(112).uint64(message.saiIpmCounterStatRxPktsBin12);
    }
    if (message.saiIpmCounterStatRxPktsBin13 !== 0) {
      writer.uint32(120).uint64(message.saiIpmCounterStatRxPktsBin13);
    }
    if (message.saiIpmCounterStatRxPktsBin14 !== 0) {
      writer.uint32(128).uint64(message.saiIpmCounterStatRxPktsBin14);
    }
    if (message.saiIpmCounterStatRxPktsBin15 !== 0) {
      writer.uint32(136).uint64(message.saiIpmCounterStatRxPktsBin15);
    }
    if (message.saiIpmCounterStatRxPktsBin16 !== 0) {
      writer.uint32(144).uint64(message.saiIpmCounterStatRxPktsBin16);
    }
    if (message.saiIpmCounterStatRxPktsBin17 !== 0) {
      writer.uint32(152).uint64(message.saiIpmCounterStatRxPktsBin17);
    }
    if (message.saiIpmCounterStatRxPktsBin18 !== 0) {
      writer.uint32(160).uint64(message.saiIpmCounterStatRxPktsBin18);
    }
    if (message.saiIpmCounterStatRxPktsBin19 !== 0) {
      writer.uint32(168).uint64(message.saiIpmCounterStatRxPktsBin19);
    }
    if (message.saiIpmCounterStatRxPktsBin20 !== 0) {
      writer.uint32(176).uint64(message.saiIpmCounterStatRxPktsBin20);
    }
    if (message.saiIpmCounterStatRxPktsBin21 !== 0) {
      writer.uint32(184).uint64(message.saiIpmCounterStatRxPktsBin21);
    }
    if (message.saiIpmCounterStatRxPktsBin22 !== 0) {
      writer.uint32(192).uint64(message.saiIpmCounterStatRxPktsBin22);
    }
    if (message.saiIpmCounterStatRxPktsBin23 !== 0) {
      writer.uint32(200).uint64(message.saiIpmCounterStatRxPktsBin23);
    }
    if (message.saiIpmCounterStatRxPktsBin24 !== 0) {
      writer.uint32(208).uint64(message.saiIpmCounterStatRxPktsBin24);
    }
    if (message.saiIpmCounterStatRxPktsBin25 !== 0) {
      writer.uint32(216).uint64(message.saiIpmCounterStatRxPktsBin25);
    }
    if (message.saiIpmCounterStatRxPktsBin26 !== 0) {
      writer.uint32(224).uint64(message.saiIpmCounterStatRxPktsBin26);
    }
    if (message.saiIpmCounterStatRxPktsBin27 !== 0) {
      writer.uint32(232).uint64(message.saiIpmCounterStatRxPktsBin27);
    }
    if (message.sessionId !== "") {
      writer.uint32(242).string(message.sessionId);
    }
    if (message.srcAddr !== "") {
      writer.uint32(250).string(message.srcAddr);
    }
    if (message.dstAddr !== "") {
      writer.uint32(258).string(message.dstAddr);
    }
    if (message.vrf !== "") {
      writer.uint32(266).string(message.vrf);
    }
    if (message.flowLabel !== 0) {
      writer.uint32(272).uint32(message.flowLabel);
    }
    if (message.txInterval !== 0) {
      writer.uint32(280).uint32(message.txInterval);
    }
    if (message.dscp !== 0) {
      writer.uint32(288).uint32(message.dscp);
    }
    if (message.histogramTemplate !== "") {
      writer.uint32(298).string(message.histogramTemplate);
    }
    if (message.rxPkts !== 0) {
      writer.uint32(304).uint64(message.rxPkts);
    }
    if (message.alternateMarkingInterval !== 0) {
      writer.uint32(312).uint32(message.alternateMarkingInterval);
    }
    if (message.livenessTimer !== 0) {
      writer.uint32(320).uint32(message.livenessTimer);
    }
    if (message.state !== "") {
      writer.uint32(330).string(message.state);
    }
    if (message.description !== "") {
      writer.uint32(338).string(message.description);
    }
    if (message.confSessionId !== "") {
      writer.uint32(346).string(message.confSessionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpmCounter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpmCounter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin0 = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin1 = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin2 = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin3 = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin4 = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin5 = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin6 = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin7 = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin8 = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin9 = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin10 = longToNumber(reader.uint64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin11 = longToNumber(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin12 = longToNumber(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin13 = longToNumber(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin14 = longToNumber(reader.uint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin15 = longToNumber(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin16 = longToNumber(reader.uint64() as Long);
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin17 = longToNumber(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin18 = longToNumber(reader.uint64() as Long);
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin19 = longToNumber(reader.uint64() as Long);
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin20 = longToNumber(reader.uint64() as Long);
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin21 = longToNumber(reader.uint64() as Long);
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin22 = longToNumber(reader.uint64() as Long);
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin23 = longToNumber(reader.uint64() as Long);
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin24 = longToNumber(reader.uint64() as Long);
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin25 = longToNumber(reader.uint64() as Long);
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin26 = longToNumber(reader.uint64() as Long);
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.saiIpmCounterStatRxPktsBin27 = longToNumber(reader.uint64() as Long);
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.sessionId = reader.string();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.srcAddr = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.dstAddr = reader.string();
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.vrf = reader.string();
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.flowLabel = reader.uint32();
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.txInterval = reader.uint32();
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.dscp = reader.uint32();
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.histogramTemplate = reader.string();
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.rxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 39:
          if (tag !== 312) {
            break;
          }

          message.alternateMarkingInterval = reader.uint32();
          continue;
        case 40:
          if (tag !== 320) {
            break;
          }

          message.livenessTimer = reader.uint32();
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.state = reader.string();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.description = reader.string();
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.confSessionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpmCounter {
    return {
      saiIpmCounterStatRxPktsBin0: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN0)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN0)
        : 0,
      saiIpmCounterStatRxPktsBin1: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN1)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN1)
        : 0,
      saiIpmCounterStatRxPktsBin2: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN2)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN2)
        : 0,
      saiIpmCounterStatRxPktsBin3: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN3)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN3)
        : 0,
      saiIpmCounterStatRxPktsBin4: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN4)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN4)
        : 0,
      saiIpmCounterStatRxPktsBin5: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN5)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN5)
        : 0,
      saiIpmCounterStatRxPktsBin6: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN6)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN6)
        : 0,
      saiIpmCounterStatRxPktsBin7: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN7)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN7)
        : 0,
      saiIpmCounterStatRxPktsBin8: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN8)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN8)
        : 0,
      saiIpmCounterStatRxPktsBin9: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN9)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN9)
        : 0,
      saiIpmCounterStatRxPktsBin10: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN10)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN10)
        : 0,
      saiIpmCounterStatRxPktsBin11: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN11)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN11)
        : 0,
      saiIpmCounterStatRxPktsBin12: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN12)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN12)
        : 0,
      saiIpmCounterStatRxPktsBin13: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN13)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN13)
        : 0,
      saiIpmCounterStatRxPktsBin14: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN14)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN14)
        : 0,
      saiIpmCounterStatRxPktsBin15: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN15)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN15)
        : 0,
      saiIpmCounterStatRxPktsBin16: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN16)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN16)
        : 0,
      saiIpmCounterStatRxPktsBin17: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN17)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN17)
        : 0,
      saiIpmCounterStatRxPktsBin18: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN18)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN18)
        : 0,
      saiIpmCounterStatRxPktsBin19: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN19)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN19)
        : 0,
      saiIpmCounterStatRxPktsBin20: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN20)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN20)
        : 0,
      saiIpmCounterStatRxPktsBin21: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN21)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN21)
        : 0,
      saiIpmCounterStatRxPktsBin22: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN22)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN22)
        : 0,
      saiIpmCounterStatRxPktsBin23: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN23)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN23)
        : 0,
      saiIpmCounterStatRxPktsBin24: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN24)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN24)
        : 0,
      saiIpmCounterStatRxPktsBin25: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN25)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN25)
        : 0,
      saiIpmCounterStatRxPktsBin26: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN26)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN26)
        : 0,
      saiIpmCounterStatRxPktsBin27: isSet(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN27)
        ? globalThis.Number(object.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN27)
        : 0,
      sessionId: isSet(object.SESSION_ID) ? globalThis.String(object.SESSION_ID) : "",
      srcAddr: isSet(object.SRC_ADDR) ? globalThis.String(object.SRC_ADDR) : "",
      dstAddr: isSet(object.DST_ADDR) ? globalThis.String(object.DST_ADDR) : "",
      vrf: isSet(object.VRF) ? globalThis.String(object.VRF) : "",
      flowLabel: isSet(object.FLOW_LABEL) ? globalThis.Number(object.FLOW_LABEL) : 0,
      txInterval: isSet(object.TX_INTERVAL) ? globalThis.Number(object.TX_INTERVAL) : 0,
      dscp: isSet(object.DSCP) ? globalThis.Number(object.DSCP) : 0,
      histogramTemplate: isSet(object.HISTOGRAM_TEMPLATE) ? globalThis.String(object.HISTOGRAM_TEMPLATE) : "",
      rxPkts: isSet(object.RX_PKTS) ? globalThis.Number(object.RX_PKTS) : 0,
      alternateMarkingInterval: isSet(object.ALTERNATE_MARKING_INTERVAL)
        ? globalThis.Number(object.ALTERNATE_MARKING_INTERVAL)
        : 0,
      livenessTimer: isSet(object.LIVENESS_TIMER) ? globalThis.Number(object.LIVENESS_TIMER) : 0,
      state: isSet(object.STATE) ? globalThis.String(object.STATE) : "",
      description: isSet(object.DESCRIPTION) ? globalThis.String(object.DESCRIPTION) : "",
      confSessionId: isSet(object.CONF_SESSION_ID) ? globalThis.String(object.CONF_SESSION_ID) : "",
    };
  },

  toJSON(message: IpmCounter): unknown {
    const obj: any = {};
    if (message.saiIpmCounterStatRxPktsBin0 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN0 = Math.round(message.saiIpmCounterStatRxPktsBin0);
    }
    if (message.saiIpmCounterStatRxPktsBin1 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN1 = Math.round(message.saiIpmCounterStatRxPktsBin1);
    }
    if (message.saiIpmCounterStatRxPktsBin2 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN2 = Math.round(message.saiIpmCounterStatRxPktsBin2);
    }
    if (message.saiIpmCounterStatRxPktsBin3 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN3 = Math.round(message.saiIpmCounterStatRxPktsBin3);
    }
    if (message.saiIpmCounterStatRxPktsBin4 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN4 = Math.round(message.saiIpmCounterStatRxPktsBin4);
    }
    if (message.saiIpmCounterStatRxPktsBin5 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN5 = Math.round(message.saiIpmCounterStatRxPktsBin5);
    }
    if (message.saiIpmCounterStatRxPktsBin6 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN6 = Math.round(message.saiIpmCounterStatRxPktsBin6);
    }
    if (message.saiIpmCounterStatRxPktsBin7 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN7 = Math.round(message.saiIpmCounterStatRxPktsBin7);
    }
    if (message.saiIpmCounterStatRxPktsBin8 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN8 = Math.round(message.saiIpmCounterStatRxPktsBin8);
    }
    if (message.saiIpmCounterStatRxPktsBin9 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN9 = Math.round(message.saiIpmCounterStatRxPktsBin9);
    }
    if (message.saiIpmCounterStatRxPktsBin10 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN10 = Math.round(message.saiIpmCounterStatRxPktsBin10);
    }
    if (message.saiIpmCounterStatRxPktsBin11 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN11 = Math.round(message.saiIpmCounterStatRxPktsBin11);
    }
    if (message.saiIpmCounterStatRxPktsBin12 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN12 = Math.round(message.saiIpmCounterStatRxPktsBin12);
    }
    if (message.saiIpmCounterStatRxPktsBin13 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN13 = Math.round(message.saiIpmCounterStatRxPktsBin13);
    }
    if (message.saiIpmCounterStatRxPktsBin14 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN14 = Math.round(message.saiIpmCounterStatRxPktsBin14);
    }
    if (message.saiIpmCounterStatRxPktsBin15 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN15 = Math.round(message.saiIpmCounterStatRxPktsBin15);
    }
    if (message.saiIpmCounterStatRxPktsBin16 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN16 = Math.round(message.saiIpmCounterStatRxPktsBin16);
    }
    if (message.saiIpmCounterStatRxPktsBin17 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN17 = Math.round(message.saiIpmCounterStatRxPktsBin17);
    }
    if (message.saiIpmCounterStatRxPktsBin18 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN18 = Math.round(message.saiIpmCounterStatRxPktsBin18);
    }
    if (message.saiIpmCounterStatRxPktsBin19 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN19 = Math.round(message.saiIpmCounterStatRxPktsBin19);
    }
    if (message.saiIpmCounterStatRxPktsBin20 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN20 = Math.round(message.saiIpmCounterStatRxPktsBin20);
    }
    if (message.saiIpmCounterStatRxPktsBin21 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN21 = Math.round(message.saiIpmCounterStatRxPktsBin21);
    }
    if (message.saiIpmCounterStatRxPktsBin22 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN22 = Math.round(message.saiIpmCounterStatRxPktsBin22);
    }
    if (message.saiIpmCounterStatRxPktsBin23 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN23 = Math.round(message.saiIpmCounterStatRxPktsBin23);
    }
    if (message.saiIpmCounterStatRxPktsBin24 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN24 = Math.round(message.saiIpmCounterStatRxPktsBin24);
    }
    if (message.saiIpmCounterStatRxPktsBin25 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN25 = Math.round(message.saiIpmCounterStatRxPktsBin25);
    }
    if (message.saiIpmCounterStatRxPktsBin26 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN26 = Math.round(message.saiIpmCounterStatRxPktsBin26);
    }
    if (message.saiIpmCounterStatRxPktsBin27 !== 0) {
      obj.SAI_IPM_COUNTER_STAT_RX_PKTS_BIN27 = Math.round(message.saiIpmCounterStatRxPktsBin27);
    }
    if (message.sessionId !== "") {
      obj.SESSION_ID = message.sessionId;
    }
    if (message.srcAddr !== "") {
      obj.SRC_ADDR = message.srcAddr;
    }
    if (message.dstAddr !== "") {
      obj.DST_ADDR = message.dstAddr;
    }
    if (message.vrf !== "") {
      obj.VRF = message.vrf;
    }
    if (message.flowLabel !== 0) {
      obj.FLOW_LABEL = Math.round(message.flowLabel);
    }
    if (message.txInterval !== 0) {
      obj.TX_INTERVAL = Math.round(message.txInterval);
    }
    if (message.dscp !== 0) {
      obj.DSCP = Math.round(message.dscp);
    }
    if (message.histogramTemplate !== "") {
      obj.HISTOGRAM_TEMPLATE = message.histogramTemplate;
    }
    if (message.rxPkts !== 0) {
      obj.RX_PKTS = Math.round(message.rxPkts);
    }
    if (message.alternateMarkingInterval !== 0) {
      obj.ALTERNATE_MARKING_INTERVAL = Math.round(message.alternateMarkingInterval);
    }
    if (message.livenessTimer !== 0) {
      obj.LIVENESS_TIMER = Math.round(message.livenessTimer);
    }
    if (message.state !== "") {
      obj.STATE = message.state;
    }
    if (message.description !== "") {
      obj.DESCRIPTION = message.description;
    }
    if (message.confSessionId !== "") {
      obj.CONF_SESSION_ID = message.confSessionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpmCounter>, I>>(base?: I): IpmCounter {
    return IpmCounter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpmCounter>, I>>(object: I): IpmCounter {
    const message = createBaseIpmCounter();
    message.saiIpmCounterStatRxPktsBin0 = object.saiIpmCounterStatRxPktsBin0 ?? 0;
    message.saiIpmCounterStatRxPktsBin1 = object.saiIpmCounterStatRxPktsBin1 ?? 0;
    message.saiIpmCounterStatRxPktsBin2 = object.saiIpmCounterStatRxPktsBin2 ?? 0;
    message.saiIpmCounterStatRxPktsBin3 = object.saiIpmCounterStatRxPktsBin3 ?? 0;
    message.saiIpmCounterStatRxPktsBin4 = object.saiIpmCounterStatRxPktsBin4 ?? 0;
    message.saiIpmCounterStatRxPktsBin5 = object.saiIpmCounterStatRxPktsBin5 ?? 0;
    message.saiIpmCounterStatRxPktsBin6 = object.saiIpmCounterStatRxPktsBin6 ?? 0;
    message.saiIpmCounterStatRxPktsBin7 = object.saiIpmCounterStatRxPktsBin7 ?? 0;
    message.saiIpmCounterStatRxPktsBin8 = object.saiIpmCounterStatRxPktsBin8 ?? 0;
    message.saiIpmCounterStatRxPktsBin9 = object.saiIpmCounterStatRxPktsBin9 ?? 0;
    message.saiIpmCounterStatRxPktsBin10 = object.saiIpmCounterStatRxPktsBin10 ?? 0;
    message.saiIpmCounterStatRxPktsBin11 = object.saiIpmCounterStatRxPktsBin11 ?? 0;
    message.saiIpmCounterStatRxPktsBin12 = object.saiIpmCounterStatRxPktsBin12 ?? 0;
    message.saiIpmCounterStatRxPktsBin13 = object.saiIpmCounterStatRxPktsBin13 ?? 0;
    message.saiIpmCounterStatRxPktsBin14 = object.saiIpmCounterStatRxPktsBin14 ?? 0;
    message.saiIpmCounterStatRxPktsBin15 = object.saiIpmCounterStatRxPktsBin15 ?? 0;
    message.saiIpmCounterStatRxPktsBin16 = object.saiIpmCounterStatRxPktsBin16 ?? 0;
    message.saiIpmCounterStatRxPktsBin17 = object.saiIpmCounterStatRxPktsBin17 ?? 0;
    message.saiIpmCounterStatRxPktsBin18 = object.saiIpmCounterStatRxPktsBin18 ?? 0;
    message.saiIpmCounterStatRxPktsBin19 = object.saiIpmCounterStatRxPktsBin19 ?? 0;
    message.saiIpmCounterStatRxPktsBin20 = object.saiIpmCounterStatRxPktsBin20 ?? 0;
    message.saiIpmCounterStatRxPktsBin21 = object.saiIpmCounterStatRxPktsBin21 ?? 0;
    message.saiIpmCounterStatRxPktsBin22 = object.saiIpmCounterStatRxPktsBin22 ?? 0;
    message.saiIpmCounterStatRxPktsBin23 = object.saiIpmCounterStatRxPktsBin23 ?? 0;
    message.saiIpmCounterStatRxPktsBin24 = object.saiIpmCounterStatRxPktsBin24 ?? 0;
    message.saiIpmCounterStatRxPktsBin25 = object.saiIpmCounterStatRxPktsBin25 ?? 0;
    message.saiIpmCounterStatRxPktsBin26 = object.saiIpmCounterStatRxPktsBin26 ?? 0;
    message.saiIpmCounterStatRxPktsBin27 = object.saiIpmCounterStatRxPktsBin27 ?? 0;
    message.sessionId = object.sessionId ?? "";
    message.srcAddr = object.srcAddr ?? "";
    message.dstAddr = object.dstAddr ?? "";
    message.vrf = object.vrf ?? "";
    message.flowLabel = object.flowLabel ?? 0;
    message.txInterval = object.txInterval ?? 0;
    message.dscp = object.dscp ?? 0;
    message.histogramTemplate = object.histogramTemplate ?? "";
    message.rxPkts = object.rxPkts ?? 0;
    message.alternateMarkingInterval = object.alternateMarkingInterval ?? 0;
    message.livenessTimer = object.livenessTimer ?? 0;
    message.state = object.state ?? "";
    message.description = object.description ?? "";
    message.confSessionId = object.confSessionId ?? "";
    return message;
  },
};

function createBaseIpmSessionStatus(): IpmSessionStatus {
  return {
    srcAddr: "",
    dstAddr: "",
    vrf: "",
    flowLabel: 0,
    txInterval: 0,
    dscp: 0,
    srcPort: 0,
    dstPort: 0,
    histogramTemplate: "",
    livenessTimer: 0,
    state: "",
    sessionId: "",
    description: "",
    confSessionId: "",
  };
}

export const IpmSessionStatus = {
  encode(message: IpmSessionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.srcAddr !== "") {
      writer.uint32(18).string(message.srcAddr);
    }
    if (message.dstAddr !== "") {
      writer.uint32(26).string(message.dstAddr);
    }
    if (message.vrf !== "") {
      writer.uint32(34).string(message.vrf);
    }
    if (message.flowLabel !== 0) {
      writer.uint32(40).uint32(message.flowLabel);
    }
    if (message.txInterval !== 0) {
      writer.uint32(48).uint32(message.txInterval);
    }
    if (message.dscp !== 0) {
      writer.uint32(56).uint32(message.dscp);
    }
    if (message.srcPort !== 0) {
      writer.uint32(64).uint32(message.srcPort);
    }
    if (message.dstPort !== 0) {
      writer.uint32(72).uint32(message.dstPort);
    }
    if (message.histogramTemplate !== "") {
      writer.uint32(90).string(message.histogramTemplate);
    }
    if (message.livenessTimer !== 0) {
      writer.uint32(96).uint32(message.livenessTimer);
    }
    if (message.state !== "") {
      writer.uint32(106).string(message.state);
    }
    if (message.sessionId !== "") {
      writer.uint32(114).string(message.sessionId);
    }
    if (message.description !== "") {
      writer.uint32(122).string(message.description);
    }
    if (message.confSessionId !== "") {
      writer.uint32(130).string(message.confSessionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpmSessionStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpmSessionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.srcAddr = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dstAddr = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.vrf = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.flowLabel = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.txInterval = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.dscp = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.srcPort = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.dstPort = reader.uint32();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.histogramTemplate = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.livenessTimer = reader.uint32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.state = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.sessionId = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.description = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.confSessionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpmSessionStatus {
    return {
      srcAddr: isSet(object.srcAddr) ? globalThis.String(object.srcAddr) : "",
      dstAddr: isSet(object.dstAddr) ? globalThis.String(object.dstAddr) : "",
      vrf: isSet(object.vrf) ? globalThis.String(object.vrf) : "",
      flowLabel: isSet(object.flowLabel) ? globalThis.Number(object.flowLabel) : 0,
      txInterval: isSet(object.txInterval) ? globalThis.Number(object.txInterval) : 0,
      dscp: isSet(object.dscp) ? globalThis.Number(object.dscp) : 0,
      srcPort: isSet(object.srcPort) ? globalThis.Number(object.srcPort) : 0,
      dstPort: isSet(object.dstPort) ? globalThis.Number(object.dstPort) : 0,
      histogramTemplate: isSet(object.histogramTemplate) ? globalThis.String(object.histogramTemplate) : "",
      livenessTimer: isSet(object.livenessTimer) ? globalThis.Number(object.livenessTimer) : 0,
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      sessionId: isSet(object.sessionId) ? globalThis.String(object.sessionId) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      confSessionId: isSet(object.confSessionId) ? globalThis.String(object.confSessionId) : "",
    };
  },

  toJSON(message: IpmSessionStatus): unknown {
    const obj: any = {};
    if (message.srcAddr !== "") {
      obj.srcAddr = message.srcAddr;
    }
    if (message.dstAddr !== "") {
      obj.dstAddr = message.dstAddr;
    }
    if (message.vrf !== "") {
      obj.vrf = message.vrf;
    }
    if (message.flowLabel !== 0) {
      obj.flowLabel = Math.round(message.flowLabel);
    }
    if (message.txInterval !== 0) {
      obj.txInterval = Math.round(message.txInterval);
    }
    if (message.dscp !== 0) {
      obj.dscp = Math.round(message.dscp);
    }
    if (message.srcPort !== 0) {
      obj.srcPort = Math.round(message.srcPort);
    }
    if (message.dstPort !== 0) {
      obj.dstPort = Math.round(message.dstPort);
    }
    if (message.histogramTemplate !== "") {
      obj.histogramTemplate = message.histogramTemplate;
    }
    if (message.livenessTimer !== 0) {
      obj.livenessTimer = Math.round(message.livenessTimer);
    }
    if (message.state !== "") {
      obj.state = message.state;
    }
    if (message.sessionId !== "") {
      obj.sessionId = message.sessionId;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.confSessionId !== "") {
      obj.confSessionId = message.confSessionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpmSessionStatus>, I>>(base?: I): IpmSessionStatus {
    return IpmSessionStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpmSessionStatus>, I>>(object: I): IpmSessionStatus {
    const message = createBaseIpmSessionStatus();
    message.srcAddr = object.srcAddr ?? "";
    message.dstAddr = object.dstAddr ?? "";
    message.vrf = object.vrf ?? "";
    message.flowLabel = object.flowLabel ?? 0;
    message.txInterval = object.txInterval ?? 0;
    message.dscp = object.dscp ?? 0;
    message.srcPort = object.srcPort ?? 0;
    message.dstPort = object.dstPort ?? 0;
    message.histogramTemplate = object.histogramTemplate ?? "";
    message.livenessTimer = object.livenessTimer ?? 0;
    message.state = object.state ?? "";
    message.sessionId = object.sessionId ?? "";
    message.description = object.description ?? "";
    message.confSessionId = object.confSessionId ?? "";
    return message;
  },
};

function createBaseIpmStats(): IpmStats {
  return { statsInfo: undefined, chassisInfo: undefined, ipmCounters: [] };
}

export const IpmStats = {
  encode(message: IpmStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statsInfo !== undefined) {
      StatsMetadata.encode(message.statsInfo, writer.uint32(18).fork()).ldelim();
    }
    if (message.chassisInfo !== undefined) {
      ChassisInfo.encode(message.chassisInfo, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.ipmCounters) {
      IpmCounter.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpmStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpmStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.statsInfo = StatsMetadata.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.chassisInfo = ChassisInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ipmCounters.push(IpmCounter.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpmStats {
    return {
      statsInfo: isSet(object.statsInfo) ? StatsMetadata.fromJSON(object.statsInfo) : undefined,
      chassisInfo: isSet(object.chassisInfo) ? ChassisInfo.fromJSON(object.chassisInfo) : undefined,
      ipmCounters: globalThis.Array.isArray(object?.ipmCounters)
        ? object.ipmCounters.map((e: any) => IpmCounter.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IpmStats): unknown {
    const obj: any = {};
    if (message.statsInfo !== undefined) {
      obj.statsInfo = StatsMetadata.toJSON(message.statsInfo);
    }
    if (message.chassisInfo !== undefined) {
      obj.chassisInfo = ChassisInfo.toJSON(message.chassisInfo);
    }
    if (message.ipmCounters?.length) {
      obj.ipmCounters = message.ipmCounters.map((e) => IpmCounter.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpmStats>, I>>(base?: I): IpmStats {
    return IpmStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpmStats>, I>>(object: I): IpmStats {
    const message = createBaseIpmStats();
    message.statsInfo = (object.statsInfo !== undefined && object.statsInfo !== null)
      ? StatsMetadata.fromPartial(object.statsInfo)
      : undefined;
    message.chassisInfo = (object.chassisInfo !== undefined && object.chassisInfo !== null)
      ? ChassisInfo.fromPartial(object.chassisInfo)
      : undefined;
    message.ipmCounters = object.ipmCounters?.map((e) => IpmCounter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIpv4Config(): Ipv4Config {
  return { enabled: false, mtu: 0, dhcpClient: false };
}

export const Ipv4Config = {
  encode(message: Ipv4Config, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    if (message.mtu !== 0) {
      writer.uint32(24).uint32(message.mtu);
    }
    if (message.dhcpClient !== false) {
      writer.uint32(32).bool(message.dhcpClient);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv4Config {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv4Config();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.dhcpClient = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv4Config {
    return {
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      dhcpClient: isSet(object["dhcp-client"]) ? globalThis.Boolean(object["dhcp-client"]) : false,
    };
  },

  toJSON(message: Ipv4Config): unknown {
    const obj: any = {};
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.dhcpClient !== false) {
      obj["dhcp-client"] = message.dhcpClient;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv4Config>, I>>(base?: I): Ipv4Config {
    return Ipv4Config.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv4Config>, I>>(object: I): Ipv4Config {
    const message = createBaseIpv4Config();
    message.enabled = object.enabled ?? false;
    message.mtu = object.mtu ?? 0;
    message.dhcpClient = object.dhcpClient ?? false;
    return message;
  },
};

function createBaseIpv4Neighbor(): Ipv4Neighbor {
  return { ip: "", config: undefined, state: undefined };
}

export const Ipv4Neighbor = {
  encode(message: Ipv4Neighbor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ip !== "") {
      writer.uint32(18).string(message.ip);
    }
    if (message.config !== undefined) {
      Ipv4NeighborConfig.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    if (message.state !== undefined) {
      Ipv4NeighborState.encode(message.state, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv4Neighbor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv4Neighbor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ip = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = Ipv4NeighborConfig.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.state = Ipv4NeighborState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv4Neighbor {
    return {
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
      config: isSet(object.config) ? Ipv4NeighborConfig.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? Ipv4NeighborState.fromJSON(object.state) : undefined,
    };
  },

  toJSON(message: Ipv4Neighbor): unknown {
    const obj: any = {};
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    if (message.config !== undefined) {
      obj.config = Ipv4NeighborConfig.toJSON(message.config);
    }
    if (message.state !== undefined) {
      obj.state = Ipv4NeighborState.toJSON(message.state);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv4Neighbor>, I>>(base?: I): Ipv4Neighbor {
    return Ipv4Neighbor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv4Neighbor>, I>>(object: I): Ipv4Neighbor {
    const message = createBaseIpv4Neighbor();
    message.ip = object.ip ?? "";
    message.config = (object.config !== undefined && object.config !== null)
      ? Ipv4NeighborConfig.fromPartial(object.config)
      : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? Ipv4NeighborState.fromPartial(object.state)
      : undefined;
    return message;
  },
};

function createBaseIpv4NeighborConfig(): Ipv4NeighborConfig {
  return { ip: "", linkLayerAddress: "" };
}

export const Ipv4NeighborConfig = {
  encode(message: Ipv4NeighborConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ip !== "") {
      writer.uint32(18).string(message.ip);
    }
    if (message.linkLayerAddress !== "") {
      writer.uint32(26).string(message.linkLayerAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv4NeighborConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv4NeighborConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ip = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.linkLayerAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv4NeighborConfig {
    return {
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
      linkLayerAddress: isSet(object["link-layer-address"]) ? globalThis.String(object["link-layer-address"]) : "",
    };
  },

  toJSON(message: Ipv4NeighborConfig): unknown {
    const obj: any = {};
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    if (message.linkLayerAddress !== "") {
      obj["link-layer-address"] = message.linkLayerAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv4NeighborConfig>, I>>(base?: I): Ipv4NeighborConfig {
    return Ipv4NeighborConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv4NeighborConfig>, I>>(object: I): Ipv4NeighborConfig {
    const message = createBaseIpv4NeighborConfig();
    message.ip = object.ip ?? "";
    message.linkLayerAddress = object.linkLayerAddress ?? "";
    return message;
  },
};

function createBaseIpv4NeighborState(): Ipv4NeighborState {
  return { ip: "", linkLayerAddress: "", origin: "" };
}

export const Ipv4NeighborState = {
  encode(message: Ipv4NeighborState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ip !== "") {
      writer.uint32(18).string(message.ip);
    }
    if (message.linkLayerAddress !== "") {
      writer.uint32(26).string(message.linkLayerAddress);
    }
    if (message.origin !== "") {
      writer.uint32(34).string(message.origin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv4NeighborState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv4NeighborState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ip = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.linkLayerAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.origin = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv4NeighborState {
    return {
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
      linkLayerAddress: isSet(object["link-layer-address"]) ? globalThis.String(object["link-layer-address"]) : "",
      origin: isSet(object.origin) ? globalThis.String(object.origin) : "",
    };
  },

  toJSON(message: Ipv4NeighborState): unknown {
    const obj: any = {};
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    if (message.linkLayerAddress !== "") {
      obj["link-layer-address"] = message.linkLayerAddress;
    }
    if (message.origin !== "") {
      obj.origin = message.origin;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv4NeighborState>, I>>(base?: I): Ipv4NeighborState {
    return Ipv4NeighborState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv4NeighborState>, I>>(object: I): Ipv4NeighborState {
    const message = createBaseIpv4NeighborState();
    message.ip = object.ip ?? "";
    message.linkLayerAddress = object.linkLayerAddress ?? "";
    message.origin = object.origin ?? "";
    return message;
  },
};

function createBaseIpv4Neighbors(): Ipv4Neighbors {
  return { neighbor: [] };
}

export const Ipv4Neighbors = {
  encode(message: Ipv4Neighbors, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.neighbor) {
      Ipv4Neighbor.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv4Neighbors {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv4Neighbors();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.neighbor.push(Ipv4Neighbor.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv4Neighbors {
    return {
      neighbor: globalThis.Array.isArray(object?.neighbor)
        ? object.neighbor.map((e: any) => Ipv4Neighbor.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Ipv4Neighbors): unknown {
    const obj: any = {};
    if (message.neighbor?.length) {
      obj.neighbor = message.neighbor.map((e) => Ipv4Neighbor.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv4Neighbors>, I>>(base?: I): Ipv4Neighbors {
    return Ipv4Neighbors.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv4Neighbors>, I>>(object: I): Ipv4Neighbors {
    const message = createBaseIpv4Neighbors();
    message.neighbor = object.neighbor?.map((e) => Ipv4Neighbor.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIpv4State(): Ipv4State {
  return { enabled: false, mtu: 0, dhcpClient: false, counters: undefined };
}

export const Ipv4State = {
  encode(message: Ipv4State, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    if (message.mtu !== 0) {
      writer.uint32(24).uint32(message.mtu);
    }
    if (message.dhcpClient !== false) {
      writer.uint32(32).bool(message.dhcpClient);
    }
    if (message.counters !== undefined) {
      IpCounters.encode(message.counters, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv4State {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv4State();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.dhcpClient = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.counters = IpCounters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv4State {
    return {
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      dhcpClient: isSet(object["dhcp-client"]) ? globalThis.Boolean(object["dhcp-client"]) : false,
      counters: isSet(object.counters) ? IpCounters.fromJSON(object.counters) : undefined,
    };
  },

  toJSON(message: Ipv4State): unknown {
    const obj: any = {};
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.dhcpClient !== false) {
      obj["dhcp-client"] = message.dhcpClient;
    }
    if (message.counters !== undefined) {
      obj.counters = IpCounters.toJSON(message.counters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv4State>, I>>(base?: I): Ipv4State {
    return Ipv4State.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv4State>, I>>(object: I): Ipv4State {
    const message = createBaseIpv4State();
    message.enabled = object.enabled ?? false;
    message.mtu = object.mtu ?? 0;
    message.dhcpClient = object.dhcpClient ?? false;
    message.counters = (object.counters !== undefined && object.counters !== null)
      ? IpCounters.fromPartial(object.counters)
      : undefined;
    return message;
  },
};

function createBaseIpv4Unnumbered(): Ipv4Unnumbered {
  return { config: undefined, state: undefined, interfaceRef: undefined };
}

export const Ipv4Unnumbered = {
  encode(message: Ipv4Unnumbered, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      Ipv4UnnumberedConfig.encode(message.config, writer.uint32(18).fork()).ldelim();
    }
    if (message.state !== undefined) {
      Ipv4UnnumberedState.encode(message.state, writer.uint32(26).fork()).ldelim();
    }
    if (message.interfaceRef !== undefined) {
      InterfaceRef.encode(message.interfaceRef, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv4Unnumbered {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv4Unnumbered();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.config = Ipv4UnnumberedConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.state = Ipv4UnnumberedState.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.interfaceRef = InterfaceRef.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv4Unnumbered {
    return {
      config: isSet(object.config) ? Ipv4UnnumberedConfig.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? Ipv4UnnumberedState.fromJSON(object.state) : undefined,
      interfaceRef: isSet(object["interface-ref"]) ? InterfaceRef.fromJSON(object["interface-ref"]) : undefined,
    };
  },

  toJSON(message: Ipv4Unnumbered): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = Ipv4UnnumberedConfig.toJSON(message.config);
    }
    if (message.state !== undefined) {
      obj.state = Ipv4UnnumberedState.toJSON(message.state);
    }
    if (message.interfaceRef !== undefined) {
      obj["interface-ref"] = InterfaceRef.toJSON(message.interfaceRef);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv4Unnumbered>, I>>(base?: I): Ipv4Unnumbered {
    return Ipv4Unnumbered.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv4Unnumbered>, I>>(object: I): Ipv4Unnumbered {
    const message = createBaseIpv4Unnumbered();
    message.config = (object.config !== undefined && object.config !== null)
      ? Ipv4UnnumberedConfig.fromPartial(object.config)
      : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? Ipv4UnnumberedState.fromPartial(object.state)
      : undefined;
    message.interfaceRef = (object.interfaceRef !== undefined && object.interfaceRef !== null)
      ? InterfaceRef.fromPartial(object.interfaceRef)
      : undefined;
    return message;
  },
};

function createBaseIpv4UnnumberedConfig(): Ipv4UnnumberedConfig {
  return { enabled: false };
}

export const Ipv4UnnumberedConfig = {
  encode(message: Ipv4UnnumberedConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv4UnnumberedConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv4UnnumberedConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv4UnnumberedConfig {
    return { enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false };
  },

  toJSON(message: Ipv4UnnumberedConfig): unknown {
    const obj: any = {};
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv4UnnumberedConfig>, I>>(base?: I): Ipv4UnnumberedConfig {
    return Ipv4UnnumberedConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv4UnnumberedConfig>, I>>(object: I): Ipv4UnnumberedConfig {
    const message = createBaseIpv4UnnumberedConfig();
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseIpv4UnnumberedState(): Ipv4UnnumberedState {
  return { enabled: false };
}

export const Ipv4UnnumberedState = {
  encode(message: Ipv4UnnumberedState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv4UnnumberedState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv4UnnumberedState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv4UnnumberedState {
    return { enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false };
  },

  toJSON(message: Ipv4UnnumberedState): unknown {
    const obj: any = {};
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv4UnnumberedState>, I>>(base?: I): Ipv4UnnumberedState {
    return Ipv4UnnumberedState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv4UnnumberedState>, I>>(object: I): Ipv4UnnumberedState {
    const message = createBaseIpv4UnnumberedState();
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseIpv6AutoConf(): Ipv6AutoConf {
  return { config: undefined, state: undefined };
}

export const Ipv6AutoConf = {
  encode(message: Ipv6AutoConf, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      Ipv6AutoConfConfig.encode(message.config, writer.uint32(18).fork()).ldelim();
    }
    if (message.state !== undefined) {
      Ipv6AutoConfState.encode(message.state, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6AutoConf {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6AutoConf();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.config = Ipv6AutoConfConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.state = Ipv6AutoConfState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6AutoConf {
    return {
      config: isSet(object.config) ? Ipv6AutoConfConfig.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? Ipv6AutoConfState.fromJSON(object.state) : undefined,
    };
  },

  toJSON(message: Ipv6AutoConf): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = Ipv6AutoConfConfig.toJSON(message.config);
    }
    if (message.state !== undefined) {
      obj.state = Ipv6AutoConfState.toJSON(message.state);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6AutoConf>, I>>(base?: I): Ipv6AutoConf {
    return Ipv6AutoConf.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6AutoConf>, I>>(object: I): Ipv6AutoConf {
    const message = createBaseIpv6AutoConf();
    message.config = (object.config !== undefined && object.config !== null)
      ? Ipv6AutoConfConfig.fromPartial(object.config)
      : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? Ipv6AutoConfState.fromPartial(object.state)
      : undefined;
    return message;
  },
};

function createBaseIpv6AutoConfConfig(): Ipv6AutoConfConfig {
  return {
    createGlobalAddresses: false,
    createTemporaryAddresses: false,
    temporaryValidLifetime: 0,
    temporaryPreferredLifetime: 0,
  };
}

export const Ipv6AutoConfConfig = {
  encode(message: Ipv6AutoConfConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createGlobalAddresses !== false) {
      writer.uint32(16).bool(message.createGlobalAddresses);
    }
    if (message.createTemporaryAddresses !== false) {
      writer.uint32(24).bool(message.createTemporaryAddresses);
    }
    if (message.temporaryValidLifetime !== 0) {
      writer.uint32(32).uint32(message.temporaryValidLifetime);
    }
    if (message.temporaryPreferredLifetime !== 0) {
      writer.uint32(40).uint32(message.temporaryPreferredLifetime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6AutoConfConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6AutoConfConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.createGlobalAddresses = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.createTemporaryAddresses = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.temporaryValidLifetime = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.temporaryPreferredLifetime = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6AutoConfConfig {
    return {
      createGlobalAddresses: isSet(object["create-global-addresses"])
        ? globalThis.Boolean(object["create-global-addresses"])
        : false,
      createTemporaryAddresses: isSet(object["create-temporary-addresses"])
        ? globalThis.Boolean(object["create-temporary-addresses"])
        : false,
      temporaryValidLifetime: isSet(object["temporary-valid-lifetime"])
        ? globalThis.Number(object["temporary-valid-lifetime"])
        : 0,
      temporaryPreferredLifetime: isSet(object["temporary-preferred-lifetime"])
        ? globalThis.Number(object["temporary-preferred-lifetime"])
        : 0,
    };
  },

  toJSON(message: Ipv6AutoConfConfig): unknown {
    const obj: any = {};
    if (message.createGlobalAddresses !== false) {
      obj["create-global-addresses"] = message.createGlobalAddresses;
    }
    if (message.createTemporaryAddresses !== false) {
      obj["create-temporary-addresses"] = message.createTemporaryAddresses;
    }
    if (message.temporaryValidLifetime !== 0) {
      obj["temporary-valid-lifetime"] = Math.round(message.temporaryValidLifetime);
    }
    if (message.temporaryPreferredLifetime !== 0) {
      obj["temporary-preferred-lifetime"] = Math.round(message.temporaryPreferredLifetime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6AutoConfConfig>, I>>(base?: I): Ipv6AutoConfConfig {
    return Ipv6AutoConfConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6AutoConfConfig>, I>>(object: I): Ipv6AutoConfConfig {
    const message = createBaseIpv6AutoConfConfig();
    message.createGlobalAddresses = object.createGlobalAddresses ?? false;
    message.createTemporaryAddresses = object.createTemporaryAddresses ?? false;
    message.temporaryValidLifetime = object.temporaryValidLifetime ?? 0;
    message.temporaryPreferredLifetime = object.temporaryPreferredLifetime ?? 0;
    return message;
  },
};

function createBaseIpv6AutoConfState(): Ipv6AutoConfState {
  return {
    createGlobalAddresses: false,
    createTemporaryAddresses: false,
    temporaryValidLifetime: 0,
    temporaryPreferredLifetime: 0,
  };
}

export const Ipv6AutoConfState = {
  encode(message: Ipv6AutoConfState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createGlobalAddresses !== false) {
      writer.uint32(16).bool(message.createGlobalAddresses);
    }
    if (message.createTemporaryAddresses !== false) {
      writer.uint32(24).bool(message.createTemporaryAddresses);
    }
    if (message.temporaryValidLifetime !== 0) {
      writer.uint32(32).uint32(message.temporaryValidLifetime);
    }
    if (message.temporaryPreferredLifetime !== 0) {
      writer.uint32(40).uint32(message.temporaryPreferredLifetime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6AutoConfState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6AutoConfState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.createGlobalAddresses = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.createTemporaryAddresses = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.temporaryValidLifetime = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.temporaryPreferredLifetime = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6AutoConfState {
    return {
      createGlobalAddresses: isSet(object["create-global-addresses"])
        ? globalThis.Boolean(object["create-global-addresses"])
        : false,
      createTemporaryAddresses: isSet(object["create-temporary-addresses"])
        ? globalThis.Boolean(object["create-temporary-addresses"])
        : false,
      temporaryValidLifetime: isSet(object["temporary-valid-lifetime"])
        ? globalThis.Number(object["temporary-valid-lifetime"])
        : 0,
      temporaryPreferredLifetime: isSet(object["temporary-preferred-lifetime"])
        ? globalThis.Number(object["temporary-preferred-lifetime"])
        : 0,
    };
  },

  toJSON(message: Ipv6AutoConfState): unknown {
    const obj: any = {};
    if (message.createGlobalAddresses !== false) {
      obj["create-global-addresses"] = message.createGlobalAddresses;
    }
    if (message.createTemporaryAddresses !== false) {
      obj["create-temporary-addresses"] = message.createTemporaryAddresses;
    }
    if (message.temporaryValidLifetime !== 0) {
      obj["temporary-valid-lifetime"] = Math.round(message.temporaryValidLifetime);
    }
    if (message.temporaryPreferredLifetime !== 0) {
      obj["temporary-preferred-lifetime"] = Math.round(message.temporaryPreferredLifetime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6AutoConfState>, I>>(base?: I): Ipv6AutoConfState {
    return Ipv6AutoConfState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6AutoConfState>, I>>(object: I): Ipv6AutoConfState {
    const message = createBaseIpv6AutoConfState();
    message.createGlobalAddresses = object.createGlobalAddresses ?? false;
    message.createTemporaryAddresses = object.createTemporaryAddresses ?? false;
    message.temporaryValidLifetime = object.temporaryValidLifetime ?? 0;
    message.temporaryPreferredLifetime = object.temporaryPreferredLifetime ?? 0;
    return message;
  },
};

function createBaseIpv6Config(): Ipv6Config {
  return { enabled: false, mtu: 0, dhcpClient: false, dupAddrDetectTransmits: 0, counters: undefined };
}

export const Ipv6Config = {
  encode(message: Ipv6Config, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    if (message.mtu !== 0) {
      writer.uint32(24).uint32(message.mtu);
    }
    if (message.dhcpClient !== false) {
      writer.uint32(32).bool(message.dhcpClient);
    }
    if (message.dupAddrDetectTransmits !== 0) {
      writer.uint32(40).uint32(message.dupAddrDetectTransmits);
    }
    if (message.counters !== undefined) {
      IpCounters.encode(message.counters, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6Config {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6Config();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.dhcpClient = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.dupAddrDetectTransmits = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.counters = IpCounters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6Config {
    return {
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      dhcpClient: isSet(object["dhcp-client"]) ? globalThis.Boolean(object["dhcp-client"]) : false,
      dupAddrDetectTransmits: isSet(object["dup-addr-detect-transmits"])
        ? globalThis.Number(object["dup-addr-detect-transmits"])
        : 0,
      counters: isSet(object.counters) ? IpCounters.fromJSON(object.counters) : undefined,
    };
  },

  toJSON(message: Ipv6Config): unknown {
    const obj: any = {};
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.dhcpClient !== false) {
      obj["dhcp-client"] = message.dhcpClient;
    }
    if (message.dupAddrDetectTransmits !== 0) {
      obj["dup-addr-detect-transmits"] = Math.round(message.dupAddrDetectTransmits);
    }
    if (message.counters !== undefined) {
      obj.counters = IpCounters.toJSON(message.counters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6Config>, I>>(base?: I): Ipv6Config {
    return Ipv6Config.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6Config>, I>>(object: I): Ipv6Config {
    const message = createBaseIpv6Config();
    message.enabled = object.enabled ?? false;
    message.mtu = object.mtu ?? 0;
    message.dhcpClient = object.dhcpClient ?? false;
    message.dupAddrDetectTransmits = object.dupAddrDetectTransmits ?? 0;
    message.counters = (object.counters !== undefined && object.counters !== null)
      ? IpCounters.fromPartial(object.counters)
      : undefined;
    return message;
  },
};

function createBaseIpv6Neighbor(): Ipv6Neighbor {
  return { ip: "", config: undefined, state: undefined };
}

export const Ipv6Neighbor = {
  encode(message: Ipv6Neighbor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ip !== "") {
      writer.uint32(18).string(message.ip);
    }
    if (message.config !== undefined) {
      Ipv6NeighborConfig.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    if (message.state !== undefined) {
      Ipv6NeighborState.encode(message.state, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6Neighbor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6Neighbor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ip = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = Ipv6NeighborConfig.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.state = Ipv6NeighborState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6Neighbor {
    return {
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
      config: isSet(object.config) ? Ipv6NeighborConfig.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? Ipv6NeighborState.fromJSON(object.state) : undefined,
    };
  },

  toJSON(message: Ipv6Neighbor): unknown {
    const obj: any = {};
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    if (message.config !== undefined) {
      obj.config = Ipv6NeighborConfig.toJSON(message.config);
    }
    if (message.state !== undefined) {
      obj.state = Ipv6NeighborState.toJSON(message.state);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6Neighbor>, I>>(base?: I): Ipv6Neighbor {
    return Ipv6Neighbor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6Neighbor>, I>>(object: I): Ipv6Neighbor {
    const message = createBaseIpv6Neighbor();
    message.ip = object.ip ?? "";
    message.config = (object.config !== undefined && object.config !== null)
      ? Ipv6NeighborConfig.fromPartial(object.config)
      : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? Ipv6NeighborState.fromPartial(object.state)
      : undefined;
    return message;
  },
};

function createBaseIpv6NeighborConfig(): Ipv6NeighborConfig {
  return { ip: "", linkLayerAddress: "" };
}

export const Ipv6NeighborConfig = {
  encode(message: Ipv6NeighborConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ip !== "") {
      writer.uint32(18).string(message.ip);
    }
    if (message.linkLayerAddress !== "") {
      writer.uint32(26).string(message.linkLayerAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6NeighborConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6NeighborConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ip = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.linkLayerAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6NeighborConfig {
    return {
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
      linkLayerAddress: isSet(object["link-layer-address"]) ? globalThis.String(object["link-layer-address"]) : "",
    };
  },

  toJSON(message: Ipv6NeighborConfig): unknown {
    const obj: any = {};
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    if (message.linkLayerAddress !== "") {
      obj["link-layer-address"] = message.linkLayerAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6NeighborConfig>, I>>(base?: I): Ipv6NeighborConfig {
    return Ipv6NeighborConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6NeighborConfig>, I>>(object: I): Ipv6NeighborConfig {
    const message = createBaseIpv6NeighborConfig();
    message.ip = object.ip ?? "";
    message.linkLayerAddress = object.linkLayerAddress ?? "";
    return message;
  },
};

function createBaseIpv6NeighborState(): Ipv6NeighborState {
  return { ip: "", linkLayerAddress: "", origin: "", isRouter: false, neighborState: "" };
}

export const Ipv6NeighborState = {
  encode(message: Ipv6NeighborState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ip !== "") {
      writer.uint32(18).string(message.ip);
    }
    if (message.linkLayerAddress !== "") {
      writer.uint32(26).string(message.linkLayerAddress);
    }
    if (message.origin !== "") {
      writer.uint32(34).string(message.origin);
    }
    if (message.isRouter !== false) {
      writer.uint32(40).bool(message.isRouter);
    }
    if (message.neighborState !== "") {
      writer.uint32(50).string(message.neighborState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6NeighborState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6NeighborState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ip = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.linkLayerAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.origin = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isRouter = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.neighborState = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6NeighborState {
    return {
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
      linkLayerAddress: isSet(object["link-layer-address"]) ? globalThis.String(object["link-layer-address"]) : "",
      origin: isSet(object.origin) ? globalThis.String(object.origin) : "",
      isRouter: isSet(object["is-router"]) ? globalThis.Boolean(object["is-router"]) : false,
      neighborState: isSet(object["neighbor-state"]) ? globalThis.String(object["neighbor-state"]) : "",
    };
  },

  toJSON(message: Ipv6NeighborState): unknown {
    const obj: any = {};
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    if (message.linkLayerAddress !== "") {
      obj["link-layer-address"] = message.linkLayerAddress;
    }
    if (message.origin !== "") {
      obj.origin = message.origin;
    }
    if (message.isRouter !== false) {
      obj["is-router"] = message.isRouter;
    }
    if (message.neighborState !== "") {
      obj["neighbor-state"] = message.neighborState;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6NeighborState>, I>>(base?: I): Ipv6NeighborState {
    return Ipv6NeighborState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6NeighborState>, I>>(object: I): Ipv6NeighborState {
    const message = createBaseIpv6NeighborState();
    message.ip = object.ip ?? "";
    message.linkLayerAddress = object.linkLayerAddress ?? "";
    message.origin = object.origin ?? "";
    message.isRouter = object.isRouter ?? false;
    message.neighborState = object.neighborState ?? "";
    return message;
  },
};

function createBaseIpv6Neighbors(): Ipv6Neighbors {
  return { neighbor: [] };
}

export const Ipv6Neighbors = {
  encode(message: Ipv6Neighbors, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.neighbor) {
      Ipv6Neighbor.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6Neighbors {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6Neighbors();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.neighbor.push(Ipv6Neighbor.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6Neighbors {
    return {
      neighbor: globalThis.Array.isArray(object?.neighbor)
        ? object.neighbor.map((e: any) => Ipv6Neighbor.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Ipv6Neighbors): unknown {
    const obj: any = {};
    if (message.neighbor?.length) {
      obj.neighbor = message.neighbor.map((e) => Ipv6Neighbor.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6Neighbors>, I>>(base?: I): Ipv6Neighbors {
    return Ipv6Neighbors.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6Neighbors>, I>>(object: I): Ipv6Neighbors {
    const message = createBaseIpv6Neighbors();
    message.neighbor = object.neighbor?.map((e) => Ipv6Neighbor.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIpv6Unnumbered(): Ipv6Unnumbered {
  return { config: undefined, state: undefined, interfaceRef: undefined };
}

export const Ipv6Unnumbered = {
  encode(message: Ipv6Unnumbered, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      Ipv6UnnumberedConfig.encode(message.config, writer.uint32(18).fork()).ldelim();
    }
    if (message.state !== undefined) {
      Ipv6UnnumberedState.encode(message.state, writer.uint32(26).fork()).ldelim();
    }
    if (message.interfaceRef !== undefined) {
      InterfaceRef.encode(message.interfaceRef, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6Unnumbered {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6Unnumbered();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.config = Ipv6UnnumberedConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.state = Ipv6UnnumberedState.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.interfaceRef = InterfaceRef.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6Unnumbered {
    return {
      config: isSet(object.config) ? Ipv6UnnumberedConfig.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? Ipv6UnnumberedState.fromJSON(object.state) : undefined,
      interfaceRef: isSet(object["interface-ref"]) ? InterfaceRef.fromJSON(object["interface-ref"]) : undefined,
    };
  },

  toJSON(message: Ipv6Unnumbered): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = Ipv6UnnumberedConfig.toJSON(message.config);
    }
    if (message.state !== undefined) {
      obj.state = Ipv6UnnumberedState.toJSON(message.state);
    }
    if (message.interfaceRef !== undefined) {
      obj["interface-ref"] = InterfaceRef.toJSON(message.interfaceRef);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6Unnumbered>, I>>(base?: I): Ipv6Unnumbered {
    return Ipv6Unnumbered.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6Unnumbered>, I>>(object: I): Ipv6Unnumbered {
    const message = createBaseIpv6Unnumbered();
    message.config = (object.config !== undefined && object.config !== null)
      ? Ipv6UnnumberedConfig.fromPartial(object.config)
      : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? Ipv6UnnumberedState.fromPartial(object.state)
      : undefined;
    message.interfaceRef = (object.interfaceRef !== undefined && object.interfaceRef !== null)
      ? InterfaceRef.fromPartial(object.interfaceRef)
      : undefined;
    return message;
  },
};

function createBaseIpv6UnnumberedConfig(): Ipv6UnnumberedConfig {
  return { enabled: false };
}

export const Ipv6UnnumberedConfig = {
  encode(message: Ipv6UnnumberedConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6UnnumberedConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6UnnumberedConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6UnnumberedConfig {
    return { enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false };
  },

  toJSON(message: Ipv6UnnumberedConfig): unknown {
    const obj: any = {};
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6UnnumberedConfig>, I>>(base?: I): Ipv6UnnumberedConfig {
    return Ipv6UnnumberedConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6UnnumberedConfig>, I>>(object: I): Ipv6UnnumberedConfig {
    const message = createBaseIpv6UnnumberedConfig();
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseIpv6UnnumberedState(): Ipv6UnnumberedState {
  return { enabled: false };
}

export const Ipv6UnnumberedState = {
  encode(message: Ipv6UnnumberedState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ipv6UnnumberedState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpv6UnnumberedState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ipv6UnnumberedState {
    return { enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false };
  },

  toJSON(message: Ipv6UnnumberedState): unknown {
    const obj: any = {};
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ipv6UnnumberedState>, I>>(base?: I): Ipv6UnnumberedState {
    return Ipv6UnnumberedState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ipv6UnnumberedState>, I>>(object: I): Ipv6UnnumberedState {
    const message = createBaseIpv6UnnumberedState();
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseLldpEntry(): LldpEntry {
  return {
    lldpRemChassisId: "",
    lldpRemChassisIdSubtype: "",
    lldpRemIndex: 0,
    lldpRemManAddr: "",
    lldpRemPortDesc: "",
    lldpRemPortId: "",
    lldpRemPortIdSubtype: "",
    lldpRemSysCapEnabled: "",
    lldpRemSysCapSupported: "",
    lldpRemSysDesc: "",
    lldpRemSysName: "",
    lldpRemTimeMark: "",
    localPort: "",
  };
}

export const LldpEntry = {
  encode(message: LldpEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lldpRemChassisId !== "") {
      writer.uint32(18).string(message.lldpRemChassisId);
    }
    if (message.lldpRemChassisIdSubtype !== "") {
      writer.uint32(26).string(message.lldpRemChassisIdSubtype);
    }
    if (message.lldpRemIndex !== 0) {
      writer.uint32(32).uint32(message.lldpRemIndex);
    }
    if (message.lldpRemManAddr !== "") {
      writer.uint32(42).string(message.lldpRemManAddr);
    }
    if (message.lldpRemPortDesc !== "") {
      writer.uint32(50).string(message.lldpRemPortDesc);
    }
    if (message.lldpRemPortId !== "") {
      writer.uint32(58).string(message.lldpRemPortId);
    }
    if (message.lldpRemPortIdSubtype !== "") {
      writer.uint32(66).string(message.lldpRemPortIdSubtype);
    }
    if (message.lldpRemSysCapEnabled !== "") {
      writer.uint32(74).string(message.lldpRemSysCapEnabled);
    }
    if (message.lldpRemSysCapSupported !== "") {
      writer.uint32(82).string(message.lldpRemSysCapSupported);
    }
    if (message.lldpRemSysDesc !== "") {
      writer.uint32(90).string(message.lldpRemSysDesc);
    }
    if (message.lldpRemSysName !== "") {
      writer.uint32(98).string(message.lldpRemSysName);
    }
    if (message.lldpRemTimeMark !== "") {
      writer.uint32(106).string(message.lldpRemTimeMark);
    }
    if (message.localPort !== "") {
      writer.uint32(114).string(message.localPort);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LldpEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLldpEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lldpRemChassisId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lldpRemChassisIdSubtype = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lldpRemIndex = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lldpRemManAddr = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lldpRemPortDesc = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lldpRemPortId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.lldpRemPortIdSubtype = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.lldpRemSysCapEnabled = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lldpRemSysCapSupported = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.lldpRemSysDesc = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.lldpRemSysName = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.lldpRemTimeMark = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.localPort = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LldpEntry {
    return {
      lldpRemChassisId: isSet(object.lldpRemChassisId) ? globalThis.String(object.lldpRemChassisId) : "",
      lldpRemChassisIdSubtype: isSet(object.lldpRemChassisIdSubtype)
        ? globalThis.String(object.lldpRemChassisIdSubtype)
        : "",
      lldpRemIndex: isSet(object.lldpRemIndex) ? globalThis.Number(object.lldpRemIndex) : 0,
      lldpRemManAddr: isSet(object.lldpRemManAddr) ? globalThis.String(object.lldpRemManAddr) : "",
      lldpRemPortDesc: isSet(object.lldpRemPortDesc) ? globalThis.String(object.lldpRemPortDesc) : "",
      lldpRemPortId: isSet(object.lldpRemPortId) ? globalThis.String(object.lldpRemPortId) : "",
      lldpRemPortIdSubtype: isSet(object.lldpRemPortIdSubtype) ? globalThis.String(object.lldpRemPortIdSubtype) : "",
      lldpRemSysCapEnabled: isSet(object.lldpRemSysCapEnabled) ? globalThis.String(object.lldpRemSysCapEnabled) : "",
      lldpRemSysCapSupported: isSet(object.lldpRemSysCapSupported)
        ? globalThis.String(object.lldpRemSysCapSupported)
        : "",
      lldpRemSysDesc: isSet(object.lldpRemSysDesc) ? globalThis.String(object.lldpRemSysDesc) : "",
      lldpRemSysName: isSet(object.lldpRemSysName) ? globalThis.String(object.lldpRemSysName) : "",
      lldpRemTimeMark: isSet(object.lldpRemTimeMark) ? globalThis.String(object.lldpRemTimeMark) : "",
      localPort: isSet(object.localPort) ? globalThis.String(object.localPort) : "",
    };
  },

  toJSON(message: LldpEntry): unknown {
    const obj: any = {};
    if (message.lldpRemChassisId !== "") {
      obj.lldpRemChassisId = message.lldpRemChassisId;
    }
    if (message.lldpRemChassisIdSubtype !== "") {
      obj.lldpRemChassisIdSubtype = message.lldpRemChassisIdSubtype;
    }
    if (message.lldpRemIndex !== 0) {
      obj.lldpRemIndex = Math.round(message.lldpRemIndex);
    }
    if (message.lldpRemManAddr !== "") {
      obj.lldpRemManAddr = message.lldpRemManAddr;
    }
    if (message.lldpRemPortDesc !== "") {
      obj.lldpRemPortDesc = message.lldpRemPortDesc;
    }
    if (message.lldpRemPortId !== "") {
      obj.lldpRemPortId = message.lldpRemPortId;
    }
    if (message.lldpRemPortIdSubtype !== "") {
      obj.lldpRemPortIdSubtype = message.lldpRemPortIdSubtype;
    }
    if (message.lldpRemSysCapEnabled !== "") {
      obj.lldpRemSysCapEnabled = message.lldpRemSysCapEnabled;
    }
    if (message.lldpRemSysCapSupported !== "") {
      obj.lldpRemSysCapSupported = message.lldpRemSysCapSupported;
    }
    if (message.lldpRemSysDesc !== "") {
      obj.lldpRemSysDesc = message.lldpRemSysDesc;
    }
    if (message.lldpRemSysName !== "") {
      obj.lldpRemSysName = message.lldpRemSysName;
    }
    if (message.lldpRemTimeMark !== "") {
      obj.lldpRemTimeMark = message.lldpRemTimeMark;
    }
    if (message.localPort !== "") {
      obj.localPort = message.localPort;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LldpEntry>, I>>(base?: I): LldpEntry {
    return LldpEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LldpEntry>, I>>(object: I): LldpEntry {
    const message = createBaseLldpEntry();
    message.lldpRemChassisId = object.lldpRemChassisId ?? "";
    message.lldpRemChassisIdSubtype = object.lldpRemChassisIdSubtype ?? "";
    message.lldpRemIndex = object.lldpRemIndex ?? 0;
    message.lldpRemManAddr = object.lldpRemManAddr ?? "";
    message.lldpRemPortDesc = object.lldpRemPortDesc ?? "";
    message.lldpRemPortId = object.lldpRemPortId ?? "";
    message.lldpRemPortIdSubtype = object.lldpRemPortIdSubtype ?? "";
    message.lldpRemSysCapEnabled = object.lldpRemSysCapEnabled ?? "";
    message.lldpRemSysCapSupported = object.lldpRemSysCapSupported ?? "";
    message.lldpRemSysDesc = object.lldpRemSysDesc ?? "";
    message.lldpRemSysName = object.lldpRemSysName ?? "";
    message.lldpRemTimeMark = object.lldpRemTimeMark ?? "";
    message.localPort = object.localPort ?? "";
    return message;
  },
};

function createBaseMgmtConfig(): MgmtConfig {
  return {
    sonic: undefined,
    proxy: undefined,
    dns: undefined,
    drake: undefined,
    ntpServers: [],
    configSource: 0,
    ipv4Method: 0,
    ipv6Method: 0,
  };
}

export const MgmtConfig = {
  encode(message: MgmtConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sonic !== undefined) {
      SonicConfig.encode(message.sonic, writer.uint32(18).fork()).ldelim();
    }
    if (message.proxy !== undefined) {
      ProxyConfig.encode(message.proxy, writer.uint32(26).fork()).ldelim();
    }
    if (message.dns !== undefined) {
      DnsConfig.encode(message.dns, writer.uint32(34).fork()).ldelim();
    }
    if (message.drake !== undefined) {
      DrakeConfig.encode(message.drake, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.ntpServers) {
      writer.uint32(50).string(v!);
    }
    if (message.configSource !== 0) {
      writer.uint32(56).int32(message.configSource);
    }
    if (message.ipv4Method !== 0) {
      writer.uint32(64).int32(message.ipv4Method);
    }
    if (message.ipv6Method !== 0) {
      writer.uint32(72).int32(message.ipv6Method);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MgmtConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMgmtConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sonic = SonicConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proxy = ProxyConfig.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dns = DnsConfig.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.drake = DrakeConfig.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ntpServers.push(reader.string());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.configSource = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.ipv4Method = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.ipv6Method = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MgmtConfig {
    return {
      sonic: isSet(object.sonic) ? SonicConfig.fromJSON(object.sonic) : undefined,
      proxy: isSet(object.proxy) ? ProxyConfig.fromJSON(object.proxy) : undefined,
      dns: isSet(object.dns) ? DnsConfig.fromJSON(object.dns) : undefined,
      drake: isSet(object.drake) ? DrakeConfig.fromJSON(object.drake) : undefined,
      ntpServers: globalThis.Array.isArray(object?.ntpServers)
        ? object.ntpServers.map((e: any) => globalThis.String(e))
        : [],
      configSource: isSet(object.configSource) ? configSourceTypeFromJSON(object.configSource) : 0,
      ipv4Method: isSet(object.ipv4Method) ? ipMethodTypeFromJSON(object.ipv4Method) : 0,
      ipv6Method: isSet(object.ipv6Method) ? ipMethodTypeFromJSON(object.ipv6Method) : 0,
    };
  },

  toJSON(message: MgmtConfig): unknown {
    const obj: any = {};
    if (message.sonic !== undefined) {
      obj.sonic = SonicConfig.toJSON(message.sonic);
    }
    if (message.proxy !== undefined) {
      obj.proxy = ProxyConfig.toJSON(message.proxy);
    }
    if (message.dns !== undefined) {
      obj.dns = DnsConfig.toJSON(message.dns);
    }
    if (message.drake !== undefined) {
      obj.drake = DrakeConfig.toJSON(message.drake);
    }
    if (message.ntpServers?.length) {
      obj.ntpServers = message.ntpServers;
    }
    if (message.configSource !== 0) {
      obj.configSource = configSourceTypeToJSON(message.configSource);
    }
    if (message.ipv4Method !== 0) {
      obj.ipv4Method = ipMethodTypeToJSON(message.ipv4Method);
    }
    if (message.ipv6Method !== 0) {
      obj.ipv6Method = ipMethodTypeToJSON(message.ipv6Method);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MgmtConfig>, I>>(base?: I): MgmtConfig {
    return MgmtConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MgmtConfig>, I>>(object: I): MgmtConfig {
    const message = createBaseMgmtConfig();
    message.sonic = (object.sonic !== undefined && object.sonic !== null)
      ? SonicConfig.fromPartial(object.sonic)
      : undefined;
    message.proxy = (object.proxy !== undefined && object.proxy !== null)
      ? ProxyConfig.fromPartial(object.proxy)
      : undefined;
    message.dns = (object.dns !== undefined && object.dns !== null) ? DnsConfig.fromPartial(object.dns) : undefined;
    message.drake = (object.drake !== undefined && object.drake !== null)
      ? DrakeConfig.fromPartial(object.drake)
      : undefined;
    message.ntpServers = object.ntpServers?.map((e) => e) || [];
    message.configSource = object.configSource ?? 0;
    message.ipv4Method = object.ipv4Method ?? 0;
    message.ipv6Method = object.ipv6Method ?? 0;
    return message;
  },
};

function createBaseNodeProcessStats(): NodeProcessStats {
  return { metaData: undefined, chassisInfo: undefined, processStats: [] };
}

export const NodeProcessStats = {
  encode(message: NodeProcessStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.metaData !== undefined) {
      StatsMetadata.encode(message.metaData, writer.uint32(18).fork()).ldelim();
    }
    if (message.chassisInfo !== undefined) {
      ChassisInfo.encode(message.chassisInfo, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.processStats) {
      ProcessStat.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NodeProcessStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeProcessStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.metaData = StatsMetadata.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.chassisInfo = ChassisInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.processStats.push(ProcessStat.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeProcessStats {
    return {
      metaData: isSet(object.metaData) ? StatsMetadata.fromJSON(object.metaData) : undefined,
      chassisInfo: isSet(object.chassisInfo) ? ChassisInfo.fromJSON(object.chassisInfo) : undefined,
      processStats: globalThis.Array.isArray(object?.processStats)
        ? object.processStats.map((e: any) => ProcessStat.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NodeProcessStats): unknown {
    const obj: any = {};
    if (message.metaData !== undefined) {
      obj.metaData = StatsMetadata.toJSON(message.metaData);
    }
    if (message.chassisInfo !== undefined) {
      obj.chassisInfo = ChassisInfo.toJSON(message.chassisInfo);
    }
    if (message.processStats?.length) {
      obj.processStats = message.processStats.map((e) => ProcessStat.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeProcessStats>, I>>(base?: I): NodeProcessStats {
    return NodeProcessStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeProcessStats>, I>>(object: I): NodeProcessStats {
    const message = createBaseNodeProcessStats();
    message.metaData = (object.metaData !== undefined && object.metaData !== null)
      ? StatsMetadata.fromPartial(object.metaData)
      : undefined;
    message.chassisInfo = (object.chassisInfo !== undefined && object.chassisInfo !== null)
      ? ChassisInfo.fromPartial(object.chassisInfo)
      : undefined;
    message.processStats = object.processStats?.map((e) => ProcessStat.fromPartial(e)) || [];
    return message;
  },
};

function createBasePgWatermarkCounters(): PgWatermarkCounters {
  return {
    saiIngressPriorityGroupStatDroppedPackets: 0,
    saiIngressPriorityGroupStatSharedWatermarkBytes: 0,
    portName: "",
  };
}

export const PgWatermarkCounters = {
  encode(message: PgWatermarkCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.saiIngressPriorityGroupStatDroppedPackets !== 0) {
      writer.uint32(16).uint64(message.saiIngressPriorityGroupStatDroppedPackets);
    }
    if (message.saiIngressPriorityGroupStatSharedWatermarkBytes !== 0) {
      writer.uint32(24).uint64(message.saiIngressPriorityGroupStatSharedWatermarkBytes);
    }
    if (message.portName !== "") {
      writer.uint32(34).string(message.portName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PgWatermarkCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePgWatermarkCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.saiIngressPriorityGroupStatDroppedPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.saiIngressPriorityGroupStatSharedWatermarkBytes = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.portName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PgWatermarkCounters {
    return {
      saiIngressPriorityGroupStatDroppedPackets: isSet(object.SAI_INGRESS_PRIORITY_GROUP_STAT_DROPPED_PACKETS)
        ? globalThis.Number(object.SAI_INGRESS_PRIORITY_GROUP_STAT_DROPPED_PACKETS)
        : 0,
      saiIngressPriorityGroupStatSharedWatermarkBytes:
        isSet(object.SAI_INGRESS_PRIORITY_GROUP_STAT_SHARED_WATERMARK_BYTES)
          ? globalThis.Number(object.SAI_INGRESS_PRIORITY_GROUP_STAT_SHARED_WATERMARK_BYTES)
          : 0,
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
    };
  },

  toJSON(message: PgWatermarkCounters): unknown {
    const obj: any = {};
    if (message.saiIngressPriorityGroupStatDroppedPackets !== 0) {
      obj.SAI_INGRESS_PRIORITY_GROUP_STAT_DROPPED_PACKETS = Math.round(
        message.saiIngressPriorityGroupStatDroppedPackets,
      );
    }
    if (message.saiIngressPriorityGroupStatSharedWatermarkBytes !== 0) {
      obj.SAI_INGRESS_PRIORITY_GROUP_STAT_SHARED_WATERMARK_BYTES = Math.round(
        message.saiIngressPriorityGroupStatSharedWatermarkBytes,
      );
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PgWatermarkCounters>, I>>(base?: I): PgWatermarkCounters {
    return PgWatermarkCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PgWatermarkCounters>, I>>(object: I): PgWatermarkCounters {
    const message = createBasePgWatermarkCounters();
    message.saiIngressPriorityGroupStatDroppedPackets = object.saiIngressPriorityGroupStatDroppedPackets ?? 0;
    message.saiIngressPriorityGroupStatSharedWatermarkBytes = object.saiIngressPriorityGroupStatSharedWatermarkBytes ??
      0;
    message.portName = object.portName ?? "";
    return message;
  },
};

function createBasePhyPortConfig(): PhyPortConfig {
  return { adminStatus: "", alias: "", index: 0, lanes: "", mtu: 0, speed: "" };
}

export const PhyPortConfig = {
  encode(message: PhyPortConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.adminStatus !== "") {
      writer.uint32(18).string(message.adminStatus);
    }
    if (message.alias !== "") {
      writer.uint32(26).string(message.alias);
    }
    if (message.index !== 0) {
      writer.uint32(32).uint32(message.index);
    }
    if (message.lanes !== "") {
      writer.uint32(42).string(message.lanes);
    }
    if (message.mtu !== 0) {
      writer.uint32(48).uint32(message.mtu);
    }
    if (message.speed !== "") {
      writer.uint32(58).string(message.speed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PhyPortConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePhyPortConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.adminStatus = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.alias = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.index = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lanes = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.speed = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PhyPortConfig {
    return {
      adminStatus: isSet(object.adminStatus) ? globalThis.String(object.adminStatus) : "",
      alias: isSet(object.alias) ? globalThis.String(object.alias) : "",
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      lanes: isSet(object.lanes) ? globalThis.String(object.lanes) : "",
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      speed: isSet(object.speed) ? globalThis.String(object.speed) : "",
    };
  },

  toJSON(message: PhyPortConfig): unknown {
    const obj: any = {};
    if (message.adminStatus !== "") {
      obj.adminStatus = message.adminStatus;
    }
    if (message.alias !== "") {
      obj.alias = message.alias;
    }
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.lanes !== "") {
      obj.lanes = message.lanes;
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.speed !== "") {
      obj.speed = message.speed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PhyPortConfig>, I>>(base?: I): PhyPortConfig {
    return PhyPortConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PhyPortConfig>, I>>(object: I): PhyPortConfig {
    const message = createBasePhyPortConfig();
    message.adminStatus = object.adminStatus ?? "";
    message.alias = object.alias ?? "";
    message.index = object.index ?? 0;
    message.lanes = object.lanes ?? "";
    message.mtu = object.mtu ?? 0;
    message.speed = object.speed ?? "";
    return message;
  },
};

function createBasePhyPortStatus(): PhyPortStatus {
  return {
    adminStatus: "",
    hostTxReady: "",
    mtu: 0,
    netdevOperStatus: "",
    speed: "",
    state: "",
    supportedFecs: "",
    supportedSpeeds: "",
    alias: "",
    description: "",
    index: 0,
    lanes: "",
    operStatus: "",
    portName: "",
    subport: "",
    asicPortName: "",
    role: "",
    sourceNpu: "",
    targetNpu: "",
    lineOperStatus: "",
    systemOperStatus: "",
  };
}

export const PhyPortStatus = {
  encode(message: PhyPortStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.adminStatus !== "") {
      writer.uint32(18).string(message.adminStatus);
    }
    if (message.hostTxReady !== "") {
      writer.uint32(26).string(message.hostTxReady);
    }
    if (message.mtu !== 0) {
      writer.uint32(32).uint32(message.mtu);
    }
    if (message.netdevOperStatus !== "") {
      writer.uint32(42).string(message.netdevOperStatus);
    }
    if (message.speed !== "") {
      writer.uint32(50).string(message.speed);
    }
    if (message.state !== "") {
      writer.uint32(58).string(message.state);
    }
    if (message.supportedFecs !== "") {
      writer.uint32(66).string(message.supportedFecs);
    }
    if (message.supportedSpeeds !== "") {
      writer.uint32(74).string(message.supportedSpeeds);
    }
    if (message.alias !== "") {
      writer.uint32(90).string(message.alias);
    }
    if (message.description !== "") {
      writer.uint32(98).string(message.description);
    }
    if (message.index !== 0) {
      writer.uint32(104).uint32(message.index);
    }
    if (message.lanes !== "") {
      writer.uint32(114).string(message.lanes);
    }
    if (message.operStatus !== "") {
      writer.uint32(122).string(message.operStatus);
    }
    if (message.portName !== "") {
      writer.uint32(130).string(message.portName);
    }
    if (message.subport !== "") {
      writer.uint32(138).string(message.subport);
    }
    if (message.asicPortName !== "") {
      writer.uint32(146).string(message.asicPortName);
    }
    if (message.role !== "") {
      writer.uint32(154).string(message.role);
    }
    if (message.sourceNpu !== "") {
      writer.uint32(162).string(message.sourceNpu);
    }
    if (message.targetNpu !== "") {
      writer.uint32(170).string(message.targetNpu);
    }
    if (message.lineOperStatus !== "") {
      writer.uint32(178).string(message.lineOperStatus);
    }
    if (message.systemOperStatus !== "") {
      writer.uint32(186).string(message.systemOperStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PhyPortStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePhyPortStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.adminStatus = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.hostTxReady = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.netdevOperStatus = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.speed = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.state = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.supportedFecs = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.supportedSpeeds = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.alias = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.description = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.index = reader.uint32();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.lanes = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.operStatus = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.subport = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.asicPortName = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.role = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.sourceNpu = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.targetNpu = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.lineOperStatus = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.systemOperStatus = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PhyPortStatus {
    return {
      adminStatus: isSet(object.adminStatus) ? globalThis.String(object.adminStatus) : "",
      hostTxReady: isSet(object.hostTxReady) ? globalThis.String(object.hostTxReady) : "",
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      netdevOperStatus: isSet(object.netdevOperStatus) ? globalThis.String(object.netdevOperStatus) : "",
      speed: isSet(object.speed) ? globalThis.String(object.speed) : "",
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      supportedFecs: isSet(object.supportedFecs) ? globalThis.String(object.supportedFecs) : "",
      supportedSpeeds: isSet(object.supportedSpeeds) ? globalThis.String(object.supportedSpeeds) : "",
      alias: isSet(object.alias) ? globalThis.String(object.alias) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      lanes: isSet(object.lanes) ? globalThis.String(object.lanes) : "",
      operStatus: isSet(object.operStatus) ? globalThis.String(object.operStatus) : "",
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      subport: isSet(object.subport) ? globalThis.String(object.subport) : "",
      asicPortName: isSet(object.asicPortName) ? globalThis.String(object.asicPortName) : "",
      role: isSet(object.role) ? globalThis.String(object.role) : "",
      sourceNpu: isSet(object.sourceNpu) ? globalThis.String(object.sourceNpu) : "",
      targetNpu: isSet(object.targetNpu) ? globalThis.String(object.targetNpu) : "",
      lineOperStatus: isSet(object.lineOperStatus) ? globalThis.String(object.lineOperStatus) : "",
      systemOperStatus: isSet(object.systemOperStatus) ? globalThis.String(object.systemOperStatus) : "",
    };
  },

  toJSON(message: PhyPortStatus): unknown {
    const obj: any = {};
    if (message.adminStatus !== "") {
      obj.adminStatus = message.adminStatus;
    }
    if (message.hostTxReady !== "") {
      obj.hostTxReady = message.hostTxReady;
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.netdevOperStatus !== "") {
      obj.netdevOperStatus = message.netdevOperStatus;
    }
    if (message.speed !== "") {
      obj.speed = message.speed;
    }
    if (message.state !== "") {
      obj.state = message.state;
    }
    if (message.supportedFecs !== "") {
      obj.supportedFecs = message.supportedFecs;
    }
    if (message.supportedSpeeds !== "") {
      obj.supportedSpeeds = message.supportedSpeeds;
    }
    if (message.alias !== "") {
      obj.alias = message.alias;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.lanes !== "") {
      obj.lanes = message.lanes;
    }
    if (message.operStatus !== "") {
      obj.operStatus = message.operStatus;
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.subport !== "") {
      obj.subport = message.subport;
    }
    if (message.asicPortName !== "") {
      obj.asicPortName = message.asicPortName;
    }
    if (message.role !== "") {
      obj.role = message.role;
    }
    if (message.sourceNpu !== "") {
      obj.sourceNpu = message.sourceNpu;
    }
    if (message.targetNpu !== "") {
      obj.targetNpu = message.targetNpu;
    }
    if (message.lineOperStatus !== "") {
      obj.lineOperStatus = message.lineOperStatus;
    }
    if (message.systemOperStatus !== "") {
      obj.systemOperStatus = message.systemOperStatus;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PhyPortStatus>, I>>(base?: I): PhyPortStatus {
    return PhyPortStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PhyPortStatus>, I>>(object: I): PhyPortStatus {
    const message = createBasePhyPortStatus();
    message.adminStatus = object.adminStatus ?? "";
    message.hostTxReady = object.hostTxReady ?? "";
    message.mtu = object.mtu ?? 0;
    message.netdevOperStatus = object.netdevOperStatus ?? "";
    message.speed = object.speed ?? "";
    message.state = object.state ?? "";
    message.supportedFecs = object.supportedFecs ?? "";
    message.supportedSpeeds = object.supportedSpeeds ?? "";
    message.alias = object.alias ?? "";
    message.description = object.description ?? "";
    message.index = object.index ?? 0;
    message.lanes = object.lanes ?? "";
    message.operStatus = object.operStatus ?? "";
    message.portName = object.portName ?? "";
    message.subport = object.subport ?? "";
    message.asicPortName = object.asicPortName ?? "";
    message.role = object.role ?? "";
    message.sourceNpu = object.sourceNpu ?? "";
    message.targetNpu = object.targetNpu ?? "";
    message.lineOperStatus = object.lineOperStatus ?? "";
    message.systemOperStatus = object.systemOperStatus ?? "";
    return message;
  },
};

function createBasePlatformInfo(): PlatformInfo {
  return {
    bufferModel: "",
    defaultBgpStatus: "",
    defaultPfcwdStatus: "",
    hostname: "",
    hwsku: "",
    mac: "",
    platform: "",
    synchronousMode: "",
  };
}

export const PlatformInfo = {
  encode(message: PlatformInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bufferModel !== "") {
      writer.uint32(18).string(message.bufferModel);
    }
    if (message.defaultBgpStatus !== "") {
      writer.uint32(26).string(message.defaultBgpStatus);
    }
    if (message.defaultPfcwdStatus !== "") {
      writer.uint32(34).string(message.defaultPfcwdStatus);
    }
    if (message.hostname !== "") {
      writer.uint32(42).string(message.hostname);
    }
    if (message.hwsku !== "") {
      writer.uint32(50).string(message.hwsku);
    }
    if (message.mac !== "") {
      writer.uint32(58).string(message.mac);
    }
    if (message.platform !== "") {
      writer.uint32(66).string(message.platform);
    }
    if (message.synchronousMode !== "") {
      writer.uint32(74).string(message.synchronousMode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PlatformInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlatformInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bufferModel = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.defaultBgpStatus = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.defaultPfcwdStatus = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.hostname = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.hwsku = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.mac = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.platform = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.synchronousMode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlatformInfo {
    return {
      bufferModel: isSet(object.bufferModel) ? globalThis.String(object.bufferModel) : "",
      defaultBgpStatus: isSet(object.defaultBgpStatus) ? globalThis.String(object.defaultBgpStatus) : "",
      defaultPfcwdStatus: isSet(object.defaultPfcwdStatus) ? globalThis.String(object.defaultPfcwdStatus) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      hwsku: isSet(object.hwsku) ? globalThis.String(object.hwsku) : "",
      mac: isSet(object.mac) ? globalThis.String(object.mac) : "",
      platform: isSet(object.platform) ? globalThis.String(object.platform) : "",
      synchronousMode: isSet(object.synchronousMode) ? globalThis.String(object.synchronousMode) : "",
    };
  },

  toJSON(message: PlatformInfo): unknown {
    const obj: any = {};
    if (message.bufferModel !== "") {
      obj.bufferModel = message.bufferModel;
    }
    if (message.defaultBgpStatus !== "") {
      obj.defaultBgpStatus = message.defaultBgpStatus;
    }
    if (message.defaultPfcwdStatus !== "") {
      obj.defaultPfcwdStatus = message.defaultPfcwdStatus;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.hwsku !== "") {
      obj.hwsku = message.hwsku;
    }
    if (message.mac !== "") {
      obj.mac = message.mac;
    }
    if (message.platform !== "") {
      obj.platform = message.platform;
    }
    if (message.synchronousMode !== "") {
      obj.synchronousMode = message.synchronousMode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlatformInfo>, I>>(base?: I): PlatformInfo {
    return PlatformInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlatformInfo>, I>>(object: I): PlatformInfo {
    const message = createBasePlatformInfo();
    message.bufferModel = object.bufferModel ?? "";
    message.defaultBgpStatus = object.defaultBgpStatus ?? "";
    message.defaultPfcwdStatus = object.defaultPfcwdStatus ?? "";
    message.hostname = object.hostname ?? "";
    message.hwsku = object.hwsku ?? "";
    message.mac = object.mac ?? "";
    message.platform = object.platform ?? "";
    message.synchronousMode = object.synchronousMode ?? "";
    return message;
  },
};

function createBasePortRateCounters(): PortRateCounters {
  return {
    rxBps: 0,
    rxPps: 0,
    saiPortStatIfInNonUcastPktsLast: 0,
    saiPortStatIfInOctetsLast: 0,
    saiPortStatIfInUcastPktsLast: 0,
    saiPortStatIfOutNonUcastPktsLast: 0,
    saiPortStatIfOutUcastPktsLast: 0,
    saiPortStatIfOutOctetsLast: 0,
    txBps: 0,
    txPps: 0,
  };
}

export const PortRateCounters = {
  encode(message: PortRateCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rxBps !== 0) {
      writer.uint32(17).double(message.rxBps);
    }
    if (message.rxPps !== 0) {
      writer.uint32(25).double(message.rxPps);
    }
    if (message.saiPortStatIfInNonUcastPktsLast !== 0) {
      writer.uint32(32).uint64(message.saiPortStatIfInNonUcastPktsLast);
    }
    if (message.saiPortStatIfInOctetsLast !== 0) {
      writer.uint32(40).uint64(message.saiPortStatIfInOctetsLast);
    }
    if (message.saiPortStatIfInUcastPktsLast !== 0) {
      writer.uint32(48).uint64(message.saiPortStatIfInUcastPktsLast);
    }
    if (message.saiPortStatIfOutNonUcastPktsLast !== 0) {
      writer.uint32(56).uint64(message.saiPortStatIfOutNonUcastPktsLast);
    }
    if (message.saiPortStatIfOutUcastPktsLast !== 0) {
      writer.uint32(64).uint64(message.saiPortStatIfOutUcastPktsLast);
    }
    if (message.saiPortStatIfOutOctetsLast !== 0) {
      writer.uint32(72).uint64(message.saiPortStatIfOutOctetsLast);
    }
    if (message.txBps !== 0) {
      writer.uint32(81).double(message.txBps);
    }
    if (message.txPps !== 0) {
      writer.uint32(89).double(message.txPps);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortRateCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortRateCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 17) {
            break;
          }

          message.rxBps = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.rxPps = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.saiPortStatIfInNonUcastPktsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.saiPortStatIfInOctetsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.saiPortStatIfInUcastPktsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.saiPortStatIfOutNonUcastPktsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.saiPortStatIfOutUcastPktsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.saiPortStatIfOutOctetsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.txBps = reader.double();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.txPps = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortRateCounters {
    return {
      rxBps: isSet(object.RX_BPS) ? globalThis.Number(object.RX_BPS) : 0,
      rxPps: isSet(object.RX_PPS) ? globalThis.Number(object.RX_PPS) : 0,
      saiPortStatIfInNonUcastPktsLast: isSet(object.SAI_PORT_STAT_IF_IN_NON_UCAST_PKTS_last)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_NON_UCAST_PKTS_last)
        : 0,
      saiPortStatIfInOctetsLast: isSet(object.SAI_PORT_STAT_IF_IN_OCTETS_last)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_OCTETS_last)
        : 0,
      saiPortStatIfInUcastPktsLast: isSet(object.SAI_PORT_STAT_IF_IN_UCAST_PKTS_last)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_UCAST_PKTS_last)
        : 0,
      saiPortStatIfOutNonUcastPktsLast: isSet(object.SAI_PORT_STAT_IF_OUT_NON_UCAST_PKTS_last)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_NON_UCAST_PKTS_last)
        : 0,
      saiPortStatIfOutUcastPktsLast: isSet(object.SAI_PORT_STAT_IF_OUT_UCAST_PKTS_last)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_UCAST_PKTS_last)
        : 0,
      saiPortStatIfOutOctetsLast: isSet(object.SAI_PORT_STAT_IF_OUT_OCTETS_last)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_OCTETS_last)
        : 0,
      txBps: isSet(object.TX_BPS) ? globalThis.Number(object.TX_BPS) : 0,
      txPps: isSet(object.TX_PPS) ? globalThis.Number(object.TX_PPS) : 0,
    };
  },

  toJSON(message: PortRateCounters): unknown {
    const obj: any = {};
    if (message.rxBps !== 0) {
      obj.RX_BPS = message.rxBps;
    }
    if (message.rxPps !== 0) {
      obj.RX_PPS = message.rxPps;
    }
    if (message.saiPortStatIfInNonUcastPktsLast !== 0) {
      obj.SAI_PORT_STAT_IF_IN_NON_UCAST_PKTS_last = Math.round(message.saiPortStatIfInNonUcastPktsLast);
    }
    if (message.saiPortStatIfInOctetsLast !== 0) {
      obj.SAI_PORT_STAT_IF_IN_OCTETS_last = Math.round(message.saiPortStatIfInOctetsLast);
    }
    if (message.saiPortStatIfInUcastPktsLast !== 0) {
      obj.SAI_PORT_STAT_IF_IN_UCAST_PKTS_last = Math.round(message.saiPortStatIfInUcastPktsLast);
    }
    if (message.saiPortStatIfOutNonUcastPktsLast !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_NON_UCAST_PKTS_last = Math.round(message.saiPortStatIfOutNonUcastPktsLast);
    }
    if (message.saiPortStatIfOutUcastPktsLast !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_UCAST_PKTS_last = Math.round(message.saiPortStatIfOutUcastPktsLast);
    }
    if (message.saiPortStatIfOutOctetsLast !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_OCTETS_last = Math.round(message.saiPortStatIfOutOctetsLast);
    }
    if (message.txBps !== 0) {
      obj.TX_BPS = message.txBps;
    }
    if (message.txPps !== 0) {
      obj.TX_PPS = message.txPps;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortRateCounters>, I>>(base?: I): PortRateCounters {
    return PortRateCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortRateCounters>, I>>(object: I): PortRateCounters {
    const message = createBasePortRateCounters();
    message.rxBps = object.rxBps ?? 0;
    message.rxPps = object.rxPps ?? 0;
    message.saiPortStatIfInNonUcastPktsLast = object.saiPortStatIfInNonUcastPktsLast ?? 0;
    message.saiPortStatIfInOctetsLast = object.saiPortStatIfInOctetsLast ?? 0;
    message.saiPortStatIfInUcastPktsLast = object.saiPortStatIfInUcastPktsLast ?? 0;
    message.saiPortStatIfOutNonUcastPktsLast = object.saiPortStatIfOutNonUcastPktsLast ?? 0;
    message.saiPortStatIfOutUcastPktsLast = object.saiPortStatIfOutUcastPktsLast ?? 0;
    message.saiPortStatIfOutOctetsLast = object.saiPortStatIfOutOctetsLast ?? 0;
    message.txBps = object.txBps ?? 0;
    message.txPps = object.txPps ?? 0;
    return message;
  },
};

function createBaseProcessStat(): ProcessStat {
  return { cpu: "", mem: "", cmd: "", ppid: "", stime: "", time: "", tt: "", uid: "", pid: "" };
}

export const ProcessStat = {
  encode(message: ProcessStat, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cpu !== "") {
      writer.uint32(18).string(message.cpu);
    }
    if (message.mem !== "") {
      writer.uint32(26).string(message.mem);
    }
    if (message.cmd !== "") {
      writer.uint32(34).string(message.cmd);
    }
    if (message.ppid !== "") {
      writer.uint32(42).string(message.ppid);
    }
    if (message.stime !== "") {
      writer.uint32(50).string(message.stime);
    }
    if (message.time !== "") {
      writer.uint32(58).string(message.time);
    }
    if (message.tt !== "") {
      writer.uint32(66).string(message.tt);
    }
    if (message.uid !== "") {
      writer.uint32(74).string(message.uid);
    }
    if (message.pid !== "") {
      writer.uint32(82).string(message.pid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessStat {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessStat();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cpu = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mem = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cmd = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ppid = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.stime = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.time = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tt = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.uid = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProcessStat {
    return {
      cpu: isSet(object["%CPU"]) ? globalThis.String(object["%CPU"]) : "",
      mem: isSet(object["%MEM"]) ? globalThis.String(object["%MEM"]) : "",
      cmd: isSet(object.CMD) ? globalThis.String(object.CMD) : "",
      ppid: isSet(object.PPID) ? globalThis.String(object.PPID) : "",
      stime: isSet(object.STIME) ? globalThis.String(object.STIME) : "",
      time: isSet(object.TIME) ? globalThis.String(object.TIME) : "",
      tt: isSet(object.TT) ? globalThis.String(object.TT) : "",
      uid: isSet(object.UID) ? globalThis.String(object.UID) : "",
      pid: isSet(object.PID) ? globalThis.String(object.PID) : "",
    };
  },

  toJSON(message: ProcessStat): unknown {
    const obj: any = {};
    if (message.cpu !== "") {
      obj["%CPU"] = message.cpu;
    }
    if (message.mem !== "") {
      obj["%MEM"] = message.mem;
    }
    if (message.cmd !== "") {
      obj.CMD = message.cmd;
    }
    if (message.ppid !== "") {
      obj.PPID = message.ppid;
    }
    if (message.stime !== "") {
      obj.STIME = message.stime;
    }
    if (message.time !== "") {
      obj.TIME = message.time;
    }
    if (message.tt !== "") {
      obj.TT = message.tt;
    }
    if (message.uid !== "") {
      obj.UID = message.uid;
    }
    if (message.pid !== "") {
      obj.PID = message.pid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProcessStat>, I>>(base?: I): ProcessStat {
    return ProcessStat.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProcessStat>, I>>(object: I): ProcessStat {
    const message = createBaseProcessStat();
    message.cpu = object.cpu ?? "";
    message.mem = object.mem ?? "";
    message.cmd = object.cmd ?? "";
    message.ppid = object.ppid ?? "";
    message.stime = object.stime ?? "";
    message.time = object.time ?? "";
    message.tt = object.tt ?? "";
    message.uid = object.uid ?? "";
    message.pid = object.pid ?? "";
    return message;
  },
};

function createBaseProxyConfig(): ProxyConfig {
  return { httpProxy: "", httpsProxy: "", noProxy: "", user: "", pass: "" };
}

export const ProxyConfig = {
  encode(message: ProxyConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.httpProxy !== "") {
      writer.uint32(18).string(message.httpProxy);
    }
    if (message.httpsProxy !== "") {
      writer.uint32(26).string(message.httpsProxy);
    }
    if (message.noProxy !== "") {
      writer.uint32(34).string(message.noProxy);
    }
    if (message.user !== "") {
      writer.uint32(42).string(message.user);
    }
    if (message.pass !== "") {
      writer.uint32(50).string(message.pass);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProxyConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProxyConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.httpProxy = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.httpsProxy = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.noProxy = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.user = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pass = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProxyConfig {
    return {
      httpProxy: isSet(object.httpProxy) ? globalThis.String(object.httpProxy) : "",
      httpsProxy: isSet(object.httpsProxy) ? globalThis.String(object.httpsProxy) : "",
      noProxy: isSet(object.noProxy) ? globalThis.String(object.noProxy) : "",
      user: isSet(object.user) ? globalThis.String(object.user) : "",
      pass: isSet(object.pass) ? globalThis.String(object.pass) : "",
    };
  },

  toJSON(message: ProxyConfig): unknown {
    const obj: any = {};
    if (message.httpProxy !== "") {
      obj.httpProxy = message.httpProxy;
    }
    if (message.httpsProxy !== "") {
      obj.httpsProxy = message.httpsProxy;
    }
    if (message.noProxy !== "") {
      obj.noProxy = message.noProxy;
    }
    if (message.user !== "") {
      obj.user = message.user;
    }
    if (message.pass !== "") {
      obj.pass = message.pass;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProxyConfig>, I>>(base?: I): ProxyConfig {
    return ProxyConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProxyConfig>, I>>(object: I): ProxyConfig {
    const message = createBaseProxyConfig();
    message.httpProxy = object.httpProxy ?? "";
    message.httpsProxy = object.httpsProxy ?? "";
    message.noProxy = object.noProxy ?? "";
    message.user = object.user ?? "";
    message.pass = object.pass ?? "";
    return message;
  },
};

function createBasePsuinfo(): Psuinfo {
  return {
    current: "",
    isReplaceable: "",
    ledStatus: "",
    model: "",
    power: "",
    presence: "",
    revision: "",
    serial: "",
    status: "",
    temp: "",
    tempThreshold: "",
    voltage: "",
    voltageMaxThreshold: "",
    voltageMinThreshold: "",
  };
}

export const Psuinfo = {
  encode(message: Psuinfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.current !== "") {
      writer.uint32(18).string(message.current);
    }
    if (message.isReplaceable !== "") {
      writer.uint32(26).string(message.isReplaceable);
    }
    if (message.ledStatus !== "") {
      writer.uint32(34).string(message.ledStatus);
    }
    if (message.model !== "") {
      writer.uint32(42).string(message.model);
    }
    if (message.power !== "") {
      writer.uint32(50).string(message.power);
    }
    if (message.presence !== "") {
      writer.uint32(58).string(message.presence);
    }
    if (message.revision !== "") {
      writer.uint32(66).string(message.revision);
    }
    if (message.serial !== "") {
      writer.uint32(74).string(message.serial);
    }
    if (message.status !== "") {
      writer.uint32(82).string(message.status);
    }
    if (message.temp !== "") {
      writer.uint32(90).string(message.temp);
    }
    if (message.tempThreshold !== "") {
      writer.uint32(98).string(message.tempThreshold);
    }
    if (message.voltage !== "") {
      writer.uint32(106).string(message.voltage);
    }
    if (message.voltageMaxThreshold !== "") {
      writer.uint32(114).string(message.voltageMaxThreshold);
    }
    if (message.voltageMinThreshold !== "") {
      writer.uint32(122).string(message.voltageMinThreshold);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Psuinfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePsuinfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.current = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.isReplaceable = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ledStatus = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.model = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.power = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.presence = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.revision = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.status = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.temp = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.tempThreshold = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.voltage = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.voltageMaxThreshold = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.voltageMinThreshold = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Psuinfo {
    return {
      current: isSet(object.current) ? globalThis.String(object.current) : "",
      isReplaceable: isSet(object.isReplaceable) ? globalThis.String(object.isReplaceable) : "",
      ledStatus: isSet(object.ledStatus) ? globalThis.String(object.ledStatus) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      power: isSet(object.power) ? globalThis.String(object.power) : "",
      presence: isSet(object.presence) ? globalThis.String(object.presence) : "",
      revision: isSet(object.revision) ? globalThis.String(object.revision) : "",
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      temp: isSet(object.temp) ? globalThis.String(object.temp) : "",
      tempThreshold: isSet(object.tempThreshold) ? globalThis.String(object.tempThreshold) : "",
      voltage: isSet(object.voltage) ? globalThis.String(object.voltage) : "",
      voltageMaxThreshold: isSet(object.voltageMaxThreshold) ? globalThis.String(object.voltageMaxThreshold) : "",
      voltageMinThreshold: isSet(object.voltageMinThreshold) ? globalThis.String(object.voltageMinThreshold) : "",
    };
  },

  toJSON(message: Psuinfo): unknown {
    const obj: any = {};
    if (message.current !== "") {
      obj.current = message.current;
    }
    if (message.isReplaceable !== "") {
      obj.isReplaceable = message.isReplaceable;
    }
    if (message.ledStatus !== "") {
      obj.ledStatus = message.ledStatus;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.power !== "") {
      obj.power = message.power;
    }
    if (message.presence !== "") {
      obj.presence = message.presence;
    }
    if (message.revision !== "") {
      obj.revision = message.revision;
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.temp !== "") {
      obj.temp = message.temp;
    }
    if (message.tempThreshold !== "") {
      obj.tempThreshold = message.tempThreshold;
    }
    if (message.voltage !== "") {
      obj.voltage = message.voltage;
    }
    if (message.voltageMaxThreshold !== "") {
      obj.voltageMaxThreshold = message.voltageMaxThreshold;
    }
    if (message.voltageMinThreshold !== "") {
      obj.voltageMinThreshold = message.voltageMinThreshold;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Psuinfo>, I>>(base?: I): Psuinfo {
    return Psuinfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Psuinfo>, I>>(object: I): Psuinfo {
    const message = createBasePsuinfo();
    message.current = object.current ?? "";
    message.isReplaceable = object.isReplaceable ?? "";
    message.ledStatus = object.ledStatus ?? "";
    message.model = object.model ?? "";
    message.power = object.power ?? "";
    message.presence = object.presence ?? "";
    message.revision = object.revision ?? "";
    message.serial = object.serial ?? "";
    message.status = object.status ?? "";
    message.temp = object.temp ?? "";
    message.tempThreshold = object.tempThreshold ?? "";
    message.voltage = object.voltage ?? "";
    message.voltageMaxThreshold = object.voltageMaxThreshold ?? "";
    message.voltageMinThreshold = object.voltageMinThreshold ?? "";
    return message;
  },
};

function createBasePuntHandlerRequest(): PuntHandlerRequest {
  return { unknownVlanRequest: undefined };
}

export const PuntHandlerRequest = {
  encode(message: PuntHandlerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.unknownVlanRequest !== undefined) {
      UnknownVlansRequest.encode(message.unknownVlanRequest, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PuntHandlerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePuntHandlerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.unknownVlanRequest = UnknownVlansRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PuntHandlerRequest {
    return {
      unknownVlanRequest: isSet(object.unknownVlanRequest)
        ? UnknownVlansRequest.fromJSON(object.unknownVlanRequest)
        : undefined,
    };
  },

  toJSON(message: PuntHandlerRequest): unknown {
    const obj: any = {};
    if (message.unknownVlanRequest !== undefined) {
      obj.unknownVlanRequest = UnknownVlansRequest.toJSON(message.unknownVlanRequest);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PuntHandlerRequest>, I>>(base?: I): PuntHandlerRequest {
    return PuntHandlerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PuntHandlerRequest>, I>>(object: I): PuntHandlerRequest {
    const message = createBasePuntHandlerRequest();
    message.unknownVlanRequest = (object.unknownVlanRequest !== undefined && object.unknownVlanRequest !== null)
      ? UnknownVlansRequest.fromPartial(object.unknownVlanRequest)
      : undefined;
    return message;
  },
};

function createBaseQueueStatCounters(): QueueStatCounters {
  return {
    saiQueueStatBytes: 0,
    saiQueueStatDroppedBytes: 0,
    saiQueueStatDroppedPackets: 0,
    saiQueueStatPackets: 0,
    saiQueueStatSharedWatermarkBytes: 0,
    queuePortName: "",
  };
}

export const QueueStatCounters = {
  encode(message: QueueStatCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.saiQueueStatBytes !== 0) {
      writer.uint32(16).uint64(message.saiQueueStatBytes);
    }
    if (message.saiQueueStatDroppedBytes !== 0) {
      writer.uint32(24).uint64(message.saiQueueStatDroppedBytes);
    }
    if (message.saiQueueStatDroppedPackets !== 0) {
      writer.uint32(32).uint64(message.saiQueueStatDroppedPackets);
    }
    if (message.saiQueueStatPackets !== 0) {
      writer.uint32(40).uint64(message.saiQueueStatPackets);
    }
    if (message.saiQueueStatSharedWatermarkBytes !== 0) {
      writer.uint32(48).uint64(message.saiQueueStatSharedWatermarkBytes);
    }
    if (message.queuePortName !== "") {
      writer.uint32(58).string(message.queuePortName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueueStatCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueueStatCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.saiQueueStatBytes = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.saiQueueStatDroppedBytes = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.saiQueueStatDroppedPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.saiQueueStatPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.saiQueueStatSharedWatermarkBytes = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.queuePortName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueueStatCounters {
    return {
      saiQueueStatBytes: isSet(object.SAI_QUEUE_STAT_BYTES) ? globalThis.Number(object.SAI_QUEUE_STAT_BYTES) : 0,
      saiQueueStatDroppedBytes: isSet(object.SAI_QUEUE_STAT_DROPPED_BYTES)
        ? globalThis.Number(object.SAI_QUEUE_STAT_DROPPED_BYTES)
        : 0,
      saiQueueStatDroppedPackets: isSet(object.SAI_QUEUE_STAT_DROPPED_PACKETS)
        ? globalThis.Number(object.SAI_QUEUE_STAT_DROPPED_PACKETS)
        : 0,
      saiQueueStatPackets: isSet(object.SAI_QUEUE_STAT_PACKETS) ? globalThis.Number(object.SAI_QUEUE_STAT_PACKETS) : 0,
      saiQueueStatSharedWatermarkBytes: isSet(object.SAI_QUEUE_STAT_SHARED_WATERMARK_BYTES)
        ? globalThis.Number(object.SAI_QUEUE_STAT_SHARED_WATERMARK_BYTES)
        : 0,
      queuePortName: isSet(object.queuePortName) ? globalThis.String(object.queuePortName) : "",
    };
  },

  toJSON(message: QueueStatCounters): unknown {
    const obj: any = {};
    if (message.saiQueueStatBytes !== 0) {
      obj.SAI_QUEUE_STAT_BYTES = Math.round(message.saiQueueStatBytes);
    }
    if (message.saiQueueStatDroppedBytes !== 0) {
      obj.SAI_QUEUE_STAT_DROPPED_BYTES = Math.round(message.saiQueueStatDroppedBytes);
    }
    if (message.saiQueueStatDroppedPackets !== 0) {
      obj.SAI_QUEUE_STAT_DROPPED_PACKETS = Math.round(message.saiQueueStatDroppedPackets);
    }
    if (message.saiQueueStatPackets !== 0) {
      obj.SAI_QUEUE_STAT_PACKETS = Math.round(message.saiQueueStatPackets);
    }
    if (message.saiQueueStatSharedWatermarkBytes !== 0) {
      obj.SAI_QUEUE_STAT_SHARED_WATERMARK_BYTES = Math.round(message.saiQueueStatSharedWatermarkBytes);
    }
    if (message.queuePortName !== "") {
      obj.queuePortName = message.queuePortName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueueStatCounters>, I>>(base?: I): QueueStatCounters {
    return QueueStatCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueueStatCounters>, I>>(object: I): QueueStatCounters {
    const message = createBaseQueueStatCounters();
    message.saiQueueStatBytes = object.saiQueueStatBytes ?? 0;
    message.saiQueueStatDroppedBytes = object.saiQueueStatDroppedBytes ?? 0;
    message.saiQueueStatDroppedPackets = object.saiQueueStatDroppedPackets ?? 0;
    message.saiQueueStatPackets = object.saiQueueStatPackets ?? 0;
    message.saiQueueStatSharedWatermarkBytes = object.saiQueueStatSharedWatermarkBytes ?? 0;
    message.queuePortName = object.queuePortName ?? "";
    return message;
  },
};

function createBaseRateStats(): RateStats {
  return { statsInfo: undefined, portRates: [], rifRates: [], tunnelRates: [] };
}

export const RateStats = {
  encode(message: RateStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statsInfo !== undefined) {
      StatsMetadata.encode(message.statsInfo, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.portRates) {
      PortRateCounters.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.rifRates) {
      RifRateCounters.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tunnelRates) {
      TunnelRateCounters.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RateStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRateStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.statsInfo = StatsMetadata.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.portRates.push(PortRateCounters.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rifRates.push(RifRateCounters.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tunnelRates.push(TunnelRateCounters.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RateStats {
    return {
      statsInfo: isSet(object.statsInfo) ? StatsMetadata.fromJSON(object.statsInfo) : undefined,
      portRates: globalThis.Array.isArray(object?.portRates)
        ? object.portRates.map((e: any) => PortRateCounters.fromJSON(e))
        : [],
      rifRates: globalThis.Array.isArray(object?.rifRates)
        ? object.rifRates.map((e: any) => RifRateCounters.fromJSON(e))
        : [],
      tunnelRates: globalThis.Array.isArray(object?.tunnelRates)
        ? object.tunnelRates.map((e: any) => TunnelRateCounters.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RateStats): unknown {
    const obj: any = {};
    if (message.statsInfo !== undefined) {
      obj.statsInfo = StatsMetadata.toJSON(message.statsInfo);
    }
    if (message.portRates?.length) {
      obj.portRates = message.portRates.map((e) => PortRateCounters.toJSON(e));
    }
    if (message.rifRates?.length) {
      obj.rifRates = message.rifRates.map((e) => RifRateCounters.toJSON(e));
    }
    if (message.tunnelRates?.length) {
      obj.tunnelRates = message.tunnelRates.map((e) => TunnelRateCounters.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RateStats>, I>>(base?: I): RateStats {
    return RateStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RateStats>, I>>(object: I): RateStats {
    const message = createBaseRateStats();
    message.statsInfo = (object.statsInfo !== undefined && object.statsInfo !== null)
      ? StatsMetadata.fromPartial(object.statsInfo)
      : undefined;
    message.portRates = object.portRates?.map((e) => PortRateCounters.fromPartial(e)) || [];
    message.rifRates = object.rifRates?.map((e) => RifRateCounters.fromPartial(e)) || [];
    message.tunnelRates = object.tunnelRates?.map((e) => TunnelRateCounters.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRifCounters(): RifCounters {
  return {
    saiRouterInterfaceStatInErrorOctets: 0,
    saiRouterInterfaceStatInErrorPackets: 0,
    saiRouterInterfaceStatInOctets: 0,
    saiRouterInterfaceStatInPackets: 0,
    saiRouterInterfaceStatOutErrorOctets: 0,
    saiRouterInterfaceStatOutErrorPackets: 0,
    saiRouterInterfaceStatOutOctets: 0,
    saiRouterInterfaceStatOutPackets: 0,
    rifName: "",
    rifType: 0,
  };
}

export const RifCounters = {
  encode(message: RifCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.saiRouterInterfaceStatInErrorOctets !== 0) {
      writer.uint32(16).uint64(message.saiRouterInterfaceStatInErrorOctets);
    }
    if (message.saiRouterInterfaceStatInErrorPackets !== 0) {
      writer.uint32(24).uint64(message.saiRouterInterfaceStatInErrorPackets);
    }
    if (message.saiRouterInterfaceStatInOctets !== 0) {
      writer.uint32(32).uint64(message.saiRouterInterfaceStatInOctets);
    }
    if (message.saiRouterInterfaceStatInPackets !== 0) {
      writer.uint32(40).uint64(message.saiRouterInterfaceStatInPackets);
    }
    if (message.saiRouterInterfaceStatOutErrorOctets !== 0) {
      writer.uint32(48).uint64(message.saiRouterInterfaceStatOutErrorOctets);
    }
    if (message.saiRouterInterfaceStatOutErrorPackets !== 0) {
      writer.uint32(56).uint64(message.saiRouterInterfaceStatOutErrorPackets);
    }
    if (message.saiRouterInterfaceStatOutOctets !== 0) {
      writer.uint32(64).uint64(message.saiRouterInterfaceStatOutOctets);
    }
    if (message.saiRouterInterfaceStatOutPackets !== 0) {
      writer.uint32(72).uint64(message.saiRouterInterfaceStatOutPackets);
    }
    if (message.rifName !== "") {
      writer.uint32(82).string(message.rifName);
    }
    if (message.rifType !== 0) {
      writer.uint32(88).int32(message.rifType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RifCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRifCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.saiRouterInterfaceStatInErrorOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.saiRouterInterfaceStatInErrorPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.saiRouterInterfaceStatInOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.saiRouterInterfaceStatInPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.saiRouterInterfaceStatOutErrorOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.saiRouterInterfaceStatOutErrorPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.saiRouterInterfaceStatOutOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.saiRouterInterfaceStatOutPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.rifName = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.rifType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RifCounters {
    return {
      saiRouterInterfaceStatInErrorOctets: isSet(object.SAI_ROUTER_INTERFACE_STAT_IN_ERROR_OCTETS)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_IN_ERROR_OCTETS)
        : 0,
      saiRouterInterfaceStatInErrorPackets: isSet(object.SAI_ROUTER_INTERFACE_STAT_IN_ERROR_PACKETS)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_IN_ERROR_PACKETS)
        : 0,
      saiRouterInterfaceStatInOctets: isSet(object.SAI_ROUTER_INTERFACE_STAT_IN_OCTETS)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_IN_OCTETS)
        : 0,
      saiRouterInterfaceStatInPackets: isSet(object.SAI_ROUTER_INTERFACE_STAT_IN_PACKETS)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_IN_PACKETS)
        : 0,
      saiRouterInterfaceStatOutErrorOctets: isSet(object.SAI_ROUTER_INTERFACE_STAT_OUT_ERROR_OCTETS)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_OUT_ERROR_OCTETS)
        : 0,
      saiRouterInterfaceStatOutErrorPackets: isSet(object.SAI_ROUTER_INTERFACE_STAT_OUT_ERROR_PACKETS)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_OUT_ERROR_PACKETS)
        : 0,
      saiRouterInterfaceStatOutOctets: isSet(object.SAI_ROUTER_INTERFACE_STAT_OUT_OCTETS)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_OUT_OCTETS)
        : 0,
      saiRouterInterfaceStatOutPackets: isSet(object.SAI_ROUTER_INTERFACE_STAT_OUT_PACKETS)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_OUT_PACKETS)
        : 0,
      rifName: isSet(object.rifName) ? globalThis.String(object.rifName) : "",
      rifType: isSet(object.rifType) ? rifInterfaceTypeFromJSON(object.rifType) : 0,
    };
  },

  toJSON(message: RifCounters): unknown {
    const obj: any = {};
    if (message.saiRouterInterfaceStatInErrorOctets !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_IN_ERROR_OCTETS = Math.round(message.saiRouterInterfaceStatInErrorOctets);
    }
    if (message.saiRouterInterfaceStatInErrorPackets !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_IN_ERROR_PACKETS = Math.round(message.saiRouterInterfaceStatInErrorPackets);
    }
    if (message.saiRouterInterfaceStatInOctets !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_IN_OCTETS = Math.round(message.saiRouterInterfaceStatInOctets);
    }
    if (message.saiRouterInterfaceStatInPackets !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_IN_PACKETS = Math.round(message.saiRouterInterfaceStatInPackets);
    }
    if (message.saiRouterInterfaceStatOutErrorOctets !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_OUT_ERROR_OCTETS = Math.round(message.saiRouterInterfaceStatOutErrorOctets);
    }
    if (message.saiRouterInterfaceStatOutErrorPackets !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_OUT_ERROR_PACKETS = Math.round(message.saiRouterInterfaceStatOutErrorPackets);
    }
    if (message.saiRouterInterfaceStatOutOctets !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_OUT_OCTETS = Math.round(message.saiRouterInterfaceStatOutOctets);
    }
    if (message.saiRouterInterfaceStatOutPackets !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_OUT_PACKETS = Math.round(message.saiRouterInterfaceStatOutPackets);
    }
    if (message.rifName !== "") {
      obj.rifName = message.rifName;
    }
    if (message.rifType !== 0) {
      obj.rifType = rifInterfaceTypeToJSON(message.rifType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RifCounters>, I>>(base?: I): RifCounters {
    return RifCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RifCounters>, I>>(object: I): RifCounters {
    const message = createBaseRifCounters();
    message.saiRouterInterfaceStatInErrorOctets = object.saiRouterInterfaceStatInErrorOctets ?? 0;
    message.saiRouterInterfaceStatInErrorPackets = object.saiRouterInterfaceStatInErrorPackets ?? 0;
    message.saiRouterInterfaceStatInOctets = object.saiRouterInterfaceStatInOctets ?? 0;
    message.saiRouterInterfaceStatInPackets = object.saiRouterInterfaceStatInPackets ?? 0;
    message.saiRouterInterfaceStatOutErrorOctets = object.saiRouterInterfaceStatOutErrorOctets ?? 0;
    message.saiRouterInterfaceStatOutErrorPackets = object.saiRouterInterfaceStatOutErrorPackets ?? 0;
    message.saiRouterInterfaceStatOutOctets = object.saiRouterInterfaceStatOutOctets ?? 0;
    message.saiRouterInterfaceStatOutPackets = object.saiRouterInterfaceStatOutPackets ?? 0;
    message.rifName = object.rifName ?? "";
    message.rifType = object.rifType ?? 0;
    return message;
  },
};

function createBaseRifRateCounters(): RifRateCounters {
  return {
    rxBps: 0,
    rxPps: 0,
    saiRouterInterfaceStatInOctetsLast: 0,
    saiRouterInterfaceStatInPacketsLast: 0,
    saiRouterInterfaceStatOutOctetsLast: 0,
    saiRouterInterfaceStatOutPacketsLast: 0,
    txBps: 0,
    txPps: 0,
  };
}

export const RifRateCounters = {
  encode(message: RifRateCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rxBps !== 0) {
      writer.uint32(17).double(message.rxBps);
    }
    if (message.rxPps !== 0) {
      writer.uint32(25).double(message.rxPps);
    }
    if (message.saiRouterInterfaceStatInOctetsLast !== 0) {
      writer.uint32(32).uint64(message.saiRouterInterfaceStatInOctetsLast);
    }
    if (message.saiRouterInterfaceStatInPacketsLast !== 0) {
      writer.uint32(40).uint64(message.saiRouterInterfaceStatInPacketsLast);
    }
    if (message.saiRouterInterfaceStatOutOctetsLast !== 0) {
      writer.uint32(48).uint64(message.saiRouterInterfaceStatOutOctetsLast);
    }
    if (message.saiRouterInterfaceStatOutPacketsLast !== 0) {
      writer.uint32(56).uint64(message.saiRouterInterfaceStatOutPacketsLast);
    }
    if (message.txBps !== 0) {
      writer.uint32(65).double(message.txBps);
    }
    if (message.txPps !== 0) {
      writer.uint32(73).double(message.txPps);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RifRateCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRifRateCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 17) {
            break;
          }

          message.rxBps = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.rxPps = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.saiRouterInterfaceStatInOctetsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.saiRouterInterfaceStatInPacketsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.saiRouterInterfaceStatOutOctetsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.saiRouterInterfaceStatOutPacketsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.txBps = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.txPps = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RifRateCounters {
    return {
      rxBps: isSet(object.RX_BPS) ? globalThis.Number(object.RX_BPS) : 0,
      rxPps: isSet(object.RX_PPS) ? globalThis.Number(object.RX_PPS) : 0,
      saiRouterInterfaceStatInOctetsLast: isSet(object.SAI_ROUTER_INTERFACE_STAT_IN_OCTETS_last)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_IN_OCTETS_last)
        : 0,
      saiRouterInterfaceStatInPacketsLast: isSet(object.SAI_ROUTER_INTERFACE_STAT_IN_PACKETS_last)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_IN_PACKETS_last)
        : 0,
      saiRouterInterfaceStatOutOctetsLast: isSet(object.SAI_ROUTER_INTERFACE_STAT_OUT_OCTETS_last)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_OUT_OCTETS_last)
        : 0,
      saiRouterInterfaceStatOutPacketsLast: isSet(object.SAI_ROUTER_INTERFACE_STAT_OUT_PACKETS_last)
        ? globalThis.Number(object.SAI_ROUTER_INTERFACE_STAT_OUT_PACKETS_last)
        : 0,
      txBps: isSet(object.TX_BPS) ? globalThis.Number(object.TX_BPS) : 0,
      txPps: isSet(object.TX_PPS) ? globalThis.Number(object.TX_PPS) : 0,
    };
  },

  toJSON(message: RifRateCounters): unknown {
    const obj: any = {};
    if (message.rxBps !== 0) {
      obj.RX_BPS = message.rxBps;
    }
    if (message.rxPps !== 0) {
      obj.RX_PPS = message.rxPps;
    }
    if (message.saiRouterInterfaceStatInOctetsLast !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_IN_OCTETS_last = Math.round(message.saiRouterInterfaceStatInOctetsLast);
    }
    if (message.saiRouterInterfaceStatInPacketsLast !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_IN_PACKETS_last = Math.round(message.saiRouterInterfaceStatInPacketsLast);
    }
    if (message.saiRouterInterfaceStatOutOctetsLast !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_OUT_OCTETS_last = Math.round(message.saiRouterInterfaceStatOutOctetsLast);
    }
    if (message.saiRouterInterfaceStatOutPacketsLast !== 0) {
      obj.SAI_ROUTER_INTERFACE_STAT_OUT_PACKETS_last = Math.round(message.saiRouterInterfaceStatOutPacketsLast);
    }
    if (message.txBps !== 0) {
      obj.TX_BPS = message.txBps;
    }
    if (message.txPps !== 0) {
      obj.TX_PPS = message.txPps;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RifRateCounters>, I>>(base?: I): RifRateCounters {
    return RifRateCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RifRateCounters>, I>>(object: I): RifRateCounters {
    const message = createBaseRifRateCounters();
    message.rxBps = object.rxBps ?? 0;
    message.rxPps = object.rxPps ?? 0;
    message.saiRouterInterfaceStatInOctetsLast = object.saiRouterInterfaceStatInOctetsLast ?? 0;
    message.saiRouterInterfaceStatInPacketsLast = object.saiRouterInterfaceStatInPacketsLast ?? 0;
    message.saiRouterInterfaceStatOutOctetsLast = object.saiRouterInterfaceStatOutOctetsLast ?? 0;
    message.saiRouterInterfaceStatOutPacketsLast = object.saiRouterInterfaceStatOutPacketsLast ?? 0;
    message.txBps = object.txBps ?? 0;
    message.txPps = object.txPps ?? 0;
    return message;
  },
};

function createBaseSaiPortCounters(): SaiPortCounters {
  return {
    saiPortStatEtherInPkts1024To1518Octets: 0,
    saiPortStatEtherInPkts128To255Octets: 0,
    saiPortStatEtherInPkts1519To2047Octets: 0,
    saiPortStatEtherInPkts2048To4095Octets: 0,
    saiPortStatEtherInPkts256To511Octets: 0,
    saiPortStatEtherInPkts4096To9216Octets: 0,
    saiPortStatEtherInPkts512To1023Octets: 0,
    saiPortStatEtherInPkts64Octets: 0,
    saiPortStatEtherInPkts65To127Octets: 0,
    saiPortStatEtherInPkts9217To16383Octets: 0,
    saiPortStatEtherOutPkts1024To1518Octets: 0,
    saiPortStatEtherOutPkts128To255Octets: 0,
    saiPortStatEtherOutPkts1519To2047Octets: 0,
    saiPortStatEtherOutPkts2048To4095Octets: 0,
    saiPortStatEtherOutPkts256To511Octets: 0,
    saiPortStatEtherOutPkts4096To9216Octets: 0,
    saiPortStatEtherOutPkts512To1023Octets: 0,
    saiPortStatEtherOutPkts64Octets: 0,
    saiPortStatEtherOutPkts65To127Octets: 0,
    saiPortStatEtherOutPkts9217To16383Octets: 0,
    saiPortStatEtherRxOversizePkts: 0,
    saiPortStatEtherStatsFragments: 0,
    saiPortStatEtherStatsJabbers: 0,
    saiPortStatEtherStatsTxNoErrors: 0,
    saiPortStatEtherStatsUndersizePkts: 0,
    saiPortStatEtherTxOversizePkts: 0,
    saiPortStatIfInBroadcastPkts: 0,
    saiPortStatIfInDiscards: 0,
    saiPortStatIfInErrors: 0,
    saiPortStatIfInFecCorrectableFrames: 0,
    saiPortStatIfInFecNotCorrectableFrames: 0,
    saiPortStatIfInFecSymbolErrors: 0,
    saiPortStatIfInMulticastPkts: 0,
    saiPortStatIfInNonUcastPkts: 0,
    saiPortStatIfInOctets: 0,
    saiPortStatIfInUcastPkts: 0,
    saiPortStatIfInUnknownProtos: 0,
    saiPortStatIfOutBroadcastPkts: 0,
    saiPortStatIfOutDiscards: 0,
    saiPortStatIfOutErrors: 0,
    saiPortStatIfOutMulticastPkts: 0,
    saiPortStatIfOutNonUcastPkts: 0,
    saiPortStatIfOutOctets: 0,
    saiPortStatIfOutQlen: 0,
    saiPortStatIfOutUcastPkts: 0,
    saiPortStatInDroppedPkts: 0,
    saiPortStatIpInReceives: 0,
    saiPortStatIpInUcastPkts: 0,
    saiPortStatOutDroppedPkts: 0,
    saiPortStatPauseRxPkts: 0,
    saiPortStatPauseTxPkts: 0,
    saiPortStatPfc0RxPkts: 0,
    saiPortStatPfc0TxPkts: 0,
    saiPortStatPfc1RxPkts: 0,
    saiPortStatPfc1TxPkts: 0,
    saiPortStatPfc2RxPkts: 0,
    saiPortStatPfc2TxPkts: 0,
    saiPortStatPfc3RxPkts: 0,
    saiPortStatPfc3TxPkts: 0,
    saiPortStatPfc4RxPkts: 0,
    saiPortStatPfc4TxPkts: 0,
    saiPortStatPfc5RxPkts: 0,
    saiPortStatPfc5TxPkts: 0,
    saiPortStatPfc6RxPkts: 0,
    saiPortStatPfc6TxPkts: 0,
    saiPortStatPfc7RxPkts: 0,
    saiPortStatPfc7TxPkts: 0,
    portName: "",
    portIndex: 0,
  };
}

export const SaiPortCounters = {
  encode(message: SaiPortCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.saiPortStatEtherInPkts1024To1518Octets !== 0) {
      writer.uint32(16).uint64(message.saiPortStatEtherInPkts1024To1518Octets);
    }
    if (message.saiPortStatEtherInPkts128To255Octets !== 0) {
      writer.uint32(24).uint64(message.saiPortStatEtherInPkts128To255Octets);
    }
    if (message.saiPortStatEtherInPkts1519To2047Octets !== 0) {
      writer.uint32(32).uint64(message.saiPortStatEtherInPkts1519To2047Octets);
    }
    if (message.saiPortStatEtherInPkts2048To4095Octets !== 0) {
      writer.uint32(40).uint64(message.saiPortStatEtherInPkts2048To4095Octets);
    }
    if (message.saiPortStatEtherInPkts256To511Octets !== 0) {
      writer.uint32(48).uint64(message.saiPortStatEtherInPkts256To511Octets);
    }
    if (message.saiPortStatEtherInPkts4096To9216Octets !== 0) {
      writer.uint32(56).uint64(message.saiPortStatEtherInPkts4096To9216Octets);
    }
    if (message.saiPortStatEtherInPkts512To1023Octets !== 0) {
      writer.uint32(64).uint64(message.saiPortStatEtherInPkts512To1023Octets);
    }
    if (message.saiPortStatEtherInPkts64Octets !== 0) {
      writer.uint32(72).uint64(message.saiPortStatEtherInPkts64Octets);
    }
    if (message.saiPortStatEtherInPkts65To127Octets !== 0) {
      writer.uint32(80).uint64(message.saiPortStatEtherInPkts65To127Octets);
    }
    if (message.saiPortStatEtherInPkts9217To16383Octets !== 0) {
      writer.uint32(88).uint64(message.saiPortStatEtherInPkts9217To16383Octets);
    }
    if (message.saiPortStatEtherOutPkts1024To1518Octets !== 0) {
      writer.uint32(96).uint64(message.saiPortStatEtherOutPkts1024To1518Octets);
    }
    if (message.saiPortStatEtherOutPkts128To255Octets !== 0) {
      writer.uint32(104).uint64(message.saiPortStatEtherOutPkts128To255Octets);
    }
    if (message.saiPortStatEtherOutPkts1519To2047Octets !== 0) {
      writer.uint32(112).uint64(message.saiPortStatEtherOutPkts1519To2047Octets);
    }
    if (message.saiPortStatEtherOutPkts2048To4095Octets !== 0) {
      writer.uint32(120).uint64(message.saiPortStatEtherOutPkts2048To4095Octets);
    }
    if (message.saiPortStatEtherOutPkts256To511Octets !== 0) {
      writer.uint32(128).uint64(message.saiPortStatEtherOutPkts256To511Octets);
    }
    if (message.saiPortStatEtherOutPkts4096To9216Octets !== 0) {
      writer.uint32(136).uint64(message.saiPortStatEtherOutPkts4096To9216Octets);
    }
    if (message.saiPortStatEtherOutPkts512To1023Octets !== 0) {
      writer.uint32(144).uint64(message.saiPortStatEtherOutPkts512To1023Octets);
    }
    if (message.saiPortStatEtherOutPkts64Octets !== 0) {
      writer.uint32(152).uint64(message.saiPortStatEtherOutPkts64Octets);
    }
    if (message.saiPortStatEtherOutPkts65To127Octets !== 0) {
      writer.uint32(160).uint64(message.saiPortStatEtherOutPkts65To127Octets);
    }
    if (message.saiPortStatEtherOutPkts9217To16383Octets !== 0) {
      writer.uint32(168).uint64(message.saiPortStatEtherOutPkts9217To16383Octets);
    }
    if (message.saiPortStatEtherRxOversizePkts !== 0) {
      writer.uint32(176).uint64(message.saiPortStatEtherRxOversizePkts);
    }
    if (message.saiPortStatEtherStatsFragments !== 0) {
      writer.uint32(184).uint64(message.saiPortStatEtherStatsFragments);
    }
    if (message.saiPortStatEtherStatsJabbers !== 0) {
      writer.uint32(192).uint64(message.saiPortStatEtherStatsJabbers);
    }
    if (message.saiPortStatEtherStatsTxNoErrors !== 0) {
      writer.uint32(200).uint64(message.saiPortStatEtherStatsTxNoErrors);
    }
    if (message.saiPortStatEtherStatsUndersizePkts !== 0) {
      writer.uint32(208).uint64(message.saiPortStatEtherStatsUndersizePkts);
    }
    if (message.saiPortStatEtherTxOversizePkts !== 0) {
      writer.uint32(216).uint64(message.saiPortStatEtherTxOversizePkts);
    }
    if (message.saiPortStatIfInBroadcastPkts !== 0) {
      writer.uint32(224).uint64(message.saiPortStatIfInBroadcastPkts);
    }
    if (message.saiPortStatIfInDiscards !== 0) {
      writer.uint32(232).uint64(message.saiPortStatIfInDiscards);
    }
    if (message.saiPortStatIfInErrors !== 0) {
      writer.uint32(240).uint64(message.saiPortStatIfInErrors);
    }
    if (message.saiPortStatIfInFecCorrectableFrames !== 0) {
      writer.uint32(248).uint64(message.saiPortStatIfInFecCorrectableFrames);
    }
    if (message.saiPortStatIfInFecNotCorrectableFrames !== 0) {
      writer.uint32(256).uint64(message.saiPortStatIfInFecNotCorrectableFrames);
    }
    if (message.saiPortStatIfInFecSymbolErrors !== 0) {
      writer.uint32(264).uint64(message.saiPortStatIfInFecSymbolErrors);
    }
    if (message.saiPortStatIfInMulticastPkts !== 0) {
      writer.uint32(272).uint64(message.saiPortStatIfInMulticastPkts);
    }
    if (message.saiPortStatIfInNonUcastPkts !== 0) {
      writer.uint32(280).uint64(message.saiPortStatIfInNonUcastPkts);
    }
    if (message.saiPortStatIfInOctets !== 0) {
      writer.uint32(288).uint64(message.saiPortStatIfInOctets);
    }
    if (message.saiPortStatIfInUcastPkts !== 0) {
      writer.uint32(296).uint64(message.saiPortStatIfInUcastPkts);
    }
    if (message.saiPortStatIfInUnknownProtos !== 0) {
      writer.uint32(304).uint64(message.saiPortStatIfInUnknownProtos);
    }
    if (message.saiPortStatIfOutBroadcastPkts !== 0) {
      writer.uint32(312).uint64(message.saiPortStatIfOutBroadcastPkts);
    }
    if (message.saiPortStatIfOutDiscards !== 0) {
      writer.uint32(320).uint64(message.saiPortStatIfOutDiscards);
    }
    if (message.saiPortStatIfOutErrors !== 0) {
      writer.uint32(328).uint64(message.saiPortStatIfOutErrors);
    }
    if (message.saiPortStatIfOutMulticastPkts !== 0) {
      writer.uint32(336).uint64(message.saiPortStatIfOutMulticastPkts);
    }
    if (message.saiPortStatIfOutNonUcastPkts !== 0) {
      writer.uint32(344).uint64(message.saiPortStatIfOutNonUcastPkts);
    }
    if (message.saiPortStatIfOutOctets !== 0) {
      writer.uint32(352).uint64(message.saiPortStatIfOutOctets);
    }
    if (message.saiPortStatIfOutQlen !== 0) {
      writer.uint32(360).uint64(message.saiPortStatIfOutQlen);
    }
    if (message.saiPortStatIfOutUcastPkts !== 0) {
      writer.uint32(368).uint64(message.saiPortStatIfOutUcastPkts);
    }
    if (message.saiPortStatInDroppedPkts !== 0) {
      writer.uint32(376).uint64(message.saiPortStatInDroppedPkts);
    }
    if (message.saiPortStatIpInReceives !== 0) {
      writer.uint32(384).uint64(message.saiPortStatIpInReceives);
    }
    if (message.saiPortStatIpInUcastPkts !== 0) {
      writer.uint32(392).uint64(message.saiPortStatIpInUcastPkts);
    }
    if (message.saiPortStatOutDroppedPkts !== 0) {
      writer.uint32(400).uint64(message.saiPortStatOutDroppedPkts);
    }
    if (message.saiPortStatPauseRxPkts !== 0) {
      writer.uint32(408).uint64(message.saiPortStatPauseRxPkts);
    }
    if (message.saiPortStatPauseTxPkts !== 0) {
      writer.uint32(416).uint64(message.saiPortStatPauseTxPkts);
    }
    if (message.saiPortStatPfc0RxPkts !== 0) {
      writer.uint32(424).uint64(message.saiPortStatPfc0RxPkts);
    }
    if (message.saiPortStatPfc0TxPkts !== 0) {
      writer.uint32(432).uint64(message.saiPortStatPfc0TxPkts);
    }
    if (message.saiPortStatPfc1RxPkts !== 0) {
      writer.uint32(440).uint64(message.saiPortStatPfc1RxPkts);
    }
    if (message.saiPortStatPfc1TxPkts !== 0) {
      writer.uint32(448).uint64(message.saiPortStatPfc1TxPkts);
    }
    if (message.saiPortStatPfc2RxPkts !== 0) {
      writer.uint32(456).uint64(message.saiPortStatPfc2RxPkts);
    }
    if (message.saiPortStatPfc2TxPkts !== 0) {
      writer.uint32(464).uint64(message.saiPortStatPfc2TxPkts);
    }
    if (message.saiPortStatPfc3RxPkts !== 0) {
      writer.uint32(472).uint64(message.saiPortStatPfc3RxPkts);
    }
    if (message.saiPortStatPfc3TxPkts !== 0) {
      writer.uint32(480).uint64(message.saiPortStatPfc3TxPkts);
    }
    if (message.saiPortStatPfc4RxPkts !== 0) {
      writer.uint32(488).uint64(message.saiPortStatPfc4RxPkts);
    }
    if (message.saiPortStatPfc4TxPkts !== 0) {
      writer.uint32(496).uint64(message.saiPortStatPfc4TxPkts);
    }
    if (message.saiPortStatPfc5RxPkts !== 0) {
      writer.uint32(504).uint64(message.saiPortStatPfc5RxPkts);
    }
    if (message.saiPortStatPfc5TxPkts !== 0) {
      writer.uint32(512).uint64(message.saiPortStatPfc5TxPkts);
    }
    if (message.saiPortStatPfc6RxPkts !== 0) {
      writer.uint32(520).uint64(message.saiPortStatPfc6RxPkts);
    }
    if (message.saiPortStatPfc6TxPkts !== 0) {
      writer.uint32(528).uint64(message.saiPortStatPfc6TxPkts);
    }
    if (message.saiPortStatPfc7RxPkts !== 0) {
      writer.uint32(536).uint64(message.saiPortStatPfc7RxPkts);
    }
    if (message.saiPortStatPfc7TxPkts !== 0) {
      writer.uint32(544).uint64(message.saiPortStatPfc7TxPkts);
    }
    if (message.portName !== "") {
      writer.uint32(554).string(message.portName);
    }
    if (message.portIndex !== 0) {
      writer.uint32(560).uint32(message.portIndex);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SaiPortCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSaiPortCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.saiPortStatEtherInPkts1024To1518Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.saiPortStatEtherInPkts128To255Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.saiPortStatEtherInPkts1519To2047Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.saiPortStatEtherInPkts2048To4095Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.saiPortStatEtherInPkts256To511Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.saiPortStatEtherInPkts4096To9216Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.saiPortStatEtherInPkts512To1023Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.saiPortStatEtherInPkts64Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.saiPortStatEtherInPkts65To127Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.saiPortStatEtherInPkts9217To16383Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.saiPortStatEtherOutPkts1024To1518Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.saiPortStatEtherOutPkts128To255Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.saiPortStatEtherOutPkts1519To2047Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.saiPortStatEtherOutPkts2048To4095Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.saiPortStatEtherOutPkts256To511Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.saiPortStatEtherOutPkts4096To9216Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.saiPortStatEtherOutPkts512To1023Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.saiPortStatEtherOutPkts64Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.saiPortStatEtherOutPkts65To127Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.saiPortStatEtherOutPkts9217To16383Octets = longToNumber(reader.uint64() as Long);
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.saiPortStatEtherRxOversizePkts = longToNumber(reader.uint64() as Long);
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.saiPortStatEtherStatsFragments = longToNumber(reader.uint64() as Long);
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.saiPortStatEtherStatsJabbers = longToNumber(reader.uint64() as Long);
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.saiPortStatEtherStatsTxNoErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.saiPortStatEtherStatsUndersizePkts = longToNumber(reader.uint64() as Long);
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.saiPortStatEtherTxOversizePkts = longToNumber(reader.uint64() as Long);
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.saiPortStatIfInBroadcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.saiPortStatIfInDiscards = longToNumber(reader.uint64() as Long);
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.saiPortStatIfInErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.saiPortStatIfInFecCorrectableFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.saiPortStatIfInFecNotCorrectableFrames = longToNumber(reader.uint64() as Long);
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.saiPortStatIfInFecSymbolErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.saiPortStatIfInMulticastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.saiPortStatIfInNonUcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.saiPortStatIfInOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.saiPortStatIfInUcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.saiPortStatIfInUnknownProtos = longToNumber(reader.uint64() as Long);
          continue;
        case 39:
          if (tag !== 312) {
            break;
          }

          message.saiPortStatIfOutBroadcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 40:
          if (tag !== 320) {
            break;
          }

          message.saiPortStatIfOutDiscards = longToNumber(reader.uint64() as Long);
          continue;
        case 41:
          if (tag !== 328) {
            break;
          }

          message.saiPortStatIfOutErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 42:
          if (tag !== 336) {
            break;
          }

          message.saiPortStatIfOutMulticastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 43:
          if (tag !== 344) {
            break;
          }

          message.saiPortStatIfOutNonUcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 44:
          if (tag !== 352) {
            break;
          }

          message.saiPortStatIfOutOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 45:
          if (tag !== 360) {
            break;
          }

          message.saiPortStatIfOutQlen = longToNumber(reader.uint64() as Long);
          continue;
        case 46:
          if (tag !== 368) {
            break;
          }

          message.saiPortStatIfOutUcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 47:
          if (tag !== 376) {
            break;
          }

          message.saiPortStatInDroppedPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 48:
          if (tag !== 384) {
            break;
          }

          message.saiPortStatIpInReceives = longToNumber(reader.uint64() as Long);
          continue;
        case 49:
          if (tag !== 392) {
            break;
          }

          message.saiPortStatIpInUcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 50:
          if (tag !== 400) {
            break;
          }

          message.saiPortStatOutDroppedPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 51:
          if (tag !== 408) {
            break;
          }

          message.saiPortStatPauseRxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 52:
          if (tag !== 416) {
            break;
          }

          message.saiPortStatPauseTxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 53:
          if (tag !== 424) {
            break;
          }

          message.saiPortStatPfc0RxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 54:
          if (tag !== 432) {
            break;
          }

          message.saiPortStatPfc0TxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 55:
          if (tag !== 440) {
            break;
          }

          message.saiPortStatPfc1RxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 56:
          if (tag !== 448) {
            break;
          }

          message.saiPortStatPfc1TxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 57:
          if (tag !== 456) {
            break;
          }

          message.saiPortStatPfc2RxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 58:
          if (tag !== 464) {
            break;
          }

          message.saiPortStatPfc2TxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 59:
          if (tag !== 472) {
            break;
          }

          message.saiPortStatPfc3RxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 60:
          if (tag !== 480) {
            break;
          }

          message.saiPortStatPfc3TxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 61:
          if (tag !== 488) {
            break;
          }

          message.saiPortStatPfc4RxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 62:
          if (tag !== 496) {
            break;
          }

          message.saiPortStatPfc4TxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 63:
          if (tag !== 504) {
            break;
          }

          message.saiPortStatPfc5RxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 64:
          if (tag !== 512) {
            break;
          }

          message.saiPortStatPfc5TxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 65:
          if (tag !== 520) {
            break;
          }

          message.saiPortStatPfc6RxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 66:
          if (tag !== 528) {
            break;
          }

          message.saiPortStatPfc6TxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 67:
          if (tag !== 536) {
            break;
          }

          message.saiPortStatPfc7RxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 68:
          if (tag !== 544) {
            break;
          }

          message.saiPortStatPfc7TxPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 69:
          if (tag !== 554) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 70:
          if (tag !== 560) {
            break;
          }

          message.portIndex = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SaiPortCounters {
    return {
      saiPortStatEtherInPkts1024To1518Octets: isSet(object.SAI_PORT_STAT_ETHER_IN_PKTS_1024_TO_1518_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_IN_PKTS_1024_TO_1518_OCTETS)
        : 0,
      saiPortStatEtherInPkts128To255Octets: isSet(object.SAI_PORT_STAT_ETHER_IN_PKTS_128_TO_255_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_IN_PKTS_128_TO_255_OCTETS)
        : 0,
      saiPortStatEtherInPkts1519To2047Octets: isSet(object.SAI_PORT_STAT_ETHER_IN_PKTS_1519_TO_2047_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_IN_PKTS_1519_TO_2047_OCTETS)
        : 0,
      saiPortStatEtherInPkts2048To4095Octets: isSet(object.SAI_PORT_STAT_ETHER_IN_PKTS_2048_TO_4095_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_IN_PKTS_2048_TO_4095_OCTETS)
        : 0,
      saiPortStatEtherInPkts256To511Octets: isSet(object.SAI_PORT_STAT_ETHER_IN_PKTS_256_TO_511_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_IN_PKTS_256_TO_511_OCTETS)
        : 0,
      saiPortStatEtherInPkts4096To9216Octets: isSet(object.SAI_PORT_STAT_ETHER_IN_PKTS_4096_TO_9216_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_IN_PKTS_4096_TO_9216_OCTETS)
        : 0,
      saiPortStatEtherInPkts512To1023Octets: isSet(object.SAI_PORT_STAT_ETHER_IN_PKTS_512_TO_1023_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_IN_PKTS_512_TO_1023_OCTETS)
        : 0,
      saiPortStatEtherInPkts64Octets: isSet(object.SAI_PORT_STAT_ETHER_IN_PKTS_64_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_IN_PKTS_64_OCTETS)
        : 0,
      saiPortStatEtherInPkts65To127Octets: isSet(object.SAI_PORT_STAT_ETHER_IN_PKTS_65_TO_127_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_IN_PKTS_65_TO_127_OCTETS)
        : 0,
      saiPortStatEtherInPkts9217To16383Octets: isSet(object.SAI_PORT_STAT_ETHER_IN_PKTS_9217_TO_16383_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_IN_PKTS_9217_TO_16383_OCTETS)
        : 0,
      saiPortStatEtherOutPkts1024To1518Octets: isSet(object.SAI_PORT_STAT_ETHER_OUT_PKTS_1024_TO_1518_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_OUT_PKTS_1024_TO_1518_OCTETS)
        : 0,
      saiPortStatEtherOutPkts128To255Octets: isSet(object.SAI_PORT_STAT_ETHER_OUT_PKTS_128_TO_255_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_OUT_PKTS_128_TO_255_OCTETS)
        : 0,
      saiPortStatEtherOutPkts1519To2047Octets: isSet(object.SAI_PORT_STAT_ETHER_OUT_PKTS_1519_TO_2047_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_OUT_PKTS_1519_TO_2047_OCTETS)
        : 0,
      saiPortStatEtherOutPkts2048To4095Octets: isSet(object.SAI_PORT_STAT_ETHER_OUT_PKTS_2048_TO_4095_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_OUT_PKTS_2048_TO_4095_OCTETS)
        : 0,
      saiPortStatEtherOutPkts256To511Octets: isSet(object.SAI_PORT_STAT_ETHER_OUT_PKTS_256_TO_511_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_OUT_PKTS_256_TO_511_OCTETS)
        : 0,
      saiPortStatEtherOutPkts4096To9216Octets: isSet(object.SAI_PORT_STAT_ETHER_OUT_PKTS_4096_TO_9216_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_OUT_PKTS_4096_TO_9216_OCTETS)
        : 0,
      saiPortStatEtherOutPkts512To1023Octets: isSet(object.SAI_PORT_STAT_ETHER_OUT_PKTS_512_TO_1023_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_OUT_PKTS_512_TO_1023_OCTETS)
        : 0,
      saiPortStatEtherOutPkts64Octets: isSet(object.SAI_PORT_STAT_ETHER_OUT_PKTS_64_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_OUT_PKTS_64_OCTETS)
        : 0,
      saiPortStatEtherOutPkts65To127Octets: isSet(object.SAI_PORT_STAT_ETHER_OUT_PKTS_65_TO_127_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_OUT_PKTS_65_TO_127_OCTETS)
        : 0,
      saiPortStatEtherOutPkts9217To16383Octets: isSet(object.SAI_PORT_STAT_ETHER_OUT_PKTS_9217_TO_16383_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_OUT_PKTS_9217_TO_16383_OCTETS)
        : 0,
      saiPortStatEtherRxOversizePkts: isSet(object.SAI_PORT_STAT_ETHER_RX_OVERSIZE_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_RX_OVERSIZE_PKTS)
        : 0,
      saiPortStatEtherStatsFragments: isSet(object.SAI_PORT_STAT_ETHER_STATS_FRAGMENTS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_STATS_FRAGMENTS)
        : 0,
      saiPortStatEtherStatsJabbers: isSet(object.SAI_PORT_STAT_ETHER_STATS_JABBERS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_STATS_JABBERS)
        : 0,
      saiPortStatEtherStatsTxNoErrors: isSet(object.SAI_PORT_STAT_ETHER_STATS_TX_NO_ERRORS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_STATS_TX_NO_ERRORS)
        : 0,
      saiPortStatEtherStatsUndersizePkts: isSet(object.SAI_PORT_STAT_ETHER_STATS_UNDERSIZE_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_STATS_UNDERSIZE_PKTS)
        : 0,
      saiPortStatEtherTxOversizePkts: isSet(object.SAI_PORT_STAT_ETHER_TX_OVERSIZE_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_ETHER_TX_OVERSIZE_PKTS)
        : 0,
      saiPortStatIfInBroadcastPkts: isSet(object.SAI_PORT_STAT_IF_IN_BROADCAST_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_BROADCAST_PKTS)
        : 0,
      saiPortStatIfInDiscards: isSet(object.SAI_PORT_STAT_IF_IN_DISCARDS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_DISCARDS)
        : 0,
      saiPortStatIfInErrors: isSet(object.SAI_PORT_STAT_IF_IN_ERRORS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_ERRORS)
        : 0,
      saiPortStatIfInFecCorrectableFrames: isSet(object.SAI_PORT_STAT_IF_IN_FEC_CORRECTABLE_FRAMES)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_FEC_CORRECTABLE_FRAMES)
        : 0,
      saiPortStatIfInFecNotCorrectableFrames: isSet(object.SAI_PORT_STAT_IF_IN_FEC_NOT_CORRECTABLE_FRAMES)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_FEC_NOT_CORRECTABLE_FRAMES)
        : 0,
      saiPortStatIfInFecSymbolErrors: isSet(object.SAI_PORT_STAT_IF_IN_FEC_SYMBOL_ERRORS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_FEC_SYMBOL_ERRORS)
        : 0,
      saiPortStatIfInMulticastPkts: isSet(object.SAI_PORT_STAT_IF_IN_MULTICAST_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_MULTICAST_PKTS)
        : 0,
      saiPortStatIfInNonUcastPkts: isSet(object.SAI_PORT_STAT_IF_IN_NON_UCAST_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_NON_UCAST_PKTS)
        : 0,
      saiPortStatIfInOctets: isSet(object.SAI_PORT_STAT_IF_IN_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_OCTETS)
        : 0,
      saiPortStatIfInUcastPkts: isSet(object.SAI_PORT_STAT_IF_IN_UCAST_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_UCAST_PKTS)
        : 0,
      saiPortStatIfInUnknownProtos: isSet(object.SAI_PORT_STAT_IF_IN_UNKNOWN_PROTOS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_IN_UNKNOWN_PROTOS)
        : 0,
      saiPortStatIfOutBroadcastPkts: isSet(object.SAI_PORT_STAT_IF_OUT_BROADCAST_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_BROADCAST_PKTS)
        : 0,
      saiPortStatIfOutDiscards: isSet(object.SAI_PORT_STAT_IF_OUT_DISCARDS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_DISCARDS)
        : 0,
      saiPortStatIfOutErrors: isSet(object.SAI_PORT_STAT_IF_OUT_ERRORS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_ERRORS)
        : 0,
      saiPortStatIfOutMulticastPkts: isSet(object.SAI_PORT_STAT_IF_OUT_MULTICAST_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_MULTICAST_PKTS)
        : 0,
      saiPortStatIfOutNonUcastPkts: isSet(object.SAI_PORT_STAT_IF_OUT_NON_UCAST_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_NON_UCAST_PKTS)
        : 0,
      saiPortStatIfOutOctets: isSet(object.SAI_PORT_STAT_IF_OUT_OCTETS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_OCTETS)
        : 0,
      saiPortStatIfOutQlen: isSet(object.SAI_PORT_STAT_IF_OUT_QLEN)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_QLEN)
        : 0,
      saiPortStatIfOutUcastPkts: isSet(object.SAI_PORT_STAT_IF_OUT_UCAST_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_IF_OUT_UCAST_PKTS)
        : 0,
      saiPortStatInDroppedPkts: isSet(object.SAI_PORT_STAT_IN_DROPPED_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_IN_DROPPED_PKTS)
        : 0,
      saiPortStatIpInReceives: isSet(object.SAI_PORT_STAT_IP_IN_RECEIVES)
        ? globalThis.Number(object.SAI_PORT_STAT_IP_IN_RECEIVES)
        : 0,
      saiPortStatIpInUcastPkts: isSet(object.SAI_PORT_STAT_IP_IN_UCAST_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_IP_IN_UCAST_PKTS)
        : 0,
      saiPortStatOutDroppedPkts: isSet(object.SAI_PORT_STAT_OUT_DROPPED_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_OUT_DROPPED_PKTS)
        : 0,
      saiPortStatPauseRxPkts: isSet(object.SAI_PORT_STAT_PAUSE_RX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PAUSE_RX_PKTS)
        : 0,
      saiPortStatPauseTxPkts: isSet(object.SAI_PORT_STAT_PAUSE_TX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PAUSE_TX_PKTS)
        : 0,
      saiPortStatPfc0RxPkts: isSet(object.SAI_PORT_STAT_PFC_0_RX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_0_RX_PKTS)
        : 0,
      saiPortStatPfc0TxPkts: isSet(object.SAI_PORT_STAT_PFC_0_TX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_0_TX_PKTS)
        : 0,
      saiPortStatPfc1RxPkts: isSet(object.SAI_PORT_STAT_PFC_1_RX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_1_RX_PKTS)
        : 0,
      saiPortStatPfc1TxPkts: isSet(object.SAI_PORT_STAT_PFC_1_TX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_1_TX_PKTS)
        : 0,
      saiPortStatPfc2RxPkts: isSet(object.SAI_PORT_STAT_PFC_2_RX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_2_RX_PKTS)
        : 0,
      saiPortStatPfc2TxPkts: isSet(object.SAI_PORT_STAT_PFC_2_TX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_2_TX_PKTS)
        : 0,
      saiPortStatPfc3RxPkts: isSet(object.SAI_PORT_STAT_PFC_3_RX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_3_RX_PKTS)
        : 0,
      saiPortStatPfc3TxPkts: isSet(object.SAI_PORT_STAT_PFC_3_TX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_3_TX_PKTS)
        : 0,
      saiPortStatPfc4RxPkts: isSet(object.SAI_PORT_STAT_PFC_4_RX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_4_RX_PKTS)
        : 0,
      saiPortStatPfc4TxPkts: isSet(object.SAI_PORT_STAT_PFC_4_TX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_4_TX_PKTS)
        : 0,
      saiPortStatPfc5RxPkts: isSet(object.SAI_PORT_STAT_PFC_5_RX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_5_RX_PKTS)
        : 0,
      saiPortStatPfc5TxPkts: isSet(object.SAI_PORT_STAT_PFC_5_TX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_5_TX_PKTS)
        : 0,
      saiPortStatPfc6RxPkts: isSet(object.SAI_PORT_STAT_PFC_6_RX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_6_RX_PKTS)
        : 0,
      saiPortStatPfc6TxPkts: isSet(object.SAI_PORT_STAT_PFC_6_TX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_6_TX_PKTS)
        : 0,
      saiPortStatPfc7RxPkts: isSet(object.SAI_PORT_STAT_PFC_7_RX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_7_RX_PKTS)
        : 0,
      saiPortStatPfc7TxPkts: isSet(object.SAI_PORT_STAT_PFC_7_TX_PKTS)
        ? globalThis.Number(object.SAI_PORT_STAT_PFC_7_TX_PKTS)
        : 0,
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      portIndex: isSet(object.portIndex) ? globalThis.Number(object.portIndex) : 0,
    };
  },

  toJSON(message: SaiPortCounters): unknown {
    const obj: any = {};
    if (message.saiPortStatEtherInPkts1024To1518Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_IN_PKTS_1024_TO_1518_OCTETS = Math.round(message.saiPortStatEtherInPkts1024To1518Octets);
    }
    if (message.saiPortStatEtherInPkts128To255Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_IN_PKTS_128_TO_255_OCTETS = Math.round(message.saiPortStatEtherInPkts128To255Octets);
    }
    if (message.saiPortStatEtherInPkts1519To2047Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_IN_PKTS_1519_TO_2047_OCTETS = Math.round(message.saiPortStatEtherInPkts1519To2047Octets);
    }
    if (message.saiPortStatEtherInPkts2048To4095Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_IN_PKTS_2048_TO_4095_OCTETS = Math.round(message.saiPortStatEtherInPkts2048To4095Octets);
    }
    if (message.saiPortStatEtherInPkts256To511Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_IN_PKTS_256_TO_511_OCTETS = Math.round(message.saiPortStatEtherInPkts256To511Octets);
    }
    if (message.saiPortStatEtherInPkts4096To9216Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_IN_PKTS_4096_TO_9216_OCTETS = Math.round(message.saiPortStatEtherInPkts4096To9216Octets);
    }
    if (message.saiPortStatEtherInPkts512To1023Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_IN_PKTS_512_TO_1023_OCTETS = Math.round(message.saiPortStatEtherInPkts512To1023Octets);
    }
    if (message.saiPortStatEtherInPkts64Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_IN_PKTS_64_OCTETS = Math.round(message.saiPortStatEtherInPkts64Octets);
    }
    if (message.saiPortStatEtherInPkts65To127Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_IN_PKTS_65_TO_127_OCTETS = Math.round(message.saiPortStatEtherInPkts65To127Octets);
    }
    if (message.saiPortStatEtherInPkts9217To16383Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_IN_PKTS_9217_TO_16383_OCTETS = Math.round(
        message.saiPortStatEtherInPkts9217To16383Octets,
      );
    }
    if (message.saiPortStatEtherOutPkts1024To1518Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_OUT_PKTS_1024_TO_1518_OCTETS = Math.round(
        message.saiPortStatEtherOutPkts1024To1518Octets,
      );
    }
    if (message.saiPortStatEtherOutPkts128To255Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_OUT_PKTS_128_TO_255_OCTETS = Math.round(message.saiPortStatEtherOutPkts128To255Octets);
    }
    if (message.saiPortStatEtherOutPkts1519To2047Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_OUT_PKTS_1519_TO_2047_OCTETS = Math.round(
        message.saiPortStatEtherOutPkts1519To2047Octets,
      );
    }
    if (message.saiPortStatEtherOutPkts2048To4095Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_OUT_PKTS_2048_TO_4095_OCTETS = Math.round(
        message.saiPortStatEtherOutPkts2048To4095Octets,
      );
    }
    if (message.saiPortStatEtherOutPkts256To511Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_OUT_PKTS_256_TO_511_OCTETS = Math.round(message.saiPortStatEtherOutPkts256To511Octets);
    }
    if (message.saiPortStatEtherOutPkts4096To9216Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_OUT_PKTS_4096_TO_9216_OCTETS = Math.round(
        message.saiPortStatEtherOutPkts4096To9216Octets,
      );
    }
    if (message.saiPortStatEtherOutPkts512To1023Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_OUT_PKTS_512_TO_1023_OCTETS = Math.round(message.saiPortStatEtherOutPkts512To1023Octets);
    }
    if (message.saiPortStatEtherOutPkts64Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_OUT_PKTS_64_OCTETS = Math.round(message.saiPortStatEtherOutPkts64Octets);
    }
    if (message.saiPortStatEtherOutPkts65To127Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_OUT_PKTS_65_TO_127_OCTETS = Math.round(message.saiPortStatEtherOutPkts65To127Octets);
    }
    if (message.saiPortStatEtherOutPkts9217To16383Octets !== 0) {
      obj.SAI_PORT_STAT_ETHER_OUT_PKTS_9217_TO_16383_OCTETS = Math.round(
        message.saiPortStatEtherOutPkts9217To16383Octets,
      );
    }
    if (message.saiPortStatEtherRxOversizePkts !== 0) {
      obj.SAI_PORT_STAT_ETHER_RX_OVERSIZE_PKTS = Math.round(message.saiPortStatEtherRxOversizePkts);
    }
    if (message.saiPortStatEtherStatsFragments !== 0) {
      obj.SAI_PORT_STAT_ETHER_STATS_FRAGMENTS = Math.round(message.saiPortStatEtherStatsFragments);
    }
    if (message.saiPortStatEtherStatsJabbers !== 0) {
      obj.SAI_PORT_STAT_ETHER_STATS_JABBERS = Math.round(message.saiPortStatEtherStatsJabbers);
    }
    if (message.saiPortStatEtherStatsTxNoErrors !== 0) {
      obj.SAI_PORT_STAT_ETHER_STATS_TX_NO_ERRORS = Math.round(message.saiPortStatEtherStatsTxNoErrors);
    }
    if (message.saiPortStatEtherStatsUndersizePkts !== 0) {
      obj.SAI_PORT_STAT_ETHER_STATS_UNDERSIZE_PKTS = Math.round(message.saiPortStatEtherStatsUndersizePkts);
    }
    if (message.saiPortStatEtherTxOversizePkts !== 0) {
      obj.SAI_PORT_STAT_ETHER_TX_OVERSIZE_PKTS = Math.round(message.saiPortStatEtherTxOversizePkts);
    }
    if (message.saiPortStatIfInBroadcastPkts !== 0) {
      obj.SAI_PORT_STAT_IF_IN_BROADCAST_PKTS = Math.round(message.saiPortStatIfInBroadcastPkts);
    }
    if (message.saiPortStatIfInDiscards !== 0) {
      obj.SAI_PORT_STAT_IF_IN_DISCARDS = Math.round(message.saiPortStatIfInDiscards);
    }
    if (message.saiPortStatIfInErrors !== 0) {
      obj.SAI_PORT_STAT_IF_IN_ERRORS = Math.round(message.saiPortStatIfInErrors);
    }
    if (message.saiPortStatIfInFecCorrectableFrames !== 0) {
      obj.SAI_PORT_STAT_IF_IN_FEC_CORRECTABLE_FRAMES = Math.round(message.saiPortStatIfInFecCorrectableFrames);
    }
    if (message.saiPortStatIfInFecNotCorrectableFrames !== 0) {
      obj.SAI_PORT_STAT_IF_IN_FEC_NOT_CORRECTABLE_FRAMES = Math.round(message.saiPortStatIfInFecNotCorrectableFrames);
    }
    if (message.saiPortStatIfInFecSymbolErrors !== 0) {
      obj.SAI_PORT_STAT_IF_IN_FEC_SYMBOL_ERRORS = Math.round(message.saiPortStatIfInFecSymbolErrors);
    }
    if (message.saiPortStatIfInMulticastPkts !== 0) {
      obj.SAI_PORT_STAT_IF_IN_MULTICAST_PKTS = Math.round(message.saiPortStatIfInMulticastPkts);
    }
    if (message.saiPortStatIfInNonUcastPkts !== 0) {
      obj.SAI_PORT_STAT_IF_IN_NON_UCAST_PKTS = Math.round(message.saiPortStatIfInNonUcastPkts);
    }
    if (message.saiPortStatIfInOctets !== 0) {
      obj.SAI_PORT_STAT_IF_IN_OCTETS = Math.round(message.saiPortStatIfInOctets);
    }
    if (message.saiPortStatIfInUcastPkts !== 0) {
      obj.SAI_PORT_STAT_IF_IN_UCAST_PKTS = Math.round(message.saiPortStatIfInUcastPkts);
    }
    if (message.saiPortStatIfInUnknownProtos !== 0) {
      obj.SAI_PORT_STAT_IF_IN_UNKNOWN_PROTOS = Math.round(message.saiPortStatIfInUnknownProtos);
    }
    if (message.saiPortStatIfOutBroadcastPkts !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_BROADCAST_PKTS = Math.round(message.saiPortStatIfOutBroadcastPkts);
    }
    if (message.saiPortStatIfOutDiscards !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_DISCARDS = Math.round(message.saiPortStatIfOutDiscards);
    }
    if (message.saiPortStatIfOutErrors !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_ERRORS = Math.round(message.saiPortStatIfOutErrors);
    }
    if (message.saiPortStatIfOutMulticastPkts !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_MULTICAST_PKTS = Math.round(message.saiPortStatIfOutMulticastPkts);
    }
    if (message.saiPortStatIfOutNonUcastPkts !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_NON_UCAST_PKTS = Math.round(message.saiPortStatIfOutNonUcastPkts);
    }
    if (message.saiPortStatIfOutOctets !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_OCTETS = Math.round(message.saiPortStatIfOutOctets);
    }
    if (message.saiPortStatIfOutQlen !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_QLEN = Math.round(message.saiPortStatIfOutQlen);
    }
    if (message.saiPortStatIfOutUcastPkts !== 0) {
      obj.SAI_PORT_STAT_IF_OUT_UCAST_PKTS = Math.round(message.saiPortStatIfOutUcastPkts);
    }
    if (message.saiPortStatInDroppedPkts !== 0) {
      obj.SAI_PORT_STAT_IN_DROPPED_PKTS = Math.round(message.saiPortStatInDroppedPkts);
    }
    if (message.saiPortStatIpInReceives !== 0) {
      obj.SAI_PORT_STAT_IP_IN_RECEIVES = Math.round(message.saiPortStatIpInReceives);
    }
    if (message.saiPortStatIpInUcastPkts !== 0) {
      obj.SAI_PORT_STAT_IP_IN_UCAST_PKTS = Math.round(message.saiPortStatIpInUcastPkts);
    }
    if (message.saiPortStatOutDroppedPkts !== 0) {
      obj.SAI_PORT_STAT_OUT_DROPPED_PKTS = Math.round(message.saiPortStatOutDroppedPkts);
    }
    if (message.saiPortStatPauseRxPkts !== 0) {
      obj.SAI_PORT_STAT_PAUSE_RX_PKTS = Math.round(message.saiPortStatPauseRxPkts);
    }
    if (message.saiPortStatPauseTxPkts !== 0) {
      obj.SAI_PORT_STAT_PAUSE_TX_PKTS = Math.round(message.saiPortStatPauseTxPkts);
    }
    if (message.saiPortStatPfc0RxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_0_RX_PKTS = Math.round(message.saiPortStatPfc0RxPkts);
    }
    if (message.saiPortStatPfc0TxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_0_TX_PKTS = Math.round(message.saiPortStatPfc0TxPkts);
    }
    if (message.saiPortStatPfc1RxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_1_RX_PKTS = Math.round(message.saiPortStatPfc1RxPkts);
    }
    if (message.saiPortStatPfc1TxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_1_TX_PKTS = Math.round(message.saiPortStatPfc1TxPkts);
    }
    if (message.saiPortStatPfc2RxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_2_RX_PKTS = Math.round(message.saiPortStatPfc2RxPkts);
    }
    if (message.saiPortStatPfc2TxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_2_TX_PKTS = Math.round(message.saiPortStatPfc2TxPkts);
    }
    if (message.saiPortStatPfc3RxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_3_RX_PKTS = Math.round(message.saiPortStatPfc3RxPkts);
    }
    if (message.saiPortStatPfc3TxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_3_TX_PKTS = Math.round(message.saiPortStatPfc3TxPkts);
    }
    if (message.saiPortStatPfc4RxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_4_RX_PKTS = Math.round(message.saiPortStatPfc4RxPkts);
    }
    if (message.saiPortStatPfc4TxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_4_TX_PKTS = Math.round(message.saiPortStatPfc4TxPkts);
    }
    if (message.saiPortStatPfc5RxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_5_RX_PKTS = Math.round(message.saiPortStatPfc5RxPkts);
    }
    if (message.saiPortStatPfc5TxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_5_TX_PKTS = Math.round(message.saiPortStatPfc5TxPkts);
    }
    if (message.saiPortStatPfc6RxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_6_RX_PKTS = Math.round(message.saiPortStatPfc6RxPkts);
    }
    if (message.saiPortStatPfc6TxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_6_TX_PKTS = Math.round(message.saiPortStatPfc6TxPkts);
    }
    if (message.saiPortStatPfc7RxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_7_RX_PKTS = Math.round(message.saiPortStatPfc7RxPkts);
    }
    if (message.saiPortStatPfc7TxPkts !== 0) {
      obj.SAI_PORT_STAT_PFC_7_TX_PKTS = Math.round(message.saiPortStatPfc7TxPkts);
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.portIndex !== 0) {
      obj.portIndex = Math.round(message.portIndex);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SaiPortCounters>, I>>(base?: I): SaiPortCounters {
    return SaiPortCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SaiPortCounters>, I>>(object: I): SaiPortCounters {
    const message = createBaseSaiPortCounters();
    message.saiPortStatEtherInPkts1024To1518Octets = object.saiPortStatEtherInPkts1024To1518Octets ?? 0;
    message.saiPortStatEtherInPkts128To255Octets = object.saiPortStatEtherInPkts128To255Octets ?? 0;
    message.saiPortStatEtherInPkts1519To2047Octets = object.saiPortStatEtherInPkts1519To2047Octets ?? 0;
    message.saiPortStatEtherInPkts2048To4095Octets = object.saiPortStatEtherInPkts2048To4095Octets ?? 0;
    message.saiPortStatEtherInPkts256To511Octets = object.saiPortStatEtherInPkts256To511Octets ?? 0;
    message.saiPortStatEtherInPkts4096To9216Octets = object.saiPortStatEtherInPkts4096To9216Octets ?? 0;
    message.saiPortStatEtherInPkts512To1023Octets = object.saiPortStatEtherInPkts512To1023Octets ?? 0;
    message.saiPortStatEtherInPkts64Octets = object.saiPortStatEtherInPkts64Octets ?? 0;
    message.saiPortStatEtherInPkts65To127Octets = object.saiPortStatEtherInPkts65To127Octets ?? 0;
    message.saiPortStatEtherInPkts9217To16383Octets = object.saiPortStatEtherInPkts9217To16383Octets ?? 0;
    message.saiPortStatEtherOutPkts1024To1518Octets = object.saiPortStatEtherOutPkts1024To1518Octets ?? 0;
    message.saiPortStatEtherOutPkts128To255Octets = object.saiPortStatEtherOutPkts128To255Octets ?? 0;
    message.saiPortStatEtherOutPkts1519To2047Octets = object.saiPortStatEtherOutPkts1519To2047Octets ?? 0;
    message.saiPortStatEtherOutPkts2048To4095Octets = object.saiPortStatEtherOutPkts2048To4095Octets ?? 0;
    message.saiPortStatEtherOutPkts256To511Octets = object.saiPortStatEtherOutPkts256To511Octets ?? 0;
    message.saiPortStatEtherOutPkts4096To9216Octets = object.saiPortStatEtherOutPkts4096To9216Octets ?? 0;
    message.saiPortStatEtherOutPkts512To1023Octets = object.saiPortStatEtherOutPkts512To1023Octets ?? 0;
    message.saiPortStatEtherOutPkts64Octets = object.saiPortStatEtherOutPkts64Octets ?? 0;
    message.saiPortStatEtherOutPkts65To127Octets = object.saiPortStatEtherOutPkts65To127Octets ?? 0;
    message.saiPortStatEtherOutPkts9217To16383Octets = object.saiPortStatEtherOutPkts9217To16383Octets ?? 0;
    message.saiPortStatEtherRxOversizePkts = object.saiPortStatEtherRxOversizePkts ?? 0;
    message.saiPortStatEtherStatsFragments = object.saiPortStatEtherStatsFragments ?? 0;
    message.saiPortStatEtherStatsJabbers = object.saiPortStatEtherStatsJabbers ?? 0;
    message.saiPortStatEtherStatsTxNoErrors = object.saiPortStatEtherStatsTxNoErrors ?? 0;
    message.saiPortStatEtherStatsUndersizePkts = object.saiPortStatEtherStatsUndersizePkts ?? 0;
    message.saiPortStatEtherTxOversizePkts = object.saiPortStatEtherTxOversizePkts ?? 0;
    message.saiPortStatIfInBroadcastPkts = object.saiPortStatIfInBroadcastPkts ?? 0;
    message.saiPortStatIfInDiscards = object.saiPortStatIfInDiscards ?? 0;
    message.saiPortStatIfInErrors = object.saiPortStatIfInErrors ?? 0;
    message.saiPortStatIfInFecCorrectableFrames = object.saiPortStatIfInFecCorrectableFrames ?? 0;
    message.saiPortStatIfInFecNotCorrectableFrames = object.saiPortStatIfInFecNotCorrectableFrames ?? 0;
    message.saiPortStatIfInFecSymbolErrors = object.saiPortStatIfInFecSymbolErrors ?? 0;
    message.saiPortStatIfInMulticastPkts = object.saiPortStatIfInMulticastPkts ?? 0;
    message.saiPortStatIfInNonUcastPkts = object.saiPortStatIfInNonUcastPkts ?? 0;
    message.saiPortStatIfInOctets = object.saiPortStatIfInOctets ?? 0;
    message.saiPortStatIfInUcastPkts = object.saiPortStatIfInUcastPkts ?? 0;
    message.saiPortStatIfInUnknownProtos = object.saiPortStatIfInUnknownProtos ?? 0;
    message.saiPortStatIfOutBroadcastPkts = object.saiPortStatIfOutBroadcastPkts ?? 0;
    message.saiPortStatIfOutDiscards = object.saiPortStatIfOutDiscards ?? 0;
    message.saiPortStatIfOutErrors = object.saiPortStatIfOutErrors ?? 0;
    message.saiPortStatIfOutMulticastPkts = object.saiPortStatIfOutMulticastPkts ?? 0;
    message.saiPortStatIfOutNonUcastPkts = object.saiPortStatIfOutNonUcastPkts ?? 0;
    message.saiPortStatIfOutOctets = object.saiPortStatIfOutOctets ?? 0;
    message.saiPortStatIfOutQlen = object.saiPortStatIfOutQlen ?? 0;
    message.saiPortStatIfOutUcastPkts = object.saiPortStatIfOutUcastPkts ?? 0;
    message.saiPortStatInDroppedPkts = object.saiPortStatInDroppedPkts ?? 0;
    message.saiPortStatIpInReceives = object.saiPortStatIpInReceives ?? 0;
    message.saiPortStatIpInUcastPkts = object.saiPortStatIpInUcastPkts ?? 0;
    message.saiPortStatOutDroppedPkts = object.saiPortStatOutDroppedPkts ?? 0;
    message.saiPortStatPauseRxPkts = object.saiPortStatPauseRxPkts ?? 0;
    message.saiPortStatPauseTxPkts = object.saiPortStatPauseTxPkts ?? 0;
    message.saiPortStatPfc0RxPkts = object.saiPortStatPfc0RxPkts ?? 0;
    message.saiPortStatPfc0TxPkts = object.saiPortStatPfc0TxPkts ?? 0;
    message.saiPortStatPfc1RxPkts = object.saiPortStatPfc1RxPkts ?? 0;
    message.saiPortStatPfc1TxPkts = object.saiPortStatPfc1TxPkts ?? 0;
    message.saiPortStatPfc2RxPkts = object.saiPortStatPfc2RxPkts ?? 0;
    message.saiPortStatPfc2TxPkts = object.saiPortStatPfc2TxPkts ?? 0;
    message.saiPortStatPfc3RxPkts = object.saiPortStatPfc3RxPkts ?? 0;
    message.saiPortStatPfc3TxPkts = object.saiPortStatPfc3TxPkts ?? 0;
    message.saiPortStatPfc4RxPkts = object.saiPortStatPfc4RxPkts ?? 0;
    message.saiPortStatPfc4TxPkts = object.saiPortStatPfc4TxPkts ?? 0;
    message.saiPortStatPfc5RxPkts = object.saiPortStatPfc5RxPkts ?? 0;
    message.saiPortStatPfc5TxPkts = object.saiPortStatPfc5TxPkts ?? 0;
    message.saiPortStatPfc6RxPkts = object.saiPortStatPfc6RxPkts ?? 0;
    message.saiPortStatPfc6TxPkts = object.saiPortStatPfc6TxPkts ?? 0;
    message.saiPortStatPfc7RxPkts = object.saiPortStatPfc7RxPkts ?? 0;
    message.saiPortStatPfc7TxPkts = object.saiPortStatPfc7TxPkts ?? 0;
    message.portName = object.portName ?? "";
    message.portIndex = object.portIndex ?? 0;
    return message;
  },
};

function createBaseSonicConfig(): SonicConfig {
  return { ipv4Addr: "", ipv6Addr: "", ipv4Gateway: "", ipv6Gateway: "", hostname: "" };
}

export const SonicConfig = {
  encode(message: SonicConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ipv4Addr !== "") {
      writer.uint32(18).string(message.ipv4Addr);
    }
    if (message.ipv6Addr !== "") {
      writer.uint32(26).string(message.ipv6Addr);
    }
    if (message.ipv4Gateway !== "") {
      writer.uint32(34).string(message.ipv4Gateway);
    }
    if (message.ipv6Gateway !== "") {
      writer.uint32(42).string(message.ipv6Gateway);
    }
    if (message.hostname !== "") {
      writer.uint32(50).string(message.hostname);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SonicConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSonicConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ipv4Addr = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ipv6Addr = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ipv4Gateway = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ipv6Gateway = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.hostname = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SonicConfig {
    return {
      ipv4Addr: isSet(object.ipv4Addr) ? globalThis.String(object.ipv4Addr) : "",
      ipv6Addr: isSet(object.ipv6Addr) ? globalThis.String(object.ipv6Addr) : "",
      ipv4Gateway: isSet(object.ipv4Gateway) ? globalThis.String(object.ipv4Gateway) : "",
      ipv6Gateway: isSet(object.ipv6Gateway) ? globalThis.String(object.ipv6Gateway) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
    };
  },

  toJSON(message: SonicConfig): unknown {
    const obj: any = {};
    if (message.ipv4Addr !== "") {
      obj.ipv4Addr = message.ipv4Addr;
    }
    if (message.ipv6Addr !== "") {
      obj.ipv6Addr = message.ipv6Addr;
    }
    if (message.ipv4Gateway !== "") {
      obj.ipv4Gateway = message.ipv4Gateway;
    }
    if (message.ipv6Gateway !== "") {
      obj.ipv6Gateway = message.ipv6Gateway;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SonicConfig>, I>>(base?: I): SonicConfig {
    return SonicConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SonicConfig>, I>>(object: I): SonicConfig {
    const message = createBaseSonicConfig();
    message.ipv4Addr = object.ipv4Addr ?? "";
    message.ipv6Addr = object.ipv6Addr ?? "";
    message.ipv4Gateway = object.ipv4Gateway ?? "";
    message.ipv6Gateway = object.ipv6Gateway ?? "";
    message.hostname = object.hostname ?? "";
    return message;
  },
};

function createBaseSonicEvent(): SonicEvent {
  return { metaData: undefined, chassisInfo: undefined, portStateChange: [] };
}

export const SonicEvent = {
  encode(message: SonicEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.metaData !== undefined) {
      StatsMetadata.encode(message.metaData, writer.uint32(18).fork()).ldelim();
    }
    if (message.chassisInfo !== undefined) {
      ChassisInfo.encode(message.chassisInfo, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.portStateChange) {
      PhyPortStatus.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SonicEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSonicEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.metaData = StatsMetadata.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.chassisInfo = ChassisInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.portStateChange.push(PhyPortStatus.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SonicEvent {
    return {
      metaData: isSet(object.metaData) ? StatsMetadata.fromJSON(object.metaData) : undefined,
      chassisInfo: isSet(object.chassisInfo) ? ChassisInfo.fromJSON(object.chassisInfo) : undefined,
      portStateChange: globalThis.Array.isArray(object?.portStateChange)
        ? object.portStateChange.map((e: any) => PhyPortStatus.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SonicEvent): unknown {
    const obj: any = {};
    if (message.metaData !== undefined) {
      obj.metaData = StatsMetadata.toJSON(message.metaData);
    }
    if (message.chassisInfo !== undefined) {
      obj.chassisInfo = ChassisInfo.toJSON(message.chassisInfo);
    }
    if (message.portStateChange?.length) {
      obj.portStateChange = message.portStateChange.map((e) => PhyPortStatus.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SonicEvent>, I>>(base?: I): SonicEvent {
    return SonicEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SonicEvent>, I>>(object: I): SonicEvent {
    const message = createBaseSonicEvent();
    message.metaData = (object.metaData !== undefined && object.metaData !== null)
      ? StatsMetadata.fromPartial(object.metaData)
      : undefined;
    message.chassisInfo = (object.chassisInfo !== undefined && object.chassisInfo !== null)
      ? ChassisInfo.fromPartial(object.chassisInfo)
      : undefined;
    message.portStateChange = object.portStateChange?.map((e) => PhyPortStatus.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStateInfo(): StateInfo {
  return {
    metaData: undefined,
    portsState: [],
    interfacesState: [],
    tunnelsState: [],
    chassisInfo: undefined,
    lldpTable: [],
    vlansConfig: undefined,
    mgmtNetworkConfig: undefined,
    ipmSessionState: [],
  };
}

export const StateInfo = {
  encode(message: StateInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.metaData !== undefined) {
      StatsMetadata.encode(message.metaData, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.portsState) {
      PhyPortStatus.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.interfacesState) {
      InterfaceInfo.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tunnelsState) {
      VnetInfo.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.chassisInfo !== undefined) {
      ChassisInfo.encode(message.chassisInfo, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.lldpTable) {
      LldpEntry.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.vlansConfig !== undefined) {
      VlanConfig.encode(message.vlansConfig, writer.uint32(66).fork()).ldelim();
    }
    if (message.mgmtNetworkConfig !== undefined) {
      MgmtConfig.encode(message.mgmtNetworkConfig, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.ipmSessionState) {
      IpmSessionStatus.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StateInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStateInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.metaData = StatsMetadata.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.portsState.push(PhyPortStatus.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.interfacesState.push(InterfaceInfo.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tunnelsState.push(VnetInfo.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.chassisInfo = ChassisInfo.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lldpTable.push(LldpEntry.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.vlansConfig = VlanConfig.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.mgmtNetworkConfig = MgmtConfig.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ipmSessionState.push(IpmSessionStatus.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StateInfo {
    return {
      metaData: isSet(object.metaData) ? StatsMetadata.fromJSON(object.metaData) : undefined,
      portsState: globalThis.Array.isArray(object?.portsState)
        ? object.portsState.map((e: any) => PhyPortStatus.fromJSON(e))
        : [],
      interfacesState: globalThis.Array.isArray(object?.interfacesState)
        ? object.interfacesState.map((e: any) => InterfaceInfo.fromJSON(e))
        : [],
      tunnelsState: globalThis.Array.isArray(object?.tunnelsState)
        ? object.tunnelsState.map((e: any) => VnetInfo.fromJSON(e))
        : [],
      chassisInfo: isSet(object.chassisInfo) ? ChassisInfo.fromJSON(object.chassisInfo) : undefined,
      lldpTable: globalThis.Array.isArray(object?.lldpTable)
        ? object.lldpTable.map((e: any) => LldpEntry.fromJSON(e))
        : [],
      vlansConfig: isSet(object.vlansConfig) ? VlanConfig.fromJSON(object.vlansConfig) : undefined,
      mgmtNetworkConfig: isSet(object.mgmtNetworkConfig) ? MgmtConfig.fromJSON(object.mgmtNetworkConfig) : undefined,
      ipmSessionState: globalThis.Array.isArray(object?.ipmSessionState)
        ? object.ipmSessionState.map((e: any) => IpmSessionStatus.fromJSON(e))
        : [],
    };
  },

  toJSON(message: StateInfo): unknown {
    const obj: any = {};
    if (message.metaData !== undefined) {
      obj.metaData = StatsMetadata.toJSON(message.metaData);
    }
    if (message.portsState?.length) {
      obj.portsState = message.portsState.map((e) => PhyPortStatus.toJSON(e));
    }
    if (message.interfacesState?.length) {
      obj.interfacesState = message.interfacesState.map((e) => InterfaceInfo.toJSON(e));
    }
    if (message.tunnelsState?.length) {
      obj.tunnelsState = message.tunnelsState.map((e) => VnetInfo.toJSON(e));
    }
    if (message.chassisInfo !== undefined) {
      obj.chassisInfo = ChassisInfo.toJSON(message.chassisInfo);
    }
    if (message.lldpTable?.length) {
      obj.lldpTable = message.lldpTable.map((e) => LldpEntry.toJSON(e));
    }
    if (message.vlansConfig !== undefined) {
      obj.vlansConfig = VlanConfig.toJSON(message.vlansConfig);
    }
    if (message.mgmtNetworkConfig !== undefined) {
      obj.mgmtNetworkConfig = MgmtConfig.toJSON(message.mgmtNetworkConfig);
    }
    if (message.ipmSessionState?.length) {
      obj.ipmSessionState = message.ipmSessionState.map((e) => IpmSessionStatus.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StateInfo>, I>>(base?: I): StateInfo {
    return StateInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StateInfo>, I>>(object: I): StateInfo {
    const message = createBaseStateInfo();
    message.metaData = (object.metaData !== undefined && object.metaData !== null)
      ? StatsMetadata.fromPartial(object.metaData)
      : undefined;
    message.portsState = object.portsState?.map((e) => PhyPortStatus.fromPartial(e)) || [];
    message.interfacesState = object.interfacesState?.map((e) => InterfaceInfo.fromPartial(e)) || [];
    message.tunnelsState = object.tunnelsState?.map((e) => VnetInfo.fromPartial(e)) || [];
    message.chassisInfo = (object.chassisInfo !== undefined && object.chassisInfo !== null)
      ? ChassisInfo.fromPartial(object.chassisInfo)
      : undefined;
    message.lldpTable = object.lldpTable?.map((e) => LldpEntry.fromPartial(e)) || [];
    message.vlansConfig = (object.vlansConfig !== undefined && object.vlansConfig !== null)
      ? VlanConfig.fromPartial(object.vlansConfig)
      : undefined;
    message.mgmtNetworkConfig = (object.mgmtNetworkConfig !== undefined && object.mgmtNetworkConfig !== null)
      ? MgmtConfig.fromPartial(object.mgmtNetworkConfig)
      : undefined;
    message.ipmSessionState = object.ipmSessionState?.map((e) => IpmSessionStatus.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStatsMetadata(): StatsMetadata {
  return { trafficClass: 0, collectedAt: undefined };
}

export const StatsMetadata = {
  encode(message: StatsMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.trafficClass !== 0) {
      writer.uint32(16).int32(message.trafficClass);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StatsMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatsMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.trafficClass = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StatsMetadata {
    return {
      trafficClass: isSet(object.trafficClass) ? statsTrafficClassFromJSON(object.trafficClass) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: StatsMetadata): unknown {
    const obj: any = {};
    if (message.trafficClass !== 0) {
      obj.trafficClass = statsTrafficClassToJSON(message.trafficClass);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StatsMetadata>, I>>(base?: I): StatsMetadata {
    return StatsMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StatsMetadata>, I>>(object: I): StatsMetadata {
    const message = createBaseStatsMetadata();
    message.trafficClass = object.trafficClass ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseSubinterface(): Subinterface {
  return {
    index: 0,
    config: undefined,
    state: undefined,
    vlan: "",
    openconfigIfIpIpv4: undefined,
    openconfigIfIpIpv6: undefined,
  };
}

export const Subinterface = {
  encode(message: Subinterface, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.index !== 0) {
      writer.uint32(16).uint32(message.index);
    }
    if (message.config !== undefined) {
      SubinterfaceConfig.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    if (message.state !== undefined) {
      SubinterfaceState.encode(message.state, writer.uint32(34).fork()).ldelim();
    }
    if (message.vlan !== "") {
      writer.uint32(42).string(message.vlan);
    }
    if (message.openconfigIfIpIpv4 !== undefined) {
      InterfaceIpv4.encode(message.openconfigIfIpIpv4, writer.uint32(50).fork()).ldelim();
    }
    if (message.openconfigIfIpIpv6 !== undefined) {
      InterfaceIpv6.encode(message.openconfigIfIpIpv6, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Subinterface {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubinterface();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.index = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = SubinterfaceConfig.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.state = SubinterfaceState.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.vlan = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.openconfigIfIpIpv4 = InterfaceIpv4.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.openconfigIfIpIpv6 = InterfaceIpv6.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Subinterface {
    return {
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      config: isSet(object.config) ? SubinterfaceConfig.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? SubinterfaceState.fromJSON(object.state) : undefined,
      vlan: isSet(object.vlan) ? globalThis.String(object.vlan) : "",
      openconfigIfIpIpv4: isSet(object["openconfig-if-ip:ipv4"])
        ? InterfaceIpv4.fromJSON(object["openconfig-if-ip:ipv4"])
        : undefined,
      openconfigIfIpIpv6: isSet(object["openconfig-if-ip:ipv6"])
        ? InterfaceIpv6.fromJSON(object["openconfig-if-ip:ipv6"])
        : undefined,
    };
  },

  toJSON(message: Subinterface): unknown {
    const obj: any = {};
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.config !== undefined) {
      obj.config = SubinterfaceConfig.toJSON(message.config);
    }
    if (message.state !== undefined) {
      obj.state = SubinterfaceState.toJSON(message.state);
    }
    if (message.vlan !== "") {
      obj.vlan = message.vlan;
    }
    if (message.openconfigIfIpIpv4 !== undefined) {
      obj["openconfig-if-ip:ipv4"] = InterfaceIpv4.toJSON(message.openconfigIfIpIpv4);
    }
    if (message.openconfigIfIpIpv6 !== undefined) {
      obj["openconfig-if-ip:ipv6"] = InterfaceIpv6.toJSON(message.openconfigIfIpIpv6);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Subinterface>, I>>(base?: I): Subinterface {
    return Subinterface.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Subinterface>, I>>(object: I): Subinterface {
    const message = createBaseSubinterface();
    message.index = object.index ?? 0;
    message.config = (object.config !== undefined && object.config !== null)
      ? SubinterfaceConfig.fromPartial(object.config)
      : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? SubinterfaceState.fromPartial(object.state)
      : undefined;
    message.vlan = object.vlan ?? "";
    message.openconfigIfIpIpv4 = (object.openconfigIfIpIpv4 !== undefined && object.openconfigIfIpIpv4 !== null)
      ? InterfaceIpv4.fromPartial(object.openconfigIfIpIpv4)
      : undefined;
    message.openconfigIfIpIpv6 = (object.openconfigIfIpIpv6 !== undefined && object.openconfigIfIpIpv6 !== null)
      ? InterfaceIpv6.fromPartial(object.openconfigIfIpIpv6)
      : undefined;
    return message;
  },
};

function createBaseSubinterfaceConfig(): SubinterfaceConfig {
  return { index: 0, description: "", enabled: false };
}

export const SubinterfaceConfig = {
  encode(message: SubinterfaceConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.index !== 0) {
      writer.uint32(16).uint32(message.index);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.enabled !== false) {
      writer.uint32(32).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubinterfaceConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubinterfaceConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.index = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubinterfaceConfig {
    return {
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
    };
  },

  toJSON(message: SubinterfaceConfig): unknown {
    const obj: any = {};
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubinterfaceConfig>, I>>(base?: I): SubinterfaceConfig {
    return SubinterfaceConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubinterfaceConfig>, I>>(object: I): SubinterfaceConfig {
    const message = createBaseSubinterfaceConfig();
    message.index = object.index ?? 0;
    message.description = object.description ?? "";
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseSubinterfaceState(): SubinterfaceState {
  return {
    index: 0,
    description: "",
    enabled: false,
    name: "",
    ifindex: 0,
    adminStatus: "",
    operStatus: "",
    lastChange: undefined,
    logical: false,
    management: false,
    cpu: false,
    counters: undefined,
  };
}

export const SubinterfaceState = {
  encode(message: SubinterfaceState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.index !== 0) {
      writer.uint32(16).uint32(message.index);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.enabled !== false) {
      writer.uint32(32).bool(message.enabled);
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.ifindex !== 0) {
      writer.uint32(48).uint32(message.ifindex);
    }
    if (message.adminStatus !== "") {
      writer.uint32(58).string(message.adminStatus);
    }
    if (message.operStatus !== "") {
      writer.uint32(66).string(message.operStatus);
    }
    if (message.lastChange !== undefined) {
      Timestamp.encode(toTimestamp(message.lastChange), writer.uint32(74).fork()).ldelim();
    }
    if (message.logical !== false) {
      writer.uint32(80).bool(message.logical);
    }
    if (message.management !== false) {
      writer.uint32(88).bool(message.management);
    }
    if (message.cpu !== false) {
      writer.uint32(96).bool(message.cpu);
    }
    if (message.counters !== undefined) {
      Counters.encode(message.counters, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubinterfaceState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubinterfaceState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.index = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.ifindex = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.adminStatus = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.operStatus = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.lastChange = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.logical = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.management = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.cpu = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.counters = Counters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubinterfaceState {
    return {
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      ifindex: isSet(object.ifindex) ? globalThis.Number(object.ifindex) : 0,
      adminStatus: isSet(object["admin-status"]) ? globalThis.String(object["admin-status"]) : "",
      operStatus: isSet(object["oper-status"]) ? globalThis.String(object["oper-status"]) : "",
      lastChange: isSet(object["last-change"]) ? fromJsonTimestamp(object["last-change"]) : undefined,
      logical: isSet(object.logical) ? globalThis.Boolean(object.logical) : false,
      management: isSet(object.management) ? globalThis.Boolean(object.management) : false,
      cpu: isSet(object.cpu) ? globalThis.Boolean(object.cpu) : false,
      counters: isSet(object.counters) ? Counters.fromJSON(object.counters) : undefined,
    };
  },

  toJSON(message: SubinterfaceState): unknown {
    const obj: any = {};
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.ifindex !== 0) {
      obj.ifindex = Math.round(message.ifindex);
    }
    if (message.adminStatus !== "") {
      obj["admin-status"] = message.adminStatus;
    }
    if (message.operStatus !== "") {
      obj["oper-status"] = message.operStatus;
    }
    if (message.lastChange !== undefined) {
      obj["last-change"] = message.lastChange.toISOString();
    }
    if (message.logical !== false) {
      obj.logical = message.logical;
    }
    if (message.management !== false) {
      obj.management = message.management;
    }
    if (message.cpu !== false) {
      obj.cpu = message.cpu;
    }
    if (message.counters !== undefined) {
      obj.counters = Counters.toJSON(message.counters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubinterfaceState>, I>>(base?: I): SubinterfaceState {
    return SubinterfaceState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubinterfaceState>, I>>(object: I): SubinterfaceState {
    const message = createBaseSubinterfaceState();
    message.index = object.index ?? 0;
    message.description = object.description ?? "";
    message.enabled = object.enabled ?? false;
    message.name = object.name ?? "";
    message.ifindex = object.ifindex ?? 0;
    message.adminStatus = object.adminStatus ?? "";
    message.operStatus = object.operStatus ?? "";
    message.lastChange = object.lastChange ?? undefined;
    message.logical = object.logical ?? false;
    message.management = object.management ?? false;
    message.cpu = object.cpu ?? false;
    message.counters = (object.counters !== undefined && object.counters !== null)
      ? Counters.fromPartial(object.counters)
      : undefined;
    return message;
  },
};

function createBaseTemperatureInfo(): TemperatureInfo {
  return {
    criticalHighThreshold: "",
    criticalLowThreshold: "",
    highThreshold: "",
    isReplaceable: "",
    lowThreshold: "",
    maximumTemperature: "",
    minimumTemperature: "",
    temperature: "",
    timestamp: "",
    warningStatus: "",
  };
}

export const TemperatureInfo = {
  encode(message: TemperatureInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.criticalHighThreshold !== "") {
      writer.uint32(18).string(message.criticalHighThreshold);
    }
    if (message.criticalLowThreshold !== "") {
      writer.uint32(26).string(message.criticalLowThreshold);
    }
    if (message.highThreshold !== "") {
      writer.uint32(34).string(message.highThreshold);
    }
    if (message.isReplaceable !== "") {
      writer.uint32(42).string(message.isReplaceable);
    }
    if (message.lowThreshold !== "") {
      writer.uint32(50).string(message.lowThreshold);
    }
    if (message.maximumTemperature !== "") {
      writer.uint32(58).string(message.maximumTemperature);
    }
    if (message.minimumTemperature !== "") {
      writer.uint32(66).string(message.minimumTemperature);
    }
    if (message.temperature !== "") {
      writer.uint32(74).string(message.temperature);
    }
    if (message.timestamp !== "") {
      writer.uint32(82).string(message.timestamp);
    }
    if (message.warningStatus !== "") {
      writer.uint32(90).string(message.warningStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TemperatureInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTemperatureInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.criticalHighThreshold = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.criticalLowThreshold = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.highThreshold = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.isReplaceable = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lowThreshold = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.maximumTemperature = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.minimumTemperature = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.temperature = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.timestamp = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.warningStatus = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TemperatureInfo {
    return {
      criticalHighThreshold: isSet(object.criticalHighThreshold) ? globalThis.String(object.criticalHighThreshold) : "",
      criticalLowThreshold: isSet(object.criticalLowThreshold) ? globalThis.String(object.criticalLowThreshold) : "",
      highThreshold: isSet(object.highThreshold) ? globalThis.String(object.highThreshold) : "",
      isReplaceable: isSet(object.isReplaceable) ? globalThis.String(object.isReplaceable) : "",
      lowThreshold: isSet(object.lowThreshold) ? globalThis.String(object.lowThreshold) : "",
      maximumTemperature: isSet(object.maximumTemperature) ? globalThis.String(object.maximumTemperature) : "",
      minimumTemperature: isSet(object.minimumTemperature) ? globalThis.String(object.minimumTemperature) : "",
      temperature: isSet(object.temperature) ? globalThis.String(object.temperature) : "",
      timestamp: isSet(object.timestamp) ? globalThis.String(object.timestamp) : "",
      warningStatus: isSet(object.warningStatus) ? globalThis.String(object.warningStatus) : "",
    };
  },

  toJSON(message: TemperatureInfo): unknown {
    const obj: any = {};
    if (message.criticalHighThreshold !== "") {
      obj.criticalHighThreshold = message.criticalHighThreshold;
    }
    if (message.criticalLowThreshold !== "") {
      obj.criticalLowThreshold = message.criticalLowThreshold;
    }
    if (message.highThreshold !== "") {
      obj.highThreshold = message.highThreshold;
    }
    if (message.isReplaceable !== "") {
      obj.isReplaceable = message.isReplaceable;
    }
    if (message.lowThreshold !== "") {
      obj.lowThreshold = message.lowThreshold;
    }
    if (message.maximumTemperature !== "") {
      obj.maximumTemperature = message.maximumTemperature;
    }
    if (message.minimumTemperature !== "") {
      obj.minimumTemperature = message.minimumTemperature;
    }
    if (message.temperature !== "") {
      obj.temperature = message.temperature;
    }
    if (message.timestamp !== "") {
      obj.timestamp = message.timestamp;
    }
    if (message.warningStatus !== "") {
      obj.warningStatus = message.warningStatus;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TemperatureInfo>, I>>(base?: I): TemperatureInfo {
    return TemperatureInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TemperatureInfo>, I>>(object: I): TemperatureInfo {
    const message = createBaseTemperatureInfo();
    message.criticalHighThreshold = object.criticalHighThreshold ?? "";
    message.criticalLowThreshold = object.criticalLowThreshold ?? "";
    message.highThreshold = object.highThreshold ?? "";
    message.isReplaceable = object.isReplaceable ?? "";
    message.lowThreshold = object.lowThreshold ?? "";
    message.maximumTemperature = object.maximumTemperature ?? "";
    message.minimumTemperature = object.minimumTemperature ?? "";
    message.temperature = object.temperature ?? "";
    message.timestamp = object.timestamp ?? "";
    message.warningStatus = object.warningStatus ?? "";
    return message;
  },
};

function createBaseTransceiverDomSensor(): TransceiverDomSensor {
  return {
    rx1power: "",
    rx2power: "",
    rx3power: "",
    rx4power: "",
    rxLos: "",
    temperature: "",
    tx1bias: "",
    tx1power: "",
    tx2bias: "",
    tx2power: "",
    tx3bias: "",
    tx3power: "",
    tx4bias: "",
    tx4power: "",
    txDisable: "",
    txDisabledChannel: "",
    txFault: "",
    voltage: "",
  };
}

export const TransceiverDomSensor = {
  encode(message: TransceiverDomSensor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rx1power !== "") {
      writer.uint32(18).string(message.rx1power);
    }
    if (message.rx2power !== "") {
      writer.uint32(26).string(message.rx2power);
    }
    if (message.rx3power !== "") {
      writer.uint32(34).string(message.rx3power);
    }
    if (message.rx4power !== "") {
      writer.uint32(42).string(message.rx4power);
    }
    if (message.rxLos !== "") {
      writer.uint32(50).string(message.rxLos);
    }
    if (message.temperature !== "") {
      writer.uint32(58).string(message.temperature);
    }
    if (message.tx1bias !== "") {
      writer.uint32(66).string(message.tx1bias);
    }
    if (message.tx1power !== "") {
      writer.uint32(74).string(message.tx1power);
    }
    if (message.tx2bias !== "") {
      writer.uint32(82).string(message.tx2bias);
    }
    if (message.tx2power !== "") {
      writer.uint32(90).string(message.tx2power);
    }
    if (message.tx3bias !== "") {
      writer.uint32(98).string(message.tx3bias);
    }
    if (message.tx3power !== "") {
      writer.uint32(106).string(message.tx3power);
    }
    if (message.tx4bias !== "") {
      writer.uint32(114).string(message.tx4bias);
    }
    if (message.tx4power !== "") {
      writer.uint32(122).string(message.tx4power);
    }
    if (message.txDisable !== "") {
      writer.uint32(130).string(message.txDisable);
    }
    if (message.txDisabledChannel !== "") {
      writer.uint32(138).string(message.txDisabledChannel);
    }
    if (message.txFault !== "") {
      writer.uint32(146).string(message.txFault);
    }
    if (message.voltage !== "") {
      writer.uint32(154).string(message.voltage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransceiverDomSensor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransceiverDomSensor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rx1power = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rx2power = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rx3power = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.rx4power = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.rxLos = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.temperature = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tx1bias = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.tx1power = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.tx2bias = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.tx2power = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.tx3bias = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.tx3power = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.tx4bias = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.tx4power = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.txDisable = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.txDisabledChannel = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.txFault = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.voltage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransceiverDomSensor {
    return {
      rx1power: isSet(object.rx1power) ? globalThis.String(object.rx1power) : "",
      rx2power: isSet(object.rx2power) ? globalThis.String(object.rx2power) : "",
      rx3power: isSet(object.rx3power) ? globalThis.String(object.rx3power) : "",
      rx4power: isSet(object.rx4power) ? globalThis.String(object.rx4power) : "",
      rxLos: isSet(object.rxLos) ? globalThis.String(object.rxLos) : "",
      temperature: isSet(object.temperature) ? globalThis.String(object.temperature) : "",
      tx1bias: isSet(object.tx1bias) ? globalThis.String(object.tx1bias) : "",
      tx1power: isSet(object.tx1power) ? globalThis.String(object.tx1power) : "",
      tx2bias: isSet(object.tx2bias) ? globalThis.String(object.tx2bias) : "",
      tx2power: isSet(object.tx2power) ? globalThis.String(object.tx2power) : "",
      tx3bias: isSet(object.tx3bias) ? globalThis.String(object.tx3bias) : "",
      tx3power: isSet(object.tx3power) ? globalThis.String(object.tx3power) : "",
      tx4bias: isSet(object.tx4bias) ? globalThis.String(object.tx4bias) : "",
      tx4power: isSet(object.tx4power) ? globalThis.String(object.tx4power) : "",
      txDisable: isSet(object.txDisable) ? globalThis.String(object.txDisable) : "",
      txDisabledChannel: isSet(object.txDisabledChannel) ? globalThis.String(object.txDisabledChannel) : "",
      txFault: isSet(object.txFault) ? globalThis.String(object.txFault) : "",
      voltage: isSet(object.voltage) ? globalThis.String(object.voltage) : "",
    };
  },

  toJSON(message: TransceiverDomSensor): unknown {
    const obj: any = {};
    if (message.rx1power !== "") {
      obj.rx1power = message.rx1power;
    }
    if (message.rx2power !== "") {
      obj.rx2power = message.rx2power;
    }
    if (message.rx3power !== "") {
      obj.rx3power = message.rx3power;
    }
    if (message.rx4power !== "") {
      obj.rx4power = message.rx4power;
    }
    if (message.rxLos !== "") {
      obj.rxLos = message.rxLos;
    }
    if (message.temperature !== "") {
      obj.temperature = message.temperature;
    }
    if (message.tx1bias !== "") {
      obj.tx1bias = message.tx1bias;
    }
    if (message.tx1power !== "") {
      obj.tx1power = message.tx1power;
    }
    if (message.tx2bias !== "") {
      obj.tx2bias = message.tx2bias;
    }
    if (message.tx2power !== "") {
      obj.tx2power = message.tx2power;
    }
    if (message.tx3bias !== "") {
      obj.tx3bias = message.tx3bias;
    }
    if (message.tx3power !== "") {
      obj.tx3power = message.tx3power;
    }
    if (message.tx4bias !== "") {
      obj.tx4bias = message.tx4bias;
    }
    if (message.tx4power !== "") {
      obj.tx4power = message.tx4power;
    }
    if (message.txDisable !== "") {
      obj.txDisable = message.txDisable;
    }
    if (message.txDisabledChannel !== "") {
      obj.txDisabledChannel = message.txDisabledChannel;
    }
    if (message.txFault !== "") {
      obj.txFault = message.txFault;
    }
    if (message.voltage !== "") {
      obj.voltage = message.voltage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TransceiverDomSensor>, I>>(base?: I): TransceiverDomSensor {
    return TransceiverDomSensor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransceiverDomSensor>, I>>(object: I): TransceiverDomSensor {
    const message = createBaseTransceiverDomSensor();
    message.rx1power = object.rx1power ?? "";
    message.rx2power = object.rx2power ?? "";
    message.rx3power = object.rx3power ?? "";
    message.rx4power = object.rx4power ?? "";
    message.rxLos = object.rxLos ?? "";
    message.temperature = object.temperature ?? "";
    message.tx1bias = object.tx1bias ?? "";
    message.tx1power = object.tx1power ?? "";
    message.tx2bias = object.tx2bias ?? "";
    message.tx2power = object.tx2power ?? "";
    message.tx3bias = object.tx3bias ?? "";
    message.tx3power = object.tx3power ?? "";
    message.tx4bias = object.tx4bias ?? "";
    message.tx4power = object.tx4power ?? "";
    message.txDisable = object.txDisable ?? "";
    message.txDisabledChannel = object.txDisabledChannel ?? "";
    message.txFault = object.txFault ?? "";
    message.voltage = object.voltage ?? "";
    return message;
  },
};

function createBaseTransceiverInfo(): TransceiverInfo {
  return {
    applicationAdvertisement: "",
    cableLength: 0,
    cableType: "",
    connector: "",
    domCapability: "",
    encoding: "",
    extIdentifier: "",
    extRateselectCompliance: "",
    isReplaceable: "",
    manufacturer: "",
    model: "",
    nominalBitRate: "",
    serial: "",
    specificationCompliance: "",
    type: "",
    vendorDate: "",
    vendorOui: "",
    vendorRev: "",
  };
}

export const TransceiverInfo = {
  encode(message: TransceiverInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.applicationAdvertisement !== "") {
      writer.uint32(18).string(message.applicationAdvertisement);
    }
    if (message.cableLength !== 0) {
      writer.uint32(24).uint32(message.cableLength);
    }
    if (message.cableType !== "") {
      writer.uint32(34).string(message.cableType);
    }
    if (message.connector !== "") {
      writer.uint32(42).string(message.connector);
    }
    if (message.domCapability !== "") {
      writer.uint32(50).string(message.domCapability);
    }
    if (message.encoding !== "") {
      writer.uint32(58).string(message.encoding);
    }
    if (message.extIdentifier !== "") {
      writer.uint32(66).string(message.extIdentifier);
    }
    if (message.extRateselectCompliance !== "") {
      writer.uint32(74).string(message.extRateselectCompliance);
    }
    if (message.isReplaceable !== "") {
      writer.uint32(82).string(message.isReplaceable);
    }
    if (message.manufacturer !== "") {
      writer.uint32(90).string(message.manufacturer);
    }
    if (message.model !== "") {
      writer.uint32(98).string(message.model);
    }
    if (message.nominalBitRate !== "") {
      writer.uint32(106).string(message.nominalBitRate);
    }
    if (message.serial !== "") {
      writer.uint32(114).string(message.serial);
    }
    if (message.specificationCompliance !== "") {
      writer.uint32(122).string(message.specificationCompliance);
    }
    if (message.type !== "") {
      writer.uint32(130).string(message.type);
    }
    if (message.vendorDate !== "") {
      writer.uint32(138).string(message.vendorDate);
    }
    if (message.vendorOui !== "") {
      writer.uint32(146).string(message.vendorOui);
    }
    if (message.vendorRev !== "") {
      writer.uint32(154).string(message.vendorRev);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransceiverInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransceiverInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.applicationAdvertisement = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.cableLength = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cableType = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.connector = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.domCapability = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.encoding = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.extIdentifier = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.extRateselectCompliance = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.isReplaceable = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.model = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.nominalBitRate = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.specificationCompliance = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.type = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.vendorDate = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.vendorOui = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.vendorRev = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransceiverInfo {
    return {
      applicationAdvertisement: isSet(object.applicationAdvertisement)
        ? globalThis.String(object.applicationAdvertisement)
        : "",
      cableLength: isSet(object.cableLength) ? globalThis.Number(object.cableLength) : 0,
      cableType: isSet(object.cableType) ? globalThis.String(object.cableType) : "",
      connector: isSet(object.connector) ? globalThis.String(object.connector) : "",
      domCapability: isSet(object.domCapability) ? globalThis.String(object.domCapability) : "",
      encoding: isSet(object.encoding) ? globalThis.String(object.encoding) : "",
      extIdentifier: isSet(object.extIdentifier) ? globalThis.String(object.extIdentifier) : "",
      extRateselectCompliance: isSet(object.extRateselectCompliance)
        ? globalThis.String(object.extRateselectCompliance)
        : "",
      isReplaceable: isSet(object.isReplaceable) ? globalThis.String(object.isReplaceable) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      nominalBitRate: isSet(object.nominalBitRate) ? globalThis.String(object.nominalBitRate) : "",
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      specificationCompliance: isSet(object.specificationCompliance)
        ? globalThis.String(object.specificationCompliance)
        : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      vendorDate: isSet(object.vendorDate) ? globalThis.String(object.vendorDate) : "",
      vendorOui: isSet(object.vendorOui) ? globalThis.String(object.vendorOui) : "",
      vendorRev: isSet(object.vendorRev) ? globalThis.String(object.vendorRev) : "",
    };
  },

  toJSON(message: TransceiverInfo): unknown {
    const obj: any = {};
    if (message.applicationAdvertisement !== "") {
      obj.applicationAdvertisement = message.applicationAdvertisement;
    }
    if (message.cableLength !== 0) {
      obj.cableLength = Math.round(message.cableLength);
    }
    if (message.cableType !== "") {
      obj.cableType = message.cableType;
    }
    if (message.connector !== "") {
      obj.connector = message.connector;
    }
    if (message.domCapability !== "") {
      obj.domCapability = message.domCapability;
    }
    if (message.encoding !== "") {
      obj.encoding = message.encoding;
    }
    if (message.extIdentifier !== "") {
      obj.extIdentifier = message.extIdentifier;
    }
    if (message.extRateselectCompliance !== "") {
      obj.extRateselectCompliance = message.extRateselectCompliance;
    }
    if (message.isReplaceable !== "") {
      obj.isReplaceable = message.isReplaceable;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.nominalBitRate !== "") {
      obj.nominalBitRate = message.nominalBitRate;
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.specificationCompliance !== "") {
      obj.specificationCompliance = message.specificationCompliance;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.vendorDate !== "") {
      obj.vendorDate = message.vendorDate;
    }
    if (message.vendorOui !== "") {
      obj.vendorOui = message.vendorOui;
    }
    if (message.vendorRev !== "") {
      obj.vendorRev = message.vendorRev;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TransceiverInfo>, I>>(base?: I): TransceiverInfo {
    return TransceiverInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransceiverInfo>, I>>(object: I): TransceiverInfo {
    const message = createBaseTransceiverInfo();
    message.applicationAdvertisement = object.applicationAdvertisement ?? "";
    message.cableLength = object.cableLength ?? 0;
    message.cableType = object.cableType ?? "";
    message.connector = object.connector ?? "";
    message.domCapability = object.domCapability ?? "";
    message.encoding = object.encoding ?? "";
    message.extIdentifier = object.extIdentifier ?? "";
    message.extRateselectCompliance = object.extRateselectCompliance ?? "";
    message.isReplaceable = object.isReplaceable ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.model = object.model ?? "";
    message.nominalBitRate = object.nominalBitRate ?? "";
    message.serial = object.serial ?? "";
    message.specificationCompliance = object.specificationCompliance ?? "";
    message.type = object.type ?? "";
    message.vendorDate = object.vendorDate ?? "";
    message.vendorOui = object.vendorOui ?? "";
    message.vendorRev = object.vendorRev ?? "";
    return message;
  },
};

function createBaseTunnel(): Tunnel {
  return { config: undefined, state: undefined, openconfigIfIpIpv4: undefined, openconfigIfIpIpv6: undefined };
}

export const Tunnel = {
  encode(message: Tunnel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      TunnelConfig.encode(message.config, writer.uint32(18).fork()).ldelim();
    }
    if (message.state !== undefined) {
      TunnelState.encode(message.state, writer.uint32(26).fork()).ldelim();
    }
    if (message.openconfigIfIpIpv4 !== undefined) {
      InterfaceIpv4.encode(message.openconfigIfIpIpv4, writer.uint32(34).fork()).ldelim();
    }
    if (message.openconfigIfIpIpv6 !== undefined) {
      InterfaceIpv6.encode(message.openconfigIfIpIpv6, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tunnel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTunnel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.config = TunnelConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.state = TunnelState.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.openconfigIfIpIpv4 = InterfaceIpv4.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.openconfigIfIpIpv6 = InterfaceIpv6.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Tunnel {
    return {
      config: isSet(object.config) ? TunnelConfig.fromJSON(object.config) : undefined,
      state: isSet(object.state) ? TunnelState.fromJSON(object.state) : undefined,
      openconfigIfIpIpv4: isSet(object["openconfig-if-ip:ipv4"])
        ? InterfaceIpv4.fromJSON(object["openconfig-if-ip:ipv4"])
        : undefined,
      openconfigIfIpIpv6: isSet(object["openconfig-if-ip:ipv6"])
        ? InterfaceIpv6.fromJSON(object["openconfig-if-ip:ipv6"])
        : undefined,
    };
  },

  toJSON(message: Tunnel): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = TunnelConfig.toJSON(message.config);
    }
    if (message.state !== undefined) {
      obj.state = TunnelState.toJSON(message.state);
    }
    if (message.openconfigIfIpIpv4 !== undefined) {
      obj["openconfig-if-ip:ipv4"] = InterfaceIpv4.toJSON(message.openconfigIfIpIpv4);
    }
    if (message.openconfigIfIpIpv6 !== undefined) {
      obj["openconfig-if-ip:ipv6"] = InterfaceIpv6.toJSON(message.openconfigIfIpIpv6);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Tunnel>, I>>(base?: I): Tunnel {
    return Tunnel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Tunnel>, I>>(object: I): Tunnel {
    const message = createBaseTunnel();
    message.config = (object.config !== undefined && object.config !== null)
      ? TunnelConfig.fromPartial(object.config)
      : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? TunnelState.fromPartial(object.state)
      : undefined;
    message.openconfigIfIpIpv4 = (object.openconfigIfIpIpv4 !== undefined && object.openconfigIfIpIpv4 !== null)
      ? InterfaceIpv4.fromPartial(object.openconfigIfIpIpv4)
      : undefined;
    message.openconfigIfIpIpv6 = (object.openconfigIfIpIpv6 !== undefined && object.openconfigIfIpIpv6 !== null)
      ? InterfaceIpv6.fromPartial(object.openconfigIfIpIpv6)
      : undefined;
    return message;
  },
};

function createBaseTunnelConfig(): TunnelConfig {
  return { src: "", dst: "", ttl: 0, greKey: 0 };
}

export const TunnelConfig = {
  encode(message: TunnelConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.src !== "") {
      writer.uint32(18).string(message.src);
    }
    if (message.dst !== "") {
      writer.uint32(26).string(message.dst);
    }
    if (message.ttl !== 0) {
      writer.uint32(32).uint32(message.ttl);
    }
    if (message.greKey !== 0) {
      writer.uint32(40).uint32(message.greKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TunnelConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTunnelConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.src = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dst = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.ttl = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.greKey = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TunnelConfig {
    return {
      src: isSet(object.src) ? globalThis.String(object.src) : "",
      dst: isSet(object.dst) ? globalThis.String(object.dst) : "",
      ttl: isSet(object.ttl) ? globalThis.Number(object.ttl) : 0,
      greKey: isSet(object["gre-key"]) ? globalThis.Number(object["gre-key"]) : 0,
    };
  },

  toJSON(message: TunnelConfig): unknown {
    const obj: any = {};
    if (message.src !== "") {
      obj.src = message.src;
    }
    if (message.dst !== "") {
      obj.dst = message.dst;
    }
    if (message.ttl !== 0) {
      obj.ttl = Math.round(message.ttl);
    }
    if (message.greKey !== 0) {
      obj["gre-key"] = Math.round(message.greKey);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TunnelConfig>, I>>(base?: I): TunnelConfig {
    return TunnelConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TunnelConfig>, I>>(object: I): TunnelConfig {
    const message = createBaseTunnelConfig();
    message.src = object.src ?? "";
    message.dst = object.dst ?? "";
    message.ttl = object.ttl ?? 0;
    message.greKey = object.greKey ?? 0;
    return message;
  },
};

function createBaseTunnelCounters(): TunnelCounters {
  return {
    saiTunnelStatInOctets: 0,
    saiTunnelStatInPackets: 0,
    saiTunnelStatOutOctets: 0,
    saiTunnelStatOutPackets: 0,
    tunnelName: "",
  };
}

export const TunnelCounters = {
  encode(message: TunnelCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.saiTunnelStatInOctets !== 0) {
      writer.uint32(16).uint64(message.saiTunnelStatInOctets);
    }
    if (message.saiTunnelStatInPackets !== 0) {
      writer.uint32(24).uint64(message.saiTunnelStatInPackets);
    }
    if (message.saiTunnelStatOutOctets !== 0) {
      writer.uint32(32).uint64(message.saiTunnelStatOutOctets);
    }
    if (message.saiTunnelStatOutPackets !== 0) {
      writer.uint32(40).uint64(message.saiTunnelStatOutPackets);
    }
    if (message.tunnelName !== "") {
      writer.uint32(82).string(message.tunnelName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TunnelCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTunnelCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.saiTunnelStatInOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.saiTunnelStatInPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.saiTunnelStatOutOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.saiTunnelStatOutPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.tunnelName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TunnelCounters {
    return {
      saiTunnelStatInOctets: isSet(object.SAI_TUNNEL_STAT_IN_OCTETS)
        ? globalThis.Number(object.SAI_TUNNEL_STAT_IN_OCTETS)
        : 0,
      saiTunnelStatInPackets: isSet(object.SAI_TUNNEL_STAT_IN_PACKETS)
        ? globalThis.Number(object.SAI_TUNNEL_STAT_IN_PACKETS)
        : 0,
      saiTunnelStatOutOctets: isSet(object.SAI_TUNNEL_STAT_OUT_OCTETS)
        ? globalThis.Number(object.SAI_TUNNEL_STAT_OUT_OCTETS)
        : 0,
      saiTunnelStatOutPackets: isSet(object.SAI_TUNNEL_STAT_OUT_PACKETS)
        ? globalThis.Number(object.SAI_TUNNEL_STAT_OUT_PACKETS)
        : 0,
      tunnelName: isSet(object.tunnelName) ? globalThis.String(object.tunnelName) : "",
    };
  },

  toJSON(message: TunnelCounters): unknown {
    const obj: any = {};
    if (message.saiTunnelStatInOctets !== 0) {
      obj.SAI_TUNNEL_STAT_IN_OCTETS = Math.round(message.saiTunnelStatInOctets);
    }
    if (message.saiTunnelStatInPackets !== 0) {
      obj.SAI_TUNNEL_STAT_IN_PACKETS = Math.round(message.saiTunnelStatInPackets);
    }
    if (message.saiTunnelStatOutOctets !== 0) {
      obj.SAI_TUNNEL_STAT_OUT_OCTETS = Math.round(message.saiTunnelStatOutOctets);
    }
    if (message.saiTunnelStatOutPackets !== 0) {
      obj.SAI_TUNNEL_STAT_OUT_PACKETS = Math.round(message.saiTunnelStatOutPackets);
    }
    if (message.tunnelName !== "") {
      obj.tunnelName = message.tunnelName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TunnelCounters>, I>>(base?: I): TunnelCounters {
    return TunnelCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TunnelCounters>, I>>(object: I): TunnelCounters {
    const message = createBaseTunnelCounters();
    message.saiTunnelStatInOctets = object.saiTunnelStatInOctets ?? 0;
    message.saiTunnelStatInPackets = object.saiTunnelStatInPackets ?? 0;
    message.saiTunnelStatOutOctets = object.saiTunnelStatOutOctets ?? 0;
    message.saiTunnelStatOutPackets = object.saiTunnelStatOutPackets ?? 0;
    message.tunnelName = object.tunnelName ?? "";
    return message;
  },
};

function createBaseTunnelRateCounters(): TunnelRateCounters {
  return {
    rxBps: 0,
    rxPps: 0,
    saiTunnelStatInOctetsLast: 0,
    saiTunnelStatInPacketsLast: 0,
    saiTunnelStatOutOctetsLast: 0,
    saiTunnelStatOutPacketsLast: 0,
    txBps: 0,
    txPps: 0,
  };
}

export const TunnelRateCounters = {
  encode(message: TunnelRateCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rxBps !== 0) {
      writer.uint32(17).double(message.rxBps);
    }
    if (message.rxPps !== 0) {
      writer.uint32(25).double(message.rxPps);
    }
    if (message.saiTunnelStatInOctetsLast !== 0) {
      writer.uint32(32).uint64(message.saiTunnelStatInOctetsLast);
    }
    if (message.saiTunnelStatInPacketsLast !== 0) {
      writer.uint32(40).uint64(message.saiTunnelStatInPacketsLast);
    }
    if (message.saiTunnelStatOutOctetsLast !== 0) {
      writer.uint32(48).uint64(message.saiTunnelStatOutOctetsLast);
    }
    if (message.saiTunnelStatOutPacketsLast !== 0) {
      writer.uint32(56).uint64(message.saiTunnelStatOutPacketsLast);
    }
    if (message.txBps !== 0) {
      writer.uint32(65).double(message.txBps);
    }
    if (message.txPps !== 0) {
      writer.uint32(73).double(message.txPps);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TunnelRateCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTunnelRateCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 17) {
            break;
          }

          message.rxBps = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.rxPps = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.saiTunnelStatInOctetsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.saiTunnelStatInPacketsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.saiTunnelStatOutOctetsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.saiTunnelStatOutPacketsLast = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.txBps = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.txPps = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TunnelRateCounters {
    return {
      rxBps: isSet(object.RX_BPS) ? globalThis.Number(object.RX_BPS) : 0,
      rxPps: isSet(object.RX_PPS) ? globalThis.Number(object.RX_PPS) : 0,
      saiTunnelStatInOctetsLast: isSet(object.SAI_TUNNEL_STAT_IN_OCTETS_last)
        ? globalThis.Number(object.SAI_TUNNEL_STAT_IN_OCTETS_last)
        : 0,
      saiTunnelStatInPacketsLast: isSet(object.SAI_TUNNEL_STAT_IN_PACKETS_last)
        ? globalThis.Number(object.SAI_TUNNEL_STAT_IN_PACKETS_last)
        : 0,
      saiTunnelStatOutOctetsLast: isSet(object.SAI_TUNNEL_STAT_OUT_OCTETS_last)
        ? globalThis.Number(object.SAI_TUNNEL_STAT_OUT_OCTETS_last)
        : 0,
      saiTunnelStatOutPacketsLast: isSet(object.SAI_TUNNEL_STAT_OUT_PACKETS_last)
        ? globalThis.Number(object.SAI_TUNNEL_STAT_OUT_PACKETS_last)
        : 0,
      txBps: isSet(object.TX_BPS) ? globalThis.Number(object.TX_BPS) : 0,
      txPps: isSet(object.TX_PPS) ? globalThis.Number(object.TX_PPS) : 0,
    };
  },

  toJSON(message: TunnelRateCounters): unknown {
    const obj: any = {};
    if (message.rxBps !== 0) {
      obj.RX_BPS = message.rxBps;
    }
    if (message.rxPps !== 0) {
      obj.RX_PPS = message.rxPps;
    }
    if (message.saiTunnelStatInOctetsLast !== 0) {
      obj.SAI_TUNNEL_STAT_IN_OCTETS_last = Math.round(message.saiTunnelStatInOctetsLast);
    }
    if (message.saiTunnelStatInPacketsLast !== 0) {
      obj.SAI_TUNNEL_STAT_IN_PACKETS_last = Math.round(message.saiTunnelStatInPacketsLast);
    }
    if (message.saiTunnelStatOutOctetsLast !== 0) {
      obj.SAI_TUNNEL_STAT_OUT_OCTETS_last = Math.round(message.saiTunnelStatOutOctetsLast);
    }
    if (message.saiTunnelStatOutPacketsLast !== 0) {
      obj.SAI_TUNNEL_STAT_OUT_PACKETS_last = Math.round(message.saiTunnelStatOutPacketsLast);
    }
    if (message.txBps !== 0) {
      obj.TX_BPS = message.txBps;
    }
    if (message.txPps !== 0) {
      obj.TX_PPS = message.txPps;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TunnelRateCounters>, I>>(base?: I): TunnelRateCounters {
    return TunnelRateCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TunnelRateCounters>, I>>(object: I): TunnelRateCounters {
    const message = createBaseTunnelRateCounters();
    message.rxBps = object.rxBps ?? 0;
    message.rxPps = object.rxPps ?? 0;
    message.saiTunnelStatInOctetsLast = object.saiTunnelStatInOctetsLast ?? 0;
    message.saiTunnelStatInPacketsLast = object.saiTunnelStatInPacketsLast ?? 0;
    message.saiTunnelStatOutOctetsLast = object.saiTunnelStatOutOctetsLast ?? 0;
    message.saiTunnelStatOutPacketsLast = object.saiTunnelStatOutPacketsLast ?? 0;
    message.txBps = object.txBps ?? 0;
    message.txPps = object.txPps ?? 0;
    return message;
  },
};

function createBaseTunnelState(): TunnelState {
  return { src: "", dst: "", ttl: 0, greKey: 0 };
}

export const TunnelState = {
  encode(message: TunnelState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.src !== "") {
      writer.uint32(18).string(message.src);
    }
    if (message.dst !== "") {
      writer.uint32(26).string(message.dst);
    }
    if (message.ttl !== 0) {
      writer.uint32(32).uint32(message.ttl);
    }
    if (message.greKey !== 0) {
      writer.uint32(40).uint32(message.greKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TunnelState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTunnelState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.src = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dst = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.ttl = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.greKey = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TunnelState {
    return {
      src: isSet(object.src) ? globalThis.String(object.src) : "",
      dst: isSet(object.dst) ? globalThis.String(object.dst) : "",
      ttl: isSet(object.ttl) ? globalThis.Number(object.ttl) : 0,
      greKey: isSet(object["gre-key"]) ? globalThis.Number(object["gre-key"]) : 0,
    };
  },

  toJSON(message: TunnelState): unknown {
    const obj: any = {};
    if (message.src !== "") {
      obj.src = message.src;
    }
    if (message.dst !== "") {
      obj.dst = message.dst;
    }
    if (message.ttl !== 0) {
      obj.ttl = Math.round(message.ttl);
    }
    if (message.greKey !== 0) {
      obj["gre-key"] = Math.round(message.greKey);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TunnelState>, I>>(base?: I): TunnelState {
    return TunnelState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TunnelState>, I>>(object: I): TunnelState {
    const message = createBaseTunnelState();
    message.src = object.src ?? "";
    message.dst = object.dst ?? "";
    message.ttl = object.ttl ?? 0;
    message.greKey = object.greKey ?? 0;
    return message;
  },
};

function createBaseUnknownVlan(): UnknownVlan {
  return { vlanId: 0, port: "", packetCount: 0, arrivalTime: 0, origLenBytes: 0, packetContents: new Uint8Array(0) };
}

export const UnknownVlan = {
  encode(message: UnknownVlan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vlanId !== 0) {
      writer.uint32(8).int32(message.vlanId);
    }
    if (message.port !== "") {
      writer.uint32(18).string(message.port);
    }
    if (message.packetCount !== 0) {
      writer.uint32(24).int32(message.packetCount);
    }
    if (message.arrivalTime !== 0) {
      writer.uint32(32).int64(message.arrivalTime);
    }
    if (message.origLenBytes !== 0) {
      writer.uint32(40).int32(message.origLenBytes);
    }
    if (message.packetContents.length !== 0) {
      writer.uint32(50).bytes(message.packetContents);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnknownVlan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnknownVlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.vlanId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.port = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.packetCount = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.arrivalTime = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.origLenBytes = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.packetContents = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnknownVlan {
    return {
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      packetCount: isSet(object.packetCount) ? globalThis.Number(object.packetCount) : 0,
      arrivalTime: isSet(object.arrivalTime) ? globalThis.Number(object.arrivalTime) : 0,
      origLenBytes: isSet(object.origLenBytes) ? globalThis.Number(object.origLenBytes) : 0,
      packetContents: isSet(object.packetContents) ? bytesFromBase64(object.packetContents) : new Uint8Array(0),
    };
  },

  toJSON(message: UnknownVlan): unknown {
    const obj: any = {};
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.packetCount !== 0) {
      obj.packetCount = Math.round(message.packetCount);
    }
    if (message.arrivalTime !== 0) {
      obj.arrivalTime = Math.round(message.arrivalTime);
    }
    if (message.origLenBytes !== 0) {
      obj.origLenBytes = Math.round(message.origLenBytes);
    }
    if (message.packetContents.length !== 0) {
      obj.packetContents = base64FromBytes(message.packetContents);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnknownVlan>, I>>(base?: I): UnknownVlan {
    return UnknownVlan.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnknownVlan>, I>>(object: I): UnknownVlan {
    const message = createBaseUnknownVlan();
    message.vlanId = object.vlanId ?? 0;
    message.port = object.port ?? "";
    message.packetCount = object.packetCount ?? 0;
    message.arrivalTime = object.arrivalTime ?? 0;
    message.origLenBytes = object.origLenBytes ?? 0;
    message.packetContents = object.packetContents ?? new Uint8Array(0);
    return message;
  },
};

function createBaseUnknownVlansRequest(): UnknownVlansRequest {
  return { requestContext: "", clear: false, includePacketData: false };
}

export const UnknownVlansRequest = {
  encode(message: UnknownVlansRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestContext !== "") {
      writer.uint32(10).string(message.requestContext);
    }
    if (message.clear !== false) {
      writer.uint32(16).bool(message.clear);
    }
    if (message.includePacketData !== false) {
      writer.uint32(24).bool(message.includePacketData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnknownVlansRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnknownVlansRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.requestContext = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.clear = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includePacketData = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnknownVlansRequest {
    return {
      requestContext: isSet(object.requestContext) ? globalThis.String(object.requestContext) : "",
      clear: isSet(object.clear) ? globalThis.Boolean(object.clear) : false,
      includePacketData: isSet(object.includePacketData) ? globalThis.Boolean(object.includePacketData) : false,
    };
  },

  toJSON(message: UnknownVlansRequest): unknown {
    const obj: any = {};
    if (message.requestContext !== "") {
      obj.requestContext = message.requestContext;
    }
    if (message.clear !== false) {
      obj.clear = message.clear;
    }
    if (message.includePacketData !== false) {
      obj.includePacketData = message.includePacketData;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnknownVlansRequest>, I>>(base?: I): UnknownVlansRequest {
    return UnknownVlansRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnknownVlansRequest>, I>>(object: I): UnknownVlansRequest {
    const message = createBaseUnknownVlansRequest();
    message.requestContext = object.requestContext ?? "";
    message.clear = object.clear ?? false;
    message.includePacketData = object.includePacketData ?? false;
    return message;
  },
};

function createBaseUnknownVlansTable(): UnknownVlansTable {
  return { error: false, errorMessage: "", requestContext: "", vlans: [] };
}

export const UnknownVlansTable = {
  encode(message: UnknownVlansTable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.error !== false) {
      writer.uint32(8).bool(message.error);
    }
    if (message.errorMessage !== "") {
      writer.uint32(18).string(message.errorMessage);
    }
    if (message.requestContext !== "") {
      writer.uint32(26).string(message.requestContext);
    }
    for (const v of message.vlans) {
      UnknownVlan.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnknownVlansTable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnknownVlansTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.error = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.requestContext = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.vlans.push(UnknownVlan.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnknownVlansTable {
    return {
      error: isSet(object.error) ? globalThis.Boolean(object.error) : false,
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
      requestContext: isSet(object.requestContext) ? globalThis.String(object.requestContext) : "",
      vlans: globalThis.Array.isArray(object?.vlans) ? object.vlans.map((e: any) => UnknownVlan.fromJSON(e)) : [],
    };
  },

  toJSON(message: UnknownVlansTable): unknown {
    const obj: any = {};
    if (message.error !== false) {
      obj.error = message.error;
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    if (message.requestContext !== "") {
      obj.requestContext = message.requestContext;
    }
    if (message.vlans?.length) {
      obj.vlans = message.vlans.map((e) => UnknownVlan.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnknownVlansTable>, I>>(base?: I): UnknownVlansTable {
    return UnknownVlansTable.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnknownVlansTable>, I>>(object: I): UnknownVlansTable {
    const message = createBaseUnknownVlansTable();
    message.error = object.error ?? false;
    message.errorMessage = object.errorMessage ?? "";
    message.requestContext = object.requestContext ?? "";
    message.vlans = object.vlans?.map((e) => UnknownVlan.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVlanConfig(): VlanConfig {
  return { vlans: [], vlanMembers: [] };
}

export const VlanConfig = {
  encode(message: VlanConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.vlans) {
      VlanState.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.vlanMembers) {
      VlanMemberInfo.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VlanConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlanConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vlans.push(VlanState.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vlanMembers.push(VlanMemberInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VlanConfig {
    return {
      vlans: globalThis.Array.isArray(object?.vlans) ? object.vlans.map((e: any) => VlanState.fromJSON(e)) : [],
      vlanMembers: globalThis.Array.isArray(object?.vlanMembers)
        ? object.vlanMembers.map((e: any) => VlanMemberInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: VlanConfig): unknown {
    const obj: any = {};
    if (message.vlans?.length) {
      obj.vlans = message.vlans.map((e) => VlanState.toJSON(e));
    }
    if (message.vlanMembers?.length) {
      obj.vlanMembers = message.vlanMembers.map((e) => VlanMemberInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VlanConfig>, I>>(base?: I): VlanConfig {
    return VlanConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VlanConfig>, I>>(object: I): VlanConfig {
    const message = createBaseVlanConfig();
    message.vlans = object.vlans?.map((e) => VlanState.fromPartial(e)) || [];
    message.vlanMembers = object.vlanMembers?.map((e) => VlanMemberInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVlanMemberConfig(): VlanMemberConfig {
  return { ifName: "", taggingMode: "" };
}

export const VlanMemberConfig = {
  encode(message: VlanMemberConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ifName !== "") {
      writer.uint32(18).string(message.ifName);
    }
    if (message.taggingMode !== "") {
      writer.uint32(34).string(message.taggingMode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VlanMemberConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlanMemberConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ifName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.taggingMode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VlanMemberConfig {
    return {
      ifName: isSet(object.ifName) ? globalThis.String(object.ifName) : "",
      taggingMode: isSet(object.taggingMode) ? globalThis.String(object.taggingMode) : "",
    };
  },

  toJSON(message: VlanMemberConfig): unknown {
    const obj: any = {};
    if (message.ifName !== "") {
      obj.ifName = message.ifName;
    }
    if (message.taggingMode !== "") {
      obj.taggingMode = message.taggingMode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VlanMemberConfig>, I>>(base?: I): VlanMemberConfig {
    return VlanMemberConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VlanMemberConfig>, I>>(object: I): VlanMemberConfig {
    const message = createBaseVlanMemberConfig();
    message.ifName = object.ifName ?? "";
    message.taggingMode = object.taggingMode ?? "";
    return message;
  },
};

function createBaseVlanMemberInfo(): VlanMemberInfo {
  return { vlanId: 0, members: [] };
}

export const VlanMemberInfo = {
  encode(message: VlanMemberInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vlanId !== 0) {
      writer.uint32(16).uint32(message.vlanId);
    }
    for (const v of message.members) {
      VlanMemberConfig.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VlanMemberInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlanMemberInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.vlanId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.members.push(VlanMemberConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VlanMemberInfo {
    return {
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      members: globalThis.Array.isArray(object?.members)
        ? object.members.map((e: any) => VlanMemberConfig.fromJSON(e))
        : [],
    };
  },

  toJSON(message: VlanMemberInfo): unknown {
    const obj: any = {};
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.members?.length) {
      obj.members = message.members.map((e) => VlanMemberConfig.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VlanMemberInfo>, I>>(base?: I): VlanMemberInfo {
    return VlanMemberInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VlanMemberInfo>, I>>(object: I): VlanMemberInfo {
    const message = createBaseVlanMemberInfo();
    message.vlanId = object.vlanId ?? 0;
    message.members = object.members?.map((e) => VlanMemberConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVlanState(): VlanState {
  return { adminStatus: "", hostIfname: "", mac: "", mtu: 0, vlanId: 0 };
}

export const VlanState = {
  encode(message: VlanState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.adminStatus !== "") {
      writer.uint32(18).string(message.adminStatus);
    }
    if (message.hostIfname !== "") {
      writer.uint32(26).string(message.hostIfname);
    }
    if (message.mac !== "") {
      writer.uint32(34).string(message.mac);
    }
    if (message.mtu !== 0) {
      writer.uint32(40).uint32(message.mtu);
    }
    if (message.vlanId !== 0) {
      writer.uint32(48).uint32(message.vlanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VlanState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlanState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.adminStatus = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.hostIfname = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mac = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.vlanId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VlanState {
    return {
      adminStatus: isSet(object.adminStatus) ? globalThis.String(object.adminStatus) : "",
      hostIfname: isSet(object.hostIfname) ? globalThis.String(object.hostIfname) : "",
      mac: isSet(object.mac) ? globalThis.String(object.mac) : "",
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
    };
  },

  toJSON(message: VlanState): unknown {
    const obj: any = {};
    if (message.adminStatus !== "") {
      obj.adminStatus = message.adminStatus;
    }
    if (message.hostIfname !== "") {
      obj.hostIfname = message.hostIfname;
    }
    if (message.mac !== "") {
      obj.mac = message.mac;
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VlanState>, I>>(base?: I): VlanState {
    return VlanState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VlanState>, I>>(object: I): VlanState {
    const message = createBaseVlanState();
    message.adminStatus = object.adminStatus ?? "";
    message.hostIfname = object.hostIfname ?? "";
    message.mac = object.mac ?? "";
    message.mtu = object.mtu ?? 0;
    message.vlanId = object.vlanId ?? 0;
    return message;
  },
};

function createBaseVnetInfo(): VnetInfo {
  return { name: "", scope: "", vni: "", vxlanTunnel: "" };
}

export const VnetInfo = {
  encode(message: VnetInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.scope !== "") {
      writer.uint32(26).string(message.scope);
    }
    if (message.vni !== "") {
      writer.uint32(34).string(message.vni);
    }
    if (message.vxlanTunnel !== "") {
      writer.uint32(42).string(message.vxlanTunnel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VnetInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVnetInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.scope = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.vni = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.vxlanTunnel = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VnetInfo {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      scope: isSet(object.scope) ? globalThis.String(object.scope) : "",
      vni: isSet(object.vni) ? globalThis.String(object.vni) : "",
      vxlanTunnel: isSet(object.vxlanTunnel) ? globalThis.String(object.vxlanTunnel) : "",
    };
  },

  toJSON(message: VnetInfo): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.scope !== "") {
      obj.scope = message.scope;
    }
    if (message.vni !== "") {
      obj.vni = message.vni;
    }
    if (message.vxlanTunnel !== "") {
      obj.vxlanTunnel = message.vxlanTunnel;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VnetInfo>, I>>(base?: I): VnetInfo {
    return VnetInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VnetInfo>, I>>(object: I): VnetInfo {
    const message = createBaseVnetInfo();
    message.name = object.name ?? "";
    message.scope = object.scope ?? "";
    message.vni = object.vni ?? "";
    message.vxlanTunnel = object.vxlanTunnel ?? "";
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
