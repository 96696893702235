// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/rest_fabrics.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Annotation } from "../models/global";
import { BgpPolicy } from "../models/routing";
import { Metadata, PortConnection } from "../models/types";

export const protobufPackage = "configd";

/** Add a new or update an existing fabric. Note that the fabricId is read-only. */
export interface Fabric {
  /** The unique id of the fabric. This is a read-only attribute. */
  fabricId: string;
  /** The name of the fabric. */
  name: string;
  /** A description of the fabric. */
  description: string;
  /** A user-defined location string (E.g. SJC20). */
  location: string;
  /** Physical address line (E.g. 320 My Street) */
  address: string;
  /** City name (E.g. San Jose) */
  city: string;
  /** Country code (E.g. US) */
  country: string;
  /** One or more labels for the fabric. */
  labels: string[];
  /** One or more annotations for the fabric. */
  annotations: Annotation[];
  /** Read-only revision data. */
  metadata: Metadata | undefined;
}

export interface GetAllFabricsRequest {
  /** Filter by one or more fabric ids and or names. */
  fabricId: string[];
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

export interface GetAllFabricsResponse {
  fabrics: Fabric[];
}

export interface GetFabricRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** Add one or more fabrics. */
export interface AddFabricsRequest {
  /** A list of one or more fabrics to add. */
  fabrics: Fabric[];
}

export interface AddFabricsResponse {
  fabrics: Fabric[];
}

/** Update the configuration of a named fabric. */
export interface UpdateFabricRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The updated fabric definition. */
  fabric: Fabric | undefined;
}

/** Delete a specific fabric. */
export interface DeleteFabricRequest {
  /** The fabric id or name. */
  fabricId: string;
}

/** Get the list of connections configuration information in a specific fabric. */
export interface GetFabricConnectionsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
}

/** Get a specific connection configuration information in a fabric. */
export interface GetFabricConnectionRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The connection id, connections are not named. */
  connectionId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
}

/** Delete all connections in a specific fabric. */
export interface DeleteFabricConnectionsRequest {
  /** The fabric id or name. */
  fabricId: string;
}

/** Delete a specific connection in a fabric. */
export interface DeleteFabricConnectionRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The connection id or port name. */
  connectionId: string;
}

/** Add one or more connections to a specific fabric. */
export interface AddFabricConnectionsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The connections for the fabric. */
  connections: PortConnection[];
}

/** Set the connections for a single fabric. */
export interface SetFabricConnectionsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The connections for the fabric. */
  connections: PortConnection[];
}

/** Set a single connection for a fabric. */
export interface SetFabricConnectionRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The connection id. */
  connectionId: string;
  /** The connections for the fabric. */
  connection: PortConnection | undefined;
}

export interface FabricConnectionsResponse {
  connections: PortConnection[];
}

export interface GetFabricBgpPoliciesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

export interface FabricBgpPoliciesResponse {
  /** A list of BGP policies */
  bgpPolicies: BgpPolicy[];
}

export interface GetFabricBgpPolicyRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The policy id or name. */
  policyId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

export interface AddFabricBgpPoliciesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The BGP Policies for the fabric. */
  bgpPolicies: BgpPolicy[];
}

export interface UpdateFabricBgpPolicyRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The policy id or name. */
  policyId: string;
  /** The updated BGP Policy definition */
  bgpPolicy: BgpPolicy | undefined;
}

export interface DeleteFabricBgpPolicyRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The policy id or name. */
  policyId: string;
}

function createBaseFabric(): Fabric {
  return {
    fabricId: "",
    name: "",
    description: "",
    location: "",
    address: "",
    city: "",
    country: "",
    labels: [],
    annotations: [],
    metadata: undefined,
  };
}

export const Fabric = {
  encode(message: Fabric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.location !== "") {
      writer.uint32(34).string(message.location);
    }
    if (message.address !== "") {
      writer.uint32(42).string(message.address);
    }
    if (message.city !== "") {
      writer.uint32(50).string(message.city);
    }
    if (message.country !== "") {
      writer.uint32(58).string(message.country);
    }
    for (const v of message.labels) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Fabric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.location = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.address = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.city = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.country = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Fabric {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      city: isSet(object.city) ? globalThis.String(object.city) : "",
      country: isSet(object.country) ? globalThis.String(object.country) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
    };
  },

  toJSON(message: Fabric): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.city !== "") {
      obj.city = message.city;
    }
    if (message.country !== "") {
      obj.country = message.country;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Fabric>, I>>(base?: I): Fabric {
    return Fabric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Fabric>, I>>(object: I): Fabric {
    const message = createBaseFabric();
    message.fabricId = object.fabricId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.location = object.location ?? "";
    message.address = object.address ?? "";
    message.city = object.city ?? "";
    message.country = object.country ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

function createBaseGetAllFabricsRequest(): GetAllFabricsRequest {
  return { fabricId: [], candidate: "", includeMetadata: false };
}

export const GetAllFabricsRequest = {
  encode(message: GetAllFabricsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fabricId) {
      writer.uint32(10).string(v!);
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(24).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllFabricsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllFabricsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllFabricsRequest {
    return {
      fabricId: globalThis.Array.isArray(object?.fabricId) ? object.fabricId.map((e: any) => globalThis.String(e)) : [],
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetAllFabricsRequest): unknown {
    const obj: any = {};
    if (message.fabricId?.length) {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllFabricsRequest>, I>>(base?: I): GetAllFabricsRequest {
    return GetAllFabricsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllFabricsRequest>, I>>(object: I): GetAllFabricsRequest {
    const message = createBaseGetAllFabricsRequest();
    message.fabricId = object.fabricId?.map((e) => e) || [];
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetAllFabricsResponse(): GetAllFabricsResponse {
  return { fabrics: [] };
}

export const GetAllFabricsResponse = {
  encode(message: GetAllFabricsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fabrics) {
      Fabric.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllFabricsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllFabricsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabrics.push(Fabric.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllFabricsResponse {
    return {
      fabrics: globalThis.Array.isArray(object?.fabrics) ? object.fabrics.map((e: any) => Fabric.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetAllFabricsResponse): unknown {
    const obj: any = {};
    if (message.fabrics?.length) {
      obj.fabrics = message.fabrics.map((e) => Fabric.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllFabricsResponse>, I>>(base?: I): GetAllFabricsResponse {
    return GetAllFabricsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllFabricsResponse>, I>>(object: I): GetAllFabricsResponse {
    const message = createBaseGetAllFabricsResponse();
    message.fabrics = object.fabrics?.map((e) => Fabric.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFabricRequest(): GetFabricRequest {
  return { fabricId: "", candidate: "", includeMetadata: false };
}

export const GetFabricRequest = {
  encode(message: GetFabricRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(24).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricRequest>, I>>(base?: I): GetFabricRequest {
    return GetFabricRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricRequest>, I>>(object: I): GetFabricRequest {
    const message = createBaseGetFabricRequest();
    message.fabricId = object.fabricId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddFabricsRequest(): AddFabricsRequest {
  return { fabrics: [] };
}

export const AddFabricsRequest = {
  encode(message: AddFabricsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fabrics) {
      Fabric.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabrics.push(Fabric.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricsRequest {
    return {
      fabrics: globalThis.Array.isArray(object?.fabrics) ? object.fabrics.map((e: any) => Fabric.fromJSON(e)) : [],
    };
  },

  toJSON(message: AddFabricsRequest): unknown {
    const obj: any = {};
    if (message.fabrics?.length) {
      obj.fabrics = message.fabrics.map((e) => Fabric.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricsRequest>, I>>(base?: I): AddFabricsRequest {
    return AddFabricsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricsRequest>, I>>(object: I): AddFabricsRequest {
    const message = createBaseAddFabricsRequest();
    message.fabrics = object.fabrics?.map((e) => Fabric.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddFabricsResponse(): AddFabricsResponse {
  return { fabrics: [] };
}

export const AddFabricsResponse = {
  encode(message: AddFabricsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fabrics) {
      Fabric.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabrics.push(Fabric.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricsResponse {
    return {
      fabrics: globalThis.Array.isArray(object?.fabrics) ? object.fabrics.map((e: any) => Fabric.fromJSON(e)) : [],
    };
  },

  toJSON(message: AddFabricsResponse): unknown {
    const obj: any = {};
    if (message.fabrics?.length) {
      obj.fabrics = message.fabrics.map((e) => Fabric.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricsResponse>, I>>(base?: I): AddFabricsResponse {
    return AddFabricsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricsResponse>, I>>(object: I): AddFabricsResponse {
    const message = createBaseAddFabricsResponse();
    message.fabrics = object.fabrics?.map((e) => Fabric.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateFabricRequest(): UpdateFabricRequest {
  return { fabricId: "", fabric: undefined };
}

export const UpdateFabricRequest = {
  encode(message: UpdateFabricRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.fabric !== undefined) {
      Fabric.encode(message.fabric, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFabricRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFabricRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabric = Fabric.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFabricRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      fabric: isSet(object.fabric) ? Fabric.fromJSON(object.fabric) : undefined,
    };
  },

  toJSON(message: UpdateFabricRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.fabric !== undefined) {
      obj.fabric = Fabric.toJSON(message.fabric);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFabricRequest>, I>>(base?: I): UpdateFabricRequest {
    return UpdateFabricRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFabricRequest>, I>>(object: I): UpdateFabricRequest {
    const message = createBaseUpdateFabricRequest();
    message.fabricId = object.fabricId ?? "";
    message.fabric = (object.fabric !== undefined && object.fabric !== null)
      ? Fabric.fromPartial(object.fabric)
      : undefined;
    return message;
  },
};

function createBaseDeleteFabricRequest(): DeleteFabricRequest {
  return { fabricId: "" };
}

export const DeleteFabricRequest = {
  encode(message: DeleteFabricRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFabricRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFabricRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteFabricRequest {
    return { fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "" };
  },

  toJSON(message: DeleteFabricRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteFabricRequest>, I>>(base?: I): DeleteFabricRequest {
    return DeleteFabricRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFabricRequest>, I>>(object: I): DeleteFabricRequest {
    const message = createBaseDeleteFabricRequest();
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseGetFabricConnectionsRequest(): GetFabricConnectionsRequest {
  return { fabricId: "", candidate: "" };
}

export const GetFabricConnectionsRequest = {
  encode(message: GetFabricConnectionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricConnectionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricConnectionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricConnectionsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
    };
  },

  toJSON(message: GetFabricConnectionsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricConnectionsRequest>, I>>(base?: I): GetFabricConnectionsRequest {
    return GetFabricConnectionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricConnectionsRequest>, I>>(object: I): GetFabricConnectionsRequest {
    const message = createBaseGetFabricConnectionsRequest();
    message.fabricId = object.fabricId ?? "";
    message.candidate = object.candidate ?? "";
    return message;
  },
};

function createBaseGetFabricConnectionRequest(): GetFabricConnectionRequest {
  return { fabricId: "", connectionId: "", candidate: "" };
}

export const GetFabricConnectionRequest = {
  encode(message: GetFabricConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.connectionId !== "") {
      writer.uint32(18).string(message.connectionId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connectionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricConnectionRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      connectionId: isSet(object.connectionId) ? globalThis.String(object.connectionId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
    };
  },

  toJSON(message: GetFabricConnectionRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.connectionId !== "") {
      obj.connectionId = message.connectionId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricConnectionRequest>, I>>(base?: I): GetFabricConnectionRequest {
    return GetFabricConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricConnectionRequest>, I>>(object: I): GetFabricConnectionRequest {
    const message = createBaseGetFabricConnectionRequest();
    message.fabricId = object.fabricId ?? "";
    message.connectionId = object.connectionId ?? "";
    message.candidate = object.candidate ?? "";
    return message;
  },
};

function createBaseDeleteFabricConnectionsRequest(): DeleteFabricConnectionsRequest {
  return { fabricId: "" };
}

export const DeleteFabricConnectionsRequest = {
  encode(message: DeleteFabricConnectionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFabricConnectionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFabricConnectionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteFabricConnectionsRequest {
    return { fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "" };
  },

  toJSON(message: DeleteFabricConnectionsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteFabricConnectionsRequest>, I>>(base?: I): DeleteFabricConnectionsRequest {
    return DeleteFabricConnectionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFabricConnectionsRequest>, I>>(
    object: I,
  ): DeleteFabricConnectionsRequest {
    const message = createBaseDeleteFabricConnectionsRequest();
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseDeleteFabricConnectionRequest(): DeleteFabricConnectionRequest {
  return { fabricId: "", connectionId: "" };
}

export const DeleteFabricConnectionRequest = {
  encode(message: DeleteFabricConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.connectionId !== "") {
      writer.uint32(18).string(message.connectionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFabricConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFabricConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connectionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteFabricConnectionRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      connectionId: isSet(object.connectionId) ? globalThis.String(object.connectionId) : "",
    };
  },

  toJSON(message: DeleteFabricConnectionRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.connectionId !== "") {
      obj.connectionId = message.connectionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteFabricConnectionRequest>, I>>(base?: I): DeleteFabricConnectionRequest {
    return DeleteFabricConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFabricConnectionRequest>, I>>(
    object: I,
  ): DeleteFabricConnectionRequest {
    const message = createBaseDeleteFabricConnectionRequest();
    message.fabricId = object.fabricId ?? "";
    message.connectionId = object.connectionId ?? "";
    return message;
  },
};

function createBaseAddFabricConnectionsRequest(): AddFabricConnectionsRequest {
  return { fabricId: "", connections: [] };
}

export const AddFabricConnectionsRequest = {
  encode(message: AddFabricConnectionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.connections) {
      PortConnection.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricConnectionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricConnectionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connections.push(PortConnection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricConnectionsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      connections: globalThis.Array.isArray(object?.connections)
        ? object.connections.map((e: any) => PortConnection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddFabricConnectionsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.connections?.length) {
      obj.connections = message.connections.map((e) => PortConnection.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricConnectionsRequest>, I>>(base?: I): AddFabricConnectionsRequest {
    return AddFabricConnectionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricConnectionsRequest>, I>>(object: I): AddFabricConnectionsRequest {
    const message = createBaseAddFabricConnectionsRequest();
    message.fabricId = object.fabricId ?? "";
    message.connections = object.connections?.map((e) => PortConnection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetFabricConnectionsRequest(): SetFabricConnectionsRequest {
  return { fabricId: "", connections: [] };
}

export const SetFabricConnectionsRequest = {
  encode(message: SetFabricConnectionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.connections) {
      PortConnection.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetFabricConnectionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetFabricConnectionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connections.push(PortConnection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetFabricConnectionsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      connections: globalThis.Array.isArray(object?.connections)
        ? object.connections.map((e: any) => PortConnection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetFabricConnectionsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.connections?.length) {
      obj.connections = message.connections.map((e) => PortConnection.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetFabricConnectionsRequest>, I>>(base?: I): SetFabricConnectionsRequest {
    return SetFabricConnectionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetFabricConnectionsRequest>, I>>(object: I): SetFabricConnectionsRequest {
    const message = createBaseSetFabricConnectionsRequest();
    message.fabricId = object.fabricId ?? "";
    message.connections = object.connections?.map((e) => PortConnection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetFabricConnectionRequest(): SetFabricConnectionRequest {
  return { fabricId: "", connectionId: "", connection: undefined };
}

export const SetFabricConnectionRequest = {
  encode(message: SetFabricConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.connectionId !== "") {
      writer.uint32(18).string(message.connectionId);
    }
    if (message.connection !== undefined) {
      PortConnection.encode(message.connection, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetFabricConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetFabricConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connectionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.connection = PortConnection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetFabricConnectionRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      connectionId: isSet(object.connectionId) ? globalThis.String(object.connectionId) : "",
      connection: isSet(object.connection) ? PortConnection.fromJSON(object.connection) : undefined,
    };
  },

  toJSON(message: SetFabricConnectionRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.connectionId !== "") {
      obj.connectionId = message.connectionId;
    }
    if (message.connection !== undefined) {
      obj.connection = PortConnection.toJSON(message.connection);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetFabricConnectionRequest>, I>>(base?: I): SetFabricConnectionRequest {
    return SetFabricConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetFabricConnectionRequest>, I>>(object: I): SetFabricConnectionRequest {
    const message = createBaseSetFabricConnectionRequest();
    message.fabricId = object.fabricId ?? "";
    message.connectionId = object.connectionId ?? "";
    message.connection = (object.connection !== undefined && object.connection !== null)
      ? PortConnection.fromPartial(object.connection)
      : undefined;
    return message;
  },
};

function createBaseFabricConnectionsResponse(): FabricConnectionsResponse {
  return { connections: [] };
}

export const FabricConnectionsResponse = {
  encode(message: FabricConnectionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.connections) {
      PortConnection.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricConnectionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricConnectionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connections.push(PortConnection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricConnectionsResponse {
    return {
      connections: globalThis.Array.isArray(object?.connections)
        ? object.connections.map((e: any) => PortConnection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FabricConnectionsResponse): unknown {
    const obj: any = {};
    if (message.connections?.length) {
      obj.connections = message.connections.map((e) => PortConnection.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricConnectionsResponse>, I>>(base?: I): FabricConnectionsResponse {
    return FabricConnectionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricConnectionsResponse>, I>>(object: I): FabricConnectionsResponse {
    const message = createBaseFabricConnectionsResponse();
    message.connections = object.connections?.map((e) => PortConnection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFabricBgpPoliciesRequest(): GetFabricBgpPoliciesRequest {
  return { fabricId: "", candidate: "", includeMetadata: false };
}

export const GetFabricBgpPoliciesRequest = {
  encode(message: GetFabricBgpPoliciesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(24).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricBgpPoliciesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricBgpPoliciesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricBgpPoliciesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricBgpPoliciesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricBgpPoliciesRequest>, I>>(base?: I): GetFabricBgpPoliciesRequest {
    return GetFabricBgpPoliciesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricBgpPoliciesRequest>, I>>(object: I): GetFabricBgpPoliciesRequest {
    const message = createBaseGetFabricBgpPoliciesRequest();
    message.fabricId = object.fabricId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseFabricBgpPoliciesResponse(): FabricBgpPoliciesResponse {
  return { bgpPolicies: [] };
}

export const FabricBgpPoliciesResponse = {
  encode(message: FabricBgpPoliciesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.bgpPolicies) {
      BgpPolicy.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricBgpPoliciesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricBgpPoliciesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bgpPolicies.push(BgpPolicy.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricBgpPoliciesResponse {
    return {
      bgpPolicies: globalThis.Array.isArray(object?.bgpPolicies)
        ? object.bgpPolicies.map((e: any) => BgpPolicy.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FabricBgpPoliciesResponse): unknown {
    const obj: any = {};
    if (message.bgpPolicies?.length) {
      obj.bgpPolicies = message.bgpPolicies.map((e) => BgpPolicy.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricBgpPoliciesResponse>, I>>(base?: I): FabricBgpPoliciesResponse {
    return FabricBgpPoliciesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricBgpPoliciesResponse>, I>>(object: I): FabricBgpPoliciesResponse {
    const message = createBaseFabricBgpPoliciesResponse();
    message.bgpPolicies = object.bgpPolicies?.map((e) => BgpPolicy.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFabricBgpPolicyRequest(): GetFabricBgpPolicyRequest {
  return { fabricId: "", policyId: "", candidate: "", includeMetadata: false };
}

export const GetFabricBgpPolicyRequest = {
  encode(message: GetFabricBgpPolicyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.policyId !== "") {
      writer.uint32(18).string(message.policyId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricBgpPolicyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricBgpPolicyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.policyId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricBgpPolicyRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      policyId: isSet(object.policyId) ? globalThis.String(object.policyId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricBgpPolicyRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.policyId !== "") {
      obj.policyId = message.policyId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricBgpPolicyRequest>, I>>(base?: I): GetFabricBgpPolicyRequest {
    return GetFabricBgpPolicyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricBgpPolicyRequest>, I>>(object: I): GetFabricBgpPolicyRequest {
    const message = createBaseGetFabricBgpPolicyRequest();
    message.fabricId = object.fabricId ?? "";
    message.policyId = object.policyId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddFabricBgpPoliciesRequest(): AddFabricBgpPoliciesRequest {
  return { fabricId: "", bgpPolicies: [] };
}

export const AddFabricBgpPoliciesRequest = {
  encode(message: AddFabricBgpPoliciesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.bgpPolicies) {
      BgpPolicy.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricBgpPoliciesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricBgpPoliciesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bgpPolicies.push(BgpPolicy.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricBgpPoliciesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      bgpPolicies: globalThis.Array.isArray(object?.bgpPolicies)
        ? object.bgpPolicies.map((e: any) => BgpPolicy.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddFabricBgpPoliciesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.bgpPolicies?.length) {
      obj.bgpPolicies = message.bgpPolicies.map((e) => BgpPolicy.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricBgpPoliciesRequest>, I>>(base?: I): AddFabricBgpPoliciesRequest {
    return AddFabricBgpPoliciesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricBgpPoliciesRequest>, I>>(object: I): AddFabricBgpPoliciesRequest {
    const message = createBaseAddFabricBgpPoliciesRequest();
    message.fabricId = object.fabricId ?? "";
    message.bgpPolicies = object.bgpPolicies?.map((e) => BgpPolicy.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateFabricBgpPolicyRequest(): UpdateFabricBgpPolicyRequest {
  return { fabricId: "", policyId: "", bgpPolicy: undefined };
}

export const UpdateFabricBgpPolicyRequest = {
  encode(message: UpdateFabricBgpPolicyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.policyId !== "") {
      writer.uint32(18).string(message.policyId);
    }
    if (message.bgpPolicy !== undefined) {
      BgpPolicy.encode(message.bgpPolicy, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFabricBgpPolicyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFabricBgpPolicyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.policyId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.bgpPolicy = BgpPolicy.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFabricBgpPolicyRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      policyId: isSet(object.policyId) ? globalThis.String(object.policyId) : "",
      bgpPolicy: isSet(object.bgpPolicy) ? BgpPolicy.fromJSON(object.bgpPolicy) : undefined,
    };
  },

  toJSON(message: UpdateFabricBgpPolicyRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.policyId !== "") {
      obj.policyId = message.policyId;
    }
    if (message.bgpPolicy !== undefined) {
      obj.bgpPolicy = BgpPolicy.toJSON(message.bgpPolicy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFabricBgpPolicyRequest>, I>>(base?: I): UpdateFabricBgpPolicyRequest {
    return UpdateFabricBgpPolicyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFabricBgpPolicyRequest>, I>>(object: I): UpdateFabricBgpPolicyRequest {
    const message = createBaseUpdateFabricBgpPolicyRequest();
    message.fabricId = object.fabricId ?? "";
    message.policyId = object.policyId ?? "";
    message.bgpPolicy = (object.bgpPolicy !== undefined && object.bgpPolicy !== null)
      ? BgpPolicy.fromPartial(object.bgpPolicy)
      : undefined;
    return message;
  },
};

function createBaseDeleteFabricBgpPolicyRequest(): DeleteFabricBgpPolicyRequest {
  return { fabricId: "", policyId: "" };
}

export const DeleteFabricBgpPolicyRequest = {
  encode(message: DeleteFabricBgpPolicyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.policyId !== "") {
      writer.uint32(18).string(message.policyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFabricBgpPolicyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFabricBgpPolicyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.policyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteFabricBgpPolicyRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      policyId: isSet(object.policyId) ? globalThis.String(object.policyId) : "",
    };
  },

  toJSON(message: DeleteFabricBgpPolicyRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.policyId !== "") {
      obj.policyId = message.policyId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteFabricBgpPolicyRequest>, I>>(base?: I): DeleteFabricBgpPolicyRequest {
    return DeleteFabricBgpPolicyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFabricBgpPolicyRequest>, I>>(object: I): DeleteFabricBgpPolicyRequest {
    const message = createBaseDeleteFabricBgpPolicyRequest();
    message.fabricId = object.fabricId ?? "";
    message.policyId = object.policyId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
