import { useEffect, useMemo, useState } from "react";
import { CliCommandResponse, ResultFormat } from "src/gen/schema/bilge/api";
import "./mockShellOutput.scss";
import { Card } from "@magnetic/card";
import Papa from "papaparse";
import { StringColumn } from "src/components/tables/columnConfigs/stringColumn";
import TypedTable from "src/components/typedTable";

interface MockShellOutputProps {
  readonly loading?: boolean;
  readonly cmdResult?: CliCommandResponse;
}

interface CsvData {
  [key: string]: string;
}

const MockShellCsv = (props: MockShellOutputProps) => {
  const { cmdResult } = props;
  const [tableData, setTableData] = useState<CsvData[]>([]);
  const [tableColumn, setTableColumn] =
    useState<Record<string, StringColumn>>();

  useEffect(() => {
    if (cmdResult?.executionResults) {
      const resultCSV = atob(cmdResult.executionResults);
      Papa.parse<CsvData>(resultCSV, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          if (result.data && result.data.length > 0) {
            const columns = Object.keys(result.data[0] as CsvData).reduce(
              (acc: Record<string, StringColumn>, key) => {
                acc[key] = new StringColumn({
                  label: [key]
                });
                return acc;
              },
              {}
            );
            setTableColumn(columns);
            setTableData(result.data);
          }
        }
      });
    }
  }, [cmdResult?.executionResults]);

  if (!cmdResult?.executionResults) {
    return null;
  }

  return (
    <div className="mock-shell-output-table-container">
      {tableColumn && (
        <TypedTable
          name="mock-shell-out-table"
          columns={tableColumn}
          data={tableData}
        />
      )}
    </div>
  );
};

const MockShellText = (props: MockShellOutputProps) => {
  const { cmdResult } = props;
  const [formattedText, setFormattedText] = useState<string>("");

  useEffect(() => {
    if (cmdResult?.executionResults) {
      const result = atob(cmdResult.executionResults);
      setFormattedText(result);
    }
  }, [cmdResult?.executionResults]);
  return <pre>{formattedText}</pre>;
};

const MockShellHelpText = (props: MockShellOutputProps) => {
  const { cmdResult } = props;
  const formattedHelpText = () => {
    if (cmdResult?.commandCompletions) {
      return cmdResult?.commandCompletions.map((cmd) => {
        return (
          <div key={`${cmd.keyword}`} className="mock-shell-output-help">
            <div className="mock-shell-output-help-key">{`<${cmd.keyword}>`}</div>
            <div className="mock-shell-output-help">{cmd.helpString}</div>
          </div>
        );
      });
    }
    return "";
  };
  return (
    <Card className="mock-shell-output-help-container">
      {formattedHelpText()}
    </Card>
  );
};

const DefaultResult = () => {
  return "";
};

export const MockShellOutput = (props: MockShellOutputProps) => {
  const { cmdResult } = props;
  const MockShellOutputComponent = useMemo(() => {
    if (
      cmdResult?.commandCompletions &&
      cmdResult?.commandCompletions.length > 0
    ) {
      return MockShellHelpText;
    }
    switch (cmdResult?.resultFormat) {
      // Backend needs to fix the resultFormat type returned for Text format here
      case ResultFormat.TEXT:
        return MockShellText;
      case ResultFormat.CSV:
        return MockShellCsv;
      default:
        return DefaultResult;
    }
  }, [cmdResult]);
  return (
    <>{cmdResult ? <MockShellOutputComponent cmdResult={cmdResult} /> : ""}</>
  );
};
